const emptySchema = `
type Query {
  noSchema: String
}
schema {
  query: Query
}
`

export default emptySchema
