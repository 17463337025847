import { RefObject, useCallback, DragEvent } from 'react'
import { debounce } from 'ts-debounce'

export type DraggedListItem = {
  id: string
  parentId?: string
  index: number
}

const useDndReordering = (
  ref: RefObject<HTMLUListElement | HTMLLIElement>,
  onChangeOrder?: (
    sourceItem: DraggedListItem,
    targetItem: DraggedListItem,
  ) => void,
): {
  onDropList: () => void
  onDragStartList: () => void
  onDragStart: () => void
  onDragEnter: (event: DragEvent<HTMLLIElement>) => void
  onDragLeave: (event: DragEvent<HTMLLIElement>) => void
  onDragOver: (event: DragEvent<HTMLLIElement>) => void
  onDragEnd: () => void
} => {
  const getDraggedData = useCallback(
    (
      draggedDir: 'from' | 'to',
      elements: HTMLLIElement[],
    ): DraggedListItem | undefined => {
      const listElement = ref.current as HTMLUListElement
      const draggedElement = listElement.querySelector<HTMLLIElement>(
        `li.dragged-${draggedDir}`,
      )
      if (!draggedElement) {
        return undefined
      }

      draggedElement.classList.remove('dragged-to', 'dragged-from')

      const { id, parentId } = draggedElement.dataset
      if (!id) {
        return undefined
      }

      return {
        id,
        parentId:
          draggedDir === 'to' && draggedElement.classList.contains('inside')
            ? id
            : parentId,
        index: elements
          .filter(
            (i) =>
              Boolean(i.getAttribute('draggable')) === true &&
              i.dataset.parentId === parentId,
          )
          .findIndex((i) => i.dataset.id === id),
      }
    },
    [ref],
  )

  const onDropList = useCallback(() => {
    const listElement = ref.current as HTMLUListElement
    if (listElement && listElement.classList.contains('dragging')) {
      listElement.classList.remove('dragging')
      const elements = [...Array.from(listElement.querySelectorAll('li'))]
      const draggedFrom = getDraggedData('from', elements)
      const draggedTo = getDraggedData('to', elements)

      if (draggedFrom && draggedTo && onChangeOrder) {
        onChangeOrder(draggedFrom, draggedTo)
      }
    }
  }, [ref, getDraggedData, onChangeOrder])

  const onDragStartList = useCallback(() => {
    ;(ref.current as HTMLElement).classList.add('dragging')
  }, [ref])

  const onDragStart = useCallback(() => {
    ;(ref.current as HTMLElement).classList.add('dragged-from')
  }, [ref])

  // Remove reordering class names from the hovered element
  const clearDraggedTo = useCallback(() => {
    const elements = ref.current?.parentNode?.querySelectorAll('li.dragged-to')
    if (elements) {
      elements.forEach((element) =>
        element.classList.remove('dragged-to', 'inside', 'last-item'),
      )
    }
  }, [ref])

  const onDragEnter = useCallback(
    (event: DragEvent<HTMLLIElement>) => {
      event.preventDefault()
      clearDraggedTo()
    },
    [clearDraggedTo],
  )

  const onDragLeave = useCallback(
    (event: DragEvent<HTMLLIElement>) => {
      event.preventDefault()
      const rect = ref.current?.getBoundingClientRect()
      if (
        rect &&
        (event.clientY < rect.top ||
          event.clientY >= rect.bottom ||
          event.clientX < rect.left ||
          event.clientX >= rect.right)
      ) {
        clearDraggedTo()
      }
    },
    [ref, clearDraggedTo],
  )

  const onDragOverDebounce = debounce((clientY: number) => {
    clearDraggedTo()
    const element = ref.current as HTMLElement
    const draggedFromElement = element?.parentNode?.querySelector(
      'li.dragged-from',
    ) as HTMLLIElement
    if (!element || !draggedFromElement) {
      return
    }

    const { id, parentId } = element.dataset
    const draggedFromData = draggedFromElement.dataset

    // Restrict dragging parent/element into itself
    if (draggedFromData.id === parentId || draggedFromData.id === id) {
      return
    }

    const elementRect = element.getBoundingClientRect()
    const isBottomPart = clientY > elementRect.bottom - 15
    // Toggle 'inside' class name if an element is a group
    element.classList.toggle(
      'inside',
      element.classList.contains('group') &&
        !element.parentNode?.querySelector(`li[data-parent-id="${id}"]`) &&
        isBottomPart &&
        draggedFromData.parentId !== id,
    )

    const nextElement = element.nextSibling as HTMLLIElement
    // Toggle 'last-item' class name if an element is last item on the current level
    element.classList.toggle(
      'last-item',
      isBottomPart && nextElement?.dataset.parentId !== parentId,
    )

    if (element.classList.contains('dragged-to')) {
      return
    }

    element.classList.add('dragged-to')
  }, 5)

  const onDragOver = useCallback(
    (event: DragEvent<HTMLLIElement>) => {
      event.preventDefault()
      onDragOverDebounce(event.clientY)
    },
    [onDragOverDebounce],
  )

  const onDragEnd = useCallback(() => {
    clearDraggedTo()
  }, [clearDraggedTo])

  return {
    onDropList,
    onDragStartList,
    onDragStart,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDragEnd,
  }
}

export default useDndReordering
