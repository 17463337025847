import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import tabsStateSlice from './tabs/slice'
import settingsStateSlice from './settings/slice'
import currentItemSlice from './current-item/slice'

export const uiStateReducer = persistReducer(
  {
    key: 'uiState',
    version: 1,
    storage,
    // migrations can be implemented, see:
    // https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
    // migrate: createMigrate(migrations as any, { debug: false }),
  },
  combineReducers({
    tabsState: tabsStateSlice.reducer,
    settingsState: settingsStateSlice.reducer,
    currentItemState: currentItemSlice.reducer,
  }),
)

export type UIState = ReturnType<typeof uiStateReducer>
