import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex, Box, IconButton, styled } from '@rapidapi/ui-lib'

import {
  deleteHttpExchangeListAction,
  httpExchangesActions,
} from 'store/actions'
import {
  selectHttpExchangeForCurrentRequest,
  selectHttpExchangeListForCurrentRequest,
  selectIndexOfCurrentHttpExchange,
} from 'store/selectors'

import { TabBarContainer } from 'ecosystems/tab-bar-container'

import { HttpExchangeHistoryMenu } from '../http-exchange-history-menu'
import { HttpExchangeInfoTab } from '../http-exchange-tabs'
import HttpExchangeMessageTabsContainer from './http-exchange-message-tabs-container'

const HttpExchangePanelStyled = styled(Flex)({
  position: 'relative',
})

const HttpExchangeButtonGroup = styled(Box)({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 10,
})

const TabContentContainer = styled(Flex)({
  flexBasis: 0,
  flexGrow: 1,
  overflow: 'hidden',
  flexDirection: 'column',
  justifyContent: 'stretch',
})

interface HttpExchangeTabsContainerProps {
  renderHttpExchangeButtonGroup?: () => JSX.Element
}

const HttpExchangeTabsContainer: React.FC<HttpExchangeTabsContainerProps> = ({
  renderHttpExchangeButtonGroup,
}) => {
  const httpExchange = useSelector(selectHttpExchangeForCurrentRequest)
  const httpExchangeList =
    useSelector(selectHttpExchangeListForCurrentRequest) || []
  const currentIndex = useSelector(selectIndexOfCurrentHttpExchange)
  const requestUuid = httpExchange?.requestUuid

  const config = useMemo(
    () => ({
      identifier: `httpExchangeTab/${httpExchange?.uuid}`,
      defaultTab: 'response',
      tabs: [
        { key: 'info', label: 'Info' },
        { key: 'request', label: 'Request' },
        { key: 'response', label: 'Response' },
      ],
    }),
    [httpExchange],
  )

  const dispatch = useDispatch()

  const prevHistory = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (requestUuid) {
        dispatch(
          httpExchangesActions.moveCursorCurrentHttpExchange({
            requestUuid,
            diff: -1,
          }),
        )
      }
    },
    [dispatch, requestUuid],
  )

  const nextHistory = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (requestUuid) {
        dispatch(
          httpExchangesActions.moveCursorCurrentHttpExchange({
            requestUuid,
            diff: 1,
          }),
        )
      }
    },
    [dispatch, requestUuid],
  )

  const clearHistory = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (requestUuid) {
        dispatch(
          deleteHttpExchangeListAction({
            requestUuid,
          }),
        )
      }
    },
    [dispatch, requestUuid],
  )

  const setCurrentHttpExchange = useCallback(
    (httpExchangeUuid: string) => {
      if (requestUuid && httpExchangeUuid) {
        dispatch(
          httpExchangesActions.setCurrentHttpExchange({
            requestUuid,
            httpExchangeUuid,
          }),
        )
      }
    },
    [dispatch, requestUuid],
  )

  const renderTabMemo = useCallback(
    (tabValue: string) => (
      <TabContentContainer mt={5}>
        {tabValue === 'info' && <HttpExchangeInfoTab />}
        {tabValue === 'request' && (
          <HttpExchangeMessageTabsContainer messageType="request" />
        )}
        {tabValue === 'response' && (
          <HttpExchangeMessageTabsContainer messageType="response" />
        )}
      </TabContentContainer>
    ),
    [],
  )

  return (
    <HttpExchangePanelStyled
      mt={2}
      flexBasis={0}
      flexGrow={1}
      overflow="hidden"
      flexDirection="column"
    >
      <HttpExchangeButtonGroup mr={5}>
        {renderHttpExchangeButtonGroup && renderHttpExchangeButtonGroup()}
        {!renderHttpExchangeButtonGroup && (
          <>
            <IconButton
              icon="chevron-left"
              onClick={prevHistory}
              disabled={currentIndex <= 0}
            />
            <IconButton
              icon="chevron-right"
              onClick={nextHistory}
              disabled={
                currentIndex < 0 || currentIndex >= httpExchangeList.length - 1
              }
            />
            {httpExchangeList.length > 0 && (
              <HttpExchangeHistoryMenu
                httpExchangeList={httpExchangeList}
                setCurrentHttpExchange={setCurrentHttpExchange}
              />
            )}
            <IconButton color="error" icon="trash" onClick={clearHistory} />
          </>
        )}
      </HttpExchangeButtonGroup>
      <TabBarContainer
        tabAlign="flex-start"
        config={config}
        renderTab={renderTabMemo}
        flexBasis={0}
        flexGrow={1}
        overflow="hidden"
        display="flex"
        flexDirection="column"
        tabBarProps={{ px: 5 }}
      />
    </HttpExchangePanelStyled>
  )
}

export default HttpExchangeTabsContainer
