const reorderListItem = <T>(
  list: T[],
  fromIndex: number,
  toIndex: number,
): T[] => {
  const newList = [...list]
  const item = newList[fromIndex]
  newList.splice(fromIndex, 1)
  newList.splice(toIndex, 0, item)
  return newList
}

export default reorderListItem
