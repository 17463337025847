import { createSelector } from '@reduxjs/toolkit'
import type { RequestHandling } from 'lib/request-handling/types.d'
import type { RootState } from 'store/root-reducer'
import { selectCurrentTreeItemRef } from 'store/slices/ui/selectors'
import type { HTTPExchangeList, HTTPExchangesState } from './types.d'

export const selectHttpExchanges = (state: RootState): HTTPExchangesState =>
  state.httpExchanges

/**
 * A "selector factory" that creates a selector for HTTP exchanges of a given
 * request.
 * Note: for performance reasons, selector factories should be memoized
 * in the component that is calling it.
 * @param requestUuid An array of object refs
 * @returns A selector for the HTTP exchanges matching the given request
 */
export const selectHttpExchangesForRequestFactory = (
  requestUuid: string,
): ((state: RootState) => HTTPExchangeList) =>
  createSelector(
    selectHttpExchanges,
    (httpExchanges) => httpExchanges.httpExchanges[requestUuid],
  )

export const selectHttpExchangeListForCurrentRequest = createSelector(
  selectHttpExchanges,
  selectCurrentTreeItemRef,
  (httpExchanges, treeItemRef): RequestHandling.HTTPExchange[] | null => {
    const requestUuid = treeItemRef?.ref
    if (!requestUuid) {
      return null
    }
    return httpExchanges.httpExchanges[requestUuid] || null
  },
)

export const selectHttpExchangeUuidForCurrentRequest = createSelector(
  selectHttpExchanges,
  selectCurrentTreeItemRef,
  (httpExchanges, treeItemRef): string | null => {
    const requestUuid = treeItemRef?.ref
    if (!requestUuid) {
      return null
    }
    return httpExchanges.currentHttpExchange[requestUuid] || null
  },
)

export const selectHttpExchangeForCurrentRequest = createSelector(
  selectHttpExchanges,
  selectCurrentTreeItemRef,
  selectHttpExchangeUuidForCurrentRequest,
  (httpExchanges, treeItemRef, uuid): RequestHandling.HTTPExchange | null => {
    const requestUuid = treeItemRef?.ref
    if (!requestUuid) {
      return null
    }
    if (!uuid) {
      return null
    }
    return (
      httpExchanges.httpExchanges[requestUuid]?.find(
        (item) => item.uuid === uuid,
      ) || null
    )
  },
)

export const selectIndexOfCurrentHttpExchange = createSelector(
  selectHttpExchangeListForCurrentRequest,
  selectHttpExchangeUuidForCurrentRequest,
  (httpExchanges, httpExchangeUuid): number => {
    if (!httpExchanges) {
      return -1
    }
    return httpExchanges.findIndex(({ uuid }) => uuid === httpExchangeUuid)
  },
)
