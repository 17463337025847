import config from 'config'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAppUpdateNotifier } from 'store/selectors'
import type { AppUpdateNotifierState } from 'store/slices/ui/settings/types.d'
import { settingsStateActions } from 'store/actions'
import useAppUpdateHooks from './app-update-hooks'
import AppUpdateViewSwitcher from './app-update-view'
import type { IPCRendererChannelType } from './app-update-constants'
import { ipcRendererChannel } from './app-update-constants'

const { CHNL_CHECK_UPDATE } = ipcRendererChannel

const AppUpdateNotifier: React.FC = () => {
  const dispatch = useDispatch()
  const appUpdateContext = useSelector(selectAppUpdateNotifier)

  const {
    applyUpdate,
    checkForUpdates,
    dismiss,
    downloadUpdate,
    ipcRenderer,
    retryUpdate,
  } = useAppUpdateHooks()

  useEffect(() => {
    if (!ipcRenderer || config.build !== 'desktop') {
      return undefined
    }

    ;(async () => {
      const data = await ipcRenderer.appUpdate<
        IPCRendererChannelType,
        AppUpdateNotifierState
      >(CHNL_CHECK_UPDATE)
      dispatch(settingsStateActions.setAppUpdateNotifierContext(data))
    })()
    return () =>
      ipcRenderer.removeListeners<IPCRendererChannelType>(CHNL_CHECK_UPDATE)
  }, [dispatch, ipcRenderer])

  if (!ipcRenderer || !appUpdateContext) {
    return null
  }

  const { status, latest, error } = appUpdateContext
  return (
    <AppUpdateViewSwitcher
      {...{
        applyUpdate,
        checkForUpdates,
        dismiss,
        downloadUpdate,
        error,
        latest,
        retryUpdate,
        status,
      }}
    />
  )
}

export default AppUpdateNotifier
