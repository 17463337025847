import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectAgentStatus } from 'store/selectors'

import type { AppFetch } from './app-fetch-types.d'
import getAppFetch from './get-app-fetch'

const useAppFetch = <T = unknown>(): AppFetch.AppFetch<T> => {
  // check if Paw Agent is active
  const { active: agentActive, installed: agentInstalled } =
    useSelector(selectAgentStatus)
  const usePawAgent = agentActive && agentInstalled

  // get the appropriate appFetch function
  const appFetch = useMemo(() => getAppFetch<T>(!!usePawAgent), [usePawAgent])

  return appFetch
}

export default useAppFetch
