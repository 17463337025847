import React from 'react'

import { TabBoxContainer } from 'ecosystems/http-exchange-viewer/http-exchange-tabs'
import { JsonTreeEditor } from 'ecosystems/field-editors/json-tree-editor'
import { selectCurrentRequestBodyDynamicValueRef } from 'store/selectors'
import { useSelector } from 'react-redux'

const BodyTypeJsonTree: React.FC = () => {
  const onlyDvRef = useSelector(selectCurrentRequestBodyDynamicValueRef)
  if (!onlyDvRef) {
    return null
  }
  return (
    <TabBoxContainer p={5} scrollable>
      <JsonTreeEditor objectRef={onlyDvRef} objectProperty="json" />
    </TabBoxContainer>
  )
}

export default BodyTypeJsonTree
