import React, { useState, useCallback } from 'react'
import { IconButton, Menu, MenuItem } from '@rapidapi/ui-lib'

import type { RequestHandling } from 'lib/request-handling/types.d'

export type HttpExchangeHistoryMenuProps = {
  httpExchangeList: RequestHandling.HTTPExchange[]
  setCurrentHttpExchange?: (httpExchangeUuid: string) => void
}

const HttpExchangeHistoryMenu: React.FC<HttpExchangeHistoryMenuProps> = ({
  httpExchangeList,
  setCurrentHttpExchange,
}) => {
  const [historyButtonElement, setHistoryButtonElement] =
    useState<Element | null>(null)

  const onIconButtonClick = useCallback(
    ({ currentTarget }: React.MouseEvent) =>
      setHistoryButtonElement(historyButtonElement ? null : currentTarget),
    [historyButtonElement],
  )

  const onClose = useCallback(() => {
    setHistoryButtonElement(null)
  }, [])

  const onMenuItemClick = useCallback(
    (httpExchangeUuid: string) => {
      if (setCurrentHttpExchange) {
        setCurrentHttpExchange(httpExchangeUuid)
      }
    },
    [setCurrentHttpExchange],
  )

  return (
    <>
      <IconButton icon="clock" onClick={onIconButtonClick} />
      <Menu
        popoverProps={{
          placement: 'bottom-end',
          open: Boolean(historyButtonElement),
          anchorEl: historyButtonElement,
          onClose,
        }}
      >
        {httpExchangeList.map((item) => (
          <MenuItem key={item.uuid} onSelect={() => onMenuItemClick(item.uuid)}>
            {item.response
              ? `${item.response.status} ${item.response.statusText}`
              : 'Error'}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default HttpExchangeHistoryMenu
