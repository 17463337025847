import {
  useStore as reduxUseStore,
  useDispatch as reduxUseDispatch,
  useSelector as reduxUseSelector,
} from 'react-redux'
import type { AppStore } from '../store'

type State = ReturnType<AppStore['getState']>

export const useStore = (): AppStore => reduxUseStore()

export const useDispatch: () => AppStore['dispatch'] = reduxUseDispatch

export const useSelector: <TSelected = unknown>(
  selector: (state: State) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean,
) => TSelected = reduxUseSelector
