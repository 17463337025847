/* eslint-disable react/jsx-props-no-spreading */

import { forwardRef } from 'react'
import { withTheme } from '@emotion/react'
import {
  space,
  layout,
  typography,
  color,
  flexbox,
  shadow,
} from 'styled-system'
import styled from '@emotion/styled'
import { BoxComponentProps } from './box.types'

const BoxStyled = withTheme(
  styled.div<BoxComponentProps>(
    space,
    layout,
    typography,
    color,
    flexbox,
    shadow,
    {
      boxSizing: 'border-box',
    },
  ),
)

export const Box = forwardRef<HTMLDivElement, BoxComponentProps>(
  ({ children, ...other }, forwardedRef) => (
    <BoxStyled ref={forwardedRef} {...other}>
      {children}
    </BoxStyled>
  ),
)

export default Box
