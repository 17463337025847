/* eslint-disable react/jsx-props-no-spreading */

import { isValidElement } from 'react'
import { Button, ButtonComponentProps } from '../../inputs/button'
import { Typography } from '../../data-display/typography'
import { Icon } from '../../data-display/icon'
import { Box } from '../../layout/box'
import { Flex } from '../../layout/flex'
import { NotificationComponentProps } from './notification.types'

export const NotificationAction = (props: ButtonComponentProps) => (
  <Button
    css={({ fontWeights }) => ({
      fontWeight: fontWeights.medium,
      color: 'inherit',
    })}
    ml={2}
    size="small"
    variant="text"
    {...props}
  />
)

const Notification: React.FC<NotificationComponentProps> = ({
  actions,
  children,
  displayInline = false,
  icon,
  title,
  variant = 'default',
  width = 'fit-content',
  onDismiss,
  ...other
}) => (
  <Flex
    alignItems="center"
    css={({ radii, colors }) => [
      {
        backgroundColor: colors.backgrounds.backgroundElevated1,
        borderRadius: radii.default,
        boxSizing: 'border-box',
        borderWidth: 1.5,
        borderStyle: 'solid',
      },
      variant === 'default'
        ? {
            backgroundColor: colors.backgrounds.backgroundSecondary,
            borderColor: colors.borders.borderDefault,
            color: colors.texts.highEmphasis,
          }
        : {},
      variant === 'error'
        ? {
            borderColor: colors.borders.borderDanger,
            color: colors.texts.textDanger,
          }
        : {},
      variant === 'success'
        ? {
            borderColor: colors.borders.borderSuccess,
            color: colors.texts.textSuccess,
          }
        : {},
      variant === 'info'
        ? {
            borderColor: colors.borders.borderAcent,
            color: colors.texts.textInfo,
          }
        : {},
    ]}
    justifyContent="space-between"
    m={1}
    minHeight={52}
    paddingX={4}
    paddingY="10px"
    width={width}
    {...other}
  >
    {icon && <Icon color="inherit" mr={4} size={20} symbol={icon} />}
    <Box minWidth="auto" flex={1} pl={icon ? 2 : 1} pr={2}>
      {title && (
        <Typography
          display={displayInline ? 'inline' : undefined}
          variant="body2"
          color="inherit"
          fontWeight="bold"
          mr={1}
        >
          {title}
        </Typography>
      )}
      {children &&
        (isValidElement(children) ? (
          children
        ) : (
          <Typography
            variant="body2"
            color="inherit"
            display={displayInline ? 'inline' : undefined}
          >
            {children}
          </Typography>
        ))}
    </Box>
    {(actions || onDismiss) && (
      <Flex justifyContent="space-between" alignItems="center" ml={2}>
        {actions?.map((action, idx) => (
          <NotificationAction key={idx} {...action} />
        ))}
        {onDismiss && (
          <Box
            alignSelf="stretch"
            as="button"
            css={{
              alignItems: 'center',
              backgroundColor: 'transparent',
              border: 0,
              boxSizing: 'border-box',
              color: 'inherit',
              cursor: 'pointer',
              display: 'inline-flex',
              justifyContent: 'center',
              padding: 0,
            }}
            ml={2}
            mr={-2}
            width={36}
            onClick={onDismiss}
          >
            <Icon color="inherit" size={20} symbol="close" />
          </Box>
        )}
      </Flex>
    )}
  </Flex>
)

export default Notification
