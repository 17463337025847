import type { Project } from 'lib/project/types.d'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'
import { filterArrayNonNull } from 'lib/cloud-sync/loader/helpers'

import serializeObjectRef from './serialize-object-ref'

const serializeAnything = (
  serializer: CloudSerializer.ProjectSerializer,
  object: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  // primitives
  if (
    typeof object === 'string' ||
    typeof object === 'number' ||
    typeof object === 'boolean'
  ) {
    return object
  }

  // arrays, it can be anything, just map the values
  if (object && Array.isArray(object)) {
    return filterArrayNonNull(
      (object || []).map((item) => serializeAnything(serializer, item)),
    )
  }

  // objects are considered to be refs
  if (object && typeof object === 'object') {
    return serializeObjectRef(
      serializer,
      object as Project.GenericRef<Project.AnyObject>,
    )
  }

  return null
}

export default serializeAnything
