/* eslint-disable react/jsx-props-no-spreading */

import {
  memo,
  useRef,
  useState,
  useEffect,
  MouseEvent,
  useCallback,
} from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import css from '@styled-system/css'
import { Box } from 'components/layout/box'
import { IconButton } from 'components/inputs/icon-button'
import { useDndReordering } from '../../hooks'
import { ListRowProps } from '../list-types.types'

const ListRowStyled = memo(
  withTheme(
    styled('li')<{
      collapsed?: boolean
      isParentCollapsed?: boolean
      collapsedRoot?: boolean
      hasChildren?: boolean
      depth?: number
    }>(
      ({
          collapsed = false,
          isParentCollapsed = false,
          collapsedRoot = false,
          depth = 0,
          hasChildren = false,
          theme: {
            colors: { texts },
            space,
            typography,
          },
        }) =>
        ({ theme: { addAlpha, colors } }) =>
          css({
            ...typography.body2,
            display: !isParentCollapsed && collapsed ? 'none' : 'flex',
            paddingY: 2,
            paddingRight: 3,
            paddingLeft: space[3] + depth * 28 + (hasChildren ? 28 : 0),
            cursor: 'pointer',
            borderRadius: 'default',
            position: 'relative',
            minWidth: 0,
            '.collapse': {
              position: 'absolute',
              marginLeft: -28,
              top: '5px',
              minWidth: 'auto',
              cursor: 'pointer',
            },
            '&:hover': {
              backgroundColor: 'backgrounds.backgroundDefaultHover',
              '.menu': {
                display: 'flex',
              },
            },
            // TODO: we need something smarter here
            '.white-on-hover': {
              minWidth: 0,
            },
            '&.selected-parent': {
              color: 'texts.textSelected',
              backgroundColor: 'backgrounds.backgroundSelectedSecondary',
              borderBottomLeftRadius: collapsedRoot ? 0 : undefined,
              borderBottomRightRadius: collapsedRoot ? 0 : undefined,
              svg: {
                color: 'texts.textSelected',
              },
              // TODO: we need something smarter here
              '.white-on-hover': {
                '& span': {
                  color: `${texts.textSelected} !important`,
                },
                '& [data-role="text-field-update"] span': {
                  color: 'initial !important',
                },
              },
            },
            '&.selected-child': {
              backgroundColor: addAlpha(colors.neutral.neutral100, 0.5), // @TODO: about to change
              borderRadius: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              position: 'relative',
              zIndex: 10,
              '&:hover': {
                backgroundColor: 'backgrounds.backgroundDefaultHover', // @TODO: about to change
              },
              '&.last': {
                borderBottomLeftRadius: 'default',
                borderBottomRightRadius: 'default',
              },
            },
            '&.dropArea': {
              position: 'relative',
              '&::before': {
                content: '""',
                width: '100%',
                height: '2px',
                position: 'absolute',
                backgroundColor: 'borders.borderAcent',
              },
              '&.placeholder-top::before': { top: 0 },
              '&.placeholder-bottom::before': { bottom: 0 },
            },
          }),
    ),
  ),
)

const ListRow = <T, K>({
  classNameDropArea,
  collapsed,
  itemRender,
  depth = 0,
  hasChildren,
  item,
  isParentCollapsed,
  onChangeItemData,
  onSelect,
  onToggle,
  parent,
  reorderable,
  selected,
  className: classNameProp,
}: ListRowProps<T, K> & { classNameDropArea?: string }) => {
  const ref = useRef<HTMLLIElement>(null)
  const { onDragStart, onDragOver, onDragEnter, onDragLeave, onDragEnd } =
    useDndReordering(ref)

  const [value, setValue] = useState<string | React.ReactElement>()
  const defaultValue = useCallback(
    () => (itemRender ? itemRender(item, onChangeItemData) : ''),
    [item, itemRender, onChangeItemData],
  )

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const toggleItems = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      onToggle(item)
    },
    [item, onToggle],
  )

  const onClick = useCallback(
    (event: MouseEvent) => {
      if (collapsed) {
        toggleItems(event)
      }

      if (onSelect) {
        onSelect(item)
      }
    },
    [collapsed, item, onSelect, toggleItems],
  )

  const className = [
    classNameProp,
    classNameDropArea,
    selected,
    item.group ? 'group' : '',
  ]
    .filter((i) => i)
    .join(' ')

  return (
    <ListRowStyled
      data-id={item.uuid}
      data-parent-id={parent?.uuid}
      draggable={reorderable}
      collapsedRoot={hasChildren && !collapsed}
      {...(reorderable
        ? {
            onDragStart,
            onDragOver,
            onDragEnter,
            onDragLeave,
            onDragEnd,
          }
        : undefined)}
      {...{
        ref,
        className,
        collapsed,
        isParentCollapsed,
        depth,
        onClick,
        hasChildren,
      }}
    >
      {hasChildren && (
        <Box className="collapse" onClick={toggleItems}>
          <IconButton
            icon={collapsed ? 'caret-right' : 'caret-down'}
            size="small"
            noBorder
          />
        </Box>
      )}
      {value}
    </ListRowStyled>
  )
}

export default memo(
  ListRow,
  (prevProps, nextProps) =>
    Object.keys(prevProps).find(
      (key) =>
        key !== 'item' &&
        prevProps[key as keyof typeof prevProps] !==
          nextProps[key as keyof typeof nextProps],
    ) === undefined &&
    JSON.stringify(prevProps.item.data) === JSON.stringify(nextProps.item.data),
) as typeof ListRow
