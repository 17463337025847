import type { Project } from 'lib/project/types.d'
import getImplementation from '../../../dynamic-values/get-implementation'
import getDynamicValue from '../atoms/get-dynamic-value'
import {
  getOnlyDynamicValue,
  isDynamicStringEmpty,
} from '../dynamic-value-functions'

/**
 * Returns true if the Dynamic String passed is either null, empty, or pristine
 * (contains only a dynamic value that has never been edited by the user).
 * This is used to know if the request body or request auth header have never been edited
 * by the user.
 * @param dynamicString The target dynamic string.
 * @param objects The object map.
 * @returns A boolean.
 */
const isDynamicStringEmptyOrPristine = (
  dynamicString: Project.DynamicString | null,
  objects: Project.ObjectMap,
): boolean => {
  if (!dynamicString) {
    // is null, consider empty or pristine
    return true
  }
  if (isDynamicStringEmpty(dynamicString)) {
    // if an empty string, consider empty or pristine
    return true
  }
  const onlyDynamicValueRef = getOnlyDynamicValue(dynamicString)
  if (!onlyDynamicValueRef) {
    // if not the only dynamic value, clearly not pristine
    return false
  }
  const onlyDynamicValue = getDynamicValue(onlyDynamicValueRef, objects, false)
  const implementation = getImplementation(onlyDynamicValue)
  return !!(
    implementation &&
    implementation.isEmpty &&
    implementation.isEmpty(onlyDynamicValue, objects)
  )
}

export default isDynamicStringEmptyOrPristine
