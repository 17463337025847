import { byteArrayToString, stringToByteArray } from './text-encodings'

export type Base64EncodeOptions = {
  outputLineLength?: 64 | 76
  outputNewLine?: 'CR' | 'LF' | 'CRLF'
}

export const base64Encode = (
  input: Uint8Array,
  options?: Base64EncodeOptions,
): string => {
  // options
  const outputLineLength = options?.outputLineLength || -1
  let outputNewLine: string
  switch (options?.outputNewLine) {
    case 'CR':
      outputNewLine = '\r'
      break
    case 'LF':
      outputNewLine = '\n'
      break
    case 'CRLF':
    default:
      outputNewLine = '\r\n'
      break
  }

  // base64 alphabet
  const abc = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

  // convert num to 8-bit binary string
  const bin = (n: number) => n.toString(2).padStart(8, '0')

  const l = input.length

  let result = ''
  for (let i = 0; i <= (l - 1) / 3; i += 1) {
    // case when "=" is on end
    const c1 = i * 3 + 1 >= l

    // case when "=" is on end
    const c2 = i * 3 + 2 >= l

    const chunk =
      bin(input[3 * i]) +
      bin(c1 ? 0 : input[3 * i + 1]) +
      bin(c2 ? 0 : input[3 * i + 2])

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const r = chunk.match(/.{1,6}/g)!.map((x, j) =>
      // eslint-disable-next-line
      j == 3 && c2 ? '=' : j == 2 && c1 ? '=' : abc[+('0b' + x)],
    )
    result += r.join('')
    if (outputLineLength > 0 && ((i + 1) * 4) % outputLineLength === 0) {
      result += outputNewLine
    }
  }

  return result
}

export const base64Decode = (input: string): Uint8Array => {
  const data = atob(input)
  const { length } = data
  const bytes = new Uint8Array(length)
  for (let i = 0; i < length; i += 1) {
    bytes[i] = data.charCodeAt(i)
  }
  return bytes
}

export const base64EncodeString = (
  input: string,
  encoding: 'utf-8',
  options?: Base64EncodeOptions,
): string => base64Encode(stringToByteArray(input, encoding), options)

export const base64DecodeString = (input: string, encoding: 'utf-8'): string =>
  byteArrayToString(base64Decode(input), encoding)
