/* eslint-disable react/jsx-props-no-spreading */

import { withTheme } from '@emotion/react'
import { forwardRef } from 'react'
import {
  flexbox,
  space,
  layout,
  typography,
  variant as variantStyled,
} from 'styled-system'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import {
  TypographyComponentProps,
  TypographyRefProps,
} from './typography.types'

const SpanStyled = withTheme(
  styled.span<TypographyComponentProps>(
    ({ color }) =>
      css({
        outline: '0 none',
        color: color === 'inherit' ? 'inherit' : `texts.${color}`,
      }),
    ({ theme: { typography: typographyTheme } }) =>
      variantStyled({
        variants: { ...typographyTheme },
      }),
    ({ fontFamily }) =>
      css({
        fontFamily,
      }),
    () =>
      variantStyled({
        prop: 'ellipsis',
        variants: {
          true: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        },
      }),
    flexbox,
    layout,
    space,
    typography,
  ),
)

export const Typography = forwardRef<
  TypographyRefProps,
  TypographyComponentProps
>(
  (
    {
      children,
      color = 'highEmphasis',
      ellipsis = false,
      fontFamily,
      variant = 'body1',
      display = 'block',
      ...other
    },
    forwardedRef,
  ) => (
    <SpanStyled
      ref={forwardedRef}
      {...{ color, display, ellipsis, fontFamily, variant, ...other }}
    >
      {children}
    </SpanStyled>
  ),
)

export default Typography
