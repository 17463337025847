import type { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'
import { getAllObjectOfType } from '../getters'

import addObject from './add-object'
import addEnvironment from './add-environment'

export interface AddEnvironmentDomainArgs {
  uuid?: string
  name?: string
  useNumName?: boolean
  addNewEnvironment?: boolean
}
const addEnvironmentDomain = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { uuid, name, useNumName, addNewEnvironment }: AddEnvironmentDomainArgs,
): Project.GenericRef<Project.EnvironmentDomain> => {
  // name
  let newName = name
  if (!newName && useNumName) {
    newName = `Group ${
      getAllObjectOfType(objects, 'environmentDomain').length + 1
    }`
  }

  const domain: Project.EnvironmentDomain = {
    uuid: uuid || getUuid(),
    type: 'environmentDomain',
    name: newName || 'New Group',
    environments: [],
    variables: [],
  }
  const ref = addObject(objects, domain)

  const project = objects[root.ref] as Project.Project
  project.environmentDomains.push(ref)

  // add a child environment (if needed)
  if (addNewEnvironment) {
    addEnvironment(objects, root, { domainRef: ref })
  }

  return ref
}

export default addEnvironmentDomain
