import React from 'react'

import { EmptyState } from '@rapidapi/ui-lib'
import { TabBoxContainer } from 'ecosystems/http-exchange-viewer/http-exchange-tabs'

const AuthTypeNone: React.FC = () => (
  <TabBoxContainer p={5}>
    <EmptyState
      symbol="lock"
      headline="No Authorization"
      body="No authorization was set for this request."
    />
  </TabBoxContainer>
)

export default AuthTypeNone
