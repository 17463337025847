import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuthStatus } from 'lib-hooks'
import type { AuthStatus } from 'lib-hooks/cloud-api/use-auth-status'

/**
 * Hook that will automatically redirect users to the OAuth page
 * if authentication is missing.
 * Use this hook on pages that require authentication.
 */
const useAuthRedirect = (): AuthStatus => {
  const authStatus = useAuthStatus()
  const history = useHistory()

  // redirect if missing auth
  useEffect(() => {
    if (process.env.REACT_APP_SPA) {
      return
    }

    if (authStatus === 'missing') {
      history.replace('/oauth')
    }
  }, [history, authStatus])

  // return authStatus for convenience
  return authStatus
}

export default useAuthRedirect
