/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */

import config from 'lib/cloud-sync/cloud-api/config'
import type { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { cloudApiFetch, handleError } from 'lib/cloud-sync/cloud-api/api-fetch'

/**
 * Lists the user's cloud projects.
 * @param token Access token
 */
export const listProjects = async (
  token: string,
): Promise<CloudAPI.ProjectInfoResponse[]> => {
  const { API_USE_MOCKS } = config
  if (API_USE_MOCKS) {
    // @TODO: To improve: the condition with NODE_ENV === 'production' makes the bundle without the mock.
    // eslint-disable-next-line import/no-dynamic-require
    return require(`mocks/${
      process.env.NODE_ENV === 'production' ? 'empty' : 'mock-project-list'
    }`).default
  }
  const result = await cloudApiFetch({
    method: 'GET',
    path: '/projects/',
    token,
  })

  if (!result.ok) {
    return handleError(result)
  }

  const responseBody = await result.body.json()
  return responseBody as CloudAPI.ProjectInfoResponse[]
}

/**
 * Get project branches.
 * @param projectId Project ID
 * @param token Access token
 */
export const getProjectBranches = async (
  projectId: number,
  token: string,
): Promise<CloudAPI.ProjectBranchesResponse> => {
  const { API_USE_MOCKS } = config
  if (API_USE_MOCKS) {
    throw new Error('Cannot use project branches in mock mode')
  }
  const result = await cloudApiFetch({
    method: 'GET',
    path: `/projects/${projectId}/branches/`,
    token,
  })

  if (!result.ok) {
    return handleError(result)
  }

  const responseBody = await result.body.json()
  return responseBody as CloudAPI.ProjectBranchesResponse
}

/**
 * Loads a given project commit
 * @param projectId Project ID
 * @param token Access token
 * @param args Specific arguments for the pull
 */
export const pullCommit = async (
  projectId: number,
  token: string,
  args: CloudAPI.PullArgs,
): Promise<CloudAPI.PullCommitResponse> => {
  const { API_USE_MOCKS } = config
  if (API_USE_MOCKS) {
    // use mocks only for a branch pull without base sha
    if (args.mode === 'branch' && !args.baseCommitSha) {
      // @TODO: To improve: the condition with NODE_ENV === 'production' makes the bundle without the mock.
      // eslint-disable-next-line import/no-dynamic-require
      return require(`mocks/${
        process.env.NODE_ENV === 'production' ? 'empty' : 'mock-project-10334'
      }`).default
    }
    throw new Error('Cannot sync project in mock mode')
  }

  let path: string
  if (args.mode === 'branch') {
    if (args.baseCommitSha) {
      // eslint-disable-next-line
      path = `/projects/${encodeURIComponent(
        String(projectId),
      )}/branches/${encodeURIComponent(
        args.baseCommitSha,
      )}...${encodeURIComponent(args.branchName)}/`
    } else {
      // eslint-disable-next-line
      path = `/projects/${encodeURIComponent(
        String(projectId),
      )}/branches/${encodeURIComponent(args.branchName)}/`
    }
  } else if (args.mode === 'commit') {
    if (args.baseCommitSha) {
      // eslint-disable-next-line
      path = `/projects/${encodeURIComponent(
        String(projectId),
      )}/commits/${encodeURIComponent(
        args.baseCommitSha,
      )}...${encodeURIComponent(args.commitSha)}/`
    } else {
      // eslint-disable-next-line
      path = `/projects/${encodeURIComponent(
        String(projectId),
      )}/commits/${encodeURIComponent(args.commitSha)}/`
    }
  } else {
    throw new Error(`Invalid pull commit mode in ${args}`)
  }

  const result = await cloudApiFetch({
    method: 'GET',
    path,
    token,
  })

  if (!result.ok) {
    return handleError(result)
  }

  const responseBody = await result.body.json()
  return responseBody as CloudAPI.PullCommitResponse
}

/**
 * Pushes an updated for a project.
 * @param projectId Project ID
 * @param token Access token
 * @param branchName Branch to which to push (e.g. `master`)
 * @param baseCommitSha Parent commit, on which we're basing ourselves
 * @param isAutosave Is this is auto-save or an explicit commit
 * @param mergeSourceCommitSha Performs a merge from this commit
 * @param body Content to push (full project or a diff)
 */
export const pushCommit = async (
  projectId: number,
  token: string,
  branchName: string,
  baseCommitSha: string,
  isAutosave: boolean,
  mergeSourceCommitSha: string | null,
  body: unknown,
): Promise<CloudAPI.PushCommitResponse> => {
  const { API_USE_MOCKS } = config
  if (API_USE_MOCKS) {
    throw new Error('Cannot sync project in mock mode')
  }

  let path: string
  if (mergeSourceCommitSha) {
    path = `/projects/${encodeURIComponent(
      String(projectId),
    )}/merge/${encodeURIComponent(
      baseCommitSha,
    )}...${mergeSourceCommitSha}@${encodeURIComponent(branchName)}/`
  } else if (isAutosave) {
    path = `/projects/${encodeURIComponent(
      String(projectId),
    )}/branch/${encodeURIComponent(branchName)}/commit/${encodeURIComponent(
      baseCommitSha,
    )}/autosave/`
  } else {
    path = `/projects/${encodeURIComponent(
      String(projectId),
    )}/branch/${encodeURIComponent(branchName)}/commit/${encodeURIComponent(
      baseCommitSha,
    )}/`
  }

  const result = await cloudApiFetch({
    method: 'POST',
    path,
    token,
    body,
  })

  if (!result.ok) {
    return handleError(result)
  }

  const responseBody = await result.body.json()
  return responseBody as CloudAPI.PushCommitResponse
}

/**
 * Gets a Firebase access token to listen to real-time updates
 * @param projectId Project ID
 * @param token Access token (for Paw Cloud API)
 */
export const getFirebaseToken = async (
  projectId: number,
  token: string,
): Promise<CloudAPI.GetFirebaseResponse> => {
  const { API_USE_MOCKS } = config
  if (API_USE_MOCKS) {
    throw new Error('Cannot connect to Firebase in mock mode')
  }
  const result = await cloudApiFetch({
    method: 'GET',
    path: `/projects/${projectId}/token`,
    token,
  })

  if (!result.ok) {
    return handleError(result)
  }

  const responseBody = await result.body.json()
  return responseBody as CloudAPI.GetFirebaseResponse
}

/**
 * Get a cloud project's info.
 * @param projectId Project ID
 * @param token Access token
 */
export const getProject = async (
  projectId: number,
  token: string,
): Promise<CloudAPI.ProjectInfoResponse> => {
  const { API_USE_MOCKS } = config
  if (API_USE_MOCKS) {
    throw new Error('Cannot create project in mock mode')
  }
  const result = await cloudApiFetch({
    method: 'GET',
    path: `/projects/${projectId}/`,
    token,
  })

  if (!result.ok) {
    return handleError(result)
  }

  const responseBody = await result.body.json()
  return responseBody as CloudAPI.ProjectInfoResponse
}

/**
 * Creates a new cloud project.
 * @param projectName Project name
 * @param teamId ID of the team to which the project will belong
 * @param token Access token
 */
export const createProject = async (
  projectName: string,
  teamId: number,
  token: string,
): Promise<CloudAPI.ProjectInfoResponse> => {
  const { API_USE_MOCKS } = config
  if (API_USE_MOCKS) {
    throw new Error('Cannot create project in mock mode')
  }
  const body = {
    name: projectName,
    team: teamId,
    allow_direct_commits: true,
  }
  const result = await cloudApiFetch({
    method: 'POST',
    path: '/projects/',
    token,
    body,
  })

  if (!result.ok) {
    return handleError(result)
  }

  const responseBody = await result.body.json()
  return responseBody as CloudAPI.ProjectInfoResponse
}

/**
 * Delete a cloud project.
 * @param projectId Project ID
 * @param token Access token
 */
export const deleteProject = async (
  projectId: number,
  token: string,
): Promise<void> => {
  const { API_USE_MOCKS } = config
  if (API_USE_MOCKS) {
    throw new Error('Cannot delete project in mock mode')
  }
  const result = await cloudApiFetch({
    method: 'DELETE',
    path: `/projects/${projectId}/`,
    token,
  })
  if (!result.ok) {
    await handleError(result)
  }
}

/**
 * Rename a cloud project.
 * @param projectId Project ID
 * @param projectName Project new name
 * @param token Access token
 */
export const renameProject = async (
  projectId: number,
  projectName: string,
  token: string,
): Promise<CloudAPI.ProjectInfoResponse> => {
  const { API_USE_MOCKS } = config
  if (API_USE_MOCKS) {
    throw new Error('Cannot rename project in mock mode')
  }
  const body = {
    name: projectName,
  }
  const result = await cloudApiFetch({
    method: 'PATCH',
    path: `/projects/${projectId}/`,
    body,
    token,
  })

  if (!result.ok) {
    return handleError(result)
  }
  const responseBody = await result.body.json()
  return responseBody as CloudAPI.ProjectInfoResponse
}
