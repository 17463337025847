/* eslint-disable react/jsx-props-no-spreading */

import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { space as spaceStyled } from 'styled-system'
import css from '@styled-system/css'
import { Button } from '../../../inputs/button'
import { ButtonComponentProps } from '../../../inputs/button/button.types'

export interface TabComponentProps
  extends Omit<ButtonComponentProps, 'variant' | 'onClick'> {
  selected?: boolean
}

const TabStyled = withTheme(
  styled(Button)<TabComponentProps>(
    css({
      bg: 'backgrounds.backgroundPrimary',
      height: 64,
      borderColor: 'backgrounds.backgroundPrimary',
      color: 'texts.mediumEmphasis',
      mr: 2,
      borderRadius: 0,
      '&:last-child': {
        mr: 0,
      },
      '&:hover:enabled, &.focused': {
        color: 'texts.highEmphasis',
        bg: 'backgrounds.backgroundPrimary',
        borderColor: 'borders.backgroundPrimary',
      },
      '&.selected': {
        bg: 'backgrounds.backgroundPrimary',
        borderColor: 'backgrounds.backgroundPrimary',
        color: 'texts.textSelected',
        borderBottomWidth: 2,
        borderBottomColor: 'texts.textSelected',
        '&:hover:enabled, &.focused': {
          color: 'texts.textSelected',
        },
      },
    }),
    spaceStyled,
  ),
)

export const Tab: React.FC<TabComponentProps> = ({
  children,
  selected,
  ...other
}) => (
  <TabStyled className={selected ? 'selected' : undefined} {...other}>
    {children}
  </TabStyled>
)

export default Tab
