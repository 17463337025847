/* eslint-disable react/jsx-props-no-spreading */

import { withTheme } from '@emotion/react'
import css from '@styled-system/css'
import { space, SpaceProps, variant as variantStyled } from 'styled-system'
import styled from '@emotion/styled'

export type SkeletonProps = SpaceProps & {
  variant?: 'text' | 'rect' | 'circle'
  animation?: 'pulse' | 'wave' | false
  width?: number | string
  height?: number | string
  color?: 'primary' | 'secondary' | 'tertiary'
}

const SkeletonStyled = withTheme(
  styled('span')<SkeletonProps & { hasChildren: boolean }>(
    space,
    ({
      theme: {
        colors: { neutral },
      },
      width,
      height,
      hasChildren,
      color = 'secondary',
    }) =>
      css({
        display: 'inline-flex',
        borderRadius: 'default',
        verticalAlign: 'top',
        width,
        // @TODO: temporary fix
        // backgroundColor: backgrounds[color],
        backgroundColor: neutral.neutral100,
        maxWidth: hasChildren && !width ? 'fit-content' : undefined,
        height: hasChildren && !height ? 'auto' : height ?? '1.2em',
        '& > *': hasChildren
          ? {
              visibility: 'hidden',
            }
          : undefined,
      }),
    ({
      theme: {
        colors: { neutral },
      },
    }) =>
      variantStyled({
        prop: 'animation',
        variants: {
          wave: {
            position: 'relative',
            overflow: 'hidden',
            '&::after': {
              animation: 'wave 1.6s linear 0.5s infinite',
              // @TODO: temporary fix
              background: `linear-gradient(90deg, transparent, ${neutral.neutral50}, transparent)`,
              content: '""',
              position: 'absolute',
              transform: 'translateX(-100%)',
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            },
            '@keyframes wave': {
              '0%': {
                transform: 'translateX(-100%)',
              },
              '60%': {
                transform: 'translateX(100%)',
              },
              '100%': {
                transform: 'translateX(100%)',
              },
            },
          },
          pulse: {
            animation: 'pulse 1.5s ease-in-out 0.5s infinite',
            '@keyframes pulse': {
              '0%': {
                opacity: 1,
              },
              '50%': {
                opacity: 0.4,
              },
              '100%': {
                opacity: 1,
              },
            },
          },
        },
      }),
    () =>
      variantStyled({
        variants: {
          text: {
            height: 'auto',
            '&:empty:before': {
              content: '"\\00a0"',
            },
          },
          rect: {},
          circle: {
            borderRadius: '50%',
          },
        },
      }),
  ),
)

const Skeleton: React.FC<SkeletonProps> = ({
  variant = 'text',
  animation = 'pulse',
  ...other
}) => (
  <SkeletonStyled
    hasChildren={Boolean(other.children)}
    {...{ animation, variant, ...other }}
  />
)

export default Skeleton
