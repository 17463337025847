const getStringEllipsis = (
  input: string | undefined,
  maxLength: number | undefined,
): string => {
  if (!input || !maxLength) {
    return input || ''
  }

  if (input.length <= maxLength) {
    return input
  }

  return `${input.substr(0, maxLength)}...`
}

export default getStringEllipsis
