import React, { useMemo } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import type { StoreOptions } from 'store/store'
import { getStore } from 'store/store'

type StoreProviderProps = StoreOptions & {
  usePersistGate?: boolean
}

const StoreProvider: React.FC<StoreProviderProps> = ({
  children,
  usePersistGate,
  ...more
}) => {
  const { store, persistor } = useMemo(() => getStore({ ...more }), [more])
  return (
    <Provider store={store}>
      {usePersistGate ? (
        <PersistGate loading={null} persistor={persistor}>
          {children}
        </PersistGate>
      ) : (
        children
      )}
    </Provider>
  )
}

export default StoreProvider
