import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'
import { getObject } from 'lib/project'
import type { Project } from 'lib/project/types.d'
import { serializeProjectRoot } from './object-serializers'

const serializeProject = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
): CloudSerializer.ProjectSerializer => {
  // create serializer
  const serializer: CloudSerializer.ProjectSerializer = {
    syncTree: {},
    objects,
    root,
  }

  // serialize root
  const rootObject = getObject(root, serializer.objects, false)
  serializer.syncTree.root = serializeProjectRoot(serializer, rootObject)

  return serializer
}

export default serializeProject
