import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import type { DataTableLastRow } from '@rapidapi/ui-lib'
import { setProjectValue } from 'store/actions'
import { useDynamicValueObject } from 'utils'
import type { Project } from 'lib/project'
import type { JSONDynamicValueInterface } from 'lib/dynamic-values'
import useJsonTreeBuilder from './json-tree-builder'
import DataTableFieldRenderer from './data-table-field-renderer'
import JSONDataTable from './json-data-table'
import type { DataJSONField } from './json-data-table-types.d'

type JSONTreeEditorProps<T extends Project.AnyObject> = {
  objectRef: Project.GenericRef<T>
  objectProperty: keyof T
  jsonStringProperty?: string
  isKeyEditable?: boolean
  isExtendable?: boolean
}

const JsonTreeEditor = <T extends Project.AnyObject>({
  objectRef,
  objectProperty,
  jsonStringProperty = 'json',
  isKeyEditable = true,
  isExtendable = true,
}: JSONTreeEditorProps<T>): JSX.Element => {
  const dispatch = useDispatch()
  const dv = useDynamicValueObject<JSONDynamicValueInterface>(objectRef)
  const jsonTreeContent = dv[jsonStringProperty] as string | null

  const {
    filterKeyword,
    jsonTreeFilteredContentResults,
    jsonTreeFilteredContent,
  } = useJsonTreeBuilder(jsonTreeContent || '')

  const renderItemKey = useMemo(
    () =>
      DataTableFieldRenderer(
        isKeyEditable
          ? {
              keyOrValue: 'key',
              dv,
              objectRef,
              objectProperty,
              dispatch,
              jsonStringProperty,
            }
          : {
              keyOrValue: 'key',
            },
      ),
    [
      objectRef,
      objectProperty,
      dispatch,
      dv,
      jsonStringProperty,
      isKeyEditable,
    ],
  )

  const renderItemValue = useMemo(
    () =>
      DataTableFieldRenderer({
        keyOrValue: 'value',
        dv,
        objectRef,
        objectProperty,
        dispatch,
        jsonStringProperty,
      }),
    [objectRef, objectProperty, dispatch, dv, jsonStringProperty],
  )

  const fields = useMemo(
    (): DataJSONField[] => [
      {
        key: 'Key',
        value: renderItemKey,
      },
      {
        key: 'Value',
        value: renderItemValue,
      },
    ],
    [renderItemKey, renderItemValue],
  )
  const lastRow: DataTableLastRow = {
    onClick: () => {
      if (!dv.json) {
        return
      }
      let newJSON: Project.ObjectMap<string> = {}
      try {
        newJSON = JSON.parse(dv.json)
      } catch (err) {
        newJSON = {}
      }
      const keys = Object.keys(newJSON)
      newJSON[`key${keys.length}`] = ''
      dispatch(
        setProjectValue({
          objectRef,
          update: {
            [objectProperty]: JSON.stringify(newJSON),
          },
        }),
      )
    },
    placeholders: ['Add Key Name', 'Add Value'],
    topLevelOnly: true,
  }

  return (
    <JSONDataTable
      fields={fields}
      jsonTreeFilteredContent={jsonTreeFilteredContent}
      filterKeyword={filterKeyword}
      jsonTreeFilteredContentResults={jsonTreeFilteredContentResults}
      lastRow={isExtendable ? lastRow : undefined}
    />
  )
}

export default JsonTreeEditor
