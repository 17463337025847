import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import config from 'config'
import { requestHandlingActions } from 'store/actions'

type AgentResponseStatus = {
  isAgentActive: boolean
}

/**
 * @function initEventSource
 * @summary a function that initializes an event source
 * @returns {EventSource}
 */
function initEventSource(): EventSource {
  return new EventSource(config.helper.status, {
    withCredentials: false,
  })
}

const useWatchAgent = (): void => {
  const eventSource = useRef<EventSource | null>(null)
  const retryTimeout = useRef<NodeJS.Timeout | null>(null)
  const [retryCount, setRetryCount] = useState(0)
  const dispatch = useDispatch()

  // event source callback
  // update agent status when there's an update
  const eventSourceMessageListener = useCallback(
    (e: Event) => {
      const { isAgentActive } = JSON.parse(
        (e as MessageEvent).data,
      ) as AgentResponseStatus
      dispatch(
        requestHandlingActions.setAgentStatus({
          installed: true,
          active: isAgentActive,
          checked: true,
        }),
      )
    },
    [dispatch],
  )

  // start event source (unless it's in Electron)
  useEffect(() => {
    // if running in Electron, consider always active
    // no need for event source
    if (config.build === 'desktop') {
      dispatch(
        requestHandlingActions.setAgentStatus({
          installed: true,
          active: true,
          checked: true,
        }),
      )
      return undefined
    }

    // skip if already existing
    if (eventSource.current) {
      return undefined
    }

    // start event source
    const es = initEventSource()
    eventSource.current = es

    // log to warn the user of any error
    // eslint-disable-next-line no-console
    console.info(
      '%cℹ️ Paw will attempt a connection to localhost agent (it may fail if agent if not launched and result to net::ERR_CONNECTION_REFUSED)',
      'color: #FA8072',
    )

    es.onerror = (e: Event) => {
      const currentEventSource = (e as MessageEvent)
        .currentTarget as EventSource

      // notify
      dispatch(
        requestHandlingActions.setAgentStatus({
          active: false,
          checked: true,
        }),
      )

      // close
      currentEventSource.close()
      eventSource.current = null

      // set a retry timeout
      retryTimeout.current = setTimeout(() => {
        setRetryCount((count) => count + 1)
      }, 30000)
    }

    es.addEventListener('message', eventSourceMessageListener)
    es.addEventListener('helper:started', eventSourceMessageListener)
    es.addEventListener('helper:stopped', eventSourceMessageListener)
    return () => {
      es.removeEventListener('message', eventSourceMessageListener)
      es.removeEventListener('helper:started', eventSourceMessageListener)
      es.removeEventListener('helper:stopped', eventSourceMessageListener)
      if (retryTimeout.current) {
        clearTimeout(retryTimeout.current)
      }
    }
  }, [
    dispatch,
    eventSourceMessageListener,
    retryCount /* important to have retryCount to trigger a reload */,
  ])
}

export default useWatchAgent
