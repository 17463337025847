import config from 'config'

interface CloudAPIConfig {
  API_ENDPOINT: string | undefined
  API_USE_MOCKS: boolean
  API_USE_CF_PROXY: boolean
}

/**
 * @exports CloudAPIConfig
 * @summary
 *  - the values of this config tree can be set or overridden on
 *    build time via `.env` file.
 */
const cloudAPIConfig: CloudAPIConfig = {
  ...config.cloudAPIConfig,
}

export default cloudAPIConfig
