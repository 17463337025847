/* eslint-disable no-restricted-syntax, prefer-spread */
import memo from 'memoize-one'
import getImplementation from 'lib/dynamic-values/get-implementation'
import type { Project } from '../types.d'

type RefORObj<T extends Project.AnyObject> = Project.GenericRef<T> | T

const walk = (
  objects: Project.ObjectMap<Project.AnyObject>,
  root: Project.GenericRef<Project.Project>,
  fn: (obj: Project.AnyObject) => void,
): void => {
  const getters = {
    environmentVariableValues: memo(
      function environmentVariableValues(): Project.EnvironmentVariableValue[] {
        const ret = []
        // eslint-disable-next-line no-restricted-syntax
        for (const item of Object.values(objects)) {
          if (item.type === 'environmentVariableValue') {
            ret.push(item)
          }
        }
        return ret
      },
    ),
  }

  const stack: (null | RefORObj<Project.AnyObject>)[] = []
  const visited = new WeakSet()

  const rootObject = objects[root.ref] as Project.Project | null

  if (rootObject) {
    stack.push(rootObject)
  }

  const addAny = (
    current: RefORObj<Project.AnyObject>,
    unknownObj: unknown,
  ) => {
    if (!(unknownObj && typeof unknownObj === 'object')) {
      return
    }

    if (Array.isArray(unknownObj)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const objElementElement of unknownObj) {
        addAny(current, objElementElement)
      }
      return
    }

    const obj = unknownObj as Record<string, unknown>

    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const objKey in obj) {
      const objElement = obj[objKey]
      switch (typeof objElement) {
        case 'string':
          if (objElement !== ('ref' in current ? current.ref : current.uuid)) {
            stack.push(objects[objElement])
          }

          break
        case 'object':
          if (objElement !== null) {
            if (Array.isArray(objElement)) {
              // eslint-disable-next-line no-restricted-syntax
              for (const objElementElement of objElement) {
                addAny(current, objElementElement)
              }
            } else if ('ref' in objElement) {
              const objRef = (objElement as { ref: unknown }).ref
              if (typeof objRef === 'string') {
                stack.push(objElement as Project.GenericRef<Project.AnyObject>)
              }
            }
          }
          break
        default:
          break
      }
    }
  }

  let ref: typeof stack[0] | undefined
  while (stack.length) {
    ref = stack.pop()
    if (!ref) {
      // eslint-disable-next-line no-continue
      continue
    }
    const obj =
      'uuid' in ref
        ? ref
        : objects[(ref as Project.GenericRef<Project.AnyObject>).ref]

    if (!obj || visited.has(obj)) {
      // eslint-disable-next-line no-continue
      continue
    }

    visited.add(obj)
    fn(obj)

    switch (obj.type) {
      case 'dynamicString':
        if (obj.strings) {
          for (const str of obj.strings) {
            if (typeof str !== 'string') {
              stack.push(str)
            }
          }
        }
        break
      case 'dynamicValue': {
        const impl = getImplementation(obj)
        if (impl) {
          const matches = impl?.getAllRefs(obj)
          if (matches) {
            // eslint-disable-next-line no-restricted-syntax
            for (const match of matches) {
              stack.push(typeof match === 'string' ? objects[match] : match)
            }
          }
        } else {
          addAny(ref, obj)
        }

        break
      }
      case 'environmentDomain':
        for (const item of getters.environmentVariableValues()) {
          if (item.domain.ref === obj.uuid) {
            stack.push(item)
          }
        }
        stack.push.apply(stack, obj.environments)
        break

      case 'environment':
        stack.push(obj.domain)
        break
      case 'environmentVariable':
        stack.push(obj.domain)
        break
      case 'environmentVariableValue':
        stack.push(obj.value, obj.variable, obj.environment, obj.domain)
        break
      case 'group':
        stack.push.apply(stack, obj.children)
        break
      case 'request':
        stack.push(
          obj.method,
          obj.urlFull,
          obj.bodyString,
          obj.clientCertificate,
        )
        stack.push.apply(stack, obj.headers)
        stack.push.apply(stack, obj.urlParameters)
        stack.push.apply(stack, obj.variables)
        break
      case 'parameter':
        // eslint-disable-next-line no-unused-expressions
        'key' in obj
          ? stack.push(obj.key, obj.value)
          : stack.push(obj.name, obj.value)
        break

      case 'requestVariable':
        stack.push(obj.value, obj.schema)
        break
      case 'project':
        stack.push.apply(stack, obj.requests)
        stack.push.apply(stack, obj.environmentDomains)
        stack.push.apply(stack, obj.sessions)
        break
      case 'session':
        break
      default:
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions,no-unused-expressions
        obj as never
    }
  }
}

export const getAllUsedIds = (
  nextObjects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
): Set<string> => {
  const used = new Set<string>()

  used.add('root')

  walk(nextObjects, root, (obj) => {
    used.add(obj.uuid)
  })
  return used
}

export default walk
