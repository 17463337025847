import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Box, EmptyState, Notification, styled } from '@rapidapi/ui-lib'
import { getHumanFileSize } from 'utils'

import { base64Encode } from 'lib/utils/encoding'
import { getHttpMessageBodyBinary } from 'lib/request-handling'

import type { HttpExchangeTabViewProps } from '../http-exchange-tabs-props.d'
import { useHttpExchangeMessage } from '../http-exchange-helper-hooks'
import { getImageContentType, getImageTypeName } from './image-tab-utils'

import checkerboardSvg from './checkerboard.svg'
import TabBoxContainer from '../tab-box-container'

const StyledImage = styled('img')({
  maxWidth: '90%',
  background: '#ffffff',
  backgroundImage: `url(${checkerboardSvg})`,
  backgroundRepeat: 'repeat',
  backgroundSize: 16,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '#CCCBCC',
})

const HttpExchangeImageTab: React.FC<HttpExchangeTabViewProps> = ({
  messageType,
}) => {
  const { httpMessage } = useHttpExchangeMessage(messageType)

  // decode image data and type
  const [content, contentType, contentSize, isEmpty] = useMemo(() => {
    const binaryBody = httpMessage
      ? getHttpMessageBodyBinary(httpMessage)
      : null
    if (!httpMessage || !binaryBody || binaryBody.length === 0) {
      return [null, null, 0, true]
    }

    // get image content type (or it's not an image)
    const aContentType = binaryBody ? getImageContentType(binaryBody) : null
    if (!aContentType) {
      return [null, null, 0, false]
    }

    // return a base64 image
    const base64Body = binaryBody ? base64Encode(binaryBody) : null
    return [
      `data:${aContentType};base64,${base64Body}`,
      aContentType,
      binaryBody.length,
      false,
    ]
  }, [httpMessage])

  // compute image file size
  const [imgSize, setImgSize] = useState<{
    width: number
    height: number
  } | null>(null)
  const imgRef = useRef<HTMLImageElement | null>(null)
  const imgOnLoad = useCallback(() => {
    const img = imgRef.current
    if (!img) {
      setImgSize(null)
      return
    }
    setImgSize({
      width: img.naturalWidth,
      height: img.naturalHeight,
    })
  }, [])

  // generate a nice title
  const title = useMemo(
    () =>
      imgSize && contentType
        ? `${getImageTypeName(contentType)} — ${imgSize.width}x${
            imgSize.height
          }px — ${getHumanFileSize(contentSize)}`
        : null,
    [imgSize, contentType, contentSize],
  )

  if (isEmpty) {
    return (
      <TabBoxContainer p={5}>
        <EmptyState
          symbol="danger"
          headline="Empty body"
          body={
            messageType === 'request'
              ? 'The request body is empty, there is no image to be shown'
              : 'The response body is empty, there is no image to be shown'
          }
        />
      </TabBoxContainer>
    )
  }

  if (!content) {
    return (
      <TabBoxContainer p={5}>
        <EmptyState
          symbol="alert"
          headline="Does not contain an image"
          body={
            messageType === 'request'
              ? 'The request body does not contain a valid image'
              : 'The response body does not contain a valid image'
          }
        />
      </TabBoxContainer>
    )
  }

  return (
    <TabBoxContainer p={5}>
      {title && (
        <Notification icon="info" title={title} width="100%" m={0} mb={3} />
      )}
      <Box textAlign="center">
        <StyledImage src={content} alt="Body" ref={imgRef} onLoad={imgOnLoad} />
      </Box>
    </TabBoxContainer>
  )
}

export default HttpExchangeImageTab
