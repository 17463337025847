import type { Project } from 'lib/project/types.d'
import { getDynamicString, getKeyValue, getRequest } from '../atoms'
import { getOnlyString } from '../dynamic-value-functions'

const getHeaderByName = (
  requestRef: Project.GenericRef<Project.Request>,
  objects: Project.ObjectMap,
  matchName: string,
): Project.GenericRef<Project.KeyValue> | null => {
  const request = getRequest(requestRef, objects, false)
  const result = request.headers.find((keyValueRef) => {
    const keyValue = getKeyValue(keyValueRef, objects, false)
    const name = keyValue.name
      ? getDynamicString(keyValue.name, objects, false)
      : null
    return (
      name &&
      getOnlyString(name)?.trim().toLowerCase() ===
        matchName.trim().toLowerCase()
    )
  })
  return result || null
}

export default getHeaderByName
