/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react'
import type { SpaceProps } from 'styled-system'
import { useDispatch } from 'react-redux'

import type { IconSymbol } from '@rapidapi/ui-lib'
import { Flex, Box, Checkbox, Icon, Typography } from '@rapidapi/ui-lib'
import type { Project } from 'lib/project'
import { setProjectValue } from 'store/actions'
import { useAnyObjectProperty } from 'utils/hooks'

type CheckboxEditorProps<T extends Project.AnyObject> = SpaceProps & {
  objectRef: Project.GenericRef<T>
  objectProperty: keyof T
  label?: string
  hint?: string
  hintIcon?: IconSymbol
  disabled?: boolean
}

type CheckboxEditorReturnType = React.ReactElement<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> | null

const CheckboxEditor = <T extends Project.AnyObject>({
  objectRef,
  objectProperty,
  label,
  hint,
  hintIcon,
  disabled,
  ...props
}: CheckboxEditorProps<T>): CheckboxEditorReturnType => {
  // get current value
  const currentValue = useAnyObjectProperty(
    objectRef,
    objectProperty,
    undefined /* expect */,
    true /* allowsNull */,
  ) as boolean

  // dispatch update
  const dispatch = useDispatch()
  const onChange = useCallback(() => {
    dispatch(
      setProjectValue({
        objectRef,
        update: {
          [objectProperty]: !currentValue,
        },
      }),
    )
  }, [dispatch, objectRef, objectProperty, currentValue])

  return (
    <Box>
      <Flex flexDirection="row" alignItems="stretch" {...props}>
        <Checkbox
          disabled={disabled}
          checked={!!currentValue}
          onChange={onChange}
        />
        {label && (
          <Box ml={2}>
            <Typography variant="body2" mb={1} color="highEmphasis">
              {label}
            </Typography>
          </Box>
        )}
      </Flex>
      {(hintIcon || hint) && (
        <Flex alignItems="flex-start" justifyContent="flex-start" mt={1}>
          {hintIcon && (
            <Icon
              symbol={hintIcon}
              size={14}
              mr={1}
              color="content.secondary"
            />
          )}
          {hint && (
            <Box minWidth="auto">
              <Typography variant="caption" color="mediumEmphasis">
                {hint}
              </Typography>
            </Box>
          )}
        </Flex>
      )}
    </Box>
  )
}

export default CheckboxEditor
