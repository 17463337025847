import type { Dispatch } from 'redux'
import type { IconSymbol } from '@rapidapi/ui-lib'
import type {
  JSONItem,
  JSONDict,
  JSONPrimitive,
} from 'lib/dynamic-values/implementations/json-dynamic-value/parser'
import {
  JSONItemType,
  JSONPrimitiveType,
} from 'lib/dynamic-values/implementations/json-dynamic-value/parser'
import type { DataJSONItem } from 'ecosystems/field-editors/json-tree-editor/json-data-table-types.d'
import {
  getJSONArrayElement,
  getJSONDictElement,
  getJSONDictKey,
  getJSONPrimitive,
  makeObjectFromJSONArrayElement,
  makeObjectFromJSONDictElements,
} from 'lib/dynamic-values/implementations/json-dynamic-value/parser/jsmn'
import type { Project } from 'lib/project'
import { setProjectValue } from 'store/actions'

export const getPrimitiveStringValue = (
  item: JSONPrimitive<string>,
): string => {
  switch (item.primitiveType) {
    case JSONPrimitiveType.String:
    case JSONPrimitiveType.Number:
      return `${item.value}`
    case JSONPrimitiveType.True:
      return 'true'
    case JSONPrimitiveType.False:
      return 'false'
    case JSONPrimitiveType.Null:
      return 'null'
    default:
      return ''
  }
}

export const getItemStringValue = (item: JSONItem<string>): string => {
  switch (item.itemType) {
    case JSONItemType.Primitive:
      return getPrimitiveStringValue(item)
    case JSONItemType.Array:
    case JSONItemType.Object:
      return item.items.length >= 2
        ? `${item.items.length} items`
        : `${item.items.length} item`
    default:
      return ''
  }
}

export const getChildrenItems = (
  item: JSONItem<string>,
  path: string[],
): DataJSONItem[] | undefined => {
  if (item.itemType === JSONItemType.Object) {
    return item.items.map((childDictElement) => {
      const childPath = [...path, `${childDictElement.index}`]
      return {
        uuid: childPath.join('__'),
        data: childDictElement,
        children: getChildrenItems(childDictElement.value, childPath),
      }
    })
  }
  if (item.itemType === JSONItemType.Array) {
    return item.items.map((childDictElement) => {
      const childPath = [...path, `${childDictElement.index}`]
      return {
        uuid: childPath.join('__'),
        data: childDictElement,
        children: getChildrenItems(childDictElement.item, childPath),
      }
    })
  }
  if (item.itemType === JSONItemType.Primitive) {
    // call to a primitive should return undefined (no child)
    // unless we're at the root, in which case we want to return a
    // root element for display
    if (path.length > 0) {
      return undefined
    }

    const childPath = [...path, 'value']
    const rootElement = getJSONDictElement(
      0,
      getJSONDictKey('Root'),
      getJSONPrimitive(getItemStringValue(item)),
    )
    return [
      {
        uuid: childPath.join('__'),
        data: rootElement,
      },
    ]
  }
  throw new Error('Invalid root item')
}

export const getIconForItem = (item: JSONItem<string>): IconSymbol => {
  let symbol: IconSymbol
  switch (item.itemType) {
    case JSONItemType.Object:
      symbol = 'object'
      break
    case JSONItemType.Array:
      symbol = 'array'
      break
    case JSONItemType.Primitive:
      switch (item.primitiveType) {
        case JSONPrimitiveType.String:
          symbol = 'string'
          break
        case JSONPrimitiveType.Number:
          symbol = 'num'
          break
        case JSONPrimitiveType.True:
        case JSONPrimitiveType.False:
          symbol = 'boolean'
          break
        case JSONPrimitiveType.Null:
          symbol = 'null'
          break
        default:
          symbol = 'alert'
          break
      }
      break
    default:
      symbol = 'alert'
      break
  }
  return symbol
}

export const replaceJSONValue = (
  str: string,
  start: number,
  end: number,
  replacement: string,
): string => str.substr(0, start) + replacement + str.substr(end, str.length)

export const addElementFunction = <T extends Project.AnyObject>(
  item: JSONItem<string> | JSONDict<unknown>,
  dispatch: Dispatch,
  objectRef: Project.GenericRef<T>,
  objectProperty: keyof T,
  json: string,
): (() => void) | null => {
  let fn: (() => void) | null
  switch (item.itemType) {
    case JSONItemType.Object: {
      fn = () => {
        const { items } = item
        items.push(
          getJSONDictElement(
            items.length,
            getJSONDictKey(`key${items.length}`),
            getJSONPrimitive(''),
          ),
        )
        const newItem = makeObjectFromJSONDictElements(items)
        const newJSON = replaceJSONValue(
          json,
          item.start,
          item.end,
          JSON.stringify(newItem),
        )
        dispatch(
          setProjectValue({
            objectRef,
            update: {
              [objectProperty]: newJSON,
            },
          }),
        )
      }
      break
    }
    case JSONItemType.Array: {
      fn = () => {
        const { items } = item
        items.push(getJSONArrayElement(items.length, getJSONPrimitive('')))
        const newItem = makeObjectFromJSONArrayElement(items)
        const newJSON = replaceJSONValue(
          json,
          item.start,
          item.end,
          JSON.stringify(newItem),
        )
        dispatch(
          setProjectValue({
            objectRef,
            update: {
              [objectProperty]: newJSON,
            },
          }),
        )
      }
      break
    }
    default: {
      fn = null
      break
    }
  }
  return fn
}

export const changeTypeFunction = <T extends Project.AnyObject>(
  item: JSONItem<string> | JSONDict<unknown>,
  dispatch: Dispatch,
  objectRef: Project.GenericRef<T>,
  objectProperty: keyof T,
  json: string,
): (() => void) => {
  let fn: (() => void) | null
  switch (item.itemType) {
    case JSONItemType.Object: {
      fn = () => {
        const newItem = '""'
        const newJSON = replaceJSONValue(json, item.start, item.end, newItem)
        dispatch(
          setProjectValue({
            objectRef,
            update: {
              [objectProperty]: newJSON,
            },
          }),
        )
      }
      break
    }
    case JSONItemType.Array: {
      fn = () => {
        const newJSON = replaceJSONValue(
          json,
          item.start,
          item.end,
          JSON.stringify({}),
        )
        dispatch(
          setProjectValue({
            objectRef,
            update: {
              [objectProperty]: newJSON,
            },
          }),
        )
      }
      break
    }
    case JSONItemType.Primitive: {
      fn = () => {
        const newJSON = replaceJSONValue(
          json,
          item.start - 1,
          item.end + 1,
          JSON.stringify([]),
        )
        dispatch(
          setProjectValue({
            objectRef,
            update: {
              [objectProperty]: newJSON,
            },
          }),
        )
      }
      break
    }
    default: {
      fn = () => ({})
      break
    }
  }
  return fn
}
function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\"]/g, '\\$&') // $& means the whole matched string
}

const getRegexFromValue = (value: string): RegExp => {
  const escapedValue = escapeRegExp(value)
  if (value.indexOf('{') === 0 && value.lastIndexOf('}') === value.length - 1) {
    return new RegExp(`(,?)(${escapedValue})(,?)`, 'g')
  }
  if (value.indexOf('[') === 0 && value.lastIndexOf(']') === value.length - 1) {
    return new RegExp(`(,?)(${escapedValue})(,?)`, 'g')
  }
  return new RegExp(
    `((,?)(")(\\w+( *)?)*(")(:)(")(${escapedValue})("))(,?)|(,?)(")(${escapedValue})(")(,?)`,
    'g',
  )
}

export const removeKeyFunction =
  <T extends Project.AnyObject>(
    item: JSONItem<string> | JSONDict<unknown>,
    dispatch: Dispatch,
    objectRef: Project.GenericRef<T>,
    objectProperty: keyof T,
    json: string,
  ): (() => void) =>
  () => {
    const value = json.substr(item.start, item.end - item.start)
    const stringRegex = getRegexFromValue(value)
    let newJSON = json
    const allMatches = Array.from(json.matchAll(stringRegex))
    allMatches.forEach((match) => {
      const startIndex = match.index
      if (startIndex === undefined) {
        return
      }
      const endIndex = startIndex + match[0].length
      const itemEnd = item.end
      if (
        endIndex === itemEnd ||
        endIndex === itemEnd + 1 ||
        endIndex === itemEnd + 2
      ) {
        newJSON = replaceJSONValue(json, startIndex, endIndex, '')
      }
    })
    dispatch(
      setProjectValue({
        objectRef,
        update: {
          [objectProperty]: newJSON,
        },
      }),
    )
  }
