import type { Project } from 'lib/project/types.d'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'
import type { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import {
  serializeDynamicString,
  serializeDynamicValue,
  serializeEnvironment,
  serializeEnvironmentDomain,
  serializeEnvironmentVariable,
  serializeEnvironmentVariableValue,
  serializeParameter,
  serializeRequest,
  serializeRequestGroup,
  serializeRequestVariable,
} from 'lib/cloud-sync/serializer/object-serializers'
import { getObject } from 'lib/project'

const getSerializerFn = (
  objectType: CloudAPI.ObjectTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): CloudSerializer.ObjectSerializerFn<any> => {
  switch (objectType) {
    case 'request':
      return serializeRequest
    case 'group':
      return serializeRequestGroup
    case 'parameter':
      // note: key 'parameter' applies to Key Value and Parameter
      // (due to backward compatibility with Paw Mac)
      // but this shouldn't be an issue as Key Values are serialized explicitly
      // using `serializeKeyValue` in serializeRequest
      return serializeParameter
    case 'requestVariable':
      return serializeRequestVariable
    case 'dynamicValue':
      return serializeDynamicValue
    case 'dynamicString':
      return serializeDynamicString
    case 'environmentDomain':
      return serializeEnvironmentDomain
    case 'environment':
      return serializeEnvironment
    case 'environmentVariable':
      return serializeEnvironmentVariable
    case 'environmentVariableValue':
      return serializeEnvironmentVariableValue
    default:
      throw new Error(`Serializing item of unknown type ${objectType}`)
  }
}

const serializeObjectRef = <T extends Project.AnyObject>(
  serializer: CloudSerializer.ProjectSerializer,
  ref: Project.GenericRef<T>,
  serializerFn?: CloudSerializer.ObjectSerializerFn<T>,
): Project.GenericRef<T> => {
  // get object
  const object = getObject(ref, serializer.objects, false)

  const { uuid: objectUuid, type: objectType } = object
  if (
    ref.ref !== 'root' &&
    objectUuid.toLowerCase() !== ref.ref.toLowerCase()
  ) {
    throw new Error(`Non-matching uuid = ${ref.ref} != ${objectUuid}`)
  }

  // get a serializer function
  let aSerializerFn = serializerFn || null
  if (!aSerializerFn) {
    aSerializerFn = getSerializerFn(
      objectType,
    ) as CloudSerializer.ObjectSerializerFn<T>
  }

  // run serializer to get an object result
  const r = aSerializerFn(serializer, object)

  // eslint-disable-next-line no-param-reassign
  serializer.syncTree[ref.ref] = r

  return ref
}

export default serializeObjectRef
