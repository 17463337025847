/* eslint-disable react/jsx-props-no-spreading */

import { ButtonTabsComponentProps } from '../button-tabs/button-tabs.types'
import { ButtonTabs } from '../button-tabs'

export const Tabs = (props: ButtonTabsComponentProps) => (
  <ButtonTabs {...props} />
)

export default Tabs
