import type { PrimaryWorkerState } from '../../types.d'
import type { LocalProjectStorage } from './local-storage-types.d'

export const projectLocalStorageDatabase = 'PawProject'

export const projectLocalStorageObjectsTable = 'objects'

export const projectLocalStorageCloudPendingChangesTable = 'pendingChanges'

export const projectLocalStorageSyncMetaTable = 'syncMeta'

export const getLocalStorageState = (
  workerState: PrimaryWorkerState.WorkerState,
): LocalProjectStorage.State => {
  const { localStorage } = workerState.projectState
  if (!localStorage) {
    throw new Error('localStorage not initialized')
  }
  return localStorage
}

export const getObjectKey = (projectId: number, uuid: string): string =>
  `${projectId}_${uuid}`
