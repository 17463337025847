import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import extensionSlice from './slice'

const extensionStateReducer = persistReducer(
  {
    key: 'extension',
    version: 1,
    storage,
  },
  extensionSlice.reducer,
)

export default extensionStateReducer
