/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useCallback, useRef, useMemo, memo } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { grid, GridProps } from 'styled-system'
import css from '@styled-system/css'
import { useDndReordering } from '../hooks'
import {
  DataTableRenderItem,
  DataTableProps,
  DataTableItem,
} from './data-table.types'
import { DataTableHeader } from './data-table-header'
import { DataTableRow, DataTableEmptyRow } from './data-table-row'

const DataTableStyled = withTheme(
  styled('ul')<GridProps>(
    css({
      display: 'flex',
      flexDirection: 'column',
      minWidth: 'fit-content',
      padding: 0,
      '&.dragging': {
        li: {
          '&.dragged-to': {
            position: 'relative',
            '&::before': {
              content: '""',
              width: '100%',
              height: '2px',
              position: 'absolute',
              backgroundColor: 'borders.borderAcent',
              top: 0,
            },
          },
          '&.dragged-from': {
            '&::before': {
              content: 'unset',
            },
            '& ~.dragged-to::before': {
              top: 'unset',
              bottom: 0,
            },
          },
        },
      },
    }),
    grid,
  ),
)

const RenderItem = <T, K>({
  collapsed: collapsedProp,
  collapsedIds = [],
  depth = 0,
  itemDataSelector,
  item,
  parentId,
  isLastItem,
  itemIndex,
  ...other
}: DataTableRenderItem<T, K>): JSX.Element => {
  const itemData = itemDataSelector ? itemDataSelector(item.data) : item
  const collapsed = collapsedProp || collapsedIds.includes(item.uuid)

  return (
    <React.Fragment>
      <DataTableRow
        {...{
          collapsed,
          depth,
          isParentCollapsed: collapsedIds.includes(item.uuid) && !collapsedProp,
          item: itemData,
          parentId,
          isLastItem,
          itemIndex,
          ...other,
        }}
      />
      {(itemData.children || []).map(
        (
          nestedItem: DataTableItem<T | K>,
          index: number,
          { length }: { length: number },
        ) => (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <RenderItemMemo
            key={nestedItem.uuid}
            {...{
              collapsed,
              collapsedIds,
              itemDataSelector,
              depth: depth + 1,
              item: nestedItem as DataTableItem<T>,
              parentId: itemData.uuid,
              isLastItem: index === length - 1,
              itemIndex: `${itemIndex}.${index}`,
              ...other,
            }}
          />
        ),
      )}
    </React.Fragment>
  )
}

const RenderItemMemo = memo(RenderItem) as typeof RenderItem

export const DataTable = <T, K>({
  items,
  fields,
  onChangeOrder,
  reorderable,
  endAdornment,
  hasCheckbox = false,
  lastRow,
  ...other
}: DataTableProps<T, K>): JSX.Element => {
  const [collapsedIds, setCollapsedIds] = useState<string[]>([])
  const [focusedItemCellIndex, setFocusedItemCellIndex] = useState<
    string | undefined
  >()
  const ref = useRef<HTMLUListElement>(null)
  const { onDragStartList, onDropList } = useDndReordering(ref, onChangeOrder)

  const onToggle = useCallback((id: string) => {
    setCollapsedIds((prevData) => {
      if (prevData) {
        const newData = [...prevData]
        const itemIndex = newData.indexOf(id)
        if (itemIndex > -1) {
          newData.splice(itemIndex, 1)
        } else {
          newData.push(id)
        }
        return newData
      }
      return prevData
    })
  }, [])

  const lastRowPlaceholdersWithKey = useMemo(
    () => lastRow?.placeholders?.map((value, index) => ({ key: index, value })),
    [lastRow],
  )

  return (
    <DataTableStyled
      onDragStart={onDragStartList}
      ref={ref}
      onDrop={onDropList}
    >
      <DataTableHeader
        {...{ fields, reorderable, endAdornment, hasCheckbox }}
      />
      {items &&
        items.map((item, index: number, { length }: { length: number }) => (
          <RenderItemMemo
            key={item.uuid}
            {...{
              collapsedIds,
              item,
              fields,
              onToggle,
              reorderable,
              endAdornment,
              isLastItem: index === length - 1,
              focusedItemCellIndex,
              onSetFocusedItemCellIndex: setFocusedItemCellIndex,
              itemIndex: String(index),
              lastRow,
              lastRowPlaceholdersWithKey,
              hasCheckbox,
              ...other,
            }}
          />
        ))}
      {(!(items && items.length > 0) || lastRow?.topLevelOnly) &&
        lastRow &&
        lastRowPlaceholdersWithKey && (
          <DataTableEmptyRow
            {...{
              fieldsLength: fields.length,
              isParentCollapsed: false,
              lastItemIndex: '0',
              depth: 0,
              reorderable,
              lastRowPlaceholdersWithKey,
              lastRow,
              hasCheckbox,
              isEndAdornment: Boolean(endAdornment),
              onSetFocusedItemCellIndex: setFocusedItemCellIndex,
            }}
          />
        )}
    </DataTableStyled>
  )
}

export default DataTable
