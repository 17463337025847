import type { Project } from 'lib/project/types.d'
import getObject from './get-object'

const getRequest = <Optional extends true | false>(
  ref: Project.GenericRef<Project.Request>,
  objects: Project.ObjectMap,
  isOptional: Optional,
): Optional extends true ? Project.Request | null : Project.Request =>
  getObject(ref, objects, isOptional, 'request')

export default getRequest
