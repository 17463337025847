/* eslint-disable react/jsx-props-no-spreading */

import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant as variantStyled,
  VariantArgs,
} from 'styled-system'
import css from '@styled-system/css'
import { Box } from 'components/layout/box'

export type LinearIndicatorProps = LayoutProps &
  SpaceProps &
  VariantArgs & {
    width?: number
    value?: number
    variant?: 'determinate' | 'indeterminate'
  }

const StyledLinear = withTheme(
  styled.div(
    layout,
    space,
    ({ width }) =>
      css({
        height: 6,
        bg: 'fills.tertiary',
        '& .progress-bar': {
          bg: 'backgrounds.backgroundAccent',
          height: 6,
          position: 'relative',
          overflow: 'hidden',
        },
        '@keyframes indeterminate': {
          '0%': {
            width: width / 2,
            transform: `translateX(-${width / 2}px)`,
          },
          '50%': {
            width,
            transform: `translateX(${width}px)`,
          },
          '100%': {
            width: width / 4,
            transform: `translateX(${width * 4}px)`,
          },
        },
      }),
    ({ value }) =>
      variantStyled({
        variants: {
          indeterminate: {
            '& .progress-bar': {
              animation:
                'indeterminate 1.35s cubic-bezier(0.0, 0.0, 0.2, 1) infinite',
            },
          },
          determinate: {
            '& .progress-bar': {
              width: `${value}%`,
              transition: 'width 1s linear',
            },
          },
        },
      }),
  ),
)

const LinearIndicator: React.FC<LinearIndicatorProps> = ({
  variant = 'indeterminate',
  width = 256,
  value = 0,
  ...other
}) => (
  <Box width={width} my={4} {...other}>
    <StyledLinear
      width={width}
      height="6"
      value={value}
      variant={variant}
      overflow="hidden"
    >
      <div className="progress-bar" />
    </StyledLinear>
  </Box>
)

export default LinearIndicator
