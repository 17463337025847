import type { Project } from 'lib'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCtxEvaluationResultFactory } from 'store/selectors'
import type { OAuth2Utils } from '../oauth2-functions'
import { evaluateOAuth2Params } from '../oauth2-functions'

const useGetEvaluatedOAuth2Params = (
  dynamicValueRef: Project.GenericRef<Project.DynamicValue>,
): OAuth2Utils.EvaluatedOAuth2Params | null => {
  const [selector, setSelector] = useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => OAuth2Utils.EvaluatedOAuth2Params | null
  >(() => () => null)
  const ctxSelector = useMemo(
    () => selectCtxEvaluationResultFactory((_, context) => context),
    [],
  )
  const ctx = useSelector(ctxSelector)

  useEffect(() => {
    if (!ctx) {
      return
    }
    evaluateOAuth2Params({ ref: dynamicValueRef.ref }, ctx).then((data) => {
      setSelector(() => selectCtxEvaluationResultFactory(() => data))
    })
  }, [ctx, dynamicValueRef])

  return useSelector(selector)
}

export default useGetEvaluatedOAuth2Params
