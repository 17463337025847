import React from 'react'
import type { LinkProps } from 'react-router-dom'
import { Link, useRouteMatch } from 'react-router-dom'
import { A, Box, Icon, Tooltip, styled } from '@rapidapi/ui-lib'
import type { IconSymbol } from '@rapidapi/ui-lib'

import css from '@styled-system/css'
import { variant } from 'styled-system'

export type NavItemProps = Pick<LinkProps, 'to'> & {
  title: string
  icon: IconSymbol
}

const NavItemStyled = styled(Box)<{ variant: 'default' | 'selected' }>(
  css({
    borderRadius: 'default',
    paddingTop: 2,
    paddingBottom: 2,
  }),
  () =>
    variant({
      variants: {
        default: {},
        selected: {
          backgroundColor: 'backgrounds.backgroundAccent',
        },
      },
    }),
)

const NavItem: React.FC<NavItemProps> = ({ title, icon, to }) => {
  const selected = useRouteMatch((to as string) ?? '')?.isExact
  return (
    <Tooltip content={title} placement="right">
      <NavItemStyled variant={selected ? 'selected' : 'default'}>
        <A css={{ display: 'block' }} as={Link} to={to}>
          <Icon symbol={icon} size={24} color="texts.highEmphasisConstant" />
        </A>
      </NavItemStyled>
    </Tooltip>
  )
}

export default NavItem
