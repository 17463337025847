import type { Project } from 'lib/project/types.d'
import getObject from './get-object'

const getParameter = <Optional extends true | false>(
  ref: Project.GenericRef<Project.Parameter>,
  objects: Project.ObjectMap,
  isOptional: Optional,
): Optional extends true ? Project.Parameter | null : Project.Parameter =>
  getObject(ref, objects, isOptional, 'parameter')

export default getParameter
