import type { Evaluation } from 'lib/evaluation'
import type { Project } from 'lib/project'
import { getDynamicValue, getParameter } from 'lib/project'
import type { OAuth2DynamicValueInterface } from 'lib/dynamic-values/implementations/oauth2-dynamic-value/types.d'

import type { OAuth2Utils } from './oauth2-functions-types.d'

const evaluateExtraParams = async (
  refs: Project.GenericRef<Project.Parameter>[] | null,
  ctx: Evaluation.Ctx,
): Promise<OAuth2Utils.EvaluatedOAuth2ExtraParam[]> =>
  Promise.all(
    (refs || [])
      .map((ref) => getParameter(ref, ctx.project.objects, false))
      .filter((param) => param && param.enabled)
      .map(
        async (param): Promise<OAuth2Utils.EvaluatedOAuth2ExtraParam> => ({
          key: param.key
            ? await ctx.evals.evaluateDynamicString(param.key, ctx)
            : '',
          value: param.value
            ? await ctx.evals.evaluateDynamicString(param.value, ctx)
            : '',
        }),
      ),
  )

const evaluateOAuth2Params = async (
  dynamicValueRef: Project.GenericRef<OAuth2DynamicValueInterface>,
  ctx: Evaluation.Ctx,
): Promise<OAuth2Utils.EvaluatedOAuth2Params | null> => {
  // get dv
  const dv = getDynamicValue(dynamicValueRef, ctx.project.objects, true)
  if (!dv) {
    return null
  }

  // evaluate all params
  const result: OAuth2Utils.EvaluatedOAuth2Params = {
    grantType: dv.grantType,
    explicitCallbackURL: dv.explicitCallbackURL,
    sendClientCredentialsInBody: dv.sendClientCredentialsInBody,
    strict: dv.strict,
    clientID: dv.clientID
      ? await ctx.evals.evaluateDynamicString(dv.clientID, ctx)
      : '',
    clientSecret: dv.clientSecret
      ? await ctx.evals.evaluateDynamicString(dv.clientSecret, ctx)
      : '',
    username: dv.username
      ? await ctx.evals.evaluateDynamicString(dv.username, ctx)
      : '',
    password: dv.password
      ? await ctx.evals.evaluateDynamicString(dv.password, ctx)
      : '',
    authorizationURL: dv.authorizationURL
      ? await ctx.evals.evaluateDynamicString(dv.authorizationURL, ctx)
      : '',
    accessTokenURL: dv.accessTokenURL
      ? await ctx.evals.evaluateDynamicString(dv.accessTokenURL, ctx)
      : '',
    callbackURL: dv.callbackURL
      ? await ctx.evals.evaluateDynamicString(dv.callbackURL, ctx)
      : '',
    scope: dv.scope ? await ctx.evals.evaluateDynamicString(dv.scope, ctx) : '',
    stateNonce: dv.stateNonce
      ? await ctx.evals.evaluateDynamicString(dv.stateNonce, ctx)
      : '',
    refreshToken: dv.refreshToken
      ? await ctx.evals.evaluateDynamicString(dv.refreshToken, ctx)
      : '',
    authRequestParams: await evaluateExtraParams(dv.authRequestParams, ctx),
    tokenRequestParams: await evaluateExtraParams(dv.tokenRequestParams, ctx),
  }
  return result
}

export default evaluateOAuth2Params
