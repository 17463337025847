import { setProject } from 'store/actions'
import type { PrimaryWorkerState } from 'worker/primary-worker/implementation/types.d'
import type { NotificationHandler } from '../types.d'

const handleDidSyncProject = async ({
  notification,
  next,
}: NotificationHandler<PrimaryWorkerState.ProjectStateSyncReturn>): Promise<void> => {
  const {
    root,
    objects,
    objectsBase,
    syncBranch,
    syncCommitMeta,
    syncBranches,
    cloudPendingChangesCount,
    realtimeIsConnected,
    syncAction,
  } = notification.payload

  if (
    syncAction === 'pullWithFullReturn' ||
    syncAction === 'pushWithFullReturn'
  ) {
    // pull with a full return, update everything
    next(
      setProject({
        root,
        objects,
        objectsBase,
        syncState: {
          syncBranch,
          syncCommitMeta,
          syncBranches,
          cloudPendingChangesCount,
          realtimeIsConnected,
        },
      }),
    )
  } else if (
    syncAction === 'pushWithNoReturn' ||
    syncAction === 'pullWithNoChange'
  ) {
    // push or pull with no change, only update sync state
    next(
      setProject({
        syncState: {
          syncBranch,
          syncCommitMeta,
          syncBranches,
          cloudPendingChangesCount,
          realtimeIsConnected,
        },
      }),
    )
  } else {
    throw new Error(`Invalid value for syncAction: ${syncAction}`)
  }
}

export default handleDidSyncProject
