import type { Project } from 'lib/project/types.d'
import getObject from './get-object'

const getKeyValue = <Optional extends true | false>(
  ref: Project.GenericRef<Project.KeyValue>,
  objects: Project.ObjectMap,
  isOptional: Optional,
): Optional extends true ? Project.KeyValue | null : Project.KeyValue =>
  getObject(ref, objects, isOptional, 'parameter')

export default getKeyValue
