/* eslint-disable react/jsx-props-no-spreading */

import {
  createRef,
  useEffect,
  useState,
  useCallback,
  MouseEvent,
  forwardRef,
} from 'react'
import {
  Typography,
  TypographyRefProps,
} from 'components/data-display/typography'
import { Box } from 'components/layout/box'
import { Flex } from 'components/layout/flex'
import { Icon } from 'components/data-display/icon'
import { InputContainer } from 'components/inputs/input-container'
import { ContentColors } from '../../../themes/common.types'
import { TextFieldComponentProps, TextFieldRefProps } from './text-field.types'
import { InputBase } from './input-base'

export const TextField = forwardRef<TextFieldRefProps, TextFieldComponentProps>(
  (
    {
      color = 'highEmphasis',
      disabled = false,
      error = false,
      editOnDoubleClick = false,
      embedded = false,
      endAdornment,
      focused = false,
      hint,
      hintIcon,
      height,
      minHeight,
      inputBaseProps,
      inputContainerProps,
      label,
      limit,
      multiline = false,
      password = false,
      placeholder,
      readOnly = false,
      representation = 'default',
      startAdornment,
      value,
      variant = 'default',
      width,
      onChange,
      onPressEnter: onPressEnterProp,
      onPressEscape,
      onBlur: onBlurProp,
      ...other
    },
    forwardedRef,
  ) => {
    const [errorState, setErrorState] = useState(error)
    const [editable, setEditable] = useState(false)
    const [caretPosition, setCaretPosition] = useState<number>()
    const [inputTextLength, setInputTextLength] = useState<number>(
      value?.length ?? 0,
    )
    const ref = createRef<TypographyRefProps>()

    const contentColor = (
      defaultColor: ContentColors = 'mediumEmphasis',
    ): ContentColors =>
      (disabled && 'lowEmphasis') ||
      (errorState && 'textDanger') ||
      defaultColor

    useEffect(() => {
      if (limit !== undefined) {
        setErrorState(inputTextLength > limit)
      }
    }, [inputTextLength, limit])

    const onKeyUp = useCallback(() => {
      if (limit === undefined) {
        return
      }
      setInputTextLength(ref.current?.textContent?.length ?? 0)
    }, [ref, limit])

    const onFocus = useCallback(() => {
      if (editOnDoubleClick) {
        return
      }

      setEditable(true)
      setCaretPosition(0)
    }, [editOnDoubleClick])

    const onClickAndDoubleClick = useCallback(
      (event: MouseEvent) => {
        if (readOnly || (event.type === 'click' && editOnDoubleClick)) {
          return
        }
        setEditable(true)

        const selection = window.getSelection()
        if (!selection) {
          return
        }

        setCaretPosition(selection.anchorOffset)
      },
      [readOnly, editOnDoubleClick],
    )

    const onPressEnter = useCallback(
      (val) => {
        if (multiline) {
          return
        }
        if (representation === 'hover') {
          setEditable(false)
        }
        if (onPressEnterProp) {
          onPressEnterProp(val)
        }
      },
      [onPressEnterProp, representation, multiline],
    )

    const onBlur = useCallback(
      (val) => {
        if (representation === 'hover' || editable) {
          setEditable(false)
        }

        // A performance hack
        if (caretPosition === undefined) {
          return
        }

        setCaretPosition(undefined)

        if (onBlurProp) {
          onBlurProp(val)
        }
      },
      [onBlurProp, representation, editable, caretPosition],
    )

    return (
      <Box
        ref={forwardedRef}
        verticalAlign="top"
        width={width ?? (representation === 'hover' ? 'auto' : '25ch')}
        {...other}
      >
        {label && (
          <Typography
            variant={variant === 'large' ? 'body1' : 'body2'}
            mb={1}
            color={contentColor('highEmphasis')}
          >
            {label}
          </Typography>
        )}
        <InputContainer
          data-role="input"
          error={errorState}
          focused={focused || (representation === 'hover' && editable)}
          width="100%"
          {...{
            disabled,
            embedded,
            multiline,
            startAdornment,
            endAdornment,
            representation,
            editable,
            variant,
            ...inputContainerProps,
          }}
        >
          <InputBase
            focusMode={
              representation === 'hover' || !inputBaseProps?.focusMode
                ? 'select-all'
                : inputBaseProps.focusMode
            }
            minHeight={minHeight ?? (variant === 'large' ? 24 : 20)}
            tabIndex={0}
            onClick={onClickAndDoubleClick}
            onDoubleClick={onClickAndDoubleClick}
            ml={startAdornment ? 1 : undefined}
            mr={endAdornment ? 1 : undefined}
            overflow="hidden"
            ellipsis={!editable}
            variant={variant === 'large' ? 'body1' : 'body2'}
            {...{
              caretPosition,
              color,
              disabled,
              editable,
              embedded,
              error,
              height,
              multiline,
              password,
              placeholder,
              onBlur,
              onChange,
              onFocus,
              onKeyUp,
              onPressEnter,
              onPressEscape,
              ref,
              readOnly,
              value,
              ...inputBaseProps,
            }}
          />
        </InputContainer>
        {(hintIcon || hint || limit) && (
          <Flex alignItems="flex-start" justifyContent="space-between" mt={1}>
            {hintIcon && (
              <Icon
                symbol={hintIcon}
                size={14}
                mr={1}
                color={`texts.${contentColor()}`}
              />
            )}
            {hint && (
              <Box minWidth="auto">
                <Typography variant="caption" color={contentColor()}>
                  {hint}
                </Typography>
              </Box>
            )}
            {limit && (
              <Box minWidth="auto" ml={2}>
                <Typography variant="caption" color={contentColor()}>
                  {inputTextLength}/{limit}
                </Typography>
              </Box>
            )}
          </Flex>
        )}
      </Box>
    )
  },
)

export default TextField
