export const base64EncodingDynamicValueModeEncode = 0
export const base64EncodingDynamicValueModeDecode = 1

export const base64EncodingDynamicValueNewlinesDisabledMode = 0
export const base64EncodingDynamicValueNewlinesAfter64Mode = 1
export const base64EncodingDynamicValueNewlinesAfter76Mode = 2

export const base64EncodingDynamicValueLineBreakCRLFMode = 0
export const base64EncodingDynamicValueLineBreakCRMode = 1
export const base64EncodingDynamicValueLineBreakLFMode = 2
