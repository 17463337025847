/* eslint-disable import/prefer-default-export */
import {
  base64Decode,
  base64DecodeString,
  base64EncodeString,
} from 'lib/utils/encoding'
import type { Evaluation } from 'lib/evaluation'
import type { RequestHandling } from '../types.d'

export const getHttpMessageBodyString = (
  httpMessage: RequestHandling.AnyHTTPMessage,
): string | null => {
  const body = httpMessage?.body
  if (!body || typeof body !== 'object') {
    return null
  }
  return (body && base64DecodeString(body?.text, 'utf-8')) || null
}

export const getHttpMessageBodyBinary = (
  httpMessage: RequestHandling.AnyHTTPMessage,
): Uint8Array | null => {
  const body = httpMessage?.body
  if (!body || typeof body !== 'object') {
    return null
  }
  return (body && base64Decode(body?.text)) || null
}

export const getHttpMessageContentTypeHeader = (
  httpMessage: RequestHandling.AnyHTTPMessage,
): string | null => {
  const contentTypeHeader = httpMessage.headers.find(
    (header) => header.name.toLowerCase() === 'content-type',
  )
  return contentTypeHeader?.value || null
}

export const getHttpMessageContentType = (
  httpMessage: RequestHandling.AnyHTTPMessage,
): string | null => {
  const contentTypeHeader = getHttpMessageContentTypeHeader(httpMessage)
  const match = contentTypeHeader ? contentTypeHeader.match(/^([^;]+)/) : null
  if (!match) {
    return null
  }
  return match[1]
}

export const getHttpMessageHeaderObject = (
  httpMessage: RequestHandling.AnyHTTPMessage,
): Record<string, string> =>
  Object.fromEntries(
    httpMessage.headers.map((header) => [
      header.name.toLowerCase(),
      header.value,
    ]),
  )

export const getHTTPExchangeRequest = (
  evaluatedRequest: Evaluation.EvaluatedRequest,
): RequestHandling.HTTPExchangeRequest => {
  const request: RequestHandling.HTTPExchangeRequest = {
    method: evaluatedRequest.method,
    url: evaluatedRequest.url,
    headers: evaluatedRequest.headers.map(
      ({ name, value }): RequestHandling.HTTPExchangeHeader => ({
        name,
        value,
      }),
    ),
    // @TODO sending binary body won't work
    body: {
      encoding: 'base64',
      text: base64EncodeString(evaluatedRequest.body, 'utf-8'),
    },
  }
  return request
}

export const getMessageByteSize = (
  httpMessage: RequestHandling.AnyHTTPMessage | null,
): number => {
  if (!httpMessage) {
    return 0
  }

  // estimate the size of the body without decoding base64
  // see: https://stackoverflow.com/a/13378842
  const byteSize = httpMessage.body.text.length * (3 / 4)

  return Math.round(byteSize)
}
