/* eslint-disable react/jsx-props-no-spreading */

import { KeyboardEvent, forwardRef } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import css from '@styled-system/css'
import { Elevation } from 'components/data-display/elevation'
import { Popover } from 'components/data-display/popover'
import { MenuRefProps, MenuComponentProps } from './menu.types'

const MenuStyled = withTheme(
  styled(Elevation)<Pick<MenuComponentProps, 'fullWidth' | 'maxHeight'>>(
    ({ fullWidth, maxHeight, theme: { isDark } }) =>
      css({
        backgroundColor: `backgrounds.${
          isDark ? 'backgroundElevated2' : 'backgroundPrimary'
        }`,
        borderColor: 'borders.borderDefault',
        borderStyle: 'solid',
        borderWidth: '1px',
        margin: 0,
        maxHeight,
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: 2,
        width: fullWidth ? '100%' : 'fit-content',
      }),
  ),
)

export const Menu = forwardRef<MenuRefProps, MenuComponentProps>(
  (
    { children, fullWidth = false, maxHeight = 210, popoverProps, ...other },
    forwardedRef,
  ) => {
    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault()
      event.stopPropagation()

      if (event.key === 'ArrowDown') {
        const nextElement = document.activeElement?.nextSibling as HTMLElement
        if (nextElement) {
          nextElement.focus()
        }
      }
      if (event.key === 'ArrowUp') {
        const prevElement = document.activeElement
          ?.previousSibling as HTMLElement
        if (prevElement) {
          prevElement.focus()
        }
      }

      if (event.key === 'Escape' && popoverProps.onClose) {
        popoverProps.onClose()
      }
    }

    return (
      <Popover {...popoverProps}>
        <MenuStyled
          data-role="menu"
          ref={forwardedRef}
          variant="medium"
          {...{ fullWidth, maxHeight, onKeyDown, ...other }}
        >
          {children}
        </MenuStyled>
      </Popover>
    )
  },
)

export default Menu
