import { useEffect, useState } from 'react'

import type { CloudAPI } from 'lib/cloud-sync/cloud-api'
import { getProject } from 'lib/cloud-sync/cloud-api'
import { getOAuthToken } from './cloud-api-auth'

type Status = 'idle' | 'loading' | 'succeeded' | 'failed'

type UseGetProjectResult = {
  status: Status
  error: Error | null
  response: CloudAPI.ProjectInfoResponse | null
}

const useGetProject = (projectId: number | null): UseGetProjectResult => {
  const [status, setStatus] = useState<Status>('idle')
  const [error, setError] = useState<Error | null>(null)
  const [response, setResponse] = useState<CloudAPI.ProjectInfoResponse | null>(
    null,
  )

  useEffect(() => {
    if (!projectId) {
      setStatus('idle')
      setResponse(null)
      setError(null)
      return
    }

    ;(async () => {
      setStatus('loading')
      try {
        // get token
        const token = getOAuthToken()
        if (!token) {
          setError(new Error('User is not authenticated'))
          setStatus('failed')
          return
        }

        // perform call
        const res = await getProject(projectId, token)

        // success
        setResponse(res)
        setStatus('succeeded')
      } catch (anError) {
        // failure
        setError(anError as Error)
        setStatus('failed')
      }
    })()
  }, [projectId])

  return {
    status,
    error,
    response,
  }
}

export default useGetProject
