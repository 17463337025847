import type { Project } from 'lib/project/types.d'
import type { Environments } from 'lib/environments/types.d'
import getValueForVariable from 'lib/project/getters/molecules/get-value-for-variable'
import getDomainForVariable from 'lib/project/getters/molecules/get-domain-for-variable'

const getCurrentValueForVariable = (
  variableRef: Project.GenericRef<Project.EnvironmentVariable>,
  objects: Project.ObjectMap,
  currentEnvMap: Environments.CurrentEnvironmentMap,
): Project.EnvironmentVariableValue | null => {
  const domain = getDomainForVariable(variableRef, objects)
  if (!domain) {
    return null
  }
  const environmentUuid = currentEnvMap[domain.uuid]
  if (!environmentUuid) {
    return null
  }
  const environmentRef: Project.GenericRef<Project.Environment> = {
    ref: environmentUuid,
  }
  return getValueForVariable(variableRef, environmentRef, objects)
}

export default getCurrentValueForVariable
