import type { JSONItem } from 'lib/dynamic-values/implementations/json-dynamic-value/parser'
import { parseJson } from 'lib/dynamic-values/implementations/json-dynamic-value/parser'
import { useMemo } from 'react'
import { useDebounce } from 'utils'
import jsonpath from 'jsonpath'
import { getChildrenItems } from 'lib/dynamic-values/implementations/json-dynamic-value/parser/json-functions'
import type { DataJSONItem } from './json-data-table-types.d'

export type JsonTreeFilteredContentType<T> = [
  DataJSONItem[] | null,
  number,
  T | null,
]

const useJsonTreeBuilder = <T>(
  jsonTreeContent: string,
  errFactory?: () => T,
): {
  filterKeyword: string
  filterKeywordDebounce: string
  bindFilterKeyword: React.Dispatch<string>
  jsonTreeFilteredContent: DataJSONItem[] | null
  jsonTreeFilteredContentResults: number
  jsonTreeFilteredContentError: T | null
} => {
  const [filterKeywordDebounce, filterKeyword, bindFilterKeyword] =
    useDebounce<string>('', 800)

  const [
    jsonTreeFilteredContent,
    jsonTreeFilteredContentResults,
    jsonTreeFilteredContentError,
  ] = useMemo((): JsonTreeFilteredContentType<T> => {
    let jsonRoot: JSONItem<string>
    let context: DataJSONItem[] | undefined | null

    function buildDataJSONItems(): JsonTreeFilteredContentType<T> {
      try {
        jsonRoot = parseJson(jsonTreeContent)
        context = getChildrenItems(jsonRoot, []) || null
        return [context, 0, null]
      } catch (err) {
        return [null, 0, errFactory ? errFactory() : null]
      }
    }

    if (filterKeywordDebounce.length === 0) {
      return buildDataJSONItems()
    }

    try {
      const data = JSON.parse(jsonTreeContent)
      const query = jsonpath.query(data, filterKeywordDebounce)

      if (query.length > 0) {
        jsonRoot = parseJson(JSON.stringify(query))
        context = getChildrenItems(jsonRoot, []) || null
        return [context, query.length, null]
      }
    } catch (err) {
      if (err instanceof SyntaxError) {
        return [null, 0, null]
      }
    }

    return buildDataJSONItems()
  }, [jsonTreeContent, filterKeywordDebounce, errFactory])
  return {
    filterKeyword,
    bindFilterKeyword,
    jsonTreeFilteredContent,
    jsonTreeFilteredContentResults,
    jsonTreeFilteredContentError,
    filterKeywordDebounce,
  }
}
export default useJsonTreeBuilder
