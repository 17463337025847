import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Hotkey, hkCloseDialog } from 'utils'
import { usePortalRoot } from './dialog-context'

export interface DialogHooks {
  elementRef: HTMLDivElement
  open: boolean
  disableBackdropClick?: boolean | undefined
  onClose?: () => void | undefined
}

const useDialog = ({
  elementRef,
  open,
  disableBackdropClick,
  onClose,
}: DialogHooks): {
  ref: MutableRefObject<HTMLDivElement>
  close: () => void
  portalRoot: HTMLDivElement
  isVisible: boolean
} => {
  const portalRoot = usePortalRoot()
  const [visibility, setVisibility] = useState(false)

  const ref = useRef<HTMLDivElement>(elementRef)
  const getRefCurrent = useCallback(
    () =>
      ref && typeof ref !== 'function' && ref.current ? ref.current : undefined,
    [ref],
  )

  useEffect(() => {
    // const refCurrent = getRefCurrent()
    if (open === true && portalRoot !== null) {
      setVisibility(open)
      portalRoot.focus()
      return
    }

    if (open === false && portalRoot !== null && ref && ref.current) {
      ref.current.classList.toggle('close')
      setTimeout(() => setVisibility(false), 250)
    }
  }, [ref, setVisibility, open, portalRoot])

  useEffect(() => {
    const onClickOutside = (event: Event) => {
      const eventTargetElement = event.target as HTMLElement

      if (
        ref &&
        eventTargetElement &&
        eventTargetElement.contains(ref.current) &&
        !ref.current.contains(event.target as Node) &&
        onClose
      ) {
        onClose()
      }
    }

    if (!disableBackdropClick) {
      document.addEventListener('mousedown', onClickOutside)
      return () => document.removeEventListener('mousedown', onClickOutside)
    }
    return undefined
  }, [ref, onClose, disableBackdropClick, setVisibility])

  // close hotkey (escape)
  // only bind when dialog is open to prevent blocking other (opened)
  // dialogs from receiving the event
  useEffect(() => {
    if (!open || !onClose) {
      return undefined
    }
    Hotkey.bind(hkCloseDialog.hotkey, () => {
      onClose()
      return false
    })
    return () => {
      Hotkey.unbind(hkCloseDialog.hotkey)
    }
  }, [open, onClose])

  useEffect(() => {
    document.body.classList.toggle('disable-overflow', visibility)
    return () => document.body.classList.remove('disable-overflow')
  }, [visibility])

  const close = useCallback(() => {
    const refCurrent = getRefCurrent()
    if (open === false && portalRoot !== null && refCurrent) {
      refCurrent.classList.toggle('close')
      setTimeout(() => setVisibility(false), 250)
    }
  }, [getRefCurrent, portalRoot, setVisibility, open])

  return {
    ref,
    close,
    portalRoot,
    isVisible: visibility,
  }
}

export default useDialog
