import type { PayloadAction } from '@reduxjs/toolkit'
import type { RequestHandling } from 'lib'
import type ExtensionStore from 'store/slices/extensions/types.d'
import { extensionImportRequestsWorkerAction } from 'worker/primary-worker/actions'
import type { ActionHandler } from '../types.d'

const extensionsImportRequestsActionHandler = async ({
  action: { payload },
  worker,
}: ActionHandler<
  PayloadAction<{
    extension: ExtensionStore.Importer
    content: RequestHandling.FileStored
  }>
>): Promise<void> => {
  // if no worker, throw
  if (!worker) {
    throw new Error('Worker is missing')
  }

  try {
    await worker.runAction(extensionImportRequestsWorkerAction, {
      ...payload,
    })
  } catch (error) {
    const { message } = error as Error
    window.console.warn(`Import failed: ${message}`)
  }
}

export default extensionsImportRequestsActionHandler
