/* eslint-disable react/jsx-props-no-spreading */

import { forwardRef } from 'react'
import { withTheme } from '@emotion/react'
import { flexbox, space, layout } from 'styled-system'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { Flex } from 'components/layout/flex'
import { SidebarComponentProps, SidebarRefProps } from './sidebar.types'

const SidebarStyled = withTheme(
  styled(Flex)(
    css({
      width: 240,
      p: 4,
      flexDirection: 'column',
      borderRightColor: 'borders.borderDefault',
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
    }),
    flexbox,
    layout,
    space,
  ),
)

export const Sidebar = forwardRef<SidebarRefProps, SidebarComponentProps>(
  ({ children, headerComponent, ...other }, forwardedRef) => (
    <SidebarStyled ref={forwardedRef} {...other}>
      {headerComponent}
      {children}
    </SidebarStyled>
  ),
)

export default Sidebar
