import type { Project } from 'lib/project'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { applyProjectSetter } from 'store/actions'

const useAddKeyValue = (
  objectRef: Project.GenericRef<Project.Request>,
  objectProperty: Project.GetListKeys<Project.Request>,
): (() => void) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    if (!objectRef || !objectRef.ref) {
      return
    }
    dispatch(
      applyProjectSetter('addKeyValue')({
        parentRef: objectRef,
        parentProperty: objectProperty,
      }),
    )
  }, [dispatch, objectRef, objectProperty])
}
export default useAddKeyValue
