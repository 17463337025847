import type { PayloadAction } from '@reduxjs/toolkit'
import { projectSyncWorkerAction } from 'worker/primary-worker/actions'
import type { WorkerProjectSync } from 'worker/primary-worker/implementation/project-sync/worker-project-sync.types'
import { setProject, updateSyncingActionCount } from 'store/actions'
import { segmentTrack } from 'utils/segment/segment-functions'

import type { ActionHandler } from '../types.d'

const projectSyncActionHandler = async ({
  next,
  worker,
  action,
}: ActionHandler<
  PayloadAction<WorkerProjectSync.SyncOptions | undefined>
>): Promise<void> => {
  // check worker
  if (!worker) {
    throw new Error('Worker is missing')
  }

  // analytics log
  segmentTrack('Project Sync Manual')

  // increment syncing counter
  next(updateSyncingActionCount(1))

  let result
  try {
    result = await worker.runAction(
      projectSyncWorkerAction,
      action.payload || {
        operation: 'sync' as const,
      },
    )
  } catch (error) {
    // @TODO show a message to the user
    // eslint-disable-next-line no-console
    console.error('[sync project] syncing error', error)
    return
  } finally {
    // decrement syncing counter
    next(updateSyncingActionCount(-1))
  }

  const {
    root,
    objects,
    objectsBase,
    syncBranch,
    syncCommitMeta,
    syncBranches,
    cloudPendingChangesCount,
    realtimeIsConnected,
    syncAction,
  } = result

  if (
    syncAction === 'pullWithFullReturn' ||
    syncAction === 'pushWithFullReturn'
  ) {
    // pull with a full return, update everything
    next(
      setProject({
        root,
        objects,
        objectsBase,
        syncState: {
          syncBranch,
          syncCommitMeta,
          syncBranches,
          cloudPendingChangesCount,
          realtimeIsConnected,
        },
      }),
    )
  } else if (
    syncAction === 'pushWithNoReturn' ||
    syncAction === 'pullWithNoChange'
  ) {
    // push or pull with no change, only update sync state
    next(
      setProject({
        syncState: {
          syncBranch,
          syncCommitMeta,
          syncBranches,
          cloudPendingChangesCount,
          realtimeIsConnected,
        },
      }),
    )
  } else {
    throw new Error(`Invalid value for syncAction: ${syncAction}`)
  }
}

export default projectSyncActionHandler
