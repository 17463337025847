import type { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import type { Project } from 'lib/project/types.d'
import {
  assertType,
  serializeAnything,
} from 'lib/cloud-sync/serializer/helpers'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

const serializeDynamicString = (
  serializer: CloudSerializer.ProjectSerializer,
  object: Project.DynamicString,
): CloudAPI.SyncTreeItem => {
  // check type
  assertType(object, 'dynamicString')

  const syncTreeItem: CloudAPI.SyncTreeItem = {
    _uuid: object.uuid,
    _type: 'dynamicString',
    strings: serializeAnything(serializer, object.strings),
  }
  return syncTreeItem
}

export default serializeDynamicString
