// @TODO we could import this as a polyfill and use the native version otherwise
import 'fast-text-encoding'

export const stringToByteArray = (
  input: string,
  encoding: 'utf-8',
): Uint8Array => {
  if (encoding?.toLowerCase() !== 'utf-8') {
    throw new Error('Only supports utf-8')
  }
  const encoder = new TextEncoder()
  return encoder.encode(input)
}

export const byteArrayToString = (
  input: Uint8Array,
  encoding: string,
): string => {
  const decoder = new TextDecoder(encoding)
  return decoder.decode(input)
}
