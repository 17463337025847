import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'store/root-reducer'
import type { Project } from 'lib/project/types.d'
import getValueForVariable from 'lib/project/getters/molecules/get-value-for-variable'
import getEnvironmentDomain from 'lib/project/getters/atoms/get-environment-domain'
import getEnvironment from 'lib/project/getters/atoms/get-environment'
import {
  selectProjectRoot,
  selectProjectObjects,
} from '../project/selectors/internal'
import type { EnvironmentsState } from './types.d'

export const selectEnvironments = (state: RootState): EnvironmentsState =>
  state.environments

export const selectCurrentEnvironments = createSelector(
  selectEnvironments,
  selectProjectRoot,
  selectProjectObjects,
  (environments, root, objects) => {
    const domains = root?.environmentDomains
    const currentEnvironments = { ...environments.currentEnvironments }
    if (domains) {
      // find the domains that are missing in the current environment map
      // we need to add an arbitrary "current environment" for each missing domain
      // so each domain always has a "current environment"
      domains
        .filter(({ ref }) => !currentEnvironments[ref])
        .forEach((domainRef) => {
          const domain = getEnvironmentDomain(domainRef, objects, false)
          if (domain && domain.environments.length > 0) {
            const [environment] = domain.environments
            currentEnvironments[domain.uuid] = environment.ref
          }
        })
    }
    return currentEnvironments
  },
)

export const selectEnvironmentVariables = (
  domainRef: Project.GenericRef<Project.EnvironmentDomain>,
  environmentRef: Project.GenericRef<Project.Environment>,
): ((state: RootState) => (Project.EnvironmentVariableValue | null)[]) =>
  createSelector(selectProjectObjects, (objects) =>
    getEnvironmentDomain(domainRef, objects, false).variables.map(
      (variableRef) =>
        getValueForVariable(variableRef, environmentRef, objects),
    ),
  )

/**
 * A "selector factory" that creates a selector for the given env domain.
 * Note: for performance reasons, selector factories should be memoized
 * in the component that is calling it.
 * @param domainUuid An object ref
 * @returns A selector for that object
 */
export const selectCurrentEnvironmentForDomainFactory = (
  domainUuid: string,
): ((state: RootState) => string | null) =>
  createSelector(
    selectCurrentEnvironments,
    (currentEnvironments) => currentEnvironments[domainUuid] || null,
  )

/**
 * A "selector factory" that creates a selector for the given env domain.
 * Note: for performance reasons, selector factories should be memoized
 * in the component that is calling it.
 * @param domainUuid An object ref
 * @returns A selector for that object
 */
export const selectCurrentEnvironmentObjectForDomainFactory = (
  domainUuid: string,
): ((state: RootState) => Project.Environment | null) =>
  createSelector(
    selectCurrentEnvironments,
    selectProjectObjects,
    (currentEnvironments, objects) => {
      const envUuid = currentEnvironments[domainUuid] || null
      if (!envUuid) {
        return null
      }
      return getEnvironment({ ref: envUuid }, objects, false)
    },
  )
