/* eslint-disable react/jsx-props-no-spreading */

import {
  KeyboardEvent,
  forwardRef,
  MutableRefObject,
  FormEvent,
  useState,
  useEffect,
  useCallback,
  createRef,
} from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import css from '@styled-system/css'
import setCaret from 'utils/set-caret'
import {
  Typography,
  TypographyComponentProps,
  TypographyRefProps,
} from 'components/data-display'
import { InputBaseComponentProps } from './input-base.types'

const TypographyComponent = forwardRef<
  TypographyRefProps,
  Omit<TypographyComponentProps, 'onBlur' | 'onClick' | 'onChange'>
>((props, forwardedRef) => <Typography ref={forwardedRef} {...props} />)

const InputComponentStyled = withTheme(
  styled(TypographyComponent)<InputBaseComponentProps>(
    ({
      editable,
      embedded,
      height,
      minHeight,
      multiline,
      password,
      placeholder = '',
      value,
      width = '100%',
    }) =>
      css({
        alignItems: 'center',
        backgroundClip: 'padding-box',
        border: 'none',
        cursor: embedded && !editable ? 'inherit' : 'text',
        display: !multiline ? 'block' : undefined,
        height,
        minHeight,
        outline: '0 none',
        overflow: 'hidden',
        position: 'relative',
        resize: 'none',
        whiteSpace: !multiline ? 'nowrap' : undefined,
        width,
        userSelect: 'none',
        zIndex: 10,
        // \u200b – a hack to keep a proper height for empty
        '&:empty:before': {
          color: 'texts.lowEmphasis',
          content: `"${
            value === undefined || (value?.trim().length === 0 && placeholder)
              ? placeholder
              : '\u200b'
          }"`,
          left: 0,
          overflow: 'hidden',
          position: 'absolute',
          textOverflow: 'ellipsis',
          width: '100%',
          whiteSpace: 'nowrap',
        },
        '&[disabled], &:disabled': {
          color: 'texts.lowEmphasis',
          cursor: 'default',
          '&::placeholder': {
            color: 'texts.lowEmphasis',
          },
        },
        ...(password
          ? {
              '&:not(:empty)': {
                '-webkit-text-security': 'disc',
              },
            }
          : undefined),
      }),
  ),
)

export const InputBase = forwardRef<
  TypographyRefProps,
  InputBaseComponentProps
>(
  (
    {
      caretPosition,
      color = 'highEmphasis',
      disabled = false,
      editable = true,
      ellipsis = false,
      embedded = false,
      focusMode = 'default',
      multiline = false,
      password = false,
      readOnly = false,
      value,
      onBlur: onBlurProp,
      onChange,
      onPressEnter,
      onPressEscape,
      ...other
    },
    forwardedRef,
  ) => {
    const ref = forwardedRef
      ? (forwardedRef as MutableRefObject<TypographyRefProps | null>)
      : createRef<TypographyRefProps>()
    const [setupFocus, setSetupFocus] = useState(false)
    const [refCurrent, setRefCurrent] = useState<HTMLSpanElement | null>(null)
    const [inputValue, setInputValue] = useState<string | undefined>(value)

    useEffect(() => {
      if (refCurrent?.textContent !== value) {
        setInputValue(value)
      }
    }, [refCurrent, value])

    useEffect(() => {
      setRefCurrent(ref?.current)
    }, [ref])

    useEffect(() => {
      if (!refCurrent || refCurrent.textContent === value) {
        return
      }

      refCurrent.textContent = value ?? ''

      // A hack to fix a placeholder that moves to the left in case
      // when the text is longer than input viewport
      if (
        refCurrent.textContent.trim() === '' &&
        typeof refCurrent.scrollTo === 'function'
      ) {
        refCurrent.scrollTo({ left: 0, top: 0 })
      }
    }, [refCurrent, value])

    useEffect(() => {
      if (refCurrent && refCurrent.classList) {
        refCurrent.classList.add('mousetrap')
      }

      if (editable && caretPosition !== undefined && !setupFocus) {
        setTimeout(() => {
          const element = (refCurrent?.firstChild ?? refCurrent) as HTMLElement
          if (focusMode === 'default') {
            setCaret(
              element,
              value && value.trim().length > 0 && caretPosition !== undefined
                ? caretPosition
                : 0,
            )
          }
          if (focusMode === 'select-all') {
            const range = document.createRange()
            range.selectNodeContents(element)
            const selelection = window.getSelection()
            if (selelection) {
              selelection.removeAllRanges()
              selelection.addRange(range)
            }
          }
          setSetupFocus(true)
        }, 10)
      }
    }, [editable, focusMode, refCurrent, caretPosition, value, setupFocus])

    const onKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        const target = refCurrent
        if (event.key === 'Enter' && !multiline && onPressEnter && target) {
          event.preventDefault()
          target.blur()
          onPressEnter(target.textContent ?? '')
        }
        if (event.key === 'Escape' && onPressEscape) {
          onPressEscape()
        }
      },
      [multiline, onPressEnter, onPressEscape, refCurrent],
    )

    const onInput = useCallback(
      (event: FormEvent<TypographyRefProps>) => {
        if (onChange) {
          onChange(event.currentTarget.textContent ?? '')
        }
      },
      [onChange],
    )

    const onBlur = useCallback(() => {
      setSetupFocus(false)

      if (refCurrent && typeof refCurrent.scrollTo === 'function') {
        refCurrent.scrollTo({ left: 0, top: 0 })
      }

      if (onBlurProp) {
        onBlurProp(refCurrent?.textContent ?? undefined)
      }
    }, [onBlurProp, refCurrent])

    return (
      <InputComponentStyled
        contentEditable={!disabled && !readOnly && (editable || multiline)}
        ellipsis={!multiline && ellipsis}
        height="100%"
        overflow={multiline ? 'auto' : 'initial'}
        spellCheck={false}
        suppressContentEditableWarning
        tabIndex={disabled ? -1 : 0}
        {...{
          color,
          disabled,
          editable,
          embedded,
          multiline,
          password,
          ref,
          onBlur,
          onInput,
          onKeyDown,
        }}
        {...other}
      >
        {inputValue}
      </InputComponentStyled>
    )
  },
)

export default InputBase
