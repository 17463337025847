/**
 * addAlpha
 *
 * @summary
 *  this function takes a hex color and adds alpha channel to it, it also takes colors
 *  with alpha channel and modifies it according to the opacity value passed to it.
 *
 * @param {string} color    - the hex color value
 * @param {number} opacity  - the alpha intensity 0 to 1
 *
 * @returns {string}
 */
const addAlpha = (hex: string, opacity: number): string => {
  if (opacity > 1 || opacity < 0 || hex === undefined) {
    return '#ff0000' // Highlight an element with invalid params
  }

  if (typeof hex === 'string' && hex.length === 9) {
    return addAlpha(hex.replace(/(\(\d{1,2}|\w{1,2})$/gi, ''), opacity)
  }

  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (opacity === 0) {
    return `rgb(${r}, ${g}, ${b})`
  }

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export default addAlpha
