import { filterArrayNonNull } from 'lib/cloud-sync/loader/helpers'
import type { Project } from 'lib/project/types.d'
import { getUuid } from '../../utils'
import {
  getDynamicString,
  getDynamicValue,
  getEnvironment,
  getEnvironmentDomain,
  getKeyValue,
  getObject,
} from '../getters'
import getParameter from '../getters/atoms/get-parameter'
import getRequest from '../getters/atoms/get-request'
import getRequestGroup from '../getters/atoms/get-request-group'
import findRequestTreeItemParent from '../getters/molecules/find-request-tree-item-parent'
import addDynamicString from './add-dynamic-string'
import addDynamicValue from './add-dynamic-value'
import addObject from './add-object'

type DuplicateAnythingInputItem =
  | Project.GenericRef<Project.AnyObject>
  | string
  | number
  | boolean

type DuplicateAnythingInput =
  | DuplicateAnythingInputItem
  | DuplicateAnythingInputItem[]
  | null

export interface DuplicateRequestArgs {
  uuid?: string
  sourceRef: Project.GenericRef<Project.Request>
  parentRef?: Project.GenericRef<Project.RequestGroup> | null
}

export const duplicateDynamicString = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { sourceRef }: { sourceRef: Project.GenericRef<Project.DynamicString> },
): Project.GenericRef<Project.DynamicString> => {
  // get the original/source
  const sourceDynamicString = getDynamicString(sourceRef, objects, false)

  // duplicate values (dynamic values)
  const newStrings = sourceDynamicString.strings.map((item) => {
    if (typeof item === 'string') {
      return item
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return duplicateDynamicValue(objects, root, { sourceRef: item })
  })

  // create a new dynamic string with the new strings
  return addDynamicString(objects, root, { strings: newStrings })
}

export const duplicateObject = <T extends Project.AnyObject>(
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { sourceRef }: { sourceRef: Project.GenericRef<T> },
): Project.GenericRef<T> => {
  const sourceObject = getObject(sourceRef, objects, false)

  switch (sourceObject.type) {
    case 'dynamicString':
      return duplicateDynamicString(objects, root, { sourceRef })
    case 'dynamicValue':
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return duplicateDynamicValue(objects, root, { sourceRef })
    case 'parameter':
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return duplicateParameter(objects, root, { sourceRef })
    default:
      // @TODO make sure we support everything that we need
      // we won't support KeyValues anyway, since its `type` conflicts with Parameter
      throw new Error(
        `duplicateObject() not supported for type ${sourceObject.type}`,
      )
  }
}

export const duplicateAnything = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { input }: { input: DuplicateAnythingInput | null },
): DuplicateAnythingInput => {
  // inspired by `loadAnything()`

  // primitives
  if (
    typeof input === 'string' ||
    typeof input === 'number' ||
    typeof input === 'boolean'
  ) {
    return input
  }

  // arrays, it can be anything, just map the values
  if (input && Array.isArray(input)) {
    return filterArrayNonNull(
      (input || []).map((item) =>
        duplicateAnything(objects, root, { input: item }),
      ),
    ) as DuplicateAnythingInputItem[]
  }

  // objects are considered to be refs
  if (input && typeof input === 'object') {
    if (typeof input.ref !== 'string') {
      throw new Error(
        `duplicateAnything() called with an object that is not a { ref }`,
      )
    }
    return duplicateObject(objects, root, { sourceRef: input })
  }

  return null
}

export const duplicateDynamicValue = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { sourceRef }: { sourceRef: Project.GenericRef<Project.DynamicValue> },
): Project.GenericRef<Project.DynamicValue> => {
  // get the original/source
  const sourceDynamicValue = getDynamicValue(sourceRef, objects, false)

  // extra 'dynamic` keys
  const extraKeys = Object.keys(sourceDynamicValue).filter(
    (key) => key !== 'identifier' && key !== 'type' && key !== 'uuid',
  )
  const extraValues = extraKeys.reduce((acc, key) => {
    acc[key] = duplicateAnything(objects, root, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      input: sourceDynamicValue[key] as unknown as any,
    })
    return acc
  }, {} as Project.ObjectMap<unknown>)

  // create a new dynamic value with the new values
  return addDynamicValue(objects, root, {
    ...extraValues,
    identifier: sourceDynamicValue.identifier,
    uuid: undefined,
  })
}

export interface DuplicateEnvironmentArgs {
  ref: Project.GenericRef<Project.Environment>
}

export const duplicateEnvironment = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  {
    ref,
  }: {
    ref: Project.GenericRef<Project.Environment>
  },
): Project.GenericRef<Project.Environment> => {
  const environment = getEnvironment(ref, objects, false)
  const domain = getEnvironmentDomain(environment.domain, objects, false)

  // copy environment
  const environmentCopy: Project.Environment = {
    uuid: getUuid(),
    type: 'environment',
    name: `${environment.name} copy`,
    domain: { ref: domain.uuid },
  }
  const refCopy = addObject(objects, environmentCopy)
  domain.environments.push(refCopy)

  // copy all values for this environment
  const values = (
    Object.values(objects).filter(
      ({ type }) => type === 'environmentVariableValue',
    ) as Project.EnvironmentVariableValue[]
  ).filter(({ environment: anEnvironment }) => anEnvironment.ref === ref.ref)

  // copy environment variables and environment variable values
  values.forEach(({ value, variable }) => {
    if (value) {
      // copy environment variable value
      const environmentVariableValueCopy: Project.EnvironmentVariableValue = {
        uuid: getUuid(),
        type: 'environmentVariableValue',
        value: duplicateDynamicString(objects, root, {
          sourceRef: value,
        }),
        environment: { ref: environmentCopy.uuid },
        variable,
        domain: environmentCopy.domain,
      }
      addObject(objects, environmentVariableValueCopy)
    }
  })

  return ref
}

export const duplicateRequest = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { uuid, sourceRef, parentRef }: DuplicateRequestArgs,
): Project.GenericRef<Project.Request> => {
  // get the original/source
  const sourceRequest = getRequest(sourceRef, objects, false)

  // create a new request
  const request: Project.Request = {
    uuid: uuid || getUuid(),
    type: 'request',
    title: `${sourceRequest.title} Copy`,
    summary: sourceRequest.summary,
    method: sourceRequest.method
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceRequest.method,
        })
      : addDynamicString(objects, root, { string: 'GET' }),
    urlFull: sourceRequest.urlFull
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceRequest.urlFull,
        })
      : null,
    bodyString: sourceRequest.bodyString
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceRequest.bodyString,
        })
      : null,
    headers: (sourceRequest.headers || []).map((keyValueRef) =>
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      duplicateKeyValue(objects, root, { sourceRef: keyValueRef }),
    ),
    urlParameters: (sourceRequest.urlParameters || []).map((keyValueRef) =>
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      duplicateKeyValue(objects, root, { sourceRef: keyValueRef }),
    ),
    variables: [], // @TODO fix me
    followRedirects: sourceRequest.followRedirects || false,
    redirectAuthorization: sourceRequest.redirectAuthorization || false,
    redirectMethod: sourceRequest.redirectMethod || false,
    timeoutInterval: sourceRequest.timeoutInterval || 0,
    sendCookies: sourceRequest.sendCookies || true,
    storeCookies: sourceRequest.storeCookies || true,
    clientCertificate: sourceRequest.clientCertificate || null,
  }
  const ref = addObject(objects, request)

  // find parent
  // if passing undefined, search for parent
  // if passing null or a reference, use this parent
  const newParentRef =
    parentRef === undefined
      ? findRequestTreeItemParent(sourceRef, objects, root)
      : parentRef

  // add new request to parent
  if (newParentRef) {
    const parentGroup = getRequestGroup(newParentRef, objects, false)
    parentGroup.children.push(ref)
  } else {
    const project = objects[root.ref] as Project.Project
    project.requests.push(ref)
  }

  return ref
}

export const duplicateParameter = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { sourceRef }: { sourceRef: Project.GenericRef<Project.Parameter> },
): Project.GenericRef<Project.Parameter> => {
  // get the original/source
  const sourceParameter = getParameter(sourceRef, objects, false)

  // create a new parameter
  const parameter: Project.Parameter = {
    uuid: getUuid(),
    type: 'parameter',
    enabled: sourceParameter.enabled,
    key: sourceParameter.key
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceParameter.key,
        })
      : null,
    value: sourceParameter.value
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceParameter.value,
        })
      : null,
  }
  return addObject(objects, parameter)
}

export const duplicateKeyValue = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { sourceRef }: { sourceRef: Project.GenericRef<Project.KeyValue> },
): Project.GenericRef<Project.KeyValue> => {
  // get the original/source
  const sourceKeyValue = getKeyValue(sourceRef, objects, false)

  // create a new Key Value
  const keyValue: Project.KeyValue = {
    uuid: getUuid(),
    type: 'parameter',
    enabled: sourceKeyValue.enabled,
    mode: sourceKeyValue.mode,
    name: sourceKeyValue.name
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceKeyValue.name,
        })
      : null,
    value: sourceKeyValue.value
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceKeyValue.value,
        })
      : null,
  }
  return addObject(objects, keyValue)
}
