export enum JSONItemType {
  Primitive = 0,
  Array = 1,
  Object = 2,
  DictKey = 3,
}

export interface JSONItemBase {
  start: number
  end: number
}

export interface JSONDictKey<SType> extends JSONItemBase {
  itemType: JSONItemType.DictKey
  value: SType
}

export interface JSONDictElement<SType> {
  enabled: boolean
  index: number
  key: JSONDictKey<SType>
  value: JSONItem<SType>
}

export interface JSONDict<SType> extends JSONItemBase {
  itemType: JSONItemType.Object
  items: JSONDictElement<SType>[]
}

export interface JSONArrayElement<SType> {
  enabled: boolean
  index: number
  item: JSONItem<SType>
}

export interface JSONArray<SType> extends JSONItemBase {
  itemType: JSONItemType.Array
  items: JSONArrayElement<SType>[]
}

export enum JSONPrimitiveType {
  String = 0,
  Number = 1,
  True = 2,
  False = 3,
  Null = 4,
}

// SType => String type (can be a dynamic string)
export type JSONPrimitiveValue<SType> = SType | number | boolean | null

export interface JSONPrimitive<SType> extends JSONItemBase {
  itemType: JSONItemType.Primitive
  value: JSONPrimitiveValue<SType>
  primitiveType: JSONPrimitiveType
}

export interface JSONParserOptions {
  useDynamicValues: boolean
}
// SType => String type (can be a dynamic string)
export type JSONItem<SType> =
  | JSONPrimitive<SType>
  | JSONArray<SType>
  | JSONDictKey<SType>
  | JSONDict<SType>
