import React, { useMemo } from 'react'

import { Notification, EmptyState } from '@rapidapi/ui-lib'
import { Editor } from 'ecosystems/editor'
import type { RequestHandling } from 'lib/request-handling/types.d'
import { getHttpMessageBodyString } from 'lib/request-handling'

import type { HttpExchangeTabViewProps } from '../http-exchange-tabs-props.d'
import { useHttpExchangeMessage } from '../http-exchange-helper-hooks'
import TabBoxContainer from '../tab-box-container'

const HttpExchangeBodyEmpty: React.FC<HttpExchangeTabViewProps> = ({
  messageType,
}) => {
  // Get the Request message (is useful for HEAD Responses)
  const { httpMessage: requestHttpMessage } = useHttpExchangeMessage('request')

  // If Response and request is a HEAD request
  if (
    messageType === 'response' &&
    requestHttpMessage &&
    (
      requestHttpMessage as RequestHandling.HTTPExchangeRequest
    ).method.toUpperCase() === 'HEAD'
  ) {
    return (
      <Notification m={0} width="100%" variant="info" icon="info" title="Empty">
        Response body is empty because you are sending a HEAD request. Switch to
        GET to view the corresponding body.
      </Notification>
    )
  }

  return (
    <EmptyState
      symbol="danger"
      headline="Empty body"
      body={
        messageType === 'request'
          ? 'Request body is empty'
          : 'Response body is empty'
      }
    />
  )
}

const HttpExchangeTextTab: React.FC<HttpExchangeTabViewProps> = ({
  messageType,
}) => {
  const { httpMessage } = useHttpExchangeMessage(messageType)

  const content = useMemo(
    () => (httpMessage ? getHttpMessageBodyString(httpMessage) : null),
    [httpMessage],
  )

  if (!content || content.length === 0) {
    return (
      <TabBoxContainer p={5}>
        <HttpExchangeBodyEmpty messageType={messageType} />
      </TabBoxContainer>
    )
  }

  return (
    <TabBoxContainer pt={5}>
      <Editor content={content} />
    </TabBoxContainer>
  )
}

export default HttpExchangeTextTab
