import type { Project } from 'lib/project'

const isEqualStrings = (
  a: (string | Project.GenericRef<Project.DynamicValue>)[],
  b: (string | Project.GenericRef<Project.DynamicValue>)[],
): boolean => {
  const res =
    a.length === b.length &&
    a.reduce((acc, itemA, idx) => {
      if (!acc) {
        return false
      }
      const itemB = b[idx]
      if (typeof itemA === 'string' && typeof itemB === 'string') {
        return itemA === itemB
      }
      if (typeof itemA === 'object' && typeof itemB === 'object') {
        return itemA.ref === itemB.ref
      }
      return false
    }, true)
  return res
}

export default isEqualStrings
