/* eslint-disable react/jsx-props-no-spreading */

import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { space as spaceStyled } from 'styled-system'
import css from '@styled-system/css'
import { Icon } from 'components/data-display/icon'
import { IconButtonComponentProps } from './icon-button.types'

const Btn = withTheme(
  styled.button<IconButtonComponentProps>(
    ({ color, disabled, noBorder = false, size = 'medium' }) =>
      css({
        alignItems: 'center',
        backgroundClip: 'padding-box',
        bg: 'transparent',
        '&:hover:enabled, &.focused': {
          transition: 'background-color .2s ease',
          backgroundColor: noBorder
            ? undefined
            : 'backgrounds.backgroundSecondary',
        },
        border: 0,
        borderRadius: 'default',
        borderStyle: 'solid',
        boxSizing: 'border-box',
        cursor: disabled ? 'default' : 'pointer',
        display: 'inline-flex',
        justifyContent: 'center',
        margin: 0,
        outline: '0 none',
        padding: { small: 1, medium: '6px', large: 2 }[size],
        position: 'relative',
        svg: { color },
        '&:focus, &.focused': {
          transition: 'box-shadow .2s ease',
        },
        transition: 'box-shadow .35s ease',
        '&[disabled], &:disabled': {
          svg: {
            color: 'texts.lowEmphasis',
          },
        },
      }),
    spaceStyled,
  ),
)

export const IconButton: React.FC<IconButtonComponentProps> = ({
  disabled = false,
  focused = false,
  icon = 'search',
  size = 'medium',
  type = 'button',
  ...other
}) => (
  <Btn
    className={focused ? 'focused' : undefined}
    {...{ disabled, size, type }}
    {...other}
  >
    <Icon size={{ small: 16, medium: 20, large: 24 }[size]} symbol={icon} />
  </Btn>
)

export default IconButton
