import { useEffect, useState } from 'react'

import type { CloudAPI } from 'lib/cloud-sync/cloud-api'
import { getTeamList } from 'lib/cloud-sync/cloud-api'
import { getOAuthToken } from './cloud-api-auth'

type Status = 'idle' | 'loading' | 'succeeded' | 'failed'

type UseGetTeamListResult = {
  status: Status
  error: Error | null
  response: CloudAPI.TeamListResponse | null
}

const useGetTeamList = (): UseGetTeamListResult => {
  const [status, setStatus] = useState<Status>('idle')
  const [error, setError] = useState<Error | null>(null)
  const [response, setResponse] = useState<CloudAPI.TeamListResponse | null>(
    null,
  )

  useEffect(() => {
    ;(async () => {
      setStatus('loading')
      try {
        // get token
        const token = getOAuthToken()
        if (!token) {
          setError(new Error('User is not authenticated'))
          setStatus('failed')
          return
        }

        // perform call
        const res = await getTeamList(token)

        // success
        setResponse(res.filter((team) => team.active && !team.personal_team))
        setStatus('succeeded')
      } catch (anError) {
        // failure
        setError(anError as Error)
        setStatus('failed')
      }
    })()
  }, [])

  return {
    status,
    error,
    response,
  }
}

export default useGetTeamList
