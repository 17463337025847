import React from 'react'
import { Box, TextField, IconButton, Icon, styled, css } from '@rapidapi/ui-lib'

type JsonInputFilterType = {
  onChange: (value: string) => void
  onClear: (e: React.MouseEvent) => void
  keyword: string
}

const InputContainer = styled(Box)(
  ({
    theme: {
      addAlpha,
      colors: { backgrounds },
    },
  }) =>
    css({
      position: 'absolute',
      left: 0,
      bottom: 0,
      zIndex: 1000,
      width: '100%',
      boxShadow: `0 -32px 28px -8px ${addAlpha(
        backgrounds.backgroundPrimary,
        0.95,
      )}`,
      backgroundColor: backgrounds.backgroundPrimary,
    }),
)

const placeholder = 'JsonPath expressions e.g. $.store.book[0].title'
const JsonInputFilter: React.FC<JsonInputFilterType> = ({
  keyword = '',
  onChange,
  onClear,
}) => (
  <InputContainer px={6} pt={4} pb={4}>
    <TextField
      value={keyword}
      width="100%"
      onChange={onChange}
      hint={placeholder}
      placeholder="Filter expression..."
      startAdornment={<Icon symbol="filter" />}
      endAdornment={
        <IconButton
          icon="close"
          size="small"
          onClick={onClear}
          disabled={keyword.trim() === ''}
        />
      }
    />
  </InputContainer>
)

export default JsonInputFilter
