import type { Evaluation } from 'lib/evaluation/types.d'
import type { Project } from 'lib/project/types.d'
import type { DynamicValues } from 'lib/dynamic-values'
import getDynamicString from 'lib/project/getters/atoms/get-dynamic-string'
import getDynamicValue from 'lib/project/getters/atoms/get-dynamic-value'

export const evaluateDynamicValueTokenInfo = async (
  dvRef: Project.GenericRef<Project.DynamicValue>,
  ctx: Evaluation.Ctx,
): Promise<DynamicValues.TokenInfo> => {
  const dv = getDynamicValue(dvRef, ctx.project.objects, false)
  const impl = ctx.getImplementation(dv)
  if (impl) {
    return impl.getTokenInfo(dv, ctx)
  }
  return {
    title: `Missing DV '${dv.identifier}'`,
    text: null,
  }
}

export const evaluateDynamicValue = async (
  dvRef: Project.GenericRef<Project.DynamicValue>,
  ctx: Evaluation.Ctx,
): Promise<string> => {
  const dv = getDynamicValue(dvRef, ctx.project.objects, false)
  const impl = ctx.getImplementation(dv)
  if (impl) {
    return impl.getEvaluatedString(dv, ctx)
  }
  // @TODO add a dynamic value evaluation error
  return `Missing DV '${dv.identifier}'`
}

export const evaluateDynamicStringComponents = async (
  components: (string | Project.GenericRef<Project.DynamicValue>)[],
  ctx: Evaluation.Ctx,
): Promise<string> => {
  const strings = await Promise.all(
    components.map(async (component) => {
      if (typeof component === 'string') {
        return component
      }
      return evaluateDynamicValue(component, ctx)
    }),
  )
  return strings.join('')
}

export const evaluateDynamicString = async (
  dsRef: Project.GenericRef<Project.DynamicString>,
  ctx: Evaluation.Ctx,
): Promise<string> => {
  const ds = getDynamicString(dsRef, ctx.project.objects, false)
  return evaluateDynamicStringComponents(ds.strings, ctx)
}
