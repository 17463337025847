import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  authorizationCodeGrantType,
  clientCredentialsGrantType,
  implicitGrantType,
  resourceOwnerPasswordCredentialsGrantType,
} from 'lib/dynamic-values/implementations/oauth2-dynamic-value/impl-oauth2-dynamic-value'
import type { Project } from 'lib/project/types.d'
import type { AppFetch } from 'utils/app-fetch/app-fetch-types.d'
import { useAppFetch } from 'utils/app-fetch'
import { isInElectron } from 'utils/electron'
import { applyProjectSetter } from 'store/actions'
import { useAlert, usePrompt } from '@rapidapi/ui-lib'
import { useAppUpdateHooks } from 'ecosystems/app-update'
import useGetEvaluatedOAuth2Params from './use-get-evaluated-oauth2-params'
import type { OAuth2Utils } from '../oauth2-functions'
import {
  fetchOAuth2AccessToken,
  getOAuth2AuthorizationUrl,
  runOAuth2AuthorizationFlow,
  FLOW_OAUTH2_REQUEST,
  FLOW_OAUTH2_COMPLETED,
  FLOW_OAUTH2_CANCELLED,
  FLOW_OAUTH2_SUCCESS_RESPONSE,
  FLOW_OAUTH2_FAILED_RESPONSE,
  FLOW_OAUTH2_CLEAR_COOKIES,
} from '../oauth2-functions'

export type OAuth2TokenLoadingStateType =
  | 'idle'
  | 'loading-token'
  | 'loading-refresh'

type StartGetTokenResultType = {
  accessToken: string
  refreshToken: string | null
}

type UseGetOAuth2TokenResult = {
  startGetToken: (isRefreshToken: boolean) => Promise<void>
  startGetAccessToken: () => void
  startGetRefreshToken: () => void
  clearCookies: () => void
  loadingState: OAuth2TokenLoadingStateType
}

const startGetToken = async (
  evaluatedOAuth2Params: OAuth2Utils.EvaluatedOAuth2Params,
  isRefreshToken: boolean,
  appFetch: AppFetch.AppFetch,
  code?: string,
): Promise<StartGetTokenResultType> => {
  // Access Token
  if (
    (evaluatedOAuth2Params.grantType === authorizationCodeGrantType &&
      (isRefreshToken || code)) ||
    evaluatedOAuth2Params.grantType ===
      resourceOwnerPasswordCredentialsGrantType ||
    evaluatedOAuth2Params.grantType === clientCredentialsGrantType
  ) {
    // run get token request

    return fetchOAuth2AccessToken({
      isRefreshToken,
      evaluatedOAuth2Params,
      appFetch,
      code,
    })
  }

  // Auth Request in Popup Window
  if (
    !isRefreshToken &&
    (evaluatedOAuth2Params.grantType === authorizationCodeGrantType ||
      evaluatedOAuth2Params.grantType === implicitGrantType)
  ) {
    if (!isInElectron()) {
      throw new Error(
        `In order to complete the OAuth flow, please install the Paw Desktop app.`,
      )
    }

    // show auth request
    const result = await runOAuth2AuthorizationFlow(evaluatedOAuth2Params)

    // authorizationCodeGrantType
    if (evaluatedOAuth2Params.grantType === authorizationCodeGrantType) {
      // run the same function to get an access token
      // pass the `code` param
      return startGetToken(evaluatedOAuth2Params, false, appFetch, result.code)
    }

    // implicit
    // we have the token already
    return {
      accessToken: result.accessToken as string,
      refreshToken: null,
    }
  }

  throw new Error(
    `[OAuth 2] Grant Type ${evaluatedOAuth2Params.grantType} not supported`,
  )
}

const useGetOAuth2Token = (
  dynamicValueRef: Project.GenericRef<Project.DynamicValue>,
): UseGetOAuth2TokenResult => {
  const [loadingState, setLoadingState] =
    useState<OAuth2TokenLoadingStateType>('idle')

  // evaluate OAuth 2 params
  const evaluatedOAuth2Params = useGetEvaluatedOAuth2Params(dynamicValueRef)

  // get the appropriate fetch() function
  const appFetch = useAppFetch()

  // ipcRenderer for electron stuff
  const { ipcRenderer } = useAppUpdateHooks()

  // alert and prompt UI helpers
  const alert = useAlert()
  const prompt = usePrompt()

  // set result to the store
  const dispatch = useDispatch()

  const setTokenResult = useCallback(
    ({ accessToken, refreshToken }: StartGetTokenResultType) => {
      dispatch(
        applyProjectSetter('updateCombo')({
          updates: [
            {
              setter: 'updateDynamicString',
              args: {
                parentRef: dynamicValueRef,
                parentProperty: 'token',
                strings: [accessToken],
              },
            },
            {
              setter: 'updateDynamicString',
              args: {
                parentRef: dynamicValueRef,
                parentProperty: 'refreshToken',
                strings: [refreshToken || ''],
              },
            },
          ],
        }),
      )
    },
    [dispatch, dynamicValueRef],
  )

  const startGetTokenFn = useCallback(
    async (isRefreshToken: boolean) => {
      if (!evaluatedOAuth2Params) {
        return
      }

      setLoadingState(isRefreshToken ? 'loading-refresh' : 'loading-token')

      try {
        // get token
        const { accessToken, refreshToken } = await startGetToken(
          evaluatedOAuth2Params,
          isRefreshToken,
          appFetch,
        )

        // pretty console.log for users
        window.console.log(
          `%c\tOAuth 2 Access Token\t\n%c\tAccess Token: ${accessToken}\t\n%c\tRefresh Token: ${refreshToken}\t`,
          'background-color: #000; color: #0D93F2;',
          'background-color: #000; color: #0D93F2;',
          'background-color: #000; color: #0D93F2;',
        )

        // prompt
        await new Promise((resolve, reject) => {
          prompt({
            title: 'OAuth 2 Token',
            content: `Found OAuth 2 token "${accessToken}". Use this token?`,
            proccedText: 'Use Token',
            cancelText: 'Cancel',
            onProceed: () => {
              resolve({ accessToken, refreshToken })
            },
            onCancel: () => {
              reject(new Error('User rejected'))
            },
          })
        })

        // update dv
        setTokenResult({ accessToken, refreshToken })
        setLoadingState('idle')
      } catch (error) {
        if (error instanceof Error) {
          // show and log error unless it's been rejected
          // by the user e.g. window has been closed
          if (error.message !== 'User rejected') {
            window.console.error('OAuth 2 Error:', error)
            alert({
              title: 'OAuth 2 Error',
              content: error.message || String(error),
              proccedText: 'Ok',
            })
            setLoadingState('idle')
          }
        }
      }
    },
    [evaluatedOAuth2Params, appFetch, alert, prompt, setTokenResult],
  )

  const getToken = useCallback(() => {
    setLoadingState('loading-token')

    if (!ipcRenderer || !evaluatedOAuth2Params) {
      setLoadingState('idle')
      return
    }

    const url = getOAuth2AuthorizationUrl({ evaluatedOAuth2Params })
    ipcRenderer.invoke(FLOW_OAUTH2_REQUEST, { url, evaluatedOAuth2Params })
  }, [ipcRenderer, evaluatedOAuth2Params])

  const startGetAccessToken = useCallback(() => {
    if (!evaluatedOAuth2Params || !startGetAccessToken) {
      return null
    }

    if (
      evaluatedOAuth2Params.grantType === clientCredentialsGrantType ||
      evaluatedOAuth2Params.grantType ===
        resourceOwnerPasswordCredentialsGrantType
    ) {
      return startGetTokenFn(false)
    }

    return getToken()
  }, [getToken, startGetTokenFn, evaluatedOAuth2Params])

  const startGetRefreshToken = useCallback(
    () => startGetTokenFn(true),
    [startGetTokenFn],
  )

  // A function can also be utilized for browser
  const clearCookies = useCallback(() => {
    if (!ipcRenderer || !evaluatedOAuth2Params) {
      return
    }

    if (
      evaluatedOAuth2Params.grantType === clientCredentialsGrantType ||
      evaluatedOAuth2Params.grantType ===
        resourceOwnerPasswordCredentialsGrantType
    ) {
      return
    }

    const url = getOAuth2AuthorizationUrl({ evaluatedOAuth2Params })
    ipcRenderer.invoke(FLOW_OAUTH2_CLEAR_COOKIES, {
      url,
      evaluatedOAuth2Params,
    })
    setTokenResult({ accessToken: '', refreshToken: '' })
  }, [ipcRenderer, setTokenResult, evaluatedOAuth2Params])

  // A useEffect for desktop mode to initialize event listeners that will receive
  // Oauth2 auth data from `ipcMain`.
  useEffect(() => {
    if (!ipcRenderer) {
      return undefined
    }

    // Set loading status back to idle when oauth2 window is manually closed
    ipcRenderer.receive(FLOW_OAUTH2_CANCELLED, () => setLoadingState('idle'))

    // Electron listener to receive Oauth2 auth data
    ipcRenderer.receive<StartGetTokenResultType, null>(
      FLOW_OAUTH2_SUCCESS_RESPONSE,
      ({ accessToken, refreshToken }): void => {
        ipcRenderer.invoke(FLOW_OAUTH2_COMPLETED)
        setTokenResult({ accessToken, refreshToken })
        setLoadingState('idle')
      },
    )

    // Errors from an Oauth2 flow response will be received here.
    ipcRenderer.receive(FLOW_OAUTH2_FAILED_RESPONSE, (data) => {
      // eslint-disable-next-line no-console
      console.log('FLOW_OAUTH2_FAILED_RESPONSE', data)
    })

    return () => {
      ipcRenderer.removeListeners(FLOW_OAUTH2_CANCELLED)
      ipcRenderer.removeListeners(FLOW_OAUTH2_SUCCESS_RESPONSE)
      ipcRenderer.removeListeners(FLOW_OAUTH2_FAILED_RESPONSE)
    }
  }, [ipcRenderer, setTokenResult])

  return {
    startGetToken: startGetTokenFn,
    startGetAccessToken,
    startGetRefreshToken,
    clearCookies,
    loadingState,
  }
}

export default useGetOAuth2Token
