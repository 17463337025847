/* eslint-disable no-param-reassign */
import type { Project } from 'lib/project/types.d'
import { getObject } from '../getters'
import addDynamicString from './add-dynamic-string'
import addObject from './add-object'

export type UpdateDynamicStringArgs<T extends Project.AnyObject, _Y> = {
  parentRef?: Project.GenericRef<T>
  parentProperty?: string | number | symbol
  dynamicStringRef?: Project.GenericRef<T>
  strings: (string | Project.GenericRef<Project.DynamicValue>)[]
  inserts?: Project.AnyObject[]
}
const updateDynamicString = <
  T extends Project.AnyObject,
  Y = Project.ObjectMap<unknown>,
>(
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  {
    dynamicStringRef,
    parentRef,
    parentProperty,
    strings,
    inserts,
  }: UpdateDynamicStringArgs<T, Y>,
): void => {
  if (inserts) {
    inserts.forEach((obj) => {
      addObject(objects, obj)
    })
  }
  // get ref and insert ds to parent when missing
  let ref: Project.GenericRef<Project.DynamicString>
  if (dynamicStringRef && dynamicStringRef.ref) {
    ref = dynamicStringRef
  } else if (parentRef && parentRef.ref && parentProperty) {
    // get value from parent
    const parent = getObject(parentRef, objects, false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref = (parent as any)[parentProperty]

    // dynamic string is missing
    if (!ref) {
      // insert child dynamic string
      ref = addDynamicString(objects, root, { strings: [] })

      // update parent
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(parent as any)[parentProperty] = ref
    }
  } else {
    // we don't have the required params
    throw new Error('[updateDynamicString] Missing reference params')
  }

  // update dynamic string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(objects[ref.ref] as any).strings = strings
}

export default updateDynamicString
