import {
  darkColours,
  theme as styledTheme,
  lightColours,
  darkEditorColours,
  lightEditorColours,
} from 'graphql-playground-react/lib/styled/theme'
import { useSelector } from 'react-redux'
import { selectTheme } from 'store/selectors'
import { useWatchMedia } from 'utils'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useDocumentationTheme = () => {
  const theme = useSelector(selectTheme)

  const pawLightColours = {
    ...lightColours,
    ...{
      white: '#ffffff',
      red: '#E45649',
      blue: '#4078F2',
      orange: '#C18401',
      green: '#50A14F',
      black02: '#ffffff',
      black04: '#f4f4f4',
      black07: '#e8e8e8',
      black10: '#e8e8e8',
      black30: '#A0A1A7',
      black50: '#383A42',
    },
  }
  const pawDarkColours = {
    ...darkColours,
    ...{
      white: '#222222',
      red: '#E06C75',
      blue: '#61AFEF',
      orange: '#E5C07B',
      green: '#98C379',
      black02: '#222222',
      black04: '#333333',
      black07: '#181818',
      black10: '#181818',
      black30: '#A0A1A7',
      black50: '#DBDFE7',
    },
  }

  const autoDark = useWatchMedia('(prefers-color-scheme: dark)')

  return {
    ...styledTheme,

    mode: theme,
    colours: {
      ...(theme === 'dark' || (theme === 'auto' && autoDark)
        ? pawDarkColours
        : pawLightColours),
    },
    editorColours: {
      ...(theme === 'dark' || (theme === 'auto' && autoDark)
        ? darkEditorColours
        : lightEditorColours),
    },
  }
}
export default useDocumentationTheme
