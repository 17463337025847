/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */

import { isValidElement } from 'react'
import { Box } from '../../layout/box'
import { Typography } from '../../data-display/typography'
import { SwitchBaseComponentProps } from './switch-base.types'

export const Label: React.FC<SwitchBaseComponentProps> = ({
  disabled,
  label,
}) => (
  <Box
    ml={2}
    minWidth="auto"
    color={`texts.${disabled ? 'lowEmphasis' : 'mediumEmphasis'}`}
  >
    {isValidElement(label) ? (
      label
    ) : (
      <Typography variant="body2" color="inherit">
        {label}
      </Typography>
    )}
  </Box>
)

export const Hint: React.FC<SwitchBaseComponentProps> = ({
  disabled,
  hint,
}) => (
  <Box display="flex" mt={1}>
    <Box minWidth="auto" width={20} />
    <Box ml={2} color={`texts.${disabled ? 'lowEmphasis' : 'mediumEmphasis'}`}>
      {isValidElement(hint) ? (
        hint
      ) : (
        <Typography variant="caption" color="inherit">
          {hint}
        </Typography>
      )}
    </Box>
  </Box>
)

export const SwitchBase: React.FC<SwitchBaseComponentProps> = ({
  checked = false,
  disabled,
  focused,
  hint,
  icon,
  label,
  name,
  type = 'checkbox',
  value,
  onChange,
  ...other
}) => (
  <Box
    css={({ addAlpha, colors }) => [
      {
        display: 'inline-flex',
        flexDirection: 'column',
        position: 'relative',
        label: {
          cursor: 'pointer',
          display: 'flex',
          '& [data-role="switch-base-icon"]': {
            borderColor:
              colors.borders[checked ? 'borderAcent' : 'borderDefault'],
            backgroundColor: checked
              ? colors.backgrounds.backgroundAccent
              : 'intial',
          },
        },
      },
      disabled
        ? {
            label: {
              cursor: 'default',
              '& [data-role="switch-base-icon"]': {
                borderColor: colors.borders.borderDefault,
                backgroundColor: checked
                  ? addAlpha(colors.neutral.black, 0.08)
                  : 'initial',
                color: checked ? colors.texts.lowEmphasis : 'initial',
              },
              '& [data-role="switch-base-input"]': {
                input: {
                  cursor: 'default',
                },
              },
            },
          }
        : {},
    ]}
    {...other}
  >
    <label>
      <Box width={20} height={20} data-role="switch-base-input">
        <input
          css={{
            cursor: 'pointer',
            height: '100%',
            left: 0,
            margin: 0,
            opacity: 0,
            position: 'absolute',
            top: 0,
            padding: 0,
            width: '100%',
            zIndex: 10,
          }}
          checked={checked}
          {...{ disabled, name, type, value, onChange }}
        />
        <Box
          css={({ colors }) => ({
            position: 'relative',
            alignItems: 'center',
            borderWidth: 1,
            borderColor: colors.borders.borderDefault,
            borderStyle: 'solid',
            color: 'white',
            display: 'inline-flex',
            justifyContent: 'center',
            height: 20,
            width: 20,
            '>div': {
              display: checked ? 'flex' : 'none',
              position: 'absolute',
            },
          })}
          data-role="switch-base-icon"
        >
          {icon}
        </Box>
      </Box>
      {label && <Label {...{ disabled, label }} />}
    </label>
    {hint && <Hint {...{ disabled, hint }} />}
  </Box>
)

export default SwitchBase
