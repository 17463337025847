// use info from Electron package
// so we have only one source of truth of version number etc.

export interface AppEnv {
  REACT_APP_BUILD_TYPE: 'web' | 'desktop' | undefined
  REACT_APP_PAW_HELPER_ENDPOINT: string
  REACT_APP_CF_PROXY_URL: string
  REACT_APP_PAW_CLOUD_AUTHORIZE_URL: string
  REACT_APP_PAW_CLOUD_CLIENT_ID: string
  REACT_APP_PAW_CLOUD_PASSWORD_GRANT_CLIENT_ID: string
  REACT_APP_PAW_CLOUD_PASSWORD_GRANT_CLIENT_SECRET: string
  REACT_APP_SENTRY_DSN: string
  REACT_APP_CLOUD_API_ENDPOINT: string
  REACT_APP_CLOUD_API_USE_MOCKS: string
  REACT_APP_CLOUD_API_USE_CF_PROXY: string
  REACT_APP_REVISION: string
  APP_PACKAGE_NAME: string
  APP_PACKAGE_VERSION: string
}

export type PawAppConfig = {
  productName: string
  version: string
  revision: string
  build: 'web' | 'desktop' | undefined
  placeholderURL: string
  helper: {
    endpoint: string
    status: string
  }
  cfProxy: {
    url: string
  }
  pawCloud: {
    authorizeUrl: string
    clientId: string
    passwordGrantClientId: string
    passwordGrantClientSecret: string
  }
  sentry: {
    dsn: string | undefined
  }
  logRocket: {
    id: string | undefined
  }
  cloudAPIConfig: {
    API_ENDPOINT: string
    API_USE_MOCKS: boolean
    API_USE_CF_PROXY: boolean
  }
}

const env = process.env as unknown as AppEnv
const config: PawAppConfig = {
  productName: env.APP_PACKAGE_NAME,
  version: env.APP_PACKAGE_VERSION,
  revision: env.REACT_APP_REVISION || '',
  build: env.REACT_APP_BUILD_TYPE || 'web',
  placeholderURL: 'https://echo.paw.cloud',
  helper: {
    endpoint: `${env.REACT_APP_PAW_HELPER_ENDPOINT}parcel`,
    status: `${env.REACT_APP_PAW_HELPER_ENDPOINT}status`,
  },
  cfProxy: {
    url: env.REACT_APP_CF_PROXY_URL,
  },
  pawCloud: {
    authorizeUrl: env.REACT_APP_PAW_CLOUD_AUTHORIZE_URL,
    clientId: env.REACT_APP_PAW_CLOUD_CLIENT_ID,
    passwordGrantClientId: env.REACT_APP_PAW_CLOUD_PASSWORD_GRANT_CLIENT_ID,
    passwordGrantClientSecret:
      env.REACT_APP_PAW_CLOUD_PASSWORD_GRANT_CLIENT_SECRET,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
  logRocket: {
    id: process.env.REACT_APP_LOGROCKET_ID,
  },
  cloudAPIConfig: {
    API_ENDPOINT: `${env.REACT_APP_CLOUD_API_ENDPOINT}`,
    /**
     * @todo fix me
     *
     *  environment variables returns a string and not an actual boolean so the presence
     * of the value itself will always return to true, there's probably a better way to
     * declare this, but for now we'll do it this way :D
     */
    API_USE_MOCKS: String(env.REACT_APP_CLOUD_API_USE_MOCKS) === 'true',
    API_USE_CF_PROXY: String(env.REACT_APP_CLOUD_API_USE_CF_PROXY) === 'true',
  },
}

export default config
