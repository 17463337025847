const colors = {
  neutral: {
    black: '#000000',
    neutral900: '#222629',
    neutral800: '#2D3034',
    neutral700: '#34393C',
    neutral600: '#40454A',
    neutral500: '#5F656D',
    neutral400: '#798186',
    neutral300: '#9FA6AC',
    neutral200: '#D6D9DB',
    neutral100: '#E6E8EA',
    neutral50: '#F4F4F5',
    white: '#FFFFFF',
  },
  blue: {
    blue900: '#161F2D',
    blue800: '#233248',
    blue700: '#243E61',
    blue600: '#2D588F',
    blue500: '#296EC2',
    blue400: '#3080DE',
    blue300: '#4A95ED',
    blue200: '#80B2EF',
    blue100: '#ACCAF1',
    blue75: '#CADDF6',
    blue50: '#DFEBFB',
    blue10: '#F5F9FF',
  },
  red: {
    red900: '#291919',
    red800: '#422424',
    red700: '#5A2B2B',
    red600: '#903737',
    red500: '#B63535',
    red400: '#D53939',
    red300: '#EB4C4C',
    red200: '#F47B7B',
    red100: '#F9A4A4',
    red75: '#FDC3C3',
    red50: '#FFE1E1',
    red10: '#FFF0F0',
  },
  green: {
    green900: '#112219',
    green800: '#1C3B2B',
    green700: '#24513B',
    green600: '#2C724F',
    green500: '#328F61',
    green400: '#37A46E',
    green300: '#40BF7F',
    green200: '#61D199',
    green100: '#94E0BA',
    green75: '#C3EFD9',
    green50: '#D9F7E8',
    green10: '#F2FDF7',
  },
}

export default colors
