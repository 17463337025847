type UrlQuery = { [key: string]: string | undefined }
type BodyQuery = { [key: string]: string | undefined }

export const urlEncode = (query: UrlQuery): string =>
  Object.entries(query)
    .filter(([key, value]) => key !== undefined && value !== undefined)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`,
    )
    .join('&')

export const getFullUrl = (url: string, query: UrlQuery): string =>
  `${url}${url.match(/\?$/) ? '' : '?'}${urlEncode(query)}`

/**
 * Encodes body params according to RFC 3986 and uses + for spaces.
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 * @param str Input string
 * @returns Encoded body
 */
export const urlEncodeBodyComponent = (str: string): string =>
  encodeURIComponent(str)
    .replace('%20', '+')
    .replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
    )

/**
 * Encodes body params according to RFC 3986 and uses + for spaces.
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 * @param query Input key-value dict
 * @returns Encoded body
 */
export const urlEncodeBodyQuery = (query: BodyQuery): string =>
  Object.entries(query)
    .filter(([key, value]) => key !== undefined && value !== undefined)
    .map(
      ([key, value]) =>
        `${urlEncodeBodyComponent(key)}=${urlEncodeBodyComponent(
          value as string,
        )}`,
    )
    .join('&')
