import { electropaw } from 'ecosystems/app-update/app-update-hooks'
import {
  authorizationCodeGrantType,
  implicitGrantType,
} from 'lib/dynamic-values/implementations/oauth2-dynamic-value/impl-oauth2-dynamic-value'
import getOAuth2AuthorizationUrl from './get-oauth2-authorization-url'
import type { OAuth2Utils } from './oauth2-functions-types.d'

interface RunOAuth2AuthorizationFlowResult {
  code?: string
  accessToken?: string
}

// Error instances are not clonable
export type RendererOAuth2ResponseType = Omit<
  RunOAuth2AuthorizationFlowResult,
  'error'
> & {
  error: string
}

export const FLOW_OAUTH2_REQUEST = 'OAuth2AuthorizationRequest'
export const FLOW_OAUTH2_COMPLETED = 'OAuth2AuthorizationRequestCompleted'
export const FLOW_OAUTH2_CANCELLED = 'OAuth2AuthorizationRequestCancelled'
export const FLOW_OAUTH2_SUCCESS_RESPONSE = 'OAuth2AuthorizationSuccessResponse'
export const FLOW_OAUTH2_FAILED_RESPONSE = 'OAuth2AuthorizationFailedResponse'
export const FLOW_OAUTH2_CLEAR_COOKIES = 'OAuth2AuthorizationClearCookies'

/**
 * Run the authorization flow in a modal window in the Electron app.
 * Only supported in the Electron app, not the web app.
 *
 * @param evaluatedOAuth2Params Evaluated OAuth 2 params.
 * @returns A promise that resolves with { code, accessToken } depending on the grant type.
 */
const runOAuth2AuthorizationFlow = async (
  evaluatedOAuth2Params: OAuth2Utils.EvaluatedOAuth2Params,
): Promise<RunOAuth2AuthorizationFlowResult> => {
  const url = getOAuth2AuthorizationUrl({ evaluatedOAuth2Params })

  electropaw.invoke(FLOW_OAUTH2_REQUEST, {
    url,
    evaluatedOAuth2Params,
  })

  const result = await electropaw.appUpdate<
    typeof FLOW_OAUTH2_SUCCESS_RESPONSE,
    RendererOAuth2ResponseType
  >(FLOW_OAUTH2_SUCCESS_RESPONSE)

  // error messages are returned in the `error` property
  // throw a clean error here if we got an error message from Electron
  if (result.error) {
    throw new Error(result.error)
  }

  if (
    evaluatedOAuth2Params.grantType === authorizationCodeGrantType &&
    typeof result.code === 'string'
  ) {
    return { code: result.code }
  }

  if (
    evaluatedOAuth2Params.grantType === implicitGrantType &&
    typeof result.accessToken === 'string'
  ) {
    return { accessToken: result.accessToken }
  }

  throw new Error('[runOAuth2AuthorizationFlow] Invalid grant type or result')
}

export default runOAuth2AuthorizationFlow
