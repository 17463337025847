import { useMemo } from 'react'
import type { Project } from 'lib/project'
import { useAnyObjectProperty } from 'utils'
import type { DataTableItem } from '@rapidapi/ui-lib'

const useGetDataTableItems = <T extends Project.AnyObject>(
  objectRef: Project.GenericRef<T>,
  objectProperty: keyof T,
): DataTableItem<Project.GenericRef<Project.KeyValue>>[] => {
  // get Parameter Refs
  const parametersRefs = useAnyObjectProperty(
    objectRef,
    objectProperty,
    undefined /* expect */,
    true /* allowsNull */,
  ) as Project.GenericRef<Project.Parameter>[] | null

  return useMemo(
    () =>
      (parametersRefs || []).map((data) => ({
        uuid: data.ref,
        data,
      })),
    [parametersRefs],
  )
}

export default useGetDataTableItems
