/* eslint-disable react/jsx-props-no-spreading */

import React, { forwardRef } from 'react'
import { withTheme } from '@emotion/react'
import { flexbox, space, layout, variant as variantStyled } from 'styled-system'
// import css from '@styled-system/css'
import styled from '@emotion/styled'
import { Box } from 'components/layout/box'
import {
  ButtonTabsComponentProps,
  ButtonTabsRefProps,
} from './button-tabs.types'

const ButtonTabsStyled = withTheme(
  styled(Box)<Pick<ButtonTabsComponentProps, 'variant'>>(
    () =>
      variantStyled({
        variants: {
          fullWidth: {
            display: 'flex',
            justifyContent: 'space-evenly',
          },
        },
      }),
    flexbox,
    layout,
    space,
  ),
)

export const ButtonTabs = forwardRef<
  ButtonTabsRefProps,
  ButtonTabsComponentProps
>(
  (
    { children: childrenProp, value, variant, onChange, ...other },
    forwardedRef,
  ) => {
    const children = React.Children.map(childrenProp, (child, idx) => {
      if (!React.isValidElement(child)) {
        return null
      }
      return React.cloneElement(child, {
        onClick: () => onChange(idx),
        selected: value === idx,
        width: variant === 'fullWidth' ? '100%' : undefined,
      })
    })

    return (
      <ButtonTabsStyled ref={forwardedRef} variant={variant} {...other}>
        {children}
      </ButtonTabsStyled>
    )
  },
)

export default ButtonTabs
