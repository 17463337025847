/* eslint-disable react/jsx-props-no-spreading */

import { createRef, useState, useEffect } from 'react'
import { withTheme } from '@emotion/react'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { layout, space, LayoutProps, SpaceProps } from 'styled-system'
import { Box } from 'components/layout/box'
import { Flex } from 'components/layout/flex'
import { DropzoneLoader, DropzoneContent } from './dropzone-items'

export type DropzoneProps = SpaceProps &
  LayoutProps & {
    callback: (params: DropzoneCallbackArgs) => void
    status: boolean
    accept?: string[]
  }

export type DropzoneCallbackArgs = {
  file: File | null | undefined
}

const DropContainer = withTheme(
  styled(Flex)(
    layout,
    space,
    ({
      theme: {
        addAlpha,
        colors: { borders },
      },
    }) =>
      css({
        minHeight: 320,
        border: 'dropzoneDefault',
        borderRadius: 'default',
        bg: 'backgrounds.backgroundPrimary',
        transition: 'box-shadow .2s ease, background-color .2s ease',
        'form>input[type="file"]': {
          display: 'none',
        },
        '&.dragOver': {
          boxShadow: `0px 0px 1px 1px ${addAlpha(borders.accent, 0.48)}`,
          transition: 'box-shadow .2s ease, background-color .2s ease',
        },
      }),
  ),
)

const Dropzone: React.FC<DropzoneProps> = ({
  status = false,
  accept,
  callback,
  ...other
}) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [dragStatus, setDragStatus] = useState<boolean>(false)
  const [currentFile, setCurrentFile] = useState<File | null>(null)

  const attachFormRef = createRef<HTMLFormElement>()
  const attachInputRef = createRef<HTMLInputElement>()
  const attachmentTypes = accept || []

  function fileTypeSpecifier(item: string): string {
    if (typeof item !== 'string') {
      return ''
    }

    const type = '*'
    switch (true) {
      case /^(\w+.*\/\*)$/gi.test(item):
        return item.split('/')[0] || type
      case /^(\w+.*\/\w+.*)$/.test(item):
        return item.split('/')[1] || type
      case /^(\.*.*)$/gi.test(item):
        return item.split('.')[1] || type
      default:
        return type
    }
  }

  const fileTypes = attachmentTypes
    ?.map(fileTypeSpecifier)
    .filter((item: string) => item.trim() !== '' || item !== '*')
    .join(', ')

  /**
   * @function attachFileHandler
   * @summary file-attach handler for manual button click
   */
  const attachFileHandler = (event: React.MouseEvent) => {
    event.preventDefault()
    if (attachInputRef.current) {
      attachInputRef.current.click()
    }
  }

  /**
   * @function detachFileHandler
   * @summary file-detach handler to remove the currentfile
   */
  const detachFileHandler = (event: React.MouseEvent) => {
    event.preventDefault()
    if (attachFormRef.current) {
      attachFormRef.current.reset()
      setCurrentFile(null)
    }
  }

  /**
   * @function onDragOver
   * @summary
   */
  const onDragOver = (event: React.DragEvent): void => {
    event.preventDefault()
    setDragStatus(true)
  }

  /**
   * @function onDragLeave
   * @summary
   */
  const onDragLeave = (event: React.DragEvent): void => {
    event.preventDefault()
    setDragStatus(false)
  }

  /**
   * @function onFileDrop
   * @summary
   */
  const onFileDrop = (event: React.DragEvent) => {
    event.preventDefault()

    const list = event.dataTransfer?.files
    const array: File[] = []

    if (Object.keys(list).length > 0) {
      Object.keys(list).forEach(
        (item, index) => index === 0 && array.push(list[index]),
      )

      if (!attachmentTypes.includes(list[0].type)) {
        setDragStatus(false)
        return
      }

      setCurrentFile(list[0])
      setDragStatus(false)

      if (callback) {
        callback({ file: list[0] })
      }
    }
  }

  /**
   * @function onFileAdded
   * @summary
   */
  const onFileAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || Object.keys(event.target.files).length === 0) {
      return
    }

    const list = event.target.files

    const array: File[] = []
    Object.keys(list).forEach(
      (item, index) => index === 0 && array.push(list[index]),
    )

    setCurrentFile(list[0])
    setDragStatus(false)

    if (callback) {
      callback({ file: list[0] })
    }
  }

  useEffect(() => setLoading(status), [status])

  return (
    <DropContainer
      justifyContent="center"
      alignItems="center"
      className={`${dragStatus && 'dragOver'}`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onFileDrop}
      p={4}
      {...other}
    >
      <Box textAlign="center">
        <DropzoneLoader {...{ currentFile, isLoading }} />
        <form ref={attachFormRef}>
          <input
            type="file"
            ref={attachInputRef}
            onChange={onFileAdded}
            accept={attachmentTypes.join(',')}
          />
          {!isLoading && (
            <DropzoneContent
              {...{
                currentFile,
                attachmentTypes: fileTypes,
                attachFileHandler,
                detachFileHandler,
              }}
            />
          )}
        </form>
      </Box>
    </DropContainer>
  )
}

export default Dropzone
