import type { IntrospectionQuery } from 'graphql'
import { buildClientSchema, getIntrospectionQuery, printSchema } from 'graphql'
import type { AppFetch } from 'utils/app-fetch'

interface Options {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  headers?: { [key: string]: string }
  json?: boolean
}

export default async function getRemoteSchema(
  fetchMethod: AppFetch.AppFetch<{ data: IntrospectionQuery; errors: unknown }>,
  endpoint: string,
  options: Options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  },
): Promise<
  | { status: 'ok'; schema: string }
  | { status: 'err'; message: string; schema: null }
> {
  try {
    const { data, errors } = await fetchMethod(endpoint, {
      method: options.method,
      headers: options.headers,
      body: JSON.stringify({
        operationName: null,
        query: getIntrospectionQuery(),
        variables: {},
      }),
    }).then((e) => e.body.json())

    if (errors) {
      return {
        status: 'err',
        message: JSON.stringify(errors, null, 2),
        schema: null,
      }
    }

    if (options.json) {
      return {
        status: 'ok',
        schema: JSON.stringify(data, null, 2),
      }
    }

    const schema = buildClientSchema(data)
    return {
      status: 'ok',
      schema: printSchema(schema),
    }
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return { status: 'err', message: err.message, schema: null }
  }
}
