import { combineReducers } from '@reduxjs/toolkit'

import projectReducer from './slices/project/reducer'
import httpExchangesSlice from './slices/http-exchanges/slice'
import requestHandlingSlice from './slices/request-handling/slice'
import extensionStateReducer from './slices/extensions/reducer'
import { environmentsReducer } from './slices/environments/reducer'
import accountSlice from './slices/account/slice'
import { uiStateReducer } from './slices/ui/reducer'

const rootReducer = combineReducers({
  environments: environmentsReducer,
  httpExchanges: httpExchangesSlice.reducer,
  project: projectReducer,
  requestHandling: requestHandlingSlice.reducer,
  uiState: uiStateReducer,
  extensions: extensionStateReducer,
  account: accountSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
