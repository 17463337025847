import type { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'

import { getAllObjectOfType, getRequestGroup } from '../getters'
import addDynamicString from './add-dynamic-string'
import addObject from './add-object'

export interface AddRequestArgs {
  parent?: Project.GenericRef<Project.RequestGroup>
  uuid?: string
  useNumName?: boolean
  requestUrl?: string
  args?: Partial<Project.Request>
}

const addRequest = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { parent, uuid, useNumName, requestUrl, args }: AddRequestArgs,
): Project.GenericRef<Project.Request> => {
  const request: Project.Request = {
    uuid: uuid || getUuid(),
    type: 'request',
    title: useNumName
      ? `Request ${getAllObjectOfType(objects, 'request').length + 1}`
      : 'New Request',
    summary: '',
    method: args?.method || addDynamicString(objects, root, { string: 'GET' }),
    urlFull:
      args?.urlFull ||
      addDynamicString(objects, root, {
        string: requestUrl,
      }),
    bodyString: args?.bodyString || addDynamicString(objects, root),
    headers: [],
    urlParameters: [],
    variables: [],
    followRedirects: false,
    redirectAuthorization: false,
    redirectMethod: false,
    timeoutInterval: 0,
    sendCookies: true,
    storeCookies: true,
    clientCertificate: null,
    ...args,
  }
  const ref = addObject(objects, request)

  if (parent) {
    const parentGroup = getRequestGroup(parent, objects, false)
    parentGroup.children.push(ref)
  } else {
    const project = objects[root.ref] as Project.Project
    project.requests.push(ref)
  }

  return ref
}

export default addRequest
