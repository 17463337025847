import React from 'react'
import { Box, styled } from '@rapidapi/ui-lib'

const StyledBox = styled(Box)({
  gridArea: 'aside',
})

const AppAside: React.FC = ({ children }) => (
  <StyledBox
    as="aside"
    bg="backgrounds.backgroundSecondary"
    color="texts.highEmphasis"
    width={304}
    overflow="hidden"
    display="flex"
    flexDirection="column"
  >
    {children}
  </StyledBox>
)

export default AppAside
