import React, { useCallback } from 'react'
import GraphDocs from 'graphql-playground-react/lib/components/Playground/DocExplorer/GraphDocs'
import { store } from 'graphql-playground-react'
import { ThemeProvider } from 'graphql-playground-react/lib/styled'
import { Box, styled, css } from '@rapidapi/ui-lib'
import { useDynamicValueObject } from 'utils'
import { Provider, useSelector } from 'react-redux'
import { selectCurrentRequestBodyDynamicValueRef } from 'store/selectors'
import { JsonTreeEditor, TabBarContainer } from 'ecosystems'
import type { GraphQLDynamicValueInterface } from 'lib'
import type { GraphQLSchema } from 'graphql'
import useDocumentationTheme from './use-documentation-theme'
import GQLSchemaDisplay from './gql-schema-display'

const BoxStyled = styled(Box)(
  css({
    border: 'borders.borderDefault',
    borderRadius: 'default',
  }),
)

interface GQLTabsProps {
  schema: GraphQLSchema
  schemaString: string
}

const tabs = [
  { key: 'variables', label: 'Variables' },
  { key: 'schema', label: 'Schema' },
  { key: 'docs', label: 'Docs' },
]

const GQLTabs: React.FC<GQLTabsProps> = ({
  schema,
  schemaString,
}): JSX.Element => {
  const onlyDvRef = useSelector(selectCurrentRequestBodyDynamicValueRef)
  const dynamicValue = useDynamicValueObject<GraphQLDynamicValueInterface>(
    onlyDvRef || { ref: '' },
  )
  const documentationTheme = useDocumentationTheme()

  const renderRequestTabMemo = useCallback(
    (ct: string): JSX.Element => {
      if (ct === 'variables') {
        return (
          <BoxStyled p={2} mx={4} overflow="scroll">
            <JsonTreeEditor<GraphQLDynamicValueInterface>
              objectRef={{ ref: dynamicValue.uuid }}
              objectProperty="gqlVariables"
              jsonStringProperty="gqlVariables"
              isKeyEditable={false}
              isExtendable={false}
            />
          </BoxStyled>
        )
      }
      if (ct === 'schema') {
        return (
          <BoxStyled p={2} mx={4} style={{ minHeight: '30vh' }}>
            <GQLSchemaDisplay schema={schemaString} />
          </BoxStyled>
        )
      }
      return (
        <BoxStyled p={2} mx={4} overflow="scroll">
          <ThemeProvider theme={documentationTheme}>
            <Provider store={store}>
              <GraphDocs schema={schema} setWidth={() => ({})} />
            </Provider>
          </ThemeProvider>
        </BoxStyled>
      )
    },
    [schema, schemaString, dynamicValue, documentationTheme],
  )

  return (
    <TabBarContainer
      tabAlign="flex-start"
      config={{ defaultTab: 'variables', identifier: 'key', tabs }}
      renderTab={renderRequestTabMemo}
      flexBasis={0}
      flexGrow={1}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      mt={2}
      tabBarProps={{ px: 5 }}
    />
  )
}
export default GQLTabs
