/* eslint-disable react/jsx-props-no-spreading */

import { forwardRef } from 'react'
import { Box } from '../box'
import { BoxComponentProps } from '../box/box.types'

export const AppContainer = forwardRef<HTMLDivElement, BoxComponentProps>(
  ({ children, ...other }, forwardedRef) => (
    <Box ref={forwardedRef} px={8} py={6} flexGrow={1} {...other}>
      {children}
    </Box>
  ),
)

export default AppContainer
