import type { Project } from 'lib/project'

const parseTokenId = (
  id: string,
): Project.GenericRef<Project.DynamicValue> | null => {
  const m = id.match(/^#([a-f0-9-]{32,36})#$/i)
  if (!m) {
    return null
  }
  return { ref: m[1] }
}

export default parseTokenId
