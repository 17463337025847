import type { Project } from 'lib/project/types.d'

const getAllStrings = (
  dynamicString: Project.DynamicString,
  convertDv?: (dv: Project.GenericRef<Project.DynamicValue>) => string,
): string | null =>
  dynamicString.strings
    .map((item) => {
      if (typeof item === 'string') {
        return item
      }
      return convertDv ? convertDv(item) : ''
    })
    .join('')

export default getAllStrings
