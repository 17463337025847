import type { PayloadAction } from '@reduxjs/toolkit'

import {
  selectCurrentTreeItemRef,
  selectCurrentEnvironments,
  selectAgentStatus,
} from 'store/selectors'
import {
  httpExchangesActions,
  requestHandlingActions,
  sendCurrentRequestAction,
  sendRequestAction,
} from 'store/actions'
import { requestSendWorkerAction } from 'worker/primary-worker/actions'

import { segmentTrack } from 'utils/segment/segment-functions'

import type { ActionHandler } from '../types.d'

const requestSendActionHandler = async ({
  action,
  next,
  worker,
  getState,
}: ActionHandler<PayloadAction<string | void>>): Promise<void> => {
  // for convenience, we're handling two actions here, so filter first
  let requestUuid: string | null = null
  if (sendCurrentRequestAction.match(action)) {
    requestUuid = selectCurrentTreeItemRef(getState())?.ref || null
  } else if (sendRequestAction.match(action)) {
    requestUuid = action.payload
  }
  if (!requestUuid) {
    throw new Error('Invalid action')
  }

  // check worker
  if (!worker) {
    throw new Error('Worker is missing')
  }

  const currentEnvironments = selectCurrentEnvironments(getState())
  const { active, installed } = selectAgentStatus(getState())

  // analytics log
  segmentTrack('Request Send')

  // add to queue
  next(requestHandlingActions.addPendingRequest({ requestUuid }))

  // send request
  const httpExchange = await worker.runAction(requestSendWorkerAction, {
    usePawAgent: active && installed,
    requestUuid,
    currentEnvironments,
  })

  // remove from queue
  next(requestHandlingActions.removePendingRequest({ requestUuid }))
  next(
    httpExchangesActions.appendHttpExchangesForRequest({
      requestUuid,
      httpExchange,
    }),
  )
}

export default requestSendActionHandler
