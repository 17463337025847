import AdvancedMonaco from 'ecosystems/advanced-editor/advanced-monaco'
import React from 'react'

interface GQLSchemaDisplayProps {
  schema: string
}

const GQLSchemaDisplay: React.FC<GQLSchemaDisplayProps> = ({ schema }) => (
  <AdvancedMonaco
    content={schema}
    objectRef={{ ref: '' }}
    language={{ language: 'graphql' }}
    options={{ readOnly: true }}
  />
)

export default GQLSchemaDisplay
