import type { Project } from 'lib/project/types.d'
import { getObject } from '../atoms'

type FindRequestTreeItemParentResult =
  | Project.GenericRef<Project.RequestGroup>
  | null
  | undefined

/**
 * Finds the parent of a given request tree item (Request or Group), inside
 * either the root or a sub-group.
 * Passing a Request as `parentRef` will result in returning always `undefined`.
 * @param itemRef The ref or the item to look for.
 * @param objects The object map.
 * @param parentRef The parent in which to look for (either the root or a sub-group)
 * @returns Returns the ref of the parent of the found object, null if the object is
 * at the root, or undefined if not found.
 */
const findRequestTreeItemParent = (
  itemRef: Project.GenericRef<Project.AnyRequestTreeItem>,
  objects: Project.ObjectMap,
  parentRef: Project.GenericRef<Project.Project | Project.AnyRequestTreeItem>,
): FindRequestTreeItemParentResult => {
  const parent = getObject(parentRef, objects, false)
  let childrenRefs: Project.GenericRef<Project.RequestGroup>[]
  if (parent.type === 'project') {
    childrenRefs = parent.requests
  } else if (parent.type === 'group') {
    childrenRefs = parent.children
  } else {
    return undefined
  }

  // look in direct children first and then recursively
  let result: FindRequestTreeItemParentResult

  // item is a direct child
  result = childrenRefs.find((childRef) => childRef.ref === itemRef.ref)

  // found direct child but, if is project returns null, else parentRef
  if (result) {
    return parent.type === 'project' ? null : parentRef
  }

  // direct child not found. find grand-child
  // we use every() instead of forEach() so we can stop the iteration
  childrenRefs.every((childRef) => {
    result = findRequestTreeItemParent(itemRef, objects, childRef)
    return !result
  })

  return result
}

export default findRequestTreeItemParent
