import config from 'config'
import cloudAPIConfig from 'lib/cloud-sync/cloud-api/config'
import type { Project } from 'lib/project'
import { agentProxySender, cfProxySender } from 'lib/request-handling'
import { getUuid } from 'lib/utils'
import { base64DecodeString } from 'lib/utils/encoding'
import type { FetchResult } from './fetch-types.d'

/**
 * Fetch using the CF proxy.
 * Used to test locally bypassing CORS restrictions and to use the Electron app fetch.
 * @param path Path to fetch (base URL is https://paw.cloud/ or configured in .env)
 * @param method Method, if omitted, GET.
 * @param headers Headers
 * @param body Body to include
 */
const proxyFetch = async (
  path: string,
  method?: string,
  headers?: Project.ObjectMap<string>,
  body?: string,
): Promise<FetchResult> => {
  const { API_ENDPOINT } = cloudAPIConfig
  const sender = config.build === 'desktop' ? agentProxySender : cfProxySender
  const crRequest = await sender({
    projectUuid: getUuid(),
    requestUuid: getUuid(),
    evaluatedRequest: {
      method: method || 'GET',
      url: `${API_ENDPOINT}${path}`,
      headers: Object.entries(headers || {}).map(([name, value]) => ({
        uuid: getUuid(),
        mode: 0,
        name,
        value,
      })),
      body: body || '',
    },
    httpExchange: null,
    buildSteps: [],
    sender: null,
  })
  const response = crRequest.httpExchange?.response
  if (!response) {
    throw new Error('Failed to fetch')
  }
  const responseBody = base64DecodeString(response.body.text || '', 'utf-8')

  return {
    ok: response.status >= 200 && response.status < 300,
    status: response.status,
    statusText: response.statusText,
    body: {
      text: () => Promise.resolve(responseBody),
      json: async () => JSON.parse(responseBody),
    },
  }
}

export default proxyFetch
