import iconPath from 'assets/img/icons.svg'
import addAlpha from '../utils/add-alpha'

const globalSettings = {
  iconPath,
  fonts: {
    default:
      'Inter, -apple-system, BlinkMacSystemFont, Avenir Next, Avenir, Helvetica, sans-serif',
    mono: 'JetBrains Mono, sans-serif',
  },
  radii: {
    default: '6px',
  },
  spacing: {
    defaultMargin: '12px',
  },
  fontSizes: {
    title: 36,
    headline: 24,
    body1: 16,
    body2: 14,
    caption: 12,
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 600,
  },
  lineHeights: {
    title: '36px',
    headline: '32px',
    body1: '24px',
    body2: '20px',
    caption: '16px',
  },
  letterSpacings: {
    title: '-0.02em',
    headline: 'normal',
    body1: 'normal',
    body2: 'normal',
    caption: 'normal',
  },
  space: [0, 4, 8, 12, 16, 24, 32, 40, 72, 120],
  sizes: {
    default: {
      py: 6,
    },
    large: {
      py: 8,
    },
  },
  typography: {
    title: {
      fontFamily: 'default',
      fontSize: 'title',
      lineHeight: 'title',
      letterSpacing: 'title',
    },
    headline: {
      fontFamily: 'default',
      fontSize: 'headline',
      lineHeight: 'headline',
      letterSpacing: 'headline',
    },
    body1: {
      fontFamily: 'default',
      fontSize: 'body1',
      lineHeight: 'body1',
      letterSpacing: 'body1',
    },
    body2: {
      fontFamily: 'default',
      fontSize: 'body2',
      lineHeight: 'body2',
      letterSpacing: 'body2',
    },
    caption: {
      fontFamily: 'default',
      fontSize: 'caption',
      lineHeight: 'caption',
      letterSpacing: 'caption',
    },
  },
  addAlpha,
}

export default globalSettings
