/* eslint-disable react/jsx-props-no-spreading */

import { Box } from 'components/layout/box'
import { Flex } from 'components/layout/flex'
import { Typography } from 'components/data-display/typography'
import { Icon, IconSymbol } from 'components/data-display/icon'
import { SpaceProps } from 'styled-system'

export type EmptyStateProps = SpaceProps & {
  symbol?: IconSymbol
  headline?: string
  body?: string
}

const EmptyState: React.FC<EmptyStateProps> = ({
  symbol,
  headline,
  body,
  children,
}) => (
  <Box
    justifyContent="center"
    alignContent="center"
    textAlign="center"
    minHeight={250}
  >
    {symbol && (
      <Icon
        symbol={symbol}
        size={56}
        display="block"
        mx="auto"
        color="fills.secondary"
      />
    )}
    <Box mt={3}>
      {headline && (
        <Typography variant="headline" fontWeight="bold" mb={3}>
          {headline}
        </Typography>
      )}
      {body && (
        <Typography variant="body2" color="mediumEmphasis" mb={3}>
          {body}
        </Typography>
      )}
    </Box>
    {children && (
      <Flex flexDirection="column" alignItems="center">
        {children}
      </Flex>
    )}
  </Box>
)

export default EmptyState
