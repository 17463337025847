/* eslint-disable react/jsx-props-no-spreading */

import { withTheme } from '@emotion/react'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant as variantStyled,
  VariantArgs,
} from 'styled-system'
import { Box } from 'components/layout/box'

export type SpinnerProps = LayoutProps &
  SpaceProps &
  VariantArgs & {
    size?: 'default' | 'large'
    variant?: 'indeterminate' | 'determinate'
    value?: number
    boxSize?: number
    boxRadius?: number
  }

const SpinnerIntermediate = withTheme(
  styled.svg<SpinnerProps>(
    layout,
    space,
    {
      '@keyframes circular-dash': {
        '0%': {
          strokeDasharray: '1px, 200px',
          strokeDashoffset: 0,
        },
        '50%': {
          strokeDasharray: '100px, 200px',
          strokeDashoffset: -15,
        },
        '100%': {
          strokeDasharray: '100px, 200px',
          strokeDashoffset: -145,
        },
      },
      '@keyframes circular-rotate': {
        '0%': {
          transformOrigin: '50% 50%',
        },
        '100%': {
          transform: 'rotate(360deg)',
        },
      },
    },
    () =>
      css({
        '&': {
          transformOrigin: '50% 50%',
        },
        '& .circular-path': {
          stroke: 'backgrounds.backgroundAccent',
          strokeDasharray: '0px, 100px',
          transformOrigin: 'center',
        },
      }),
    ({ size, value = 0 }) =>
      variantStyled({
        variants: {
          indeterminate: {
            '&': {
              animation: 'circular-rotate 2s linear infinite',
            },
            '& .circular-path': {
              strokeWidth: size === 'default' ? '4px' : '8px',
              animation:
                size === 'default'
                  ? 'circular-dash 3.2s cubic-bezier(0.35, 0, 0.25, 1) infinite'
                  : 'circular-dash 2.2s ease infinite',
            },
          },
          determinate: {
            '&': {
              animation: 'none',
              transform: 'rotate(-90deg)',
            },
            '& .circular-path': {
              stroke: 'backgrounds.backgroundAccent',
              strokeWidth: size === 'default' ? '4px' : '8px',
              strokeDasharray:
                size === 'default'
                  ? `${value / 1.4}px, 200px`
                  : `${value * 1.38}px, 200px`,
              strokeDashoffset: 0,
              transition: 'stroke-dasharray 1s linear',
            },
          },
        },
      }),
  ),
)

const Spinner: React.FC<SpinnerProps> = ({
  variant = 'indeterminate',
  size = 'default',
  value = 0,
  ...other
}) => {
  const boxSize = size === 'default' ? 24 : 56
  const boxRadius = Math.round(boxSize / 2.5).toFixed(0)
  return (
    <Box width={boxSize} height={boxSize} {...other}>
      <SpinnerIntermediate
        viewBox={`${boxSize / 2} ${boxSize / 2} ${boxSize} ${boxSize}`}
        width={boxSize}
        height={boxSize}
        variant={variant}
        size={size}
        value={value}
        boxSize={boxSize}
        boxRadius={Number(boxRadius)}
      >
        <circle
          className="circular-path"
          fill="none"
          cx={boxSize}
          cy={boxSize}
          r={boxRadius}
        />
      </SpinnerIntermediate>
    </Box>
  )
}

export default Spinner
