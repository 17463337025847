/* eslint-disable react/jsx-props-no-spreading */

import { withTheme } from '@emotion/react'
import React from 'react'
import { createPortal } from 'react-dom'
import styled from '@emotion/styled'
import { css } from '@styled-system/css'
import { Box } from 'components/layout/box'
import { Elevation } from 'components/data-display'
import { DialogTitle } from 'components/feedback/dialog/dialog-title'
import { IconButton } from 'components/inputs'
import useDialog from 'components/feedback/dialog/use-dialog'

export type DrawerProps = {
  title?: string | React.ReactNode
  width?: number | string
  open?: boolean
  disableBackdropClick?: boolean
  onClose?: () => void
}

const DrawerStyled = withTheme(
  styled(Box)({
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    position: 'fixed',
    zIndex: 9998,

    '&:before': {
      position: 'fixed',
      content: '""',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: -1,
    },

    '& .elevation': {
      height: '100vh',
    },

    '&.open': {
      '&:before': {
        animation: 'fade-in .2s ease',
        animationIterationCount: 1,
      },
      '& .elevation': {
        animation: 'slide-out .3s cubic-bezier(0.4, 0.0, 1, 1)',
        animationIterationCount: 1,
      },
    },

    '&.close': {
      '&:before': {
        animation: 'fade-out .3s ease',
        animationIterationCount: 1,
        animationDelay: '.5s',
      },
      '& .elevation': {
        animationIterationCount: 1,
        animation: 'slide-in .3s cubic-bezier(0.4, 0.0, 1, 1)',
      },
    },

    '@keyframes slide-out': {
      '0%': {
        opacity: 0,
        transform: 'translateX(50vw)',
      },
      '100%': {
        opacity: 1,
        transform: 'translateX(0)',
      },
    },

    '@keyframes slide-in': {
      '0%': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '100%': {
        opacity: 0,
        transform: 'translateX(50vw)',
      },
    },

    '@keyframes fade-in': {
      '0%': { backgroundColor: 'rgba(0,0,0,0)' },
      '100%': { backgroundColor: 'rgba(0,0,0,0.5)' },
    },

    '@keyframes fade-out': {
      '0%': { backgroundColor: 'rgba(0,0,0,0.5)' },
      '100%': { backgroundColor: 'rgba(0,0,0,0)' },
    },
  }),
)

const Drawer: React.FC<DrawerProps> = ({
  open = false,
  title = '',
  width = 484,
  disableBackdropClick,
  onClose,
  children,
}) => {
  const elementRef = document.createElement('div') as HTMLDivElement
  const { portalRoot, isVisible, ref, close } = useDialog({
    elementRef,
    open,
    onClose,
    disableBackdropClick,
  })

  if (!isVisible) {
    return null
  }

  return createPortal(
    <DrawerStyled className={`${open ? 'open' : 'close'}`}>
      <Box width={width} maxWidth={width} ref={ref}>
        <Elevation
          p={5}
          marginRight={0}
          marginTop={0}
          marginBottom={0}
          overflowY="scroll"
          size="large"
          rounded={false}
        >
          <Box
            css={{
              position: 'sticky',
              top: -24,
              paddingTop: 2,
              paddingBottom: 1,
              zIndex: 9001,
              backgroundColor: 'backgrounds.primary',
            }}
          >
            <DialogTitle>
              <IconButton
                icon="sidebar-show"
                mr={5}
                onClick={onClose || close}
              />
              {title}
            </DialogTitle>
          </Box>
          {children}
        </Elevation>
      </Box>
    </DrawerStyled>,
    portalRoot,
  )
}

export default Drawer
