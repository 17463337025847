import type { FallbackRender } from '@sentry/react/dist/errorboundary'
import React, { useCallback, useMemo } from 'react'
import * as Sentry from '@sentry/react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { usePrompt } from '@rapidapi/ui-lib'

import ErrorBoundaryFeedbackBox from './error-boundary-feedback-box'
import useClearProjectData from './use-clear-project-data'

interface ErrorFallbackProps {
  error?: Error
  eventId?: string | null
}

const ErrorBoundaryRouteAware: React.FC<ErrorFallbackProps> = ({
  error,
  eventId,
}) => {
  // get project ID from route
  const routeMatch = useRouteMatch('/projects/:id')
  const { id } = useParams<{ id?: string }>()
  const projectId = useMemo(
    () => (routeMatch && id ? parseInt(id, 10) : null),
    [routeMatch, id],
  )

  // reload page
  const reloadPage = useCallback(() => {
    window.location.reload()
  }, [])

  // clear project data functions
  const clearProjectDataFn = useClearProjectData(projectId)
  const prompt = usePrompt()
  const clearProjectData = useCallback(() => {
    if (clearProjectDataFn) {
      prompt({
        title: 'Clear Local Project Data?',
        content:
          'If an error persists after relaunching the application, you can try to clear the local project data. Changes that have been synced with the cloud will not be affected.',
        proccedText: 'Clear Local Data',
        cancelText: 'Cancel',
        onProceed: clearProjectDataFn,
      })
    }
  }, [prompt, clearProjectDataFn])

  return (
    <ErrorBoundaryFeedbackBox
      error={error}
      eventId={eventId}
      projectId={projectId}
      clearProjectData={clearProjectData}
      reloadPage={reloadPage}
    />
  )
}

interface ErrorBoundaryProps {
  isRouteAware?: boolean
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  isRouteAware,
}) => {
  const fallback: FallbackRender = ({ error, eventId }) =>
    isRouteAware ? (
      <ErrorBoundaryRouteAware error={error} eventId={eventId} />
    ) : (
      <ErrorBoundaryFeedbackBox error={error} eventId={eventId} />
    )
  return (
    <Sentry.ErrorBoundary fallback={fallback} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
