import React, { useMemo, useCallback } from 'react'
import { Flex, styled } from '@rapidapi/ui-lib'

import { TabBarContainer } from 'ecosystems/tab-bar-container'
import {
  RequestDefaultTab,
  RequestHeadersTab,
  RequestQueryTab,
  RequestBodyTab,
  RequestAuthTab,
  RequestDescriptionTab,
} from 'ecosystems/project/request-editor/request-tabs'
import { useCurrentRequest } from 'utils/hooks'

const TabContentContainer = styled(Flex)({
  flexBasis: 0,
  flexGrow: 1,
  overflow: 'hidden',
  flexDirection: 'column',
  justifyContent: 'stretch',
})

const components = {
  description: RequestDescriptionTab,
  headers: RequestHeadersTab,
  query: RequestQueryTab,
  body: RequestBodyTab,
  auth: RequestAuthTab,
  // options: RequestDefaultTab,
}

type TabKey = keyof typeof components

type RenderRequestTabProps = {
  tabValue: TabKey
}

const renderRequestTab: React.FC<RenderRequestTabProps> = ({
  tabValue,
}): JSX.Element => {
  const RequestTab = components[tabValue] || RequestDefaultTab
  return <RequestTab />
}

const RequestTabsContainer: React.FC = () => {
  const request = useCurrentRequest()

  const config = useMemo(
    () => ({
      identifier: `requestTabs/${request?.uuid}`,
      tabs: [
        { key: 'description', label: 'Description' },
        { key: 'headers', label: 'Headers' },
        { key: 'query', label: 'Query' },
        { key: 'body', label: 'Body' },
        { key: 'auth', label: 'Auth' },
        // { key: 'options', label: 'Options' },
      ],
      defaultTab: 'description',
    }),
    [request],
  )

  const renderRequestTabMemo = useCallback(
    (tabValue: string) => (
      <TabContentContainer>
        {renderRequestTab({
          tabValue: tabValue as TabKey,
        })}
      </TabContentContainer>
    ),
    [],
  )

  if (!request || request.type !== 'request') {
    return null
  }

  return (
    <TabBarContainer
      tabAlign="flex-start"
      config={config}
      renderTab={renderRequestTabMemo}
      flexBasis={0}
      flexGrow={1}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      mt={2}
      tabBarProps={{ px: 5 }}
    />
  )
}

export default RequestTabsContainer
