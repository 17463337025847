import { useEffect, useState } from 'react'

const useWatchMedia = (media: string): boolean => {
  const [matches, setMatches] = useState<boolean>(false)

  useEffect(() => {
    const watchedMedia = window.matchMedia(media)
    let canceled = false

    const mediaListener = () => {
      if (canceled) {
        return
      }
      setMatches(watchedMedia.matches)
    }

    mediaListener()
    watchedMedia.onchange = mediaListener

    return () => {
      canceled = true
      watchedMedia.onchange = mediaListener
    }
  }, [media])

  return matches
}

export default useWatchMedia
