import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'

import { useGetAccountInfo } from 'lib-hooks'
import { segmentIdentify } from 'utils/segment'
import { accountActions } from 'store/actions'

const useUserIdentifyLog = (): void => {
  const { status, response } = useGetAccountInfo()
  const dispatch = useDispatch()
  useEffect(() => {
    if (status === 'succeeded' && response) {
      // Log Rocket
      LogRocket.identify(String(response.id), {
        email: response.email,
      })

      // Sentry
      Sentry.setUser({
        id: String(response.id),
        email: response.email,
      })

      // Segment
      segmentIdentify(String(response.id), {
        email: response.email,
      })

      // Set state
      dispatch(
        accountActions.setAccountUserResponse({ userResponse: response }),
      )
    }
  }, [dispatch, status, response])
}

export default useUserIdentifyLog
