import type { PayloadAction } from '@reduxjs/toolkit'
import { extensionsActions } from 'store/actions'
import { extensionsLoadListWorkerAction } from 'worker/primary-worker/actions'

import type { ActionHandler } from '../types.d'

const extensionsLoadListActionHandler = async ({
  action: {
    payload: { scripts },
  },
  next,
  worker,
}: ActionHandler<PayloadAction<{ scripts: string[] }>>): Promise<void> => {
  // if no worker, throw
  if (!worker) {
    throw new Error('Worker is missing')
  }

  const extensions = await worker.runAction(extensionsLoadListWorkerAction, {
    scripts: [...scripts].filter((i) => i !== null),
  })

  if (extensions) {
    next(
      extensionsActions.setExtensionsResource({
        extensions,
      }),
    )
  }
}

export default extensionsLoadListActionHandler
