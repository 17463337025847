/* eslint-disable react/jsx-props-no-spreading */

import { useCallback, MouseEvent, HTMLAttributes } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import css from '@styled-system/css'
import { Icon } from 'components/data-display/icon'
import { Box } from 'components/layout/box'
import { Checkbox } from 'components/inputs/checkbox'
import { DataTableCellProps } from '../data-table.types'

const DataTableCellStyled = withTheme(
  styled(Box)<{
    fieldIndex?: number
    depth?: number
    disabled?: boolean
    hasCheckbox?: boolean
  }>(
    ({
      fieldIndex,
      depth = 0,
      disabled,
      hasCheckbox,
      theme: { typography, space },
    }) =>
      css({
        ...typography.body2,
        '&>div': {
          display: 'inline-flex',
        },
        'div.checked': {
          minWidth: 'auto',
          width: 'fit-content',
          flex: 'unset',
          paddingRight: 3,
        },
        display: 'flex',
        position: 'relative',
        color: `texts.${disabled ? 'lowEmphasis' : 'highEmphasis'}`,
        alignSelf: 'stretch',
        alignItems: 'center',
        paddingLeft:
          fieldIndex === 0
            ? depth * 28 + (hasCheckbox ? 20 + space[3] : 0)
            : undefined,
        paddingRight: 4,
        '&:last-of-type': {
          paddingRight: 0,
        },
      }),
  ),
)

const DataTableCell = <T, K>({
  children,
  item,
  field,
  fieldIndex,
  collapsed,
  onToggle,
  onChangeItemData,
  depth,
  checkboxProps,
  itemIndex,
  ...other
}: DataTableCellProps<T, K> & HTMLAttributes<HTMLElement>): JSX.Element => {
  const toggleItems = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      if (item && onToggle) {
        onToggle(item.uuid)
      }
    },
    [item, onToggle],
  )

  const toggleChecked = useCallback(
    ({ target: { checked } }) => {
      if (item && onChangeItemData) {
        onChangeItemData({ uuid: item.uuid, data: item.data, checked })
      }
    },
    [item, onChangeItemData],
  )

  if (!item) {
    return (
      <DataTableCellStyled
        data-idx={itemIndex}
        {...{ fieldIndex, depth, ...other }}
      >
        {children}
      </DataTableCellStyled>
    )
  }

  return (
    <DataTableCellStyled
      data-idx={itemIndex}
      {...{ disabled: item.disabled, fieldIndex, field, depth }}
    >
      {item.children && fieldIndex === 0 && item.children.length > 0 && (
        <Box
          display="flex"
          mr={2}
          minWidth="auto"
          onClick={toggleItems}
          css={{ cursor: 'pointer' }}
        >
          <Icon
            symbol={collapsed ? 'caret-up' : 'caret-down'}
            size={20}
            minWidth="auto"
          />
        </Box>
      )}
      {Object.prototype.hasOwnProperty.call(item, 'checked') &&
        fieldIndex === 0 && (
          <Box className="checked">
            <Checkbox
              disabled={item.disabled}
              checked={item.checked}
              onChange={toggleChecked}
              css={{ alignItems: 'center' }}
              m={0}
              {...checkboxProps}
            />
          </Box>
        )}
      {field ? field.value(item?.data, onChangeItemData) : children}
    </DataTableCellStyled>
  )
}

export default DataTableCell
