/* eslint-disable react/jsx-props-no-spreading */

import { isValidElement, useRef, useState, useEffect } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { space as spaceStyled, variant as variantStyled } from 'styled-system'
import css from '@styled-system/css'
import { Icon } from 'components/data-display/icon'
import { ButtonComponentProps, ButtonIconProps } from './button.types'

const ButtonStyled = withTheme(
  styled.button<ButtonComponentProps>(
    ({ children, size, theme: { typography, colorScheme }, width }) =>
      css({
        alignItems: 'center',
        appearance: 'none',
        borderRadius: 'default',
        '.spinner': {
          display: 'none',
          position: 'absolute',
          animation: 'spin 0.5s linear infinite',
        },
        borderStyle: 'solid',
        borderWidth: '1px',
        boxSizing: 'border-box',
        colorScheme,
        cursor: 'pointer',
        display: 'inline-flex',
        fontWeight: 'normal',
        justifyContent: 'center',
        height: size === 'small' ? 24 : 36,
        margin: 0,
        outline: '0 none',
        paddingX: () => {
          if (Boolean(children)) {
            return size === 'small' ? 2 : 3
          }
          return size === 'small' ? '3px' : '7px'
        },
        paddingY: 0,
        position: 'relative',
        // @todo: *Help Needed* couldn't find an example on the web... :(
        transition:
          'color .25s cubic-bezier(0.4, 0.0, 0.2, 1), background-color .25s cubic-bezier(0.4, 0.0, 0.2, 1)',
        ...typography[size === 'small' ? 'caption' : 'body2'],
        userSelect: 'none',
        verticalAlign: 'middle',
        width,
        '.container': {
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          whiteSpace: 'nowrap',
        },
        '&[disabled], &:disabled': {
          opacity: 0.4,
        },
        '&.loading': {
          '.spinner': {
            display: 'block',
          },
          '.container': {
            visibility: 'hidden',
          },
        },
        '@keyframes spin': {
          from: {
            transform: 'rotate(0deg)',
          },
          to: {
            transform: 'rotate(360deg)',
          },
        },
      }),
    variantStyled({
      variants: {
        danger: {
          bg: 'backgrounds.backgroundDanger',
          borderColor: 'backgrounds.backgroundDanger',
          '&:hover:enabled, &.focused': {
            bg: 'backgrounds.backgroundDangerHover',
            borderColor: 'backgrounds.backgroundDangerHover',
          },
          color: 'texts.highEmphasisConstant',
        },
        default: {
          bg: 'backgrounds.backgroundPrimary',
          borderColor: 'borders.borderDefault',
          '&:hover:enabled, &.focused': {
            bg: 'backgrounds.backgroundDefaultHover',
          },
          color: 'texts.highEmphasis',
        },
        primary: {
          bg: 'backgrounds.backgroundAccent',
          borderColor: 'backgrounds.backgroundAccent',
          '&:hover:enabled, &.focused': {
            bg: 'backgrounds.backgroundAccentHover',
            borderColor: 'backgrounds.backgroundAccentHover',
          },
          color: 'texts.highEmphasisConstant',
        },
        text: {
          bg: 'transparent',
          borderColor: 'transparent',
          '&:hover:enabled, &.focused': {
            bg: 'backgrounds.backgroundDefaultHover',
            borderColor: 'borders.backgroundDefaultHover',
          },
          color: 'texts.highEmphasis',
        },
      },
    }),
    spaceStyled,
  ),
)

const getIcon = ({ icon, ...other }: ButtonIconProps) =>
  isValidElement(icon) ? (
    icon
  ) : (
    <Icon
      color="inherit"
      symbol={icon}
      iconProps={{ display: 'block' }}
      {...other}
    />
  )

export const Button: React.FC<ButtonComponentProps> = ({
  as,
  children,
  disabled = false,
  endIcon,
  endIconProps,
  focused = false,
  loading,
  size = 'default',
  startIcon,
  startIconProps,
  type: typeProp,
  variant = 'default',
  onClick,
  ...other
}) => {
  const ref = useRef<HTMLButtonElement | null>(null)
  const [width, setWidth] = useState<number | undefined>()
  const type = as ? undefined : typeProp

  useEffect(() => {
    if (ref.current && loading) {
      // This a crazy kind of fix for the jumping animation issue ~~in IE6~~ in Safari
      setWidth(Math.ceil(ref.current.getBoundingClientRect().width))
    }
  }, [ref, loading])

  const iconMargin = size === 'small' ? '4px' : '6px'
  const iconSize = size === 'small' ? 16 : 20

  return (
    <ButtonStyled
      className={[focused && 'focused', loading && 'loading']
        .filter((i) => i)
        .join(' ')}
      {...{
        as,
        disabled,
        ref,
        size,
        type,
        variant,
        width,
        onClick,
      }}
      {...other}
    >
      <div className="container">
        {startIcon &&
          getIcon({
            icon: startIcon,
            size: iconSize,
            mr: children ? iconMargin : 0,
            ...startIconProps,
          })}
        {children}
        {endIcon &&
          getIcon({
            icon: endIcon,
            size: iconSize,
            ml: children ? iconMargin : 0,
            ...endIconProps,
          })}
      </div>
      {loading &&
        getIcon({ icon: 'spinner', size: iconSize, className: 'spinner' })}
    </ButtonStyled>
  )
}

export default Button
