/* eslint-disable react/jsx-props-no-spreading */

import styled from '@emotion/styled'
import css from '@styled-system/css'
import { withTheme } from '@emotion/react'
import { Button } from 'components/inputs/button'
import { ButtonComponentProps } from '../../inputs/button/button.types'

export interface LinkProps<C extends React.ElementType> {
  selected?: boolean
  as?: C
}

export interface LinkComponentProps<C extends React.ElementType>
  extends Omit<ButtonComponentProps, 'variant' | 'as'>,
    LinkProps<C> {}

const LinkStyled = withTheme(
  styled(Button)(
    css({
      bg: 'transparent',
      borderColor: 'transparent',
      color: 'texts.textInfo',
      height: 'auto',
      padding: 0,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      '&[disabled], &:disabled': {
        color: 'texts.mediumEmphasis',
      },
    }),
  ),
)

export const Link = <C extends React.ElementType = 'a'>({
  as,
  children,
  disabled,
  onClick: onClickProp,
  ...other
}: LinkComponentProps<C> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof LinkComponentProps<C>>) => {
  const onClick = disabled
    ? (event: React.MouseEvent) => {
        event.preventDefault()
      }
    : onClickProp
  return (
    <LinkStyled
      as={as ?? 'a'}
      disabled={disabled}
      variant="text"
      {...other}
      onClick={onClick}
    >
      {children}
    </LinkStyled>
  )
}

export { Link as A }
export default Link

// @TODO: finish refactoring and get rid of the commented code below

// import { jsx, withTheme } from '@emotion/react'
// import css from '@styled-system/css'
// import { LinkProps, useNavigate } from 'react-router-dom'
// import React, { useCallback } from 'react'
// import styled from '@emotion/styled'
// import {
//   color,
//   layout,
//   space,
//   variant as variantStyled,
//   VariantArgs,
// } from 'styled-system'
// import { IconSymbol, ContentColors } from '../../../themes/common.types'
// import LinkContent from './link-content'

// export type AnchorTargetProps = '_blank' | '_parent' | '_self'
// export type AnchorProps = VariantArgs & {
//   size?: 'default' | 'small'
//   variant?: ContentColors
//   // @FIXME
//   // @ts-ignore
//   link?: LinkProps<string>
//   href?: string
//   isLinkActive?: boolean
//   disabled?: boolean
//   target?: AnchorTargetProps
//   displayInline?: boolean
//   prefix?: IconSymbol
//   suffix?: IconSymbol
//   onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
//   download?: boolean | string
//   component?: any
// }

// const StyledAnchor = withTheme(
//   styled.a(
//     ({ theme: { colors, addAlpha }, isLinkActive, disabled }) =>
//       css({
//         '&, & svg, & [color="primary"], & [color="secondary"]': {
//           color: disabled && addAlpha(colors.texts.lowEmphasis, 0.28),
//           textDecoration: isLinkActive && !disabled ? 'underline' : 'none',
//           cursor: !disabled ? 'pointer' : 'not-allowed',
//           // verticalAlign: 'middle',
//         },
//         '&:hover [color="primary"], &:hover [color="secondary"]': {
//           textDecoration: 'underline',
//         },
//       }),
//     variantStyled({
//       variants: {
//         primary: {
//           '&, & svg, & [color="primary"]': {
//             color: 'texts.textInfo',
//           },
//         },
//         secondary: {
//           '&, & svg, & [color="secondary"]': {
//             color: 'inherit',
//           },
//         },
//       },
//     }),
//     color,
//     space,
//     layout,
//   ),
// )

// const A: React.FC<AnchorProps> = ({
//   size = 'default',
//   variant = 'highEmphasis',
//   target = '_self',
//   displayInline = true,
//   isLinkActive = false,
//   disabled = false,
//   component = 'a',
//   href,
//   link,
//   prefix,
//   suffix,
//   children,
//   onClick,
//   ...other
// }) => {
//   // const navigate = useNavigate()
//   const clickHandler = useCallback(
//     (event: React.MouseEvent<HTMLAnchorElement>) => {
//       if (disabled) {
//         event.preventDefault()
//       } else if (link && typeof link.to === 'string') {
//         event.preventDefault()
//         event.stopPropagation()
//         // history.push(link.to)
//         // navigate('success', { state: link.to })
//       }
//     },
//     [disabled, link],
//     // [disabled, navigate, link],
//   )

//   const Content = (
//     <LinkContent {...{ variant, size, prefix, suffix }} {...other}>
//       {children}
//     </LinkContent>
//   )

//   return (
//     <StyledAnchor
//       display={displayInline ? 'inline-block' : 'block'}
//       isLinkActive={isLinkActive ? 1 : 0}
//       onClick={onClick || clickHandler}
//       {...{ disabled, href, target, variant }}
//     >
//       {Content}
//     </StyledAnchor>
//   )
// }

// export default A
// export type { LinkProps }
