import type { Project } from 'lib/project/types.d'
import type {
  JSONItem,
  JSONPrimitive,
  JSONDictKey,
  JSONArrayElement,
  JSONDictElement,
  JSONParserOptions,
} from './json-parser-types.d'
import { JSONPrimitiveType, JSONItemType } from './json-parser-types.d'
import {
  getJSONArray,
  getJSONArrayElement,
  getJSONDict,
  getJSONDictElement,
  getJSONDictKey,
  getJSONPrimitive,
  jsmnParse,
  JsmnTokenType,
} from './jsmn'
import parseLegacyArchiveString from './parse-legacy-archive-string'

interface JSONParserInternalContext {
  input: string
  objects: Project.ObjectMap
  root: Project.GenericRef<Project.Project>
}

const getPrimitive = <SType>(
  start: number,
  end: number,
  context: JSONParserInternalContext,
): JSONPrimitive<SType> => {
  const firstChar = context.input[start]
  let item: JSONPrimitive<SType>
  switch (firstChar) {
    case 't':
      item = getJSONPrimitive<SType>(true, JSONPrimitiveType.True, {
        start,
        end,
      })
      break
    case 'f':
      item = getJSONPrimitive<SType>(false, JSONPrimitiveType.False, {
        start,
        end,
      })
      break
    case 'n':
      item = getJSONPrimitive<SType>(null, JSONPrimitiveType.Null, {
        start,
        end,
      })
      break
    case '-':
    case '0':
    case '1':
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
      // eslint-disable-next-line no-case-declarations
      const sub = context.input.substring(start, end)
      item = getJSONPrimitive<SType>(
        sub.indexOf('.') >= 0 ? parseFloat(sub) : parseInt(sub, 10),
        JSONPrimitiveType.Number,
        { start, end },
      )

      break
    default:
      throw new Error('Invalid JSON Primitive')
  }
  return item
}

const parseDynamicString = <SType>(
  input: string,
  useDynamicValues: boolean,
  context: JSONParserInternalContext,
): SType =>
  useDynamicValues
    ? (parseLegacyArchiveString(
        context.objects,
        context.root,
        input,
        true,
      ) as unknown as SType)
    : (input as unknown as SType)

const unescapedString = (
  start: number,
  end: number,
  context: JSONParserInternalContext,
): string => {
  const sub = context.input.substring(start, end)
  const replacer = (substring: string): string => {
    let c: string
    switch (substring[1]) {
      case 'b':
        c = '\b'
        break
      case 'f':
        c = '\f'
        break
      case 'r':
        c = '\r'
        break
      case 'n':
        c = '\n'
        break
      case 't':
        c = '\t'
        break
      case '"':
      case '/':
      case '\\':
        // eslint-disable-next-line prefer-destructuring
        c = substring[1]
        break
      case 'u':
        c = String.fromCodePoint(parseInt(substring.substr(2, 4), 16))
        break
      default:
        throw new Error('Unhandled')
    }
    return c
  }
  return sub.replace(/\\(?:[\\"/bfrnt]|u[0-9a-f]{4})/gi, replacer)
}

const parseJsonBase = <SType>(
  context: JSONParserInternalContext,
  { useDynamicValues }: JSONParserOptions,
): JSONItem<SType> => {
  const tokens = jsmnParse(context.input)
  const stack: JSONItem<SType>[] = []
  let lastItem: JSONItem<SType> | null = null
  for (let i = 0; i < tokens.length; i += 1) {
    const token = tokens[i]
    const { start, end } = token
    let parent = stack[stack.length - 1]

    // Pop parents if we are outside them
    while (stack.length > 0 && parent.end < start) {
      stack.pop()
      parent = stack[stack.length - 1]
    }

    // Create JSONItem
    let item: JSONItem<SType>
    switch (token.type) {
      case JsmnTokenType.Primitive:
        item = getPrimitive(start, end, context)
        break
      case JsmnTokenType.String:
        if (
          parent &&
          token.posinparent % 2 === 0 &&
          parent.itemType === JSONItemType.Object
        ) {
          item = getJSONDictKey(
            parseDynamicString(
              unescapedString(start, end, context),
              useDynamicValues,
              context,
            ),
            { start, end },
          )
        } else {
          item = getJSONPrimitive(
            parseDynamicString(
              unescapedString(start, end, context),
              useDynamicValues,
              context,
            ),
            JSONPrimitiveType.String,
            { start, end },
          )
        }
        break
      case JsmnTokenType.Object:
        item = getJSONDict([], { start, end })
        stack.push(item)
        break
      case JsmnTokenType.Array:
        item = getJSONArray([], { start, end })
        stack.push(item)
        break
      default:
        throw new Error('Invalid JsmnTokenType')
    }

    // Add to parent
    if (parent && lastItem !== null) {
      // Array
      if (parent.itemType === JSONItemType.Array) {
        const el: JSONArrayElement<SType> = getJSONArrayElement(
          token.posinparent,
          item,
          token.enabled,
        )
        parent.items.push(el)
      }
      // Object + Only for values
      else if (
        token.posinparent % 2 === 1 &&
        parent.itemType === JSONItemType.Object
      ) {
        const el: JSONDictElement<SType> = getJSONDictElement(
          // eslint-disable-next-line no-bitwise
          token.posinparent >> 1,
          lastItem as JSONDictKey<SType>,
          item as JSONPrimitive<SType>,
          token.enabled,
        )
        parent.items.push(el)
      }
    }

    lastItem = item
  }

  if (stack.length === 0) {
    if (lastItem !== null) {
      return lastItem
    }
    throw new Error('Invalid JSON Input')
  }

  return stack[0]
}

/**
 * Parses a JSON string and returns a JSON tree of JSONItem objects.
 * Supports the JSON language extensions that Paw uses:
 * - comments for disabled fields
 * - multiple entires for the same key
 * - ordered keys
 *
 * @param input JSON String
 * @returns The root JSONItem of the tree
 */
const parseJson = <SType>(input: string): JSONItem<SType> => {
  const context: JSONParserInternalContext = {
    input,
    objects: {},
    root: { ref: '' },
  }
  return parseJsonBase<SType>(context, { useDynamicValues: false })
}

/**
 * Parses a JSON string and returns a JSON tree of JSONItem objects.
 * Supports the JSON language extensions that Paw uses:
 * - comments for disabled fields
 * - multiple entires for the same key
 * - ordered keys
 * String keys are considered to be dynamic strings.
 *
 * @param objects
 * @param root
 * @param input JSON String
 * @returns The root JSONItem of the tree
 */
export const parseJsonWithDynamicStrings = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  input: string,
): JSONItem<Project.GenericRef<Project.DynamicString>> =>
  parseJsonBase(
    {
      input,
      objects,
      root,
    },
    { useDynamicValues: true },
  )

export default parseJson
