import type { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'
import addObject from './add-object'
import { getEnvironment, getEnvironmentDomain } from '../getters'

export interface AddEnvironmentVariableArgs {
  environmentRef: Project.GenericRef<Project.Environment>
  args?: Partial<Project.EnvironmentVariable>
}
const addEnvironmentVariable = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { environmentRef, args }: AddEnvironmentVariableArgs,
): Project.GenericRef<Project.EnvironmentVariable> => {
  const environment = getEnvironment(environmentRef, objects, false)
  const domain = getEnvironmentDomain(environment.domain, objects, false)

  const environmentVariable: Project.EnvironmentVariable = {
    uuid: getUuid(),
    type: 'environmentVariable',
    name: '',
    domain: environment.domain,
    ...args,
  }
  const environmentVariableRef = addObject(objects, environmentVariable)
  domain.variables.push(environmentVariableRef)

  // for each environment, add a new value for this newly create variable
  domain.environments.forEach((envRef) => {
    // add dynamic string for the value (an empty new string)
    const dynamicStringValue: Project.DynamicString = {
      uuid: getUuid(),
      type: 'dynamicString',
      strings: [''],
    }
    const dynamicStringRef = addObject(objects, dynamicStringValue)

    // add a new value
    const environmentVariableValue: Project.EnvironmentVariableValue = {
      uuid: getUuid(),
      type: 'environmentVariableValue',
      value: dynamicStringRef,
      environment: envRef,
      variable: environmentVariableRef,
      domain: environment.domain,
    }
    addObject(objects, environmentVariableValue)
  })

  return environmentVariableRef
}

export default addEnvironmentVariable
