import { createSelector } from '@reduxjs/toolkit'
import type { Project } from 'lib'
import type { RootState } from '../../../root-reducer'
import type { ProjectState } from '../types'

export const selectProject = (state: RootState): ProjectState => state.project
export const selectProjectRootRef = createSelector(
  selectProject,
  (project) => project.root,
)
export const selectProjectObjects = createSelector(
  selectProject,
  (project) => project.objects,
)
export const selectProjectRoot = createSelector(
  selectProjectObjects,
  selectProjectRootRef,
  (objects, ref): Project.Project | null =>
    ref ? (objects[ref.ref] as Project.Project) : null,
)
