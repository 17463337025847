import React from 'react'
import { Box, Icon, Typography } from '@rapidapi/ui-lib'
import DownloadSectionContent from './download-content'
import type { ContentLoadedComponentType } from './types.d'

const DownloadContentLoaded: React.FC<ContentLoadedComponentType> = ({
  targets,
  params,
  errorMessage,
}) => {
  if (errorMessage) {
    return (
      <Box textAlign="center">
        <DownloadSectionContent targets={targets} />

        <Icon symbol="alert" color="#B63047" size={48} mt={4} />
        <Typography variant="caption" display="block" color="textDanger" mt={2}>
          Seems like we&apos;re having trouble loading download links, <br />
          please try again later.
        </Typography>
      </Box>
    )
  }

  return (
    <DownloadSectionContent
      targets={targets}
      includeElectronMac={params && params.get('electron-mac') !== null}
    />
  )
}

export default DownloadContentLoaded
