import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'store/root-reducer'
import type { AccountStore } from './types.d'

export const selectAccountState = (state: RootState): AccountStore.State =>
  state.account

export const selectUserId = createSelector(
  selectAccountState,
  (accountState) => accountState.userId,
)

export const selectUserInfo = createSelector(
  selectAccountState,
  (accountState) => accountState.userResponse,
)
