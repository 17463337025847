import React, { useMemo } from 'react'
import { EmptyState, Flex, styled } from '@rapidapi/ui-lib'

import { getHttpMessageBodyString } from 'lib/request-handling'

import type { HttpExchangeTabViewProps } from '../http-exchange-tabs-props.d'
import { useHttpExchangeMessage } from '../http-exchange-helper-hooks'
import TabBoxContainer from '../tab-box-container'

const StyledContainer = styled(Flex)({
  flexBasis: 0,
  flexGrow: 1,
  overflow: 'hidden',
  flexDirection: 'column',
  justifyContent: 'stretch',
})

const StyledIframe = styled('iframe')({
  flexGrow: 1,
  background: 'white',
})

const HttpExchangeWebTab: React.FC<HttpExchangeTabViewProps> = ({
  messageType,
}) => {
  const { httpMessage } = useHttpExchangeMessage(messageType)

  // get content
  const content = useMemo(() => {
    const bodyString = httpMessage
      ? getHttpMessageBodyString(httpMessage)
      : null
    if (!bodyString) {
      return null
    }
    return bodyString
  }, [httpMessage])

  if (!content) {
    return (
      <TabBoxContainer p={5}>
        <EmptyState
          symbol="danger"
          headline="Empty body"
          body={
            messageType === 'request'
              ? 'The request body is empty'
              : 'The response body is empty'
          }
        />
      </TabBoxContainer>
    )
  }

  return (
    <StyledContainer mt={3}>
      <StyledIframe
        width="100%"
        sandbox=""
        referrerPolicy="no-referrer"
        srcDoc={content}
      />
    </StyledContainer>
  )
}

export default HttpExchangeWebTab
