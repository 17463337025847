import WordArray from 'crypto-js/lib-typedarrays'

/**
 * We want to use `crypto-js` to avoid using directly browser APIs
 * that won't be compatible with Node (and other non-browser environments).
 * Since we already use `crypto-js` it makes sense to use it also for to generate
 * crypto-secure numbers.
 */

const MAX_VALUE = 2 ** 32 // 2 ^ 32 (or MAX_INT + 1)
const MAX_VALUE_BY_2 = 2 ** 31 // 2 ^ 32 (or MAX_INT + 1)

export const randomInt32 = (): number => {
  const buf = WordArray.random(4)
  const k = Math.abs(buf.words[0] + MAX_VALUE_BY_2)
  return k % MAX_VALUE
}

/**
 * WARNING: While this uses getRandomValues() this is NOT
 * crypto-secure as this is based only on the division of an Int32
 */
export const randomPseudoFloat = (): number => randomInt32() / MAX_VALUE
