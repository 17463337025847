import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import type {
  GraphQLArgument,
  GraphQLField,
  GraphQLFieldMap,
  GraphQLSchema,
} from 'graphql'

interface Range {
  startLineNumber: number
  endLineNumber: number
  startColumn: number
  endColumn: number
}

/**
 * @description removes all paires of {} and (). also removes them if they have properties in them.
 * @example  `query { love(diva){name} heaven(code){` => `query { love heaven {`
 * @param {string} str
 * @return {*}  {string}
 */
const cleanText = (str: string): string => {
  const aux = str.replace(/{[^{]*?}/gs, '').replace(/\([^(]*?\)/gs, '')
  return aux !== str ? cleanText(aux) : aux
}

const matchPathRegex = /(\w+)(( *?)\((.*?)\)( *?))?( *?){/gs
const matchArgumentRegex = /(\w+)( *?)\(/gs

/**
 * @description extracts the word from this string format `query {` or `query(){` to this `query`
 * @param {string[]} path
 * @return {*}  {string[]}
 */
const cleanPath = (path: string[]): string[] =>
  path
    .map((e) => e.trim().match(/^([\w-]+)/))
    .map((e) => (e ? e[0] : null))
    .filter((e) => e !== null) as string[]

const getTreePath = (
  textUntilPosition: string,
): { path: string[]; key?: string } => {
  const cleanedText = cleanText(textUntilPosition)
  const matchedPath = cleanedText.match(matchPathRegex)
  const matchedArgument = cleanedText.match(matchArgumentRegex)
  if (!matchedPath) {
    return { path: [] }
  }
  return {
    path: cleanPath(matchedPath),
    key: matchedArgument ? cleanPath(matchedArgument)[0] : undefined,
  }
}

const convertToMonacoSuggestions = (
  fields: ReadonlyArray<GraphQLArgument | GraphQLField<unknown, unknown>>,
  range: Range,
): monaco.languages.ProviderResult<monaco.languages.CompletionList> => ({
  suggestions: ((r: Range) =>
    fields.map(
      (e): monaco.languages.CompletionItem => ({
        label: e.name,
        kind: monaco.languages.CompletionItemKind.Constant,
        insertText: `${e.name}`,
        range: r,
      }),
    ))(range),
})

const getFieldsFromPath = (
  fields: GraphQLFieldMap<unknown, unknown>,
  path: string[],
): GraphQLFieldMap<unknown, unknown> => {
  let filteredFields = fields
  path.forEach((e) => {
    if (!e || !filteredFields[e]) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    filteredFields = filteredFields[e].type._fields
  })
  return filteredFields
}

const getFieldsSuggestions = (
  schema: GraphQLSchema,
  textUntilPosition: string,
  range: Range,
): monaco.languages.ProviderResult<monaco.languages.CompletionList> => {
  let fields: GraphQLFieldMap<unknown, unknown> = {}
  // For easy debugging, check out what the compiler thinks by logging the path and the key
  const { path, key } = getTreePath(textUntilPosition)
  if (!path) {
    return { suggestions: [] }
  }
  if (path[0] === 'mutation') {
    fields = schema.getMutationType()?.getFields() || {}
  } else {
    fields = schema.getQueryType()?.getFields() || {}
  }

  const filteredFields = getFieldsFromPath(fields, path)
  const gqlNodes = key
    ? filteredFields[key].args
    : Object.values(
        // eslint-disable-next-line no-underscore-dangle
        filteredFields,
      )
  return convertToMonacoSuggestions(gqlNodes, range)
}

const generateProvideCompletionItems =
  (schema: GraphQLSchema) =>
  (
    model: monaco.editor.ITextModel,
    position: monaco.Position,
  ): monaco.languages.ProviderResult<monaco.languages.CompletionList> => {
    const textUntilPosition = model.getValueInRange({
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    })

    const word = model.getWordUntilPosition(position)
    const range = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: word.startColumn,
      endColumn: word.endColumn,
    }

    return getFieldsSuggestions(schema, textUntilPosition, range)
  }
export default generateProvideCompletionItems
