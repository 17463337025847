export const comparePrefix = (content: Uint8Array, to: Uint8Array): boolean => {
  const res = to.reduce((acc, val, idx) => acc && content[idx] === val, true)
  return res as boolean
}

const magicBytesTIFF1 = 0x49
const magicBytesTIFF2 = 0x4d
const magicBytesJPEG = 0xff
const magicBytesPNG = 0x89
const magicBytesGIF = 0x47
const magicBytesBMP = 0x42

const magicBytesRIFF = new Uint8Array([0x52, 0x49, 0x46, 0x46])
const magicBytesWEBP = new Uint8Array([0x57, 0x45, 0x42, 0x50])

const contentTypeTiff = 'image/tiff'
const contentTypeJpeg = 'image/jpeg'
const contentTypePng = 'image/png'
const contentTypeGif = 'image/gif'
const contentTypeBmp = 'image/bmp'
const contentTypeWebp = 'image/webp'

export const getImageContentType = (content: Uint8Array): string | null => {
  switch (content[0]) {
    case magicBytesTIFF1:
    case magicBytesTIFF2:
      return contentTypeTiff
    case magicBytesJPEG:
      return contentTypeJpeg
    case magicBytesPNG:
      return contentTypePng
    case magicBytesGIF:
      return contentTypeGif
    case magicBytesBMP:
      return contentTypeBmp
    default:
      break
  }

  // webp
  if (
    comparePrefix(content.slice(0, 4), magicBytesRIFF) &&
    comparePrefix(content.slice(8, 12), magicBytesWEBP)
  ) {
    return contentTypeWebp
  }

  // not found
  return null
}

export const getImageTypeName = (contentType: string): string => {
  switch (contentType) {
    case contentTypeTiff:
      return 'TIFF Image'
    case contentTypeJpeg:
      return 'JPEG Image'
    case contentTypePng:
      return 'PNG Image'
    case contentTypeGif:
      return 'GIF Image'
    case contentTypeBmp:
      return 'BMP Image'
    case contentTypeWebp:
      return 'WEBP Image'
    default:
      return 'Unknown Type'
  }
}
