/* eslint-disable react/jsx-props-no-spreading */

import { isValidElement, useCallback } from 'react'
import css from '@styled-system/css'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant as variantStyled,
  VariantArgs,
} from 'styled-system'
import { ContentColors } from 'themes/common.types'
import { Box } from 'components/layout/box'
import { Flex } from 'components/layout/flex'
import { Typography } from 'components/data-display/typography'
import { Icon } from 'components/data-display/icon'

export type TagProps = LayoutProps &
  SpaceProps &
  VariantArgs & {
    variant?: ContentColors | 'default'
    size?: 'default' | 'large'
    truncate?: number
    disabled?: boolean
    dismiss?: (event: React.MouseEvent<HTMLButtonElement>) => void
  }

const StyledTag = withTheme(
  styled(Flex)<TagProps>(
    layout,
    space,
    ({ disabled, size, truncate }) =>
      css({
        // Padding combination for default/large size variant does not
        // currently exists in global theme.
        padding: size === 'large' ? '2px 8px' : '2px 6px',
        borderRadius: 'default',
        cursor: disabled ? 'not-allowed' : 'default',
        opacity: disabled ? 0.5 : 1,

        '& button': {
          height: size === 'large' ? 16 : 14,
          marginY: 0,
          marginLeft: 1,
          marginRight: 0,
          padding: 0,
          lineHeight: 0,
          bg: 'transparent',
          border: 'none',
          cursor: 'pointer',
        },
        '& button:focus, & button:active': {
          outline: '0 none',
        },
        '& button[disabled], & button:disabled': {
          cursor: 'not-allowed',
        },
        '&>.truncated>span': {
          maxWidth: truncate || 'auto',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
      }),
    ({
      theme: {
        addAlpha,
        colors: { backgrounds },
      },
    }) =>
      variantStyled({
        variants: {
          default: {
            color: 'texts.lowEmphasis',
            bg: 'backgrounds.backgroundSecondary',
            '& button': {
              color: 'texts.mediumEmphasis',
            },
          },
          primary: {
            color: 'texts.textInfo',
            bg: addAlpha(backgrounds.backgroundAccent, 0.3),
            '& button': {
              color: 'texts.textInfo',
            },
          },
        },
      }),
  ),
)

export const Tag: React.FC<TagProps> = ({
  variant = 'default',
  size = 'default',
  disabled = false,
  truncate,
  dismiss,
  children,
  ...other
}) => {
  const disabledClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        e.preventDefault()
      }
    },
    [disabled],
  )
  return (
    <StyledTag
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      {...{ variant, size, disabled, truncate }}
      {...other}
    >
      {isValidElement(children) ? (
        children
      ) : (
        <Box className={truncate ? 'truncated' : undefined} as="span">
          <Typography
            variant={size === 'large' ? 'body1' : 'body2'}
            lineHeight={size === 'large' ? 'body1' : 'body2'}
            color="inherit"
            {...other}
          >
            {children}
          </Typography>
        </Box>
      )}
      {dismiss && (
        <button
          disabled={disabled}
          onClick={disabled ? disabledClick : dismiss}
          type="button"
          {...other}
        >
          <Icon
            color="inherit"
            size={size === 'large' ? 16 : 14}
            symbol="close"
          />
        </button>
      )}
    </StyledTag>
  )
}

export default Tag
