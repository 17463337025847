/* eslint-disable react/jsx-props-no-spreading */

import { forwardRef, ReactNode } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import css from '@styled-system/css'
import {
  flexbox,
  layout,
  shadow,
  FlexboxProps,
  LayoutProps,
  ShadowProps,
  space,
  SpaceProps,
} from 'styled-system'
import { Box } from 'components/layout/box'

export type ElevateProps = FlexboxProps &
  LayoutProps &
  ShadowProps &
  SpaceProps & {
    variant?: 'small' | 'medium' | 'large'
    rounded?: boolean
    children?: ReactNode | ReactNode[] | string
  }

const ElevateStyled = withTheme(
  styled(Box)<ElevateProps>(
    flexbox,
    layout,
    shadow,
    space,
    ({ variant = 'small', rounded }) =>
      css({
        borderRadius: rounded ? 'default' : 0,
        boxShadow: variant,
        backgroundColor: 'backgrounds.backgroundPrimary',
      }),
  ),
)

export const Elevation = forwardRef<HTMLDivElement, ElevateProps>(
  ({ variant = 'small', rounded = true, children, ...other }, forwardedRef) => (
    <ElevateStyled
      ref={forwardedRef}
      className="elevation"
      p={3}
      m={2}
      {...{ variant, rounded }}
      {...other}
    >
      {children}
    </ElevateStyled>
  ),
)

export default Elevation
