/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react'
import type { SpaceProps } from 'styled-system'
import { useDispatch, useSelector } from 'react-redux'

import { Flex, Box, RadioButton, Typography } from '@rapidapi/ui-lib'
import type { Project } from 'lib/project'
import { selectAllEnvironmentVariables } from 'store/selectors'
import { setProjectValue } from 'store/actions'
import { useAnyObjectProperty } from 'utils/hooks'

type EnvironmentVariablePickerEditorProps<T extends Project.AnyObject> =
  SpaceProps & {
    objectRef: Project.GenericRef<T>
    objectProperty: keyof T
    label?: string
  }

type EnvironmentVariablePickerEditorReturnType = React.ReactElement<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> | null

const EnvironmentVariablePickerEditor = <T extends Project.AnyObject>({
  objectRef,
  objectProperty,
  label,
  ...props
}: EnvironmentVariablePickerEditorProps<T>): EnvironmentVariablePickerEditorReturnType => {
  const variables = useSelector(selectAllEnvironmentVariables)

  // get selected variable
  const variableUuid = useAnyObjectProperty(
    objectRef,
    objectProperty,
    'string' /* expect */,
    true /* allowsNull */,
  ) as string

  // dispatch update
  const dispatch = useDispatch()
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newVariableUuid = event.target.value
      if (newVariableUuid) {
        dispatch(
          setProjectValue({
            objectRef,
            update: {
              [objectProperty]: newVariableUuid,
            },
          }),
        )
      }
    },
    [dispatch, objectRef, objectProperty],
  )

  return (
    <Box>
      {label && (
        <Typography variant="body2" mb={1} color="highEmphasis">
          {label}
        </Typography>
      )}
      <Box maxHeight={220} overflow="auto">
        <Flex flexDirection="column" alignItems="stretch" {...props}>
          {variables.map(({ uuid, name }) => (
            <Box key={uuid} py={1}>
              <RadioButton
                type="radio"
                label={name}
                checked={uuid === variableUuid}
                value={uuid}
                onChange={onChange}
              />
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

export default EnvironmentVariablePickerEditor
