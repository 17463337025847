import addAlpha from 'utils/add-alpha'
import colors from './colors'
import global from './global'

const { neutral, blue, red, green } = colors

const elevation = {
  elevationSmall: `0px 1px 4px ${addAlpha(neutral.black, 0.1)}`,
  elevationMedium: `0px 5px 16px ${addAlpha(neutral.black, 0.09)}`,
  elevationLarge: `0px 10px 30px ${addAlpha(neutral.black, 0.16)}`,
}

const ligthTheme = {
  ...global,
  isDark: false,
  colorScheme: 'light',
  colors: {
    ...colors,
    texts: {
      highEmphasis: addAlpha(neutral.black, 0.87),
      mediumEmphasis: addAlpha(neutral.black, 0.6),
      lowEmphasis: addAlpha(neutral.black, 0.38),
      textLink: blue.blue400,
      textSelected: blue.blue500,
      textInfo: blue.blue400,
      textDanger: red.red400,
      textSuccess: green.green400,
      highEmphasisInverse: addAlpha(neutral.white, 0.87),
      highEmphasisConstant: addAlpha(neutral.white, 0.87),
    },
    backgrounds: {
      backgroundPrimary: neutral.white,
      backgroundSecondary: neutral.neutral50,
      backgroundElevated1: neutral.white,
      backgroundElevated2: neutral.white,
      backgroundPrimaryInverse: neutral.neutral800,
      backgroundAccent: blue.blue300,
      backgroundAccentHover: blue.blue400,
      backgroundAccentActive: blue.blue500,
      backgroundDefaultHover: addAlpha(neutral.black, 0.05),
      backgroundDefaultActive: addAlpha(neutral.black, 0.08),
      backgroundDanger: red.red300,
      backgroundDangerHover: red.red400,
      backgroundDangerActive: red.red500,
      backgroundSelected: addAlpha(blue.blue400, 0.14),
      backgroundSelectedSecondary: addAlpha(blue.blue400, 0.15),
      backgroundOverlay: addAlpha(neutral.black, 0.2),
    },
    borders: {
      borderDefault: neutral.neutral200,
      borderAcent: blue.blue300,
      borderDanger: red.red300,
      borderSuccess: green.green300,
    },
  },
  shadows: {
    small: elevation.elevationSmall,
    medium: elevation.elevationMedium,
    large: elevation.elevationLarge,
  },
  borderWidth: {
    default: '1px',
  },
}

export default ligthTheme
