/* eslint-disable react/jsx-props-no-spreading */

import { useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Button, ButtonComponentProps } from '../../inputs/button'
import { Box } from '../../layout/box'
import { Flex } from '../../layout/flex'
import useDialog from '../../feedback/dialog/use-dialog'
import { Icon } from '../../data-display/icon'
import { SnackbarComponentProps } from './snackbar.types'

export const SnackbarAction = (props: ButtonComponentProps) => (
  <Button
    css={({ colors, fontWeights }) => ({
      fontWeight: fontWeights.medium,
      color: colors.texts.highEmphasisConstant,
    })}
    ml={2}
    size="small"
    variant="text"
    {...props}
  />
)

export const Snackbar: React.FC<SnackbarComponentProps> = ({
  actions,
  children,
  closeButton = false,
  duration = 2500,
  icon = undefined,
  placement = 'center',
  show = false,
  variant = 'default',
  width,
  onClose,
}) => {
  const getPlacement = {
    center: 'center',
    end: 'flex-end',
    start: 'flex-start',
  }
  const elementRef = document.createElement('div') as HTMLDivElement

  const { portalRoot, isVisible, ref } = useDialog({
    disableBackdropClick: true,
    elementRef,
    open: show,
    onClose,
  })

  useEffect(() => {
    if (!isVisible || duration === 0) {
      return undefined
    }

    const timer = setTimeout(() => {
      if (!isVisible) {
        return
      }

      if (onClose) {
        onClose()
      }
    }, duration)
    return () => clearTimeout(timer)
  }, [duration, isVisible, onClose])

  const getBg = useCallback((variant, isDark, colors) => {
    if (variant === 'negative') {
      return colors.backgrounds.backgroundDanger
    }
    return colors.neutral[isDark ? 'white' : 'neutral700']
  }, [])

  if (!isVisible) {
    return null
  }

  return createPortal(
    <Flex
      alignItems="center"
      css={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9000,
        width: '100%',
      }}
      justifyContent={getPlacement[placement]}
      p={4}
    >
      <Flex
        alignItems="center"
        boxShadow="medium"
        css={({ colors, isDark, radii }) => ({
          backgroundColor: getBg(variant, isDark, colors),
          borderRadius: radii.default,
        })}
        justifyContent="space-between"
        color="texts.highEmphasisConstant"
        fontFamily="default"
        fontSize="body2"
        flexWrap="nowrap"
        lineHeight="body2"
        letterSpacing="body2"
        paddingY={2}
        ref={ref}
        paddingX={4}
        minHeight={52}
        width={width}
      >
        {icon && (
          <Icon
            symbol={icon}
            size={20}
            mr={4}
            color="texts.highEmphasisConstant"
          />
        )}
        {children}
        {(closeButton || actions) && (
          <Flex justifyContent="space-between" alignItems="center" ml={2}>
            {actions?.map((action, idx) => (
              <SnackbarAction key={idx} {...action} />
            ))}
            {closeButton && (
              <Box
                as="button"
                css={{
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                  border: 0,
                  boxSizing: 'border-box',
                  cursor: 'pointer',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  padding: 0,
                }}
                height={36}
                ml={2}
                mr={-2}
                width={36}
                onClick={onClose}
              >
                <Icon
                  color="texts.highEmphasisConstant"
                  size={18}
                  symbol="close"
                />
              </Box>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>,
    portalRoot,
  )
}

export default Snackbar
