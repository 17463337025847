/* eslint-disable react/jsx-props-no-spreading */

import React, { memo } from 'react'
import { withTheme } from '@emotion/react'
import {
  space as spaceStyled,
  typography,
  variant as variantStyled,
} from 'styled-system'
import styled from '@emotion/styled'
import css from '@styled-system/css'
import { Box } from 'components/layout/box'
import { classNames } from '../../../utils'
import {
  InputContainerInputSizes,
  InputContainerComponentProps,
} from './input-container.types'

const getBorder = ({
  representation,
  variant,
}: {
  representation?: 'default' | 'hover'
  variant?: InputContainerInputSizes
}) => {
  if (representation === 'default') {
    return { top: '-2px', bottom: '-2px', left: '-2px', right: '-2px' }
  }
  return variant === 'large'
    ? { top: '-10px', bottom: '-10px', left: '-14px', right: '-14px' }
    : { top: '-8px', bottom: '-8px', left: '-10px', right: '-10px' }
}

const InputContainerStyled = withTheme(
  styled(Box)<InputContainerComponentProps>(
    ({ embedded, maxWidth, representation, theme, variant }) =>
      css({
        alignItems: 'flex-start',
        backgroundClip: 'padding-box',
        border: 'none',
        cursor: representation === 'default' ? 'text' : 'default',
        maxWidth,
        outline: '0 none',
        padding: () => {
          if (embedded || representation === 'hover') {
            return undefined
          }
          return variant === 'large' ? '8px 12px' : '6px 8px'
        },
        position: 'relative',
        ...theme.typography[variant === 'large' ? 'body1' : 'body2'],
        '&::before': {
          borderColor: 'borders.borderDefault',
          borderRadius: 'default',
          borderStyle: 'solid',
          borderWidth: 1,
          boxSizing: 'content-box',
          content: '""',
          margin: 'auto',
          position: 'absolute',
          transition: 'opacity .2s ease, border-color .2s ease',
          zIndex: 1,
          ...getBorder({ representation, variant }),
        },
      }),
    ({ disabled, embedded, editable }) =>
      variantStyled({
        prop: 'representation',
        variants: {
          default: {
            borderColor: 'borders.borderDefault',
            backgroundColor: 'backgrounds.backgroundPrimary',
            '&.error': {
              '&::before': {
                borderColor: 'borders.borderDanger',
                borderWidth: 1,
                bottom: '-1px',
                left: '-1px',
                opacity: 1,
                right: '-1px',
                top: '-1px',
              },
            },
            '&:focus-within, &.focused': {
              '&::before': {
                borderColor: disabled ? 'inherit' : 'borders.borderAcent',
              },
            },
          },
          hover: {
            borderColor: 'transparent',
            position: 'relative',
            '&::before': {
              borderColor: 'transparent',
              backgroundColor: 'transparent',
            },
            '&:focus-within, &.focused': editable
              ? {
                  '&::before': {
                    borderColor: embedded ? undefined : 'borders.borderAcent',
                    opacity: embedded || disabled ? 0 : 1,
                    title: embedded ? 'embedded' : 'no',
                  },
                  '&.error': {
                    '&::before': {
                      borderColor: 'borders.borderDanger',
                      borderWidth: 1,
                    },
                  },
                }
              : undefined,
          },
        },
      }),
    spaceStyled,
    typography,
  ),
)

export const InputContainer: React.FC<InputContainerComponentProps> = ({
  children,
  className = '',
  disabled = false,
  endAdornment,
  error,
  focused,
  maxWidth,
  representation = 'default',
  startAdornment,
  variant = 'default',
  width,
  ...other
}) => (
  <InputContainerStyled
    alignItems="center"
    className={`${classNames({ focused, error }) ?? ''} ${className}`}
    display="flex"
    fontFamily="default"
    maxWidth={maxWidth || 'inherit'}
    tabIndex={disabled ? -1 : 0}
    {...{ disabled, representation, variant, width, ...other }}
  >
    {startAdornment}
    {children}
    {endAdornment}
  </InputContainerStyled>
)

export default memo(InputContainer)
