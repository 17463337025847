import { createContext } from 'react'

export interface DialogContextProps {
  portalRoot: HTMLElement | null
}

const DialogContext = createContext<DialogContextProps>({
  portalRoot: null,
})

export default DialogContext
