import React, {
  ReactElement,
  useState,
  isValidElement,
  cloneElement,
  MouseEvent,
} from 'react'
import { variant as variantStyled, VariantArgs } from 'styled-system'
import { withTheme } from '@emotion/react'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { Box } from 'components/layout/box'
import { Popover, PopoverPlacements } from 'components/data-display/popover'

export const tooltipVariants = ['default', 'extended']

type TooltipVariants = 'default' | 'extended'

export type TooltipProps = VariantArgs & {
  variant?: TooltipVariants
  placement?: PopoverPlacements
  content?: ReactElement | string
  enterDelay?: number
  leaveDelay?: number
  elevation?: number
  onClose?: () => void
}

const TooltipStyled = withTheme(
  styled(Box)<TooltipProps & { arrowSize: number }>(
    ({
      theme: {
        colors: { neutral },
        addAlpha,
      },
      arrowSize,
      variant,
    }) => {
      const arrowMargin = `${variant === 'extended' ? 7 : 5}px`

      const backgroundColor = neutral.neutral700

      return css({
        fontFamily: 'default',
        backgroundColor,
        margin: 0,
        borderRadius: 'default',
        color: 'texts.highEmphasisConstant',
        position: 'relative',
        zIndex: '9999',
        '&:after': {
          content: '""',
          position: 'absolute',
          borderWidth: arrowSize,
          borderStyle: 'solid',
        },
        '&[class^="top"]': {
          '&:after': {
            top: '100%',
            borderColor: `${backgroundColor} transparent transparent transparent`,
          },
          '&.top:after': {
            left: '50%',
            marginLeft: `-${arrowSize}px`,
          },
          '&.top-start:after': { left: arrowMargin },
          '&.top-end:after': { right: arrowMargin },
        },
        '&[class^="right"]': {
          '&:after': {
            right: '100%',
            borderColor: `transparent ${backgroundColor} transparent transparent`,
          },
          '&.right:after': {
            top: '50%',
            marginTop: `-${arrowSize}px`,
          },
          '&.right-start:after': { top: arrowMargin },
          '&.right-end:after': { bottom: arrowMargin },
        },
        '&[class^="bottom"]': {
          '&:after': {
            bottom: '100%',
            borderColor: `transparent transparent ${backgroundColor} transparent`,
          },
          '&.bottom:after': {
            left: '50%',
            marginLeft: `-${arrowSize}px`,
          },
          '&.bottom-start:after': { left: arrowMargin },
          '&.bottom-end:after': { right: arrowMargin },
        },
        '&[class^="left"]': {
          '&:after': {
            left: '100%',
            borderColor: `transparent transparent transparent ${backgroundColor}`,
          },
          '&.left:after': {
            top: '50%',
            marginTop: `-${arrowSize}px`,
          },
          '&.left-start:after': { top: arrowMargin },
          '&.left-end:after': { bottom: arrowMargin },
        },
      })
    },
    ({ theme: { space } }) =>
      variantStyled({
        prop: 'variant',
        variants: {
          default: {
            fontSize: 'caption',
            lineHeight: 'caption',
            letterSpacing: 'caption',
            padding: `${space[1]}px ${space[3]}px`,
          },
          extended: {
            fontSize: 'body2',
            lineHeight: 'body2',
            letterSpacing: 'body2',
            padding: 4,
          },
        },
      }),
  ),
)

export const Tooltip: React.FC<TooltipProps> = ({
  variant = 'default',
  placement = 'bottom',
  enterDelay = 1000,
  leaveDelay = 150,
  elevation = 0,
  content,
  children,
  onClose,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [leaveTimer, setLeaveTimer] = useState<number | undefined>()
  const [enterTimer, setEnterTimer] = useState<number | undefined>()
  const open = Boolean(anchorEl)

  if (!isValidElement(children)) {
    return null
  }

  const clearOnLeave = () => window.clearTimeout(leaveTimer)

  const onEnter = (event: MouseEvent) => {
    clearOnLeave()
    if (anchorEl === null) {
      window.clearTimeout(enterTimer)
      const target = event.currentTarget as Element
      setEnterTimer(
        window.setTimeout(() => {
          setAnchorEl(target)
        }, enterDelay),
      )
    }
  }

  const onLeave = () => {
    window.clearTimeout(enterTimer)
    if (anchorEl) {
      setLeaveTimer(
        window.setTimeout(() => {
          setAnchorEl(null)
        }, leaveDelay),
      )
    }
  }

  const childrenProps = {
    ...children.props,
    onMouseOver: onEnter,
    onMouseLeave: onLeave,
  }

  const arrowSize = variant === 'extended' ? 8 : 6

  return (
    <React.Fragment>
      <Popover
        onMouseOver={clearOnLeave}
        onFocus={clearOnLeave}
        onMouseOut={onLeave}
        onBlur={onLeave}
        elevation={arrowSize + elevation}
        {...{ anchorEl, open, onClose, placement }}
      >
        <TooltipStyled
          arrowSize={arrowSize}
          variant={variant}
          className={placement}
        >
          {content}
        </TooltipStyled>
      </Popover>
      {cloneElement(children, childrenProps)}
    </React.Fragment>
  )
}

export default Tooltip
