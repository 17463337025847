/* eslint-disable react/jsx-props-no-spreading */

import { forwardRef, PropsWithChildren } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import css from '@styled-system/css'
import {
  TabBarItemButtonRefProps,
  TabBarItemButtonComponentProps,
} from './tab-bar-item-button.types'

const TabBarItemButtonStyled = withTheme(
  styled('button')(({ theme: { typography } }) =>
    css({
      ...typography.body2,
      color: 'texts.mediumEmphasis',
      margin: 0,
      outline: '0 none',
      border: 'none',
      background: 'none',
      boxSizing: 'border-box',
      cursor: 'pointer',
      padding: 0,
    }),
  ),
)

export const TabBarItemButton = forwardRef<
  TabBarItemButtonRefProps,
  PropsWithChildren<TabBarItemButtonComponentProps>
>((props, forwardedRef) => (
  <TabBarItemButtonStyled ref={forwardedRef} {...props} />
))

export default TabBarItemButton
