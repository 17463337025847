import { createContext } from 'react'

export type PromptShowRender = () => JSX.Element

export interface PromptShowProps {
  title?: string | PromptShowRender
  content?: string | PromptShowRender
  proccedText?: string | null
  cancelText?: string | null
  onCancel?: () => void
  onProceed?: () => void
}

export interface AlertShowProps {
  title?: string | PromptShowRender
  content?: string | PromptShowRender
  proccedText?: string
  onProceed?: () => void
}

export type PromptShowFn = (props: PromptShowProps) => void

export type AlertShowFn = (props: AlertShowProps) => void

export interface PromptContextProps {
  prompt?: PromptShowFn
  alert?: AlertShowFn
}

const PromptContext = createContext<PromptContextProps>({})

export default PromptContext
