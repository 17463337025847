/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import css from '@styled-system/css'
import { Link } from 'react-router-dom'
import { A, Box, Icon, styled } from '@rapidapi/ui-lib'
import { AccountMenuButton, ThemeButton } from 'pages/settings-page'
import { ProjectUndoButton } from 'pages/project-page/project-undo-button'
import type { NavItemProps } from './nav-item'
import { NavItem } from './nav-item'

export type AppHeaderProps = {
  pageNav?: NavItemProps[]
  systemNav?: NavItemProps[]
}

const HeaderStyled = styled(Box)(
  ({
    theme: {
      addAlpha,
      colors: { neutral },
    },
  }) =>
    css({
      width: 56,
      maxHeight: '100vh',
      gridArea: 'header',
      display: 'grid',
      gridTemplateRows: 'min-content 1fr min-content',
      color: addAlpha(neutral.white, 0.6),
      // @TODO: this is temporary
      bg: 'neutral.neutral900',
      padding: 2,
      '& hr': {
        mt: 2,
        mb: 3,
        border: '0 none',
        borderTop: `1px solid ${addAlpha(neutral.white, 0.1)}`,
      },
    }),
)

const AppHeader: React.FC<AppHeaderProps> = ({ pageNav, systemNav }) => {
  const pageNavItems: NavItemProps[] = pageNav || []
  const sysNavItems: NavItemProps[] = systemNav || []

  return (
    <HeaderStyled as="header">
      <Box textAlign="center">
        <A css={{ display: 'block' }} as={Link} to="/">
          <Icon symbol="luckymarmot" size={40} />
        </A>
        <hr />
      </Box>
      <Box textAlign="center">
        {pageNavItems.map((navItem: NavItemProps, navItemIndex: number) => (
          <NavItem key={navItemIndex.toString()} {...navItem} />
        ))}
      </Box>
      <Box textAlign="center">
        <ProjectUndoButton hidden />
        <ThemeButton />
        <AccountMenuButton />
        {sysNavItems.length > 0 &&
          sysNavItems.map((navItem: NavItemProps, navItemIndex: number) => (
            <Box key={navItemIndex.toString()} py={2}>
              <A css={{ display: 'block' }} {...navItem}>
                <Icon
                  symbol={navItem.icon}
                  size={24}
                  color="texts.highEmphasisConstant"
                />
              </A>
            </Box>
          ))}
      </Box>
    </HeaderStyled>
  )
}

export default AppHeader
