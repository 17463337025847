import { createSelector } from '@reduxjs/toolkit'
import * as Evals from 'lib/evaluation/evaluate'
import type { Evaluation } from 'lib/evaluation'
import type { Project } from 'lib/project/types.d'
import type { RootState } from 'store/root-reducer'
import { selectCurrentEnvironments } from 'store/slices/environments/selectors'
import { getImplementation } from '../../../../lib'
import { selectProjectObjects, selectProjectRootRef } from './internal'

type CtxEvaluationAbstract<T> = (
  requestRef: Project.GenericRef<Project.Request>,
  ctx: Evaluation.Ctx,
) => T

const selectCurrentTreeItemRef = (
  state: RootState,
): Project.GenericRef<Project.AnyRequestTreeItem> | null =>
  state.uiState.currentItemState.currentTreeItemRef

/**
 * A "selector factory" that creates a selector with a given evaluation
 * function. This way, the entire dynamic value evaluation process can
 * happen inside a single selector.
 * Note: for performance reasons, selector factories should be memoized
 * in the component that is calling it.
 * @param fn The evaluation function
 * @returns A selector for that operation
 */
const selectCtxEvaluationResultFactory = <T>(
  fn: CtxEvaluationAbstract<T>,
): ((state: RootState) => T | null) =>
  createSelector(
    selectProjectObjects,
    selectProjectRootRef,
    selectCurrentTreeItemRef,
    selectCurrentEnvironments,
    (objects, root, treeItemRef, currentEnvironments): T | null => {
      if (!treeItemRef) {
        return null
      }
      const ctx: Evaluation.Ctx = {
        evals: Evals,
        getImplementation,

        project: {
          objects,
          root,
        },
        currentEnvironments,
        layers: [
          {
            layerType: 'RequestLayer',
            requestRef: treeItemRef,
          },
        ],
      }
      return fn(treeItemRef, ctx)
    },
  )

export default selectCtxEvaluationResultFactory
