import type { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'

import { getObject } from '../getters'
import addDynamicString from './add-dynamic-string'
import addObject from './add-object'

export interface AddParameterArgs {
  objectRef: Project.GenericRef<Project.AnyObject>
  objectProperty: string | number | symbol
  uuid?: string
  args?: Partial<Project.Parameter>
}

const addParameter = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { objectRef, objectProperty, uuid, args }: AddParameterArgs,
): Project.GenericRef<Project.Parameter> => {
  const parameter: Project.Parameter = {
    uuid: uuid || getUuid(),
    type: 'parameter',
    enabled: true,
    key: args?.key || addDynamicString(objects, root),
    value: args?.value || addDynamicString(objects, root),
    ...args,
  }
  const ref = addObject(objects, parameter)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parent = getObject(objectRef, objects, false) as any
  if (parent[objectProperty]) {
    parent[objectProperty].push(ref)
  } else {
    parent[objectProperty] = [ref]
  }
  return ref
}

export default addParameter
