type MutexUnlockFn = () => void

export default class Mutex {
  private currentPromise = Promise.resolve()

  private internalIsLocked = false

  lock = (): Promise<MutexUnlockFn> => {
    let unlockMutexFn: () => void = () => {
      throw new Error('Internal error')
    }

    // Caller gets a promise that resolves when the current outstanding
    // lock resolves
    const lockPromise = this.currentPromise.then((): MutexUnlockFn => {
      this.internalIsLocked = true
      return unlockMutexFn
    })

    // Don't allow the next request until the new promise is done
    this.currentPromise = new Promise<void>((resolve) => {
      unlockMutexFn = () => {
        this.internalIsLocked = false
        resolve()
      }
    })

    return lockPromise
  }

  dispatch = async <T>(fn: () => Promise<T>): Promise<T> => {
    const unlock = await this.lock()
    try {
      return await Promise.resolve(fn())
    } finally {
      unlock()
    }
  }

  get isLocked(): boolean {
    return this.internalIsLocked
  }
}
