import type { DynamicValues } from 'lib/dynamic-values/types.d'
import type { Evaluation } from 'lib/evaluation'
import { evaluateMethodString } from 'lib/evaluation/request'
import { getRequestLayer } from 'lib/evaluation/layers'
import getRequest from '../../../project/getters/atoms/get-request'
import type { RequestMethodDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.RequestMethodDynamicValue'
const title = 'Request Method'

const defaultValue: RequestMethodDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  request: null,
}

const editForm: DynamicValues.EditForm<RequestMethodDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'request',
      fieldType: 'request',
      label: 'Request',
    },
  ],
}

function getRequestRef(requestUUID: string | null, ctx: Evaluation.Ctx) {
  return requestUUID ? { ref: requestUUID } : getRequestLayer(ctx)?.requestRef
}

const implRequestMethodDynamicValue: DynamicValues.Implementation<RequestMethodDynamicValueInterface> =
  {
    title,
    identifier,
    defaultValue,
    editForm,
    getAllRefs() {
      return null
    },
    getEvaluatedString: async ({ request: requestUUID }, ctx) => {
      const requestRef = getRequestRef(requestUUID, ctx)
      if (!requestRef) {
        return ''
      }

      const requestObject = getRequest(requestRef, ctx.project.objects, true)
      if (!requestObject) {
        return ''
      }

      return evaluateMethodString(requestObject, ctx)
    },
    getTokenInfo: async ({ request: requestUUID }, ctx) => {
      const requestRef = getRequestRef(requestUUID, ctx)
      if (requestRef) {
        const requestObject = getRequest(requestRef, ctx.project.objects, true)
        if (requestObject) {
          return {
            title,
            text: requestObject.title || null,
          }
        }
      }

      return {
        title,
        text: '',
      }
    },
  }

export default implRequestMethodDynamicValue
