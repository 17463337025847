import type { Project } from 'lib/project/types.d'
import getObject from './get-object'

const getEnvironmentDomain = <Optional extends true | false>(
  ref: Project.GenericRef<Project.EnvironmentDomain>,
  objects: Project.ObjectMap,
  isOptional: Optional,
): Optional extends true
  ? Project.EnvironmentDomain | null
  : Project.EnvironmentDomain =>
  getObject(ref, objects, isOptional, 'environmentDomain')

export default getEnvironmentDomain
