import config from 'lib/cloud-sync/cloud-api/config'

import webFetch from './web-fetch'
import proxyFetch from './proxy-fetch'
import type { FetchFn } from './fetch-types.d'

const getFetchFn = (): FetchFn => {
  const { API_USE_CF_PROXY } = config
  if (API_USE_CF_PROXY) {
    return proxyFetch
  }
  return webFetch
}

export default getFetchFn
