import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const dateFromNow = (date: Date): string => dayjs(date).fromNow()
export const dateToString = (date: Date): string => dayjs(date).toString()
export const dateSimple = (date: Date): string =>
  dayjs(date).format('DD, MMM YYYY').toString()

export const autoDateFormat = (date: Date): string =>
  dayjs(date).add(24, 'hour').hour() < 24
    ? dateFromNow(date)
    : dateToString(date)

export const timestampFromNow = (ts: number): string => dayjs.unix(ts).fromNow()
