import { createAction } from '@reduxjs/toolkit'
import httpExchangesSlice from './slice'
import type {
  RequestUuidPayload,
  HttpExchangeUuidPayload,
  DeleteHttpExchangePayload,
} from './types.d'

export const httpExchangesActions = httpExchangesSlice.actions

export const fetchHttpExchangeListAction = createAction<RequestUuidPayload>(
  'httpExchanges/fetchHttpExchangeList',
)

export const fetchHttpExchangeItemAction =
  createAction<HttpExchangeUuidPayload>('httpExchanges/fetchHttpExchangeItem')

export const deleteHttpExchangeListAction = createAction<RequestUuidPayload>(
  'httpExchanges/deleteHttpExchangeList',
)

export const deleteHttpExchangeItemAction =
  createAction<DeleteHttpExchangePayload>(
    'httpExchanges/deleteHttpExchangeItem',
  )
