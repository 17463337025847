import React from 'react'
import { A, Flex, Box, Icon, IconButton, styled } from '@rapidapi/ui-lib'
import type { JSONDynamicValueInterface, Project } from 'lib'
import type {
  JSONArrayElement,
  JSONDictElement,
  JSONItem,
} from 'lib/dynamic-values/implementations/json-dynamic-value/parser'
import { JSONItemType } from 'lib/dynamic-values/implementations/json-dynamic-value/parser'
import {
  addElementFunction,
  changeTypeFunction,
  getIconForItem,
  getItemStringValue,
  removeKeyFunction,
  replaceJSONValue,
} from 'lib/dynamic-values/implementations/json-dynamic-value/parser/json-functions'
import { escapeString } from 'lib/dynamic-values/implementations/json-dynamic-value/parser/serialize-json'
import type { Dispatch } from 'redux'
import { setProjectValue } from 'store/actions'
import { DynamicStringInlineEditor } from '../dynamic-string-editor'
import type { DataJSONElement } from './json-data-table-types.d'

const TextContainerBox = styled(Box)({
  wordBreak: 'break-all',
  overflow: 'hidden',
})

const getOnBlur =
  <T extends Project.AnyObject>(
    element: DataJSONElement,
    dv: Project.DynamicValue<JSONDynamicValueInterface>,
    dispatch: Dispatch,
    objectRef: Project.GenericRef<T>,
    objectProperty: keyof T,
    keyOrValue: 'key' | 'value',
    jsonStringProperty = 'json',
  ) =>
  (newVal?: string) => {
    if (!dv[jsonStringProperty] || !newVal) {
      return
    }
    const newElement: DataJSONElement = JSON.parse(JSON.stringify(element))
    const newItem =
      (newElement as JSONDictElement<string>)[keyOrValue] ||
      (newElement as JSONArrayElement<string>).item
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newItem.value = escapeString(newVal)

    const newJSON = replaceJSONValue(
      dv[jsonStringProperty] as string,
      newItem.start,
      newItem.end,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newItem.value || '',
    )

    dispatch(
      setProjectValue({
        objectRef,
        update: {
          [objectProperty]: newJSON,
        },
      }),
    )
  }

function isValidHttpUrl(str: string): boolean {
  let url: URL
  try {
    url = new URL(str)
  } catch (err) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

const getStringValueEditable = (
  element: DataJSONElement,
  keyOrValue: 'key' | 'value',
) => {
  let stringValue = ''
  let isEditable = false
  if (keyOrValue === 'key') {
    if ((element as JSONDictElement<string>).key) {
      // Dict/Object
      stringValue = (element as JSONDictElement<string>).key.value
      isEditable = true
    } else {
      // Array
      stringValue = `Index ${(element as JSONArrayElement<string>).index}`
    }
  } else if ((element as JSONDictElement<string>).value) {
    // Dict/Object
    stringValue = getItemStringValue((element as JSONDictElement<string>).value)
    if (
      (element as JSONDictElement<string>)[keyOrValue].itemType ===
      JSONItemType.Primitive
    ) {
      isEditable = true
    }
  } else {
    // Array
    stringValue = getItemStringValue((element as JSONArrayElement<string>).item)
    if (
      (element as JSONArrayElement<string>).item.itemType ===
      JSONItemType.Primitive
    ) {
      isEditable = true
    }
  }

  return { stringValue, isEditable }
}

const DataTableFieldRenderer =
  <T extends Project.AnyObject>({
    keyOrValue,
    dv,
    objectRef,
    objectProperty,
    dispatch,
    createNewRequest = () => ({}),
    jsonStringProperty = 'json',
  }: {
    keyOrValue: 'key' | 'value'
    dv?: Project.DynamicValue<JSONDynamicValueInterface>
    objectRef?: Project.GenericRef<T>
    objectProperty?: keyof T
    dispatch?: Dispatch
    createNewRequest?: (url: string) => void
    jsonStringProperty?: string
  }) =>
  (element?: DataJSONElement): JSX.Element | undefined => {
    if (!element) {
      return undefined
    }
    const { isEditable, stringValue } = getStringValueEditable(
      element,
      keyOrValue,
    )
    let item: JSONItem<string>
    if ((element as JSONDictElement<string>).value) {
      // Dict/Object
      item = (element as JSONDictElement<string>).value
    } else {
      // Array
      item = (element as JSONArrayElement<string>).item
    }

    const symbol = getIconForItem(item)
    if (!dv || !objectRef || !objectProperty || !dispatch) {
      return (
        <Flex flexGrow={1} flexDirection="row" alignItems="center">
          {!isValidHttpUrl(stringValue) ? (
            <TextContainerBox flexGrow={1}>{stringValue}</TextContainerBox>
          ) : (
            <A onClick={() => createNewRequest(stringValue)}>{stringValue}</A>
          )}
          {keyOrValue === 'key' && (
            <Icon symbol={symbol} color="content.secondary" ml={2} />
          )}
        </Flex>
      )
    }

    const onBlur = getOnBlur(
      element,
      dv,
      dispatch,
      objectRef,
      objectProperty,
      keyOrValue,
      jsonStringProperty,
    )

    const onAdd = addElementFunction(
      item,
      dispatch,
      objectRef,
      objectProperty,
      dv[jsonStringProperty] as string,
    )

    const onChangeType = changeTypeFunction(
      item,
      dispatch,
      objectRef,
      objectProperty,
      dv[jsonStringProperty] as string,
    )

    const onRemove = removeKeyFunction(
      item,
      dispatch,
      objectRef,
      objectProperty,
      dv[jsonStringProperty] as string,
    )

    return (
      <Flex
        flexGrow={1}
        flexDirection="row"
        alignItems="center"
        css={
          keyOrValue === 'value'
            ? {
                ':hover': {
                  button: {
                    visibility: 'visible',
                  },
                },
                button: {
                  visibility: 'hidden',
                },
              }
            : {}
        }
      >
        {isEditable ? (
          <DynamicStringInlineEditor
            inputValue={stringValue}
            setInputValue={onBlur}
            width="100%"
          />
        ) : (
          <Box flexGrow={1}>{stringValue}</Box>
        )}

        {keyOrValue === 'key' && (
          <IconButton
            icon={symbol}
            onClick={onChangeType}
            size="small"
            noBorder
          />
        )}
        {keyOrValue === 'value' && onAdd ? (
          <IconButton icon="add" noBorder size="small" onClick={onAdd} />
        ) : null}
        {keyOrValue === 'value' && (
          <IconButton icon="close" onClick={onRemove} size="small" noBorder />
        )}
      </Flex>
    )
  }

export default DataTableFieldRenderer
