import type { PayloadAction } from '@reduxjs/toolkit'

import { httpExchangesActions } from 'store/actions'
import { httpExchangeFetchItemWorkerAction } from 'worker/primary-worker/actions'
import type { HttpExchangeUuidPayload } from 'store/types.d'

import type { ActionHandler } from '../types.d'

const httpExchangeFetchItemActionHandler = async ({
  action: {
    payload: { httpExchangeUuid },
  },
  next,
  worker,
}: ActionHandler<PayloadAction<HttpExchangeUuidPayload>>): Promise<void> => {
  if (!worker) {
    throw new Error('Worker is missing')
  }

  const httpExchange = await worker.runAction(
    httpExchangeFetchItemWorkerAction,
    {
      httpExchangeUuid,
    },
  )

  if (httpExchange) {
    // note: we probably want to keep the previous http exchanges and not erase them
    next(
      httpExchangesActions.setHttpExchangesForRequest({
        requestUuid: httpExchange.requestUuid,
        httpExchanges: [httpExchange],
      }),
    )
  }
}

export default httpExchangeFetchItemActionHandler
