import Dexie from 'dexie'

import type { PrimaryWorkerState } from '../../types.d'

import {
  projectLocalStorageDatabase,
  projectLocalStorageObjectsTable,
  projectLocalStorageCloudPendingChangesTable,
  projectLocalStorageSyncMetaTable,
} from './local-storage-utils'

/**
 * Initializes the Dexie instance for project local storage/persistance
 * @param workerState The worker state object
 */
const localProjectStorageInit = (
  workerState: PrimaryWorkerState.WorkerState,
): void => {
  const { projectState } = workerState

  const db = new Dexie(projectLocalStorageDatabase)

  // version
  db.version(1).stores({
    [projectLocalStorageObjectsTable]: 'ref, projectId',
    [projectLocalStorageCloudPendingChangesTable]: 'projectId',
    [projectLocalStorageSyncMetaTable]: 'projectId',
  })

  // tables
  const objectsTable = db.table(projectLocalStorageObjectsTable)
  const cloudPendingChangesTable = db.table(
    projectLocalStorageCloudPendingChangesTable,
  )
  const syncMetaTable = db.table(projectLocalStorageSyncMetaTable)

  // set local storage info
  projectState.localStorage = {
    db,
    objectsTable,
    cloudPendingChangesTable,
    syncMetaTable,
  }
}

export default localProjectStorageInit
