import type { Project } from 'lib/project/types.d'

const getObject = <T extends Project.AnyObject, Optional extends true | false>(
  { ref }: Project.GenericRef<T>,
  objects: Project.ObjectMap,
  isOptional: Optional,
  expectedType?: Project.ObjectTypes,
): Optional extends true ? T | null : T => {
  const obj = objects[ref]
  if (!obj) {
    if (isOptional) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return null as any
    }
    throw new Error(
      `Missing object for UUID ${ref}${
        expectedType ? ` (${expectedType})` : ''
      }`,
    )
  }
  if (expectedType && obj.type !== expectedType) {
    throw new Error(`Expected type ${expectedType}, got ${obj.type}`)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return obj as any
}

export default getObject
