import React from 'react'
import { useCurrentRequestRef } from 'utils/hooks'
import { StringEditor, MarkdownEditor } from 'ecosystems'
import type { RequestTabViewProps } from 'ecosystems/project/request-editor/request-tabs/request-tabs-props.d'
import { TabBoxContainer } from 'ecosystems/http-exchange-viewer/http-exchange-tabs'
import { Box, Typography } from '@rapidapi/ui-lib'

const RequestDescriptionTab: React.FC<RequestTabViewProps> = () => {
  const requestRef = useCurrentRequestRef()

  if (!requestRef) {
    return null
  }

  // @TODO We need a Markdown renderer for descriptions
  return (
    <TabBoxContainer p={5} scrollable>
      <StringEditor
        label="Name"
        placeholder="My Request"
        width="100%"
        objectRef={requestRef}
        objectProperty="title"
        mb={4}
      />
      <Box height="100%">
        <Typography variant="body2">Description</Typography>
        <MarkdownEditor objectRef={requestRef} objectProperty="summary" />
      </Box>
    </TabBoxContainer>
  )
}

export default RequestDescriptionTab
