import type { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import type { Project } from 'lib/project/types.d'
import {
  assertType,
  serializeFutureRef,
} from 'lib/cloud-sync/serializer/helpers'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

const serializeEnvironmentVariable = (
  serializer: CloudSerializer.ProjectSerializer,
  object: Project.EnvironmentVariable,
): CloudAPI.SyncTreeItem => {
  // check type
  assertType(object, 'environmentVariable')

  const syncTreeItem: CloudAPI.SyncTreeItem = {
    _uuid: object.uuid,
    _type: 'environmentVariable',
    name: object.name,
    domain: serializeFutureRef(
      serializer,
      object.domain as Project.GenericRef<Project.EnvironmentDomain>,
    ),
  }
  return syncTreeItem
}

export default serializeEnvironmentVariable
