type ActivePrChangeEvent = CustomEvent<{ id: string | undefined }>

const PREVIEW_ID_QUERY_KEY = '_devPreview'
const COOKIE_PREVIEW_KEY = 'devPreview'
const PREVIEW_URL_PREFIX = '/_devPreviews'
const EVENT_KEY = 'dev-preview'

export const getActivePreviewId = (): string | undefined => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${COOKIE_PREVIEW_KEY}=`)
  return parts.length === 2 ? parts.pop()?.split(';').shift() : undefined
}

const switchPreviewEffect = (id: string): void => {
  document.cookie = `${COOKIE_PREVIEW_KEY}=${id}; path=/`
  const event: ActivePrChangeEvent = new CustomEvent(EVENT_KEY, {
    detail: { id },
  })
  document.dispatchEvent(event)
}

const deletePreviewFromSearch = (search: URLSearchParams) => {
  search.forEach((_value, key) => {
    if (key === PREVIEW_ID_QUERY_KEY) {
      search.delete(PREVIEW_ID_QUERY_KEY)
    }
  })
}

export const getShareUrl = (): string => {
  const search = new URLSearchParams(window.location.search)
  deletePreviewFromSearch(search)
  const previewId = getActivePreviewId()
  if (previewId) {
    search.append(PREVIEW_ID_QUERY_KEY, previewId)
  }
  let query = search.toString()
  if (query) {
    query = `?${query}`
  }
  return `${window.location.origin}${PREVIEW_URL_PREFIX}${window.location.pathname}${query}${window.location.hash}`
}

export const reloadWithoutPreviewIdInURL = (): void => {
  const search = new URLSearchParams(window.location.search)
  deletePreviewFromSearch(search)
  let query = search.toString()
  if (query) {
    query = `?${query}`
  }
  const nextUrl = `${window.location.pathname}${query}${window.location.hash}`
  window.location.href = nextUrl
  if (window.location.hash) {
    window.history.replaceState(window.history.state, document.title, nextUrl)
    window.location.reload()
  }
}

export const initFromSearch = (): void => {
  const search = new URLSearchParams(window.location.search)
  const pendingId = search.get(PREVIEW_ID_QUERY_KEY) ?? undefined
  const activePreview = getActivePreviewId()
  if (pendingId && activePreview !== pendingId) {
    const event: ActivePrChangeEvent = new CustomEvent(EVENT_KEY, {
      detail: { id: pendingId },
    })
    deletePreviewFromSearch(search)
    switchPreviewEffect(pendingId)
    document.dispatchEvent(event)
    let query = search.toString()
    if (query) {
      query = `?${query}`
    }
    const nextUrl = `${window.location.pathname}${query}${window.location.hash}`
    window.location.href = nextUrl
    if (window.location.hash) {
      window.history.replaceState(window.history.state, document.title, nextUrl)
      window.location.reload()
    }
  }
}

export const switchToMainAppFromPreviewEffect = (): void => {
  const expiration = new Date(0).toUTCString()
  document.cookie = `${COOKIE_PREVIEW_KEY}=; expires=${expiration}; path=/`
  const event: ActivePrChangeEvent = new CustomEvent('dev-preview', {
    detail: { id: undefined },
  })
  document.dispatchEvent(event)
  reloadWithoutPreviewIdInURL()
}

export const subscribe = (
  listener: (e: ActivePrChangeEvent) => void,
): { unsub: () => void } => {
  document.addEventListener(EVENT_KEY, listener as EventListener)

  return {
    unsub: () => {
      document.removeEventListener(EVENT_KEY, listener as EventListener)
    },
  }
}
