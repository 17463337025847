import { loader, useMonaco } from '@monaco-editor/react'
import type * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import { useEffect } from 'react'
import type { MonacoEditorSettings } from 'store/slices/ui/settings/types.d'
import { lightTheme, darkTheme } from '@rapidapi/ui-lib'

const useMonacoHook = (setting: MonacoEditorSettings): typeof monaco | null => {
  // declare loader config path once...
  loader.config({
    paths: { vs: setting.app.vsPath || '/vs' },
  })

  const mnco = useMonaco()

  useEffect(() => {
    if (!mnco) {
      return
    }
    mnco.editor.defineTheme('PawLight', {
      base: 'vs',
      inherit: true,
      rules: [
        {
          token: 'keyword',
          foreground: darkTheme.colors.neutral.neutral500,
          fontStyle: 'bold',
        },
        {
          token: 'variable',
          foreground: darkTheme.colors.neutral.neutral500,
          fontStyle: 'normal',
        },
        {
          token: 'string',
          foreground: darkTheme.colors.neutral.neutral500,
          fontStyle: 'normal',
        },
      ],
      colors: {
        'editor.foreground':
          lightTheme.colors.backgrounds.backgroundPrimaryInverse,
        'editor.background': lightTheme.colors.backgrounds.backgroundPrimary,
      },
    })
    mnco.editor.defineTheme('PawDark', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        {
          token: 'keyword',
          foreground: lightTheme.colors.neutral.neutral500,
          fontStyle: 'bold',
        },
        {
          token: 'variable',
          foreground: lightTheme.colors.neutral.neutral500,
          fontStyle: 'normal',
        },
        {
          token: 'string',
          foreground: lightTheme.colors.neutral.neutral500,
          fontStyle: 'normal',
        },
      ],
      colors: {
        'editor.foreground':
          darkTheme.colors.backgrounds.backgroundPrimaryInverse,
        'editor.background': darkTheme.colors.backgrounds.backgroundPrimary,
      },
    })
  }, [mnco])
  return mnco
}

export default useMonacoHook
