import Mousetrap from 'mousetrap'
import type * as _monaco_ from 'monaco-editor/esm/vs/editor/editor.api.d'

export type HotKeyType = {
  description: string
  hotkey: string | string[]
  legend: () => string
  keys?: () => string
  monaco: HotKeyActionDescriptor
}

export type HotKeyActionDescriptor = Omit<
  _monaco_.editor.IActionDescriptor,
  'run' | 'keybindings'
>

export const getOS = ({ macos, win }: { macos: string; win: string }): string =>
  navigator.userAgent.indexOf('Mac') !== -1 ? macos : win

const monacoActionDescriptor = {
  id: '',
  label: '',
  keybindings: [],
  precondition: undefined,
  keybindingContext: undefined,
}

/**
 * Reference the library to avoid imports from multiple places.
 */
export const Hotkey = Mousetrap

/**
 * Toggle Hotkey Reference
 */
export const hkToggleCheatSheet: HotKeyType = {
  description: 'Toggles hotkey cheatsheet',
  hotkey: ['mod+shift+/', 'mod+shift+/'],
  legend: () =>
    getOS({ macos: '⌘ Cmd + ⇧ Shift + /', win: '⌃ Ctrl + ⇧ Shift + /' }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.ToggleHotkeyCheatsheet',
    label: 'Toggle Hotkey Cheatsheet',
  },
}

/**
 * Create New Request
 */
export const hkCreateRequest: HotKeyType = {
  description: 'Create New Request',
  hotkey: ['mod+shift+k'],
  legend: () =>
    getOS({ macos: '⌘ Cmd + ⇧ Shift + K', win: '⌃ Ctrl + ⇧ Shift + K' }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.createRequest',
    label: 'Create Request',
  },
}

/**
 * Create Request Group
 */
export const hkCreateRequestGroup: HotKeyType = {
  description: 'Create Request Group',
  hotkey: ['mod+shift+g'],
  legend: () =>
    getOS({ macos: '⌘ Cmd + ⇧ Shift + G', win: '⌃ Ctrl + ⇧ Shift + G' }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.createRequestGroup',
    label: 'Create Request Group',
  },
}

/**
 * Send Request
 */
export const hkDuplicateRequest: HotKeyType = {
  description: 'Duplicate selected request',
  hotkey: ['mod+shift+d'],
  legend: () =>
    getOS({ macos: '⌘ Cmd + ⇧ Shift + D', win: '⌃ Ctrl + ⇧ Shift + D' }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.duplicateSelectedRequest',
    label: 'Duplicate selected request',
  },
}

/**
 * Send Requestclear

 */
export const hkSendRequest: HotKeyType = {
  description: 'Send selected request',
  hotkey: ['mod+shift+r', 'mod+enter'],
  legend: () => getOS({ macos: '⌘ Cmd + ⇧ Shift + R', win: '⌃ Ctrl + Enter' }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.sendSelectedRequest',
    label: 'Send Selected Request',
  },
}

/**
 * Delete Request
 */
export const hkDeleteRequest: HotKeyType = {
  description: 'Delete selected request',
  hotkey: ['mod+backspace'],
  legend: () =>
    getOS({ macos: '⌘ Cmd + ← Backspace', win: '⌃ Ctrl + ← Backspace' }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.deleteSelected request',
    label: 'Delete Selected Request',
  },
}

/**
 * Toggle Code Generator Visibility
 */
export const hkToggleCodeGenerator: HotKeyType = {
  description: 'Toggle Code Generator',
  hotkey: ['mod+shift+c'],
  legend: () =>
    getOS({
      macos: '⌘ Cmd + ⇧ Shift + C',
      win: '⌃ Ctrl + ⇧ Shift + C',
    }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.toggleCodeGenerator',
    label: 'Toggle Code Generator',
  },
}

/**
 * Undo Action
 */
export const hkUndoAction: HotKeyType = {
  description: 'Undo Previous Action',
  hotkey: ['mod+z'],
  legend: () => getOS({ macos: '⌘ Cmd + Z', win: '⌃ Ctrl + Z' }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.Undo Previous Action',
    label: 'Undo Previous Action',
  },
}

/**
 * Redo Action
 */
export const hkRedoAction: HotKeyType = {
  description: 'Redo Previous Action',
  hotkey: ['mod+shift+z'],
  legend: () =>
    getOS({ macos: '⌘ Cmd + ⇧ Shift + Z', win: '⌃ Ctrl + ⇧ Shift + Z' }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.Redo Previous Action',
    label: 'Redo Previous Action',
  },
}

/**
 * Close Dialog
 */
export const hkCloseDialog: HotKeyType = {
  description: 'Close Dialog',
  hotkey: ['esc'],
  legend: () => getOS({ macos: 'Escape', win: 'Escape' }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.CloseDialog',
    label: 'Close Dialog',
  },
}

/**
 * Select Environment
 */
export const hkSelectEnvironment: HotKeyType = {
  description: 'Select Environment',
  hotkey: ['mod+alt'], // The number part is added while binding
  legend: () =>
    getOS({
      macos: '⌘ Cmd + ⌥ Option + Number',
      win: '⌃ Ctrl + ⎇ Alt + Number',
    }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.SelectEnvironment',
    label: 'Select Environment',
  },
}

/**
 * Send Request
 */
export const hkToggleMarkdownPreview: HotKeyType = {
  description: 'Toggle Markdown Preview',
  hotkey: ['mod+shift+m'],
  legend: () =>
    getOS({ macos: '⌘ Cmd + ⇧ Shift + M', win: '⌃ Ctrl + ⇧ Shift + M' }),
  monaco: {
    ...monacoActionDescriptor,
    id: 'paw.toggleMarkdownPreview',
    label: 'Markdown: Toggle Preview',
  },
}

/**
 * @const hotkeyList - utilize the array for referencing key combinations and info
 */
export const hotkeyList: HotKeyType[] = [
  hkToggleCheatSheet,
  hkCreateRequest,
  hkCreateRequestGroup,
  hkDuplicateRequest,
  hkSendRequest,
  hkDeleteRequest,
  hkToggleCodeGenerator,
  hkUndoAction,
  hkRedoAction,
  hkSelectEnvironment,
]
