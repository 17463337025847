import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import environmentsSlice from './slice'

// eslint-disable-next-line import/prefer-default-export
export const environmentsReducer = persistReducer(
  {
    key: 'environments',
    version: 1,
    storage,
  },
  environmentsSlice.reducer,
)
