import type { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import type { Project } from 'lib/project/types.d'
import {
  serializeObjectNullableRef,
  assertType,
} from 'lib/cloud-sync/serializer/helpers'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

import serializeDynamicString from './serialize-dynamic-string'

const serializeRequestVariable = (
  serializer: CloudSerializer.ProjectSerializer,
  object: Project.RequestVariable,
): CloudAPI.SyncTreeItem => {
  // check type
  assertType(object, 'requestVariable')

  const syncTreeItem: CloudAPI.SyncTreeItem = {
    _uuid: object.uuid,
    _type: 'requestVariable',
    name: object.name,
    summary: object.summary,
    type: object.variableType, // warning: it's `type` in cloud format but it conflicts with `_type`
    required: object.required,
    value: serializeObjectNullableRef(
      serializer,
      object.value as Project.GenericRef<Project.DynamicString>,
      serializeDynamicString,
    ),
    schema: serializeObjectNullableRef(
      serializer,
      object.schema as Project.GenericRef<Project.DynamicString>,
      serializeDynamicString,
    ),
  }
  return syncTreeItem
}

export default serializeRequestVariable
