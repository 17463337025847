import { setProject } from 'store/actions'
import type { PrimaryWorkerState } from 'worker/primary-worker/implementation/types.d'
import type { NotificationHandler } from '../types.d'

const handledidImportRequests = async ({
  notification,
  next,
}: NotificationHandler<PrimaryWorkerState.ProjectStateObjects>): Promise<void> => {
  next(
    setProject({
      ...notification.payload,
    }),
  )
}

export default handledidImportRequests
