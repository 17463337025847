import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'store/root-reducer'
import { selectActiveCodeGeneratorIdentifier } from '../ui/selectors'
import type ExtensionStore from './types.d'

export const extensionStateSelector = (
  state: RootState,
): ExtensionStore.State => state.extensions

// List of scripts
export const selectExtensionScripts = createSelector(
  extensionStateSelector,
  (state: ExtensionStore.State): string[] => state.scripts,
)

// Code generator results
export const selectGeneratedCodeResult = createSelector(
  extensionStateSelector,
  (state: ExtensionStore.State): ExtensionStore.GeneratedCodeResult =>
    state.generatedCodeResult,
)

// Loaded extensions
export const selectExtensionList = createSelector(
  extensionStateSelector,
  (state: ExtensionStore.State): ExtensionStore.Model[] => state.list,
)

export const selectExtensionEnabledList = createSelector(
  selectExtensionList,
  (extensions: ExtensionStore.Model[]): ExtensionStore.Model[] =>
    extensions.filter((item: ExtensionStore.Model) => !item.disabled),
)

export const selectExtensionEnabledCodeGeneratorList = createSelector(
  selectExtensionEnabledList,
  (extensions: ExtensionStore.Model[]): ExtensionStore.CodeGenerator[] =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    extensions.filter(
      (item: ExtensionStore.Model) => item.extensionType === 'CG',
    ),
)

export const selectExtensionEnabledImporterList = createSelector(
  selectExtensionEnabledList,
  (extensions: ExtensionStore.Model[]): ExtensionStore.Importer[] =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    extensions.filter(
      (item: ExtensionStore.Model) => item.extensionType === 'IM',
    ),
)

export const selectExtensionEnabledDynamicValueList = createSelector(
  selectExtensionEnabledList,
  (extensions: ExtensionStore.Model[]): ExtensionStore.DynamicValue[] =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    extensions.filter(
      (item: ExtensionStore.Model) => item.extensionType === 'DV',
    ),
)

// Selected code generator
export const selectExtensionActiveCodeGenerator = createSelector(
  selectExtensionEnabledCodeGeneratorList,
  selectActiveCodeGeneratorIdentifier,
  (
    codeGenerators: ExtensionStore.Model[],
    codeGeneratorIdentifier,
  ): ExtensionStore.Model | null =>
    codeGenerators.find(
      (extension) => extension.identifier === codeGeneratorIdentifier,
    ) || (codeGenerators.length > 0 ? codeGenerators[0] : null),
)
