import React, { useMemo } from 'react'
import { getHttpMessageContentType } from 'lib/request-handling'
import { TabBarContainer } from 'ecosystems/tab-bar-container'
import type { HttpExchangeTabViewProps } from '../http-exchange-tabs'
import {
  HttpExchangeHeadersTab,
  HttpExchangeRawTab,
  HttpExchangeJsonTreeTab,
  HttpExchangeJsonTextTab,
  HttpExchangeTextTab,
  HttpExchangeImageTab,
  HttpExchangeHexTab,
  HttpExchangeWebTab,
} from '../http-exchange-tabs'
import { useHttpExchangeMessage } from '../http-exchange-tabs/http-exchange-helper-hooks'
import { getDefaultTab } from '../http-exchange-tabs/http-exchange-tab-utils'

type TabComponent = (_: HttpExchangeTabViewProps) => JSX.Element | null

const tabComponents = {
  raw: HttpExchangeRawTab,
  json: HttpExchangeJsonTreeTab,
  jsonText: HttpExchangeJsonTextTab,
  text: HttpExchangeTextTab,
  image: HttpExchangeImageTab,
  hex: HttpExchangeHexTab,
  web: HttpExchangeWebTab,
  headers: HttpExchangeHeadersTab,
}

type TabKey = keyof typeof tabComponents

const HttpExchangeMessageTabsContainer: React.FC<HttpExchangeTabViewProps> = ({
  messageType,
}) => {
  const { httpExchange, httpMessage } = useHttpExchangeMessage(messageType)

  const tabId = useMemo(() => {
    if (!httpExchange || !httpMessage || !messageType) {
      return ''
    }
    // important: we don't use / as separators here, so we're not prioritizing
    // the value of other responses over the default
    // it's either user preference for THIS tab, or the default
    // also, we add the content-type so we can have different preferences for each
    return `httpExchangeMessageTab-${
      httpExchange.requestUuid
    }-${messageType}-${encodeURIComponent(
      (httpMessage && getHttpMessageContentType(httpMessage)) || 'default',
    )}`
  }, [httpExchange, httpMessage, messageType])

  const config = useMemo(
    () => ({
      // important: we don't use / as separators here, so we're not prioritizing
      // the value of other responses over the default
      // it's either user preference for THIS tab, or the default
      // also, we add the content-type so we can have different preferences for each
      identifier: tabId,
      defaultTab: httpMessage ? getDefaultTab(httpMessage) : 'text',
      tabs: [
        { key: 'headers', label: 'Headers' },
        { key: 'text', label: 'Text' },
        { key: 'json', label: 'JSON Tree' },
        { key: 'jsonText', label: 'JSON Text' },
        { key: 'image', label: 'Image' },
        { key: 'hex', label: 'Hex' },
        { key: 'web', label: 'Web' },
        { key: 'raw', label: 'Raw' },
      ],
    }),
    [tabId, httpMessage],
  )

  const renderRequestTabMemo = useMemo(
    // eslint-disable-next-line react/no-unstable-nested-components
    () => (tabValue: string) => {
      const Tab = tabComponents[tabValue as TabKey] as TabComponent
      if (!Tab) {
        throw new Error(
          `[HttpExchangeMessageTabsContainer] Missing tab for key ${tabValue}`,
        )
      }
      return <Tab {...{ messageType, tabId }} />
    },
    [messageType, tabId],
  )

  return (
    <TabBarContainer
      config={config}
      variant="segment"
      renderTab={renderRequestTabMemo}
      flexBasis={0}
      flexGrow={1}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      tabBarProps={{ px: 5 }}
    />
  )
}

export default HttpExchangeMessageTabsContainer
