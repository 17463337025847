import type { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'
import addObject from './add-object'

const addDynamicValue = <
  DV extends Project.DynamicValue = Project.DynamicValue,
>(
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  {
    uuid,
    identifier,
    ...more
  }: {
    uuid?: string
    identifier: string
  } & Partial<DV>,
): Project.GenericRef<DV> => {
  const dynamicValue: Project.DynamicValue = {
    uuid: uuid || getUuid(),
    type: 'dynamicValue',
    identifier,
    ...more,
  }
  return addObject(objects, dynamicValue)
}

export default addDynamicValue
