import Mousetrap from 'mousetrap'

const getOS = ({ macos, win }: { macos: string; win: string }): string =>
  navigator.appVersion.indexOf('Mac') !== -1 ? macos : win

export type HotKeyType = {
  description: string
  hotkey: string | string[]
  legend: () => string
  keys?: () => string
}

/**
 * Reference the library to avoid imports from multiple places.
 */
export const Hotkey = Mousetrap

/**
 * Toggle Hotkey Reference
 */
export const hkToggleCheatSheet: HotKeyType = {
  description: 'Toggles hotkey cheatsheet',
  hotkey: ['mod+/', 'mod+?'],
  legend: () => getOS({ macos: '⌘ Cmd + ?', win: '⌃ Ctrl + ?' }),
}

/**
 * Create New Request
 */
export const hkCreateRequest: HotKeyType = {
  description: 'Create New Request',
  hotkey: ['mod+shift+k'],
  legend: () =>
    getOS({ macos: '⌘ Cmd + ⇧ Shift + K', win: '⌃ Ctrl + ⇧ Shift + K' }),
}

/**
 * Create Request Group
 */
export const hkCreateRequestGroup: HotKeyType = {
  description: 'Create Request Group',
  hotkey: ['mod+shift+g'],
  legend: () =>
    getOS({ macos: '⌘ Cmd + ⇧ Shift + G', win: '⌃ Ctrl + ⇧ Shift + G' }),
}

/**
 * Send Request
 */
export const hkDuplicateRequest: HotKeyType = {
  description: 'Duplicate selected request',
  hotkey: ['mod+shift+d'],
  legend: () => getOS({ macos: '⌘ Cmd + R', win: '⌃ Ctrl + Enter' }),
}

/**
 * Send Request
 */
export const hkSendRequest: HotKeyType = {
  description: 'Send selected request',
  hotkey: ['mod+r', 'mod+enter'],
  legend: () => getOS({ macos: '⌘ Cmd + R', win: '⌃ Ctrl + Enter' }),
}

/**
 * Delete Request
 */
export const hkDeleteRequest: HotKeyType = {
  description: 'Delete selected request',
  hotkey: ['mod+backspace'],
  legend: () =>
    getOS({ macos: '⌘ Cmd + ← Backspace', win: '⌃ Ctrl + ← Backspace' }),
}

/**
 * Toggle Code Generator Visibility
 */
export const hkToggleCodeGenerator: HotKeyType = {
  description: 'Toggle Code Generator',
  hotkey: ['mod+shift+c'],
  legend: () =>
    getOS({
      macos: '⌘ Cmd + ⇧ Shift + C',
      win: '⌃ Ctrl + ⇧ Shift + C',
    }),
}

/**
 * Undo Action
 */
export const hkUndoAction: HotKeyType = {
  description: 'Undo Previous Action',
  hotkey: ['mod+z'],
  legend: () => getOS({ macos: '⌘ Cmd + Z', win: '⌃ Ctrl + Z' }),
}

/**
 * Redo Action
 */
export const hkRedoAction: HotKeyType = {
  description: 'Redo Previous Action',
  hotkey: ['mod+shift+z'],
  legend: () =>
    getOS({ macos: '⌘ Cmd + ⇧ Shift + Z', win: '⌃ Ctrl + ⇧ Shift + Z' }),
}

/**
 * Close Dialog
 */
export const hkCloseDialog: HotKeyType = {
  description: 'Close Dialog',
  hotkey: ['esc'],
  legend: () => getOS({ macos: 'Escape', win: 'Escape' }),
}

/**
 * @const hotkeyList - utilize the array for referencing key combinations and info
 */
export const hotkeyList: HotKeyType[] = [
  hkToggleCheatSheet,
  hkCreateRequest,
  hkCreateRequestGroup,
  hkDuplicateRequest,
  hkSendRequest,
  hkDeleteRequest,
  hkToggleCodeGenerator,
  hkUndoAction,
  hkRedoAction,
]
