import type { Project } from 'lib/project'
import addDynamicString from 'lib/project/setters/add-dynamic-string'
import addDynamicValue from 'lib/project/setters/add-dynamic-value'
import { getUuid } from 'lib/utils'

interface DynamicValuePaw21Component {
  identifier: string
  uuid: string | null
  data: Project.ObjectMap<unknown>
}

type DynamicValuePropertyValue =
  | string
  | number
  | Project.GenericRef<Project.DynamicString>

const parseLegacySerializationDynamicValueItem = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  key: string,
  components: unknown[],
): Project.GenericRef<Project.DynamicString> =>
  addDynamicString(objects, root, {
    strings: components.map((item) => {
      if (typeof item === 'string') {
        return item
      }
      if (typeof item === 'object') {
        // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
        return parseLegacySerializationComponent(objects, root, item)
      }
      throw new Error(
        `[parseLegacySerializationDynamicValueItem] Invalid item of type ${typeof item}`,
      )
    }),
  })

const parseLegacySerializationDynamicDataDictionaryItem = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  key: string,
  item: unknown,
): DynamicValuePropertyValue => {
  if (typeof item === 'string' || typeof item === 'number') {
    return item
  }

  if (Array.isArray(item)) {
    return parseLegacySerializationDynamicValueItem(objects, root, key, item)
  }

  throw new Error(
    `[parseLegacySerializationDynamicDataDictionaryItem] Failed to parse item of type ${typeof item} for key "${key}"`,
  )
}

const parseLegacySerializationDynamicDataDictionary = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  dataDictionary: Project.ObjectMap<unknown>,
): { [key: string]: DynamicValuePropertyValue } =>
  Object.keys(dataDictionary)
    .filter((key) => key.indexOf('_') !== 0 && key !== 'identifier')
    .reduce(
      (obj: { [key: string]: DynamicValuePropertyValue }, key: string) => ({
        ...obj,
        [key]: parseLegacySerializationDynamicDataDictionaryItem(
          objects,
          root,
          key,
          dataDictionary[key],
        ),
      }),
      {} as { [key: string]: DynamicValuePropertyValue },
    )

const parseLegacySerializationComponent = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  component: unknown,
): string | Project.GenericRef<Project.DynamicValue> => {
  // Component is a string, just add as is
  if (typeof component === 'string') {
    return component
  }

  // Component is a dynamic value, add a Project.DynamicValue
  // If not, return an empty string as a placeholder (fail silently)
  if (!component || typeof component !== 'object') {
    return ''
  }

  // Using the full JSON (since Paw 2.1) serialization method
  const { identifier } = component as DynamicValuePaw21Component
  if (identifier) {
    const uuid = (component as DynamicValuePaw21Component).uuid || getUuid()
    const dataDictionary = parseLegacySerializationDynamicDataDictionary(
      objects,
      root,
      (component as DynamicValuePaw21Component).data,
    )
    return addDynamicValue(objects, root, {
      ...dataDictionary,
      uuid,
      identifier,
    })
  }

  // Using the legacy (Paw 2.0 series) serialization method
  // Not supported in Paw Web (deprecated since 2016), fail silently
  return ''
}

const parseLegacySerializationComponents = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  components: unknown[],
): Project.GenericRef<Project.DynamicString> => {
  const res = components.map((component) =>
    parseLegacySerializationComponent(objects, root, component),
  )
  return addDynamicString(objects, root, { strings: res })
}

const parseLegacyArchiveString = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  archiveString: string,
  alwaysReturnDynamicString?: boolean,
): Project.GenericRef<Project.DynamicString> | string | null => {
  // If does not start with { or [, it's clearly not a JSON
  if (!archiveString.match(/^[[{]/)) {
    return alwaysReturnDynamicString
      ? addDynamicString(objects, root, { string: archiveString })
      : archiveString
  }

  let components
  try {
    components = JSON.parse(archiveString)
  } catch (error) {
    // If no JSON could be decoded, return the string as is
    return alwaysReturnDynamicString
      ? addDynamicString(objects, root, { string: archiveString })
      : archiveString
  }

  // If JSON could be decoded, and it's a Paw 3.0+ record
  if (
    typeof components === 'object' &&
    // eslint-disable-next-line no-underscore-dangle
    components._uuid &&
    // eslint-disable-next-line no-underscore-dangle
    components._type === 'dynamicString'
  ) {
    throw new Error(
      'Paw 3.0+ records are not supported in Paw Web (note: they should not be in use apparently)',
    )
  }

  if (!Array.isArray(components)) {
    return alwaysReturnDynamicString
      ? addDynamicString(objects, root, { string: archiveString })
      : archiveString
  }

  return parseLegacySerializationComponents(
    objects,
    root,
    components as unknown[],
  )
}

export default parseLegacyArchiveString
