import config from 'config'
import type { Evaluation } from 'lib/evaluation/types.d'
import type { RequestHandling } from 'lib/request-handling/types.d'
import { getHTTPExchangeRequest } from 'lib/request-handling/utils'
import { base64EncodeString, getUuid } from 'lib/utils'

import type { Parcel } from './types.d'

/**
 * @module PawParcel
 *
 * @summary
 * This module is responsible for forwarding parcels (request data) to the Paw Agent.
 * It detects whether the agent is installed, and/or suggests
 * its installation to take advantage of the full-desktop feature, securely bypassing
 * CORS and Mixed Content Blocking.
 *
 * The concept of PawParcel is like how parcels, posts and packages are shipped by a
 * courier, it ensures the the `requestData` is sent to the destination intact, in return
 * the package forwarders (e.g., PawAgent) accepts the response from
 * the destination and sends it back as data.
 *
 * @exports PawParcel
 */

/**
 * @function createParcelBody
 * @summary
 *
 * @param {Object<Evaluation.EvaluatedRequest>} evaluatedRequest -
 * @returns {Object<Parcel.Request>}
 */
export const createParcelBody = (
  concreteRequest: RequestHandling.ConcreteRequest,
): Parcel.Request => {
  // get args
  const { evaluatedRequest } = concreteRequest
  if (!evaluatedRequest) {
    throw new Error('[createParcelBody] evaluatedRequest is missing')
  }
  const { url, method, body, headers } = evaluatedRequest

  // return parcel Request
  const extractHeaders = headers.map(
    ({ name, value }: Evaluation.EvaluatedParameter): Parcel.Header => ({
      name,
      value,
    }),
  )
  return {
    method,
    url,
    httpVersion: 'HTTP/1.1',
    postData: {
      text: base64EncodeString(body, 'utf-8'),
      encoding: 'base64',
    },
    headers: extractHeaders,
  }
}

/**
 * @function agentFetch
 * @summary
 *
 * @param {Object<Parcel.Request>} requestData -
 * @returns {Promise<Parcel.PartialHTTPExchange>}
 */
export const agentFetch = async (
  parcelRequest: Parcel.Request,
): Promise<Parcel.PartialHTTPExchange> => {
  const request = await fetch(config.helper.endpoint, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'omit',
    redirect: 'manual',
    referrerPolicy: 'origin',
    body: JSON.stringify(parcelRequest),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
  return request.json()
}

/**
 * @function agentProxySender
 * @summary
 *
 * @param {Object<RequestHandling.ConcreteRequest>} concreteRequest -
 * @returns {Promise<RequestHandling.ConcreteRequest>}
 */
export const agentProxySender = async (
  concreteRequest: RequestHandling.ConcreteRequest,
): Promise<RequestHandling.ConcreteRequest> => {
  if (!concreteRequest || !concreteRequest.evaluatedRequest) {
    throw new Error('Missing concreteRequest or evaluatedRequest')
  }

  // get parcel body
  const parcelBody = createParcelBody(concreteRequest)

  // send parcel to Paw Agent
  const date = new Date()
  let httpExchange: RequestHandling.HTTPExchange
  try {
    const partialHttpExchange = await agentFetch(parcelBody)

    // create HTTP Exchange
    httpExchange = {
      ...partialHttpExchange,
      uuid: getUuid(),
      projectUuid: concreteRequest.projectUuid,
      requestUuid: concreteRequest.requestUuid,
      library: 'pa',
      request: getHTTPExchangeRequest(concreteRequest.evaluatedRequest),
      errorCode: -1,
      errorData: '',
      date,
    }

    return {
      ...concreteRequest,
      httpExchange,
    }
  } catch (error) {
    // create HTTP Exchange
    httpExchange = {
      uuid: getUuid(),
      projectUuid: concreteRequest.projectUuid,
      requestUuid: concreteRequest.requestUuid,
      library: 'pa',
      request: getHTTPExchangeRequest(concreteRequest.evaluatedRequest),
      response: null,
      errorCode: 1,
      errorData: 'Failed to connect to Paw Local Proxy',
      responseTime: 0,
      downloadTime: 0,
      date,
    }
  }

  return {
    ...concreteRequest,
    httpExchange,
  }
}
