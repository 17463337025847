// @TODO: NOW
import React from 'react'

import { Flex, Box, Icon, Typography, styled, css } from '@rapidapi/ui-lib'
import type { Project } from 'lib/project'
import useDynamicValueEvaluation from './hooks/use-dynamic-value-evaluation'

type DynamicValuePreviewProps = {
  objectRef: Project.GenericRef<Project.DynamicValue>
}

const StyledBox = styled(Box)(
  css({
    border: 1,
    borderStyle: 'solid',
    borderColor: 'borders.borderDefault',
    borderRadius: 'default',
    backgroundColor: 'backgrounds.backgroundSecondary',
    whiteSpace: 'pre-wrap',
    maxHeight: 80,
    overflow: 'scroll',
  }),
)

const DynamicValuePreview: React.FC<DynamicValuePreviewProps> = ({
  objectRef,
}) => {
  const result = useDynamicValueEvaluation(objectRef)

  return (
    <Box mt={3}>
      <Flex alignItems="flex-start" justifyContent="flex-start" mt={1}>
        <Icon symbol="info" size={14} mr={1} color="texts.mediumEmphasis" />
        <Typography variant="caption" color="mediumEmphasis">
          Preview
        </Typography>
      </Flex>
      <StyledBox mt={1} p={2}>
        <Typography
          variant="caption"
          fontFamily="JetBrains Mono"
          color="mediumEmphasis"
        >
          {
            result ||
              '\u200b' /* \u200b – a hack to keep a proper height for empty */
          }
        </Typography>
      </StyledBox>
    </Box>
  )
}

export default DynamicValuePreview
