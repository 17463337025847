import type { Project } from 'lib/project'

const parseUrlEncodedObject = (input: string): Project.ObjectMap<string> =>
  Object.fromEntries(
    input
      .split('&')
      .filter((item) => !!item)
      .map((item) => {
        const m = item.match(/^([^=]*)=(.*)$/)
        if (m) {
          return [decodeURIComponent(m[1]), decodeURIComponent(m[2])]
        }
        return [decodeURIComponent(item), '']
      }),
  )

export default parseUrlEncodedObject
