import type { PayloadAction } from '@reduxjs/toolkit'

import { httpExchangesActions } from 'store/actions'
import { httpExchangeDeleteListWorkerAction } from 'worker/primary-worker/actions'
import type { RequestUuidPayload } from 'store/types.d'

import type { ActionHandler } from '../types.d'

const httpExchangeDeleteListActionHandler = async ({
  action: {
    payload: { requestUuid },
  },
  next,
  worker,
}: ActionHandler<PayloadAction<RequestUuidPayload>>): Promise<void> => {
  if (!worker) {
    throw new Error('Worker is missing')
  }
  await worker.runAction(httpExchangeDeleteListWorkerAction, { requestUuid })
  next(
    httpExchangesActions.setHttpExchangesForRequest({
      requestUuid,
      httpExchanges: [],
    }),
  )
}

export default httpExchangeDeleteListActionHandler
