/* eslint-disable react/jsx-props-no-spreading */

import styled from '@emotion/styled'
import { Box } from '../box'

export const Flex = styled(Box)({
  display: 'flex',
})

export default Flex
