import type { Project } from 'lib/project/types.d'

const deleteObject = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  {
    ref,
  }: {
    ref: Project.GenericRef<Project.AnyObject>
  },
): Project.GenericRef<Project.AnyObject> => {
  // eslint-disable-next-line no-param-reassign
  delete objects[ref.ref]

  return ref
}

export default deleteObject
