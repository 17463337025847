import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  TextField,
  Typography,
} from '@rapidapi/ui-lib'
import { selectExtensionList } from 'store/selectors'
import type ExtensionStore from 'store/slices/extensions/types.d'
import {
  extensionsActions,
  extensionsLoadListAction,
  extensionsUnloadItemAction,
} from 'store/actions'

const ProjectExtensionManager: React.FC = () => {
  const [extensionStateURL, setExtensionStateURL] = useState('')

  const dispatch = useDispatch()
  const extensions = useSelector(selectExtensionList)

  // handler that triggers a dispatch to install an extension
  const installOnSubmitHandler = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()
      if (extensionStateURL === '') {
        return
      }

      dispatch(extensionsLoadListAction({ scripts: [extensionStateURL] }))
      setExtensionStateURL('')
    },
    [dispatch, extensionStateURL],
  )

  // handler that updates installation source URL
  const installOnChangeHandler = useCallback((value: string) => {
    if (value.trim() === '') {
      return
    }
    setExtensionStateURL(value)
  }, [])

  // handler to activate an extension
  const activateExtensionHandler = useCallback(
    (e: React.MouseEvent, item: ExtensionStore.Model) => {
      e.preventDefault()
      dispatch(extensionsActions.activateExtension(item))
    },
    [dispatch],
  )

  // handler to handle extension uninstallation
  const removeExtensionHandler = useCallback(
    (e: React.MouseEvent, item: ExtensionStore.Model) => {
      e.preventDefault()
      dispatch(extensionsUnloadItemAction(item))
    },
    [dispatch],
  )

  return (
    <Box>
      <Typography variant="body1" fontWeight="bold">
        Extensions from Paw
      </Typography>
      <Divider />
      <Box p={4} mb={5} textAlign="center">
        <Typography variant="caption" color="lowEmphasis">
          List of extensions from Paw...
        </Typography>
      </Box>

      <Typography variant="body1" fontWeight="bold">
        Install from URL
      </Typography>
      <Divider />

      <Box py={4} mb={5}>
        <form onSubmit={installOnSubmitHandler}>
          <Flex justifyContent="space-between" alignItems="start">
            <TextField
              width="96%"
              value={extensionStateURL}
              placeholder="Must be a valid Web URI"
              hint="example, https://paw.cloud/extensions/ApiDocGenerator"
              onChange={installOnChangeHandler}
            />
            <Button
              type="submit"
              startIcon="add"
              disabled={extensionStateURL.trim() === ''}
            />
          </Flex>
        </form>
      </Box>

      <Typography variant="body1" fontWeight="bold">
        Manage Installed Extensions
      </Typography>
      <Divider />

      {extensions.length > 0 ? (
        extensions.map((item: ExtensionStore.Model, index: number) => (
          <React.Fragment key={index.toString()}>
            <Flex my={1} alignItems="center">
              <Box width="50%">
                <Typography
                  variant="caption"
                  color="lowEmphasis"
                  fontWeight="bold"
                >
                  {item.label}
                </Typography>
              </Box>
              <Flex
                width="50%"
                justifyContent="space-between"
                alignItems="middle"
              >
                <Box>
                  <IconButton
                    icon="extension"
                    onClick={(e: React.MouseEvent) =>
                      activateExtensionHandler(e, item)
                    }
                    color={
                      item.disabled ? 'content.tertiary' : 'content.success'
                    }
                  />

                  <IconButton icon="settings" />
                  <IconButton icon="help" />
                </Box>
                <Box>
                  {!item.isOfficial && (
                    <IconButton
                      icon="trash"
                      color="content.error"
                      onClick={(e: React.MouseEvent) =>
                        removeExtensionHandler(e, item)
                      }
                    />
                  )}
                </Box>
              </Flex>
            </Flex>
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <Typography variant="body1" fontWeight="bold">
          There are no extensions installed.
        </Typography>
      )}
    </Box>
  )
}

export default ProjectExtensionManager
