/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'
import { Button } from 'components/inputs/button'
import { Icon } from 'components/data-display/icon'
import { ProgressIndicator } from 'components/feedback/progress-indicator'
import { Typography } from 'components/data-display/typography'
import { Box } from 'components/layout/box'

type DropzoneLoaderProps = {
  currentFile?: File | null | undefined
  isLoading: boolean
}

export const DropzoneLoader: React.FC<DropzoneLoaderProps> = ({
  isLoading = false,
  currentFile = null,
}) =>
  !isLoading ? (
    <Icon
      symbol={!currentFile ? 'attach' : 'document'}
      display="block"
      color="backgrounds.backgroundAccent"
      mx="auto"
      my={5}
      size={64}
    />
  ) : (
    <Box textAlign="center">
      <ProgressIndicator display="block" mx="auto" my={6} />
      <Typography
        display="inline-block"
        mr={1}
        variant="body2"
        color="lowEmphasis"
      >
        Loading...
      </Typography>
    </Box>
  )

type DropzoneContentProps = {
  currentFile?: File | null | undefined
  attachmentTypes?: string
  attachFileHandler?: (event: React.MouseEvent) => void
  detachFileHandler?: (event: React.MouseEvent) => void
}

export const DropzoneContent: React.FC<DropzoneContentProps> = ({
  currentFile,
  attachmentTypes,
  attachFileHandler,
  detachFileHandler,
}) =>
  currentFile ? (
    <Box>
      <Typography mb={2} variant="body2" fontWeight="bold">
        {currentFile?.name}
      </Typography>
      {/* @TODO: temporary fix */}
      <Button onClick={detachFileHandler} variant="default">
        Remove
      </Button>
    </Box>
  ) : (
    <Box mx="auto">
      {/* @TODO: temporary fix */}
      <Button onClick={attachFileHandler} variant="default">
        Upload File
      </Button>
      <Typography my={5} variant="body2" color="mediumEmphasis">
        or drop a file to upload. <br />
        {attachmentTypes?.trim() !== ''
          ? `(${attachmentTypes?.toUpperCase()})`
          : ''}
      </Typography>
    </Box>
  )
