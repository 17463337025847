import type { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import type { Project } from 'lib/project/types.d'
import {
  assertType,
  serializeFutureRef,
  serializeObjectNullableRef,
} from 'lib/cloud-sync/serializer/helpers'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

import serializeDynamicString from './serialize-dynamic-string'

const serializeEnvironmentVariableValue = (
  serializer: CloudSerializer.ProjectSerializer,
  object: Project.EnvironmentVariableValue,
): CloudAPI.SyncTreeItem => {
  // check type
  assertType(object, 'environmentVariableValue')

  const syncTreeItem: CloudAPI.SyncTreeItem = {
    _uuid: object.uuid,
    _type: 'environmentVariableValue',
    value: serializeObjectNullableRef(
      serializer,
      object.value as Project.GenericRef<Project.DynamicString>,
      serializeDynamicString,
    ),
    variable: serializeFutureRef(
      serializer,
      object.variable as Project.GenericRef<Project.EnvironmentVariable>,
    ),
    environment: serializeFutureRef(
      serializer,
      object.environment as Project.GenericRef<Project.Environment>,
    ),
    domain: serializeFutureRef(
      serializer,
      object.domain as Project.GenericRef<Project.EnvironmentDomain>,
    ),
  }
  return syncTreeItem
}

export default serializeEnvironmentVariableValue
