/* eslint-disable react/jsx-props-no-spreading */

import { Icon } from '../../data-display/icon'
import { SwitchBase } from '../switch-base'
import { SwitchBaseComponentProps } from '../switch-base/switch-base.types'

export const Checkbox: React.FC<SwitchBaseComponentProps> = (props) => (
  <SwitchBase
    css={({ radii }) => ({
      '& [data-role="switch-base-icon"]': {
        borderRadius: radii.default,
      },
    })}
    icon={<Icon symbol="check" color="inherit" size={14} />}
    type="checkbox"
    {...props}
  />
)

export default Checkbox
