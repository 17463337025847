import { useContext } from 'react'
import PromptContext, { PromptShowFn, AlertShowFn } from './prompt-context'

export const usePrompt = (): PromptShowFn => {
  const { prompt } = useContext(PromptContext)
  if (!prompt) {
    throw new Error('Call to usePrompt() outside of a valid PromptContext')
  }
  return prompt
}

export const useAlert = (): AlertShowFn => {
  const { alert } = useContext(PromptContext)
  if (!alert) {
    throw new Error('Call to usePrompt() outside of a valid PromptContext')
  }
  return alert
}
