import type { Project } from 'lib/project/types.d'
import reorderListItem from 'utils/reorder-list-item'

import { getObject } from '../getters'

export interface UpdateKeyValueListArgs<
  T extends Project.TypesWithLists = Project.TypesWithLists,
> {
  parentRef: Project.GenericRef<T>
  parentProperty: Project.GetListKeys<T>
  sourceIndex: number
  targetIndex: number
}

const updateKeyValueList = <
  T extends Project.TypesWithLists = Project.TypesWithLists,
>(
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  {
    parentRef,
    parentProperty,
    sourceIndex,
    targetIndex,
  }: UpdateKeyValueListArgs<T>,
): void => {
  const object = getObject(parentRef, objects, false)
  if (object[parentProperty]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    object[parentProperty] = reorderListItem(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      object[parentProperty],
      sourceIndex,
      targetIndex,
    )
  }
}

export default updateKeyValueList
