import type { Project } from 'lib/project/types.d'
import { getEnvironmentVariable, getEnvironmentDomain } from '../getters'
import deleteObject from './delete-object'
import deleteDynamicString from './delete-dynamic-string'

export interface DeleteEnvironmentVariableArgs {
  ref: Project.GenericRef<Project.EnvironmentVariable>
}

const deleteEnvironmentVariable = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { ref }: DeleteEnvironmentVariableArgs,
): Project.GenericRef<Project.EnvironmentVariable> => {
  const variable = getEnvironmentVariable(ref, objects, false)
  const domain = getEnvironmentDomain(variable.domain, objects, false)

  // delete all values for this variable
  const values = (
    Object.values(objects).filter(
      ({ type }) => type === 'environmentVariableValue',
    ) as Project.EnvironmentVariableValue[]
  ).filter(({ variable: aVariable }) => aVariable.ref === ref.ref)
  values.forEach(({ uuid, value }) => {
    if (value) {
      deleteDynamicString(objects, root, { ref: value })
    }

    deleteObject(objects, root, { ref: { ref: uuid } })
  })

  deleteObject(objects, root, { ref })

  // delete this variable from its domain
  domain.variables = domain.variables.filter(
    ({ ref: aRef }) => aRef !== ref.ref,
  )

  return ref
}

export default deleteEnvironmentVariable
