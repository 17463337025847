import React, { useMemo } from 'react'
import { Flex, Box, Icon, Typography } from '@rapidapi/ui-lib'
import type { CoverTemplatePageProps } from 'templates'
import { CoverTemplate } from 'templates'
import { useDownloadLinks } from 'utils'
import DownloadContentLoading from './download-content-loading'
import DownloadContentLoaded from './download-content-loaded'

const DownloadPage: React.FC = () => {
  const [targets, isLoading, hasError] = useDownloadLinks()

  const page: CoverTemplatePageProps = useMemo(
    () => ({
      title: 'Paw',
      description: '- Full-featured & cross-platform HTTP client',
    }),
    [],
  )

  const searchParams = useMemo(
    () => new URL(document.location.href).searchParams,
    [],
  )

  return (
    <CoverTemplate authScreen={false} page={page}>
      <Flex
        minHeight="65%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box>
          <Icon symbol="luckymarmot" size={64} />
        </Box>
        <Box>
          <Typography fontWeight="bold" variant="title" display="inline">
            {page.title}
          </Typography>
        </Box>
        <Box maxWidth={640} mx="auto">
          <Typography my={6} variant="body1" textAlign="center">
            Paw is a full-featured HTTP client that lets <br />
            you test and describe the APIs you build or consume.
          </Typography>
          {isLoading ? (
            <DownloadContentLoading />
          ) : (
            <DownloadContentLoaded
              targets={targets}
              errorMessage={hasError}
              params={searchParams}
            />
          )}
        </Box>
      </Flex>
    </CoverTemplate>
  )
}

export default DownloadPage
