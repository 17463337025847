import type { Project } from 'lib/project/types.d'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'
import { filterArrayNonNull } from 'lib/cloud-sync/loader/helpers'
import serializeObjectRef from './serialize-object-ref'

const serializeObjectRefArray = <T extends Project.AnyObject>(
  serializer: CloudSerializer.ProjectSerializer,
  refs: Project.GenericRef<T>[],
  serializerFn?: CloudSerializer.ObjectSerializerFn<T>,
): Project.GenericRef<T>[] =>
  filterArrayNonNull(
    (refs || []).map((ref) =>
      serializeObjectRef(serializer, ref, serializerFn),
    ),
  )

export default serializeObjectRefArray
