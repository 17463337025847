/* eslint-disable react/jsx-props-no-spreading */

import { forwardRef, memo } from 'react'
import { withTheme } from '@emotion/react'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { space } from 'styled-system'
import {
  TabBarRefProps,
  TabBarProps,
  TabBarComponentProps,
} from './tab-bar.types'
import { TabBarItem } from './tab-bar-item'

const TabBarStyled = withTheme(
  styled('ul')<Pick<TabBarComponentProps, 'tabAlign'>>(
    ({ tabAlign = 'flex-start' }) =>
      css({
        margin: 0,
        padding: 0,
        justifyContent: tabAlign,
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
      }),
    space,
  ),
)

/**
 * `TabBar` allow users to switch between different pieces of content using via a tab bar.
 */
export const TabBar = forwardRef<TabBarRefProps, TabBarComponentProps>(
  (
    {
      liProps,
      ulProps,
      selectedOptions,
      selectedTab,
      tabs,
      onChange,
      ...props
    }: TabBarProps,
    forwardedRef,
  ) => (
    <TabBarStyled ref={forwardedRef} {...ulProps} {...props}>
      {tabs?.map((tab) => (
        <TabBarItem
          key={tab.key}
          {...{
            liProps,
            selectedOptions,
            selectedTab,
            tab,
            onChange,
          }}
        />
      ))}
    </TabBarStyled>
  ),
)

export default memo(TabBar)
