/* eslint-disable react/jsx-props-no-spreading */

import { memo } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { grid, GridProps } from 'styled-system'
import css from '@styled-system/css'
import { Box } from 'components/layout/box'
import { DataTableHeaderProps } from '../data-table.types'

const DataTableHeaderStyled = withTheme(
  styled('li')<
    GridProps & {
      fieldsLength: number
      reorderable?: boolean
      endAdornment?: boolean
    }
  >(grid, ({ fieldsLength, reorderable, endAdornment }) =>
    css({
      display: 'grid',
      gridTemplateColumns: `${
        reorderable ? 'max-content' : ''
      } repeat(${fieldsLength}, minmax(200px, 1fr)) ${
        endAdornment ? 'max-content' : ''
      }`,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'borders.borderDefault',
      '.adornment': {
        // width: 24, // @TODO: need to add fit-content support
        paddingLeft: 0,
        paddingRight: 3,
        boxSizing: 'content-box',
      },
    }),
  ),
)

const DataTableCellStyled = withTheme(
  styled(Box)<GridProps & { hasCheckbox?: boolean }>(
    ({ theme: { typography, space }, hasCheckbox }) =>
      css({
        ...typography.caption,
        paddingTop: 2,
        paddingBottom: 2,
        paddingRight: 4,
        paddingLeft: hasCheckbox ? 20 + space[3] : 0,
        alignSelf: 'flex-start',
        color: 'texts.mediumEmphasis',
        '&:last-of-type': {
          paddingRight: 0,
        },
      }),
  ),
)

const DataTableHeader = <T, K>({
  fields,
  reorderable,
  endAdornment,
  hasCheckbox,
}: DataTableHeaderProps<T, K>) => (
  <DataTableHeaderStyled
    fieldsLength={fields.length}
    reorderable={reorderable}
    endAdornment={Boolean(endAdornment)}
  >
    {reorderable && (
      <DataTableCellStyled className="adornment" css={{ width: 20 }} />
    )}
    {fields.map(({ key }, index) => (
      <DataTableCellStyled key={key} hasCheckbox={hasCheckbox && index === 0}>
        {key}
      </DataTableCellStyled>
    ))}
    {endAdornment && (
      <DataTableCellStyled className="adornment" css={{ width: 24 }} />
    )}
  </DataTableHeaderStyled>
)

export default memo(DataTableHeader) as typeof DataTableHeader
