import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'

type InsertBaseFunctionParams = {
  editorInstance: monaco.editor.IStandaloneCodeEditor
  options: {
    source: string
    defaultText: string
    textRegExp: RegExp
    textReplace: RegExp
    setHighlightedText: (selection: string) => string
  }
}

export const INSERT_HEADING = 'markdown-insert-heading'
export const INSERT_BOLD = 'markdown-insert-bold'
export const INSERT_ITALIC = 'markdown-insert-italic'
export const INSERT_QUOTE = 'markdown-insert-quote'
export const INSERT_CODE = 'markdown-insert-code'
export const INSERT_LINK = 'markdown-insert-link'
export const INSERT_ORDER = 'markdown-insert-order-list'
export const INSERT_LIST = 'markdown-insert-unordered-list'
export const CHECK_LIST = 'markdown-insert-check-list'

const markdownMonacoActions = (
  editor: monaco.editor.IStandaloneCodeEditor,
): void => {
  const insertBaseFunction = ({
    editorInstance,
    options,
  }: InsertBaseFunctionParams): void => {
    const model = editorInstance.getModel()
    const range: monaco.Selection | null = editorInstance.getSelection()

    function setEditorFocus(): void {
      const pos = editorInstance.getPosition()
      if (!pos) {
        return
      }

      editorInstance.revealPositionInCenter({
        lineNumber: pos.lineNumber,
        column: pos.column,
      })
      editorInstance.focus()
    }

    if (!model || !range) {
      return
    }

    const getSelection: string = model.getValueInRange(range)
    const baseOperation = { range, forceMoveMarkers: true }

    if (getSelection.trim() === '') {
      editorInstance.executeEdits(options.source, [
        {
          ...baseOperation,
          text: options.defaultText,
        },
      ])

      editor.setPosition({
        lineNumber: model.getLineCount(),
        column: model.getLineMaxColumn(model.getLineCount()),
      })

      setTimeout(() => setEditorFocus(), 100)
      return
    }

    editorInstance.executeEdits(options.source, [
      {
        ...baseOperation,
        text: options.textRegExp.test(getSelection)
          ? getSelection.replace(options.textReplace, '').trim()
          : options.setHighlightedText(getSelection),
      },
    ])

    setTimeout(() => setEditorFocus(), 100)
  }

  editor.addAction({
    id: `${INSERT_HEADING}-action`,
    label: 'Markdown: Heading',
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyH,
    ],
    run: (e: monaco.editor.IStandaloneCodeEditor) =>
      insertBaseFunction({
        editorInstance: e,
        options: {
          source: INSERT_HEADING,
          defaultText: '### Heading ',
          textRegExp: /^#{1,6}(.*?)/g,
          textReplace: /^#{1,6}/g,
          setHighlightedText: (selection) => `### ${selection}`,
        },
      }),
  })

  editor.addAction({
    id: `${INSERT_BOLD}-action`,
    label: 'Markdown: Bold',
    // eslint-disable-next-line no-bitwise
    keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyB],
    run: (e: monaco.editor.IStandaloneCodeEditor): void =>
      insertBaseFunction({
        editorInstance: e,
        options: {
          source: INSERT_BOLD,
          defaultText: '**BOLD** ',
          textRegExp: /^\*\*(.*?)\*\*$/g,
          textReplace: /\*/g,
          setHighlightedText: (selection) => `**${selection}**`,
        },
      }),
  })

  editor.addAction({
    id: `${INSERT_ITALIC}-action`,
    label: 'Markdown: Italic',
    // eslint-disable-next-line no-bitwise
    keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyI],
    run: (e: monaco.editor.IStandaloneCodeEditor): void =>
      insertBaseFunction({
        editorInstance: e,
        options: {
          source: INSERT_ITALIC,
          defaultText: '*ITALIC* ',
          textRegExp: /^\*(.*?)\*$/,
          textReplace: /\*/g,
          setHighlightedText: (selection) => `*${selection}*`,
        },
      }),
  })

  editor.addAction({
    id: `${INSERT_QUOTE}-action`,
    label: 'Markdown: Blockquote',
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Period,
    ],
    run: (e: monaco.editor.IStandaloneCodeEditor): void =>
      insertBaseFunction({
        editorInstance: e,
        options: {
          source: INSERT_QUOTE,
          defaultText: '> QUOTE \n',
          textRegExp: /(>\s)/,
          textReplace: /(>\s)/g,
          setHighlightedText: (selection) => {
            const trimWhiteSpace = selection.replace(/\s\n/g, '\n').split('\n')
            if (trimWhiteSpace.length > 1) {
              const quoteBlock = trimWhiteSpace
                .map((line) => `> ${line}\n`)
                .join('')
              return `${quoteBlock}\n`
            }
            return `\n> ${selection}\n`
          },
        },
      }),
  })

  editor.addAction({
    id: `${INSERT_CODE}-action`,
    label: 'Markdown: Code',
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Comma,
    ],
    run: (e: monaco.editor.IStandaloneCodeEditor): void =>
      insertBaseFunction({
        editorInstance: e,
        options: {
          source: INSERT_CODE,
          defaultText: '`CODE` ',
          textRegExp: /^`(.*?)`$/,
          textReplace: /`/g,
          setHighlightedText: (selection) => `\`${selection}\``,
        },
      }),
  })

  editor.addAction({
    id: `${INSERT_LINK}-action`,
    label: 'Markdown: Link',
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyL,
    ],
    run: (e: monaco.editor.IStandaloneCodeEditor): void =>
      insertBaseFunction({
        editorInstance: e,
        options: {
          source: INSERT_LINK,
          defaultText: '[Link](#) ',
          textRegExp: /^([^)]+\))$/g,
          textReplace: /[[|\]]|\(([^()]+)\)/g,
          setHighlightedText: (selection) => `[${selection}](#)`,
        },
      }),
  })

  editor.addAction({
    id: `${INSERT_LIST}-action`,
    label: 'Markdown: Unordered List',
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyU,
    ],
    run: (e: monaco.editor.IStandaloneCodeEditor): void =>
      insertBaseFunction({
        editorInstance: e,
        options: {
          source: INSERT_LIST,
          defaultText: '- List Item \n',
          textRegExp: /(\n?-\s)/g,
          textReplace: /(-\s)/g,
          setHighlightedText: (selection) => {
            const trimWhiteSpace = selection.replace(/\s\n/g, '\n').split('\n')
            if (trimWhiteSpace.length > 1) {
              const listBlock = trimWhiteSpace
                .map((line) => `- ${line}\n`)
                .join('')
              return `${listBlock}- `
            }
            return `- ${selection}`
          },
        },
      }),
  })

  editor.addAction({
    id: `${INSERT_ORDER}-action`,
    label: 'Markdown: Ordered List',
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyO,
    ],
    run: (e: monaco.editor.IStandaloneCodeEditor): void =>
      insertBaseFunction({
        editorInstance: e,
        options: {
          source: INSERT_ORDER,
          defaultText: '1. List Item \n',
          textRegExp: /(\n?\d{1,100}\.\s)/g,
          textReplace: /(\d{1,100}\.\s)/g,
          setHighlightedText: (selection) => {
            const trimWhiteSpace = selection.replace(/\s\n/g, '\n').split('\n')
            if (trimWhiteSpace.length > 1) {
              const listBlock = trimWhiteSpace
                .map((line, index) => `\n${index + 1}. ${line}`)
                .join('')
              return `${listBlock} `
            }
            return `\n1. ${selection}`
          },
        },
      }),
  })
}

export default markdownMonacoActions
