/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { SetCurrentTabAction, TabsState } from './types.d'

const initialState: TabsState = {
  tabSystems: {},
}

const tabsStateSlice = createSlice({
  name: 'tabsState',
  initialState,
  reducers: {
    setCurrentTab: (
      state: TabsState,
      action: PayloadAction<SetCurrentTabAction>,
    ) => {
      /*
       * Save preference for all tabs hierarchically,
       * for example identifier "requests/UUID" will
       * save preference for "requests" alone and
       * "requests/UUID". This way, the setting
       * will apply for all requests if nothing specific
       * is set for another request.
       */
      const { identifier, currentTab } = action.payload
      const identifierSplit = identifier.split('/')
      for (let i = 0; i < identifierSplit.length; i += 1) {
        const subId = identifierSplit
          .slice(0, identifierSplit.length - i)
          .map((slice) => slice.trim())
          .join('/')
        if (!state.tabSystems[subId]) {
          state.tabSystems[subId] = { currentTab: null }
        }
        state.tabSystems[subId].currentTab = currentTab
      }
    },
  },
})

export default tabsStateSlice
