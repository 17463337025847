import type { Dispatch } from 'react'
import type * as _monaco_ from 'monaco-editor/esm/vs/editor/editor.api'
import type { Monaco } from '@monaco-editor/react'
import type { PanelState } from 'store/types.d'
import {
  hkToggleCheatSheet,
  hkCreateRequest,
  hkCreateRequestGroup,
  hkDuplicateRequest,
  hkSendRequest,
  hkDeleteRequest,
  hkToggleCodeGenerator,
  hkUndoAction,
  hkRedoAction,
  hkCloseDialog,
} from 'utils'
import {
  redoAction,
  sendCurrentRequestAction,
  settingsStateActions,
  undoAction,
  userActionAddRequestAction,
  userActionAddRequestGroupAction,
  userActionDeleteCurrentRequestTreeItemAction,
  userActionDuplicateRequestAction,
} from 'store/actions'

export default function editorKeyBindings(
  editor: _monaco_.editor.IStandaloneCodeEditor,
  monaco: Monaco,
  dispatch: Dispatch<unknown>,
  panel: PanelState,
): void {
  editor.addAction({
    ...hkToggleCheatSheet.monaco,
    run: (_editor: _monaco_.editor.ICodeEditor) => {
      _editor.focus()
      dispatch(settingsStateActions.toggleHotkeyCheatsheet(true))
    },
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Slash,
    ],
  })

  editor.addAction({
    ...hkCloseDialog.monaco,
    run: (_editor: _monaco_.editor.ICodeEditor) => {
      _editor.focus()
      dispatch(settingsStateActions.toggleHotkeyCheatsheet(false))
    },
    keybindings: [monaco.KeyCode.Escape],
  })

  editor.addAction({
    ...hkCreateRequest.monaco,
    run: () => dispatch(userActionAddRequestAction({})),
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyK,
    ],
  })

  editor.addAction({
    ...hkCreateRequestGroup.monaco,
    run: () => dispatch(userActionAddRequestGroupAction({})),
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyG,
    ],
  })

  editor.addAction({
    ...hkDuplicateRequest.monaco,
    run: () => dispatch(userActionDuplicateRequestAction({})),
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyD,
    ],
  })

  editor.addAction({
    ...hkSendRequest.monaco,
    run: () => dispatch(sendCurrentRequestAction()),
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyR,
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Enter,
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyR,
    ],
  })

  editor.addAction({
    ...hkDeleteRequest.monaco,
    run: () => dispatch(userActionDeleteCurrentRequestTreeItemAction()),
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Backspace,
    ],
  })

  editor.addAction({
    ...hkToggleCodeGenerator.monaco,
    run: () =>
      dispatch(
        settingsStateActions.setPanel({ ...panel, leftPaneBotHeight: 0 }),
      ),
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyC,
    ],
  })

  editor.addAction({
    ...hkUndoAction.monaco,
    run: () => dispatch(undoAction()),
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyZ,
    ],
  })

  editor.addAction({
    ...hkRedoAction.monaco,
    run: () => dispatch(redoAction()),
    keybindings: [
      // eslint-disable-next-line no-bitwise
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KeyZ,
    ],
  })
}
