// Experemental collection of Rich Text Editor helpers

import { RefObject } from 'react'
import { setCaret } from '../../../../utils'
import { DynamicFieldDynamicValues } from '../dynamic-field.types'

interface InsertHTMLProps {
  id?: string
  key?: string
  content?: string
  className?: string
  start?: number
  end?: number
  contentEditable?: boolean
}

export interface CaretRelativePosition {
  left: number
  bottom: number
}

export const EMPTY_SPACE = '\u200b'
export const SPACE = '\u00A0'

export const getNodeIndex = (node: Node | null): number | null =>
  node?.parentNode
    ? Array.prototype.indexOf.call(node.parentNode.childNodes, node)
    : null

export const cleanEditorValue = (value: string | null | undefined): string =>
  value?.replace(new RegExp(`[${SPACE}|${EMPTY_SPACE}]`, 'g'), '').trim() ?? ''

// Return relative coordinates of the caret if any
export const getCaretRelativePosition = (
  range: Range,
): CaretRelativePosition | undefined => {
  const childRect = range.getClientRects()[0]
  const parentRect =
    range.startContainer.parentElement?.getBoundingClientRect() ?? {
      left: 0,
      bottom: 0,
    }

  if (!childRect || !parentRect) {
    return undefined
  }

  return {
    left: childRect.left - parentRect.left,
    bottom: childRect.bottom - parentRect.bottom,
  }
}

export type SelectedNode = {
  offset: number
  node: Node
}

export default class RichTextEditorClass {
  constructor(private ref: RefObject<HTMLDivElement>) {}

  public getEditor(): HTMLDivElement | null | undefined {
    return this.ref?.current
  }

  // eslint-disable-next-line class-methods-use-this
  public getSelection(): Selection | null {
    return ((this.getEditor()?.getRootNode() as any) ?? window)?.getSelection()
  }

  // Check if Editor has no actual value or not
  public isEmpty(): boolean {
    return cleanEditorValue(this.getEditor()?.textContent) === ''
  }

  // Return current caret range if any
  public getCaretRange(): Range | undefined {
    const selection = this.getSelection()
    if (!selection || selection.rangeCount === 0) {
      return undefined
    }
    const range = selection.getRangeAt(0).cloneRange()
    range.collapse(true)
    return range
  }

  // Handle Paste event
  public pasteToEditor(content: string): void {
    const { nodeFrom, nodeTo } = this.getSelectedNodes() ?? {}
    const selection = this.getSelection()

    if (!(nodeFrom && nodeTo && selection)) {
      return
    }

    if (nodeFrom.node === nodeTo.node) {
      const range = selection.getRangeAt(0)
      range.deleteContents()
      range.insertNode(this.createNode({ content }))
      return
    }

    if (nodeFrom.node !== nodeTo.node) {
      const isMultilineSelect =
        nodeFrom.node.parentNode !== nodeTo.node.parentNode

      const nodesFragment = document.createDocumentFragment()
      const paragraphElement = document.createElement('p')
      const nodeContainer = isMultilineSelect ? paragraphElement : nodesFragment

      nodeContainer.appendChild(
        this.createNode({
          content: nodeFrom.node.textContent?.substr(0, nodeFrom.offset),
        }),
      )

      nodeContainer.appendChild(this.createNode({ content }))

      nodeContainer.appendChild(
        this.createNode({
          content: nodeTo.node.textContent?.substr(nodeTo.offset),
        }),
      )

      if (isMultilineSelect) {
        nodesFragment.appendChild(nodeContainer)
      }

      const range = selection.getRangeAt(0)
      range.deleteContents()

      const removeNode = isMultilineSelect
        ? nodeTo.node?.parentNode
        : nodeTo.node
      if (removeNode?.parentNode) {
        removeNode.parentNode.removeChild(removeNode)
      }
      const replaceNode = isMultilineSelect
        ? nodeFrom.node?.parentNode
        : nodeFrom.node
      if (replaceNode?.parentNode) {
        replaceNode.parentNode.replaceChild(nodesFragment, replaceNode)
      }
    }
  }

  // Handle Copy event
  public copySelectedToClipboard(): void {
    const { nodeFrom, nodeTo } = this.getSelectedNodes() ?? {}

    if (!(nodeFrom && nodeTo)) {
      throw new Error('Failed to copy')
    }

    let copiedString

    // Copy selection if selected within the same node
    if (nodeFrom.node === nodeTo.node) {
      copiedString = this.getSelection()?.toString()
    }

    if (copiedString === undefined) {
      let currentNode = nodeFrom.node as ChildNode | null | undefined
      copiedString = currentNode?.textContent?.substr(nodeFrom.offset)

      // Look for content of selected nodes
      while (currentNode !== nodeTo.node && currentNode !== null) {
        if (currentNode?.nodeName.toLowerCase() === 'span') {
          copiedString +=
            (currentNode as HTMLSpanElement).getAttribute('data-id') ?? ''
        } else if (currentNode !== nodeFrom.node) {
          copiedString += currentNode?.textContent ?? ''
        }

        // Proceed to the next paragrpah if selected multiple lines
        if (currentNode?.nextSibling === null) {
          copiedString += '\n'
          currentNode = currentNode?.parentNode?.nextSibling?.firstChild
        } else {
          currentNode = currentNode?.nextSibling
        }

        if (currentNode === nodeTo.node) {
          copiedString +=
            nodeTo.node.textContent?.substr(0, nodeTo.offset) ?? ''
        }
      }
    }

    navigator.clipboard?.writeText(copiedString ?? '')
  }

  // Multiline support
  public splitParagraphs(): void {
    const selection = this.getSelection()
    const { focusNode, focusOffset } = selection ?? {}

    if (
      !(
        selection &&
        focusNode &&
        focusNode.parentNode &&
        focusOffset !== undefined
      )
    ) {
      return
    }

    const editor = focusNode.parentNode.parentNode
    if (!editor) {
      return
    }

    const focusNodeIndex = Array.prototype.indexOf.call(
      focusNode.parentNode?.childNodes,
      focusNode,
    )

    const newContent = document.createDocumentFragment()
    const p1 = document.createElement('p')
    const p2 = document.createElement('p')

    const { textContent } = focusNode

    let suffix: string | undefined
    const nodes = focusNode.parentNode.childNodes
    nodes.forEach((node, index) => {
      if (index === focusNodeIndex && textContent) {
        const prefix = textContent.substr(0, focusOffset)
        p1.appendChild(
          document.createTextNode(prefix === '' ? EMPTY_SPACE : prefix),
        )
        suffix = textContent.substr(focusOffset)

        if (focusNodeIndex === nodes.length - 1) {
          p2.appendChild(
            document.createTextNode(suffix === '' ? EMPTY_SPACE : suffix),
          )
        }
      } else if (index < focusNodeIndex) {
        p1.appendChild(node.cloneNode(true))
      } else if (index > focusNodeIndex) {
        if (suffix) {
          p2.appendChild(document.createTextNode(suffix))
          suffix = undefined
        }
        p2.appendChild(node.cloneNode(true))
      }
    })

    newContent.appendChild(p1)
    newContent.appendChild(p2)
    editor.replaceChild(newContent, focusNode.parentNode)

    if (p2.firstChild) {
      setCaret(p2.firstChild, 0)
    }
  }

  // Create DOM node
  // eslint-disable-next-line class-methods-use-this
  public createNode({
    id,
    key,
    className = 'text',
    content = EMPTY_SPACE,
    contentEditable = true,
  }: InsertHTMLProps = {}): HTMLSpanElement | Text {
    if (className === 'text') {
      return document.createTextNode(content)
    }
    const element = document.createElement('span')
    element.innerText = content
    element.className = className
    if (id) {
      element.dataset.id = id
    }
    if (key) {
      element.dataset.key = key
    }
    if (!contentEditable) {
      element.contentEditable = 'false'
      element.spellcheck = false
    } else if (content === EMPTY_SPACE) {
      const textNode = document.createTextNode(EMPTY_SPACE)
      element.appendChild(textNode)
    }
    return element
  }

  public isNextEmptySpace(): ChildNode | null | undefined {
    const { focusNode, focusOffset } = this.getSelection() ?? {}
    const isEmptySpace =
      focusNode?.nodeName === '#text' &&
      focusNode.textContent === EMPTY_SPACE &&
      focusOffset === 0
    return isEmptySpace ? (focusNode as ChildNode) : undefined
  }

  public isPrevEmptySpace(): ChildNode | null | undefined {
    const { focusNode, focusOffset } = this.getSelection() ?? {}
    const isEmptySpace =
      focusNode?.nodeName === '#text' &&
      focusNode.textContent === EMPTY_SPACE &&
      focusOffset === 1
    return isEmptySpace ? (focusNode as ChildNode) : undefined
  }

  public getSelectedNodes():
    | { nodeFrom: SelectedNode; nodeTo: SelectedNode }
    | undefined {
    const {
      anchorNode,
      anchorOffset = 0,
      focusNode,
      focusOffset = 0,
    } = this.getSelection() ?? {}
    if (!(anchorNode && focusNode)) {
      return undefined
    }

    if (anchorNode.nodeName === 'P' || focusNode.nodeName === 'P') {
      throw new Error('Failed to get selected nodes')
    }

    if (anchorNode === focusNode) {
      const node = {
        offset: anchorOffset,
        node: anchorNode,
      }
      return {
        nodeFrom: node,
        nodeTo: node,
      }
    }

    let focusNodeIndex = getNodeIndex(focusNode)
    let anchorNodeIndex = getNodeIndex(anchorNode)
    if (focusNodeIndex === 0 && anchorNodeIndex === 0) {
      focusNodeIndex = getNodeIndex(focusNode?.parentNode)
      anchorNodeIndex = getNodeIndex(anchorNode?.parentNode)
    }

    if (anchorNodeIndex === null || focusNodeIndex === null) {
      throw new Error('Failed to parse dynamic field string')
    }

    if (anchorNodeIndex < focusNodeIndex) {
      return {
        nodeFrom: {
          offset: anchorOffset,
          node: anchorNode,
        },
        nodeTo: {
          offset: focusOffset,
          node: focusNode,
        },
      }
    }

    return {
      nodeFrom: {
        offset: focusOffset,
        node: focusNode,
      },
      nodeTo: {
        offset: anchorOffset,
        node: anchorNode,
      },
    }
  }

  public parseToString(): string {
    const editor = this.getEditor()
    if (!editor) {
      return ''
    }

    const paragraphArray: string[] = []
    if (editor.firstChild?.nodeName.toLowerCase() === 'div') {
      return editor.firstChild.textContent ?? ''
    }

    const containerCopy = editor.cloneNode(true) as HTMLDivElement
    containerCopy.childNodes.forEach((paragraph: Node) => {
      if (paragraph instanceof HTMLElement) {
        paragraph.querySelectorAll('span').forEach((span) => {
          paragraph?.replaceChild(
            document.createTextNode(span.getAttribute('data-id') ?? ''),
            span,
          )
        })
      }
      paragraphArray.push(cleanEditorValue(paragraph.textContent) ?? '')
    })
    return paragraphArray.join('\n')
  }

  public updateDynamicValues(dynamicValues: DynamicFieldDynamicValues): void {
    const editor = this.getEditor()
    if (!editor) {
      return
    }

    editor.querySelectorAll('span.dynamic-value').forEach((span) => {
      const id: string | null = span.getAttribute('data-id')
      const dynamicValue = id ? dynamicValues[id] : undefined
      if (dynamicValue) {
        // eslint-disable-next-line no-param-reassign
        span.textContent = dynamicValue
      }
    })
  }

  public cleanUpHTML({
    editor: editorProp,
  }: {
    editor?: HTMLDivElement | null
  } = {}): void {
    const editor = editorProp ?? this.getEditor()

    if (!editor) {
      return
    }

    const { firstChild } = editor

    // Wrap up editor content in the newly created paragraph in case if there is none
    // Scenario: select all content and overwrite it by typing any text
    if (firstChild && firstChild.nodeName !== 'P') {
      const { textContent } = firstChild
      const paragraphElement = document.createElement('p')
      const nodeContent =
        textContent && textContent.trim().length > 0 ? textContent : undefined
      paragraphElement.appendChild(
        document.createTextNode(nodeContent ?? EMPTY_SPACE),
      )
      editor.replaceChild(paragraphElement, firstChild)

      setCaret(paragraphElement.firstChild, nodeContent?.length ?? 0)
    }

    editor.childNodes.forEach((node) => {
      const nodeName = node.nodeName.toLowerCase()

      // Remove any elements except span and text values
      node.childNodes.forEach((childNode) => {
        const childNodeName = childNode.nodeName.toLowerCase()

        if (!['#text', 'span'].includes(childNodeName)) {
          node.removeChild(childNode)
        }

        if (childNodeName === 'span') {
          const { classList, textContent } = childNode as HTMLSpanElement
          if (
            !(
              classList.contains('dynamic-value') ||
              classList.contains('popover-anchor')
            )
          ) {
            node.replaceChild(
              document.createTextNode(
                cleanEditorValue(textContent) ?? EMPTY_SPACE,
              ),
              childNode,
            )
          }
        }
      })

      node.childNodes.forEach((childNode, index, arr) => {
        const childNodeName = childNode.nodeName.toLowerCase()

        // Add an empty text node before span.dynamic-value
        if (index === 0 && childNodeName === 'span') {
          node.insertBefore(this.createNode(), childNode)
        }

        // Put an empty text node between neighbored span.dynamic-value
        if (
          childNodeName === 'span' &&
          childNode.nextSibling?.nodeName.toLowerCase() === 'span'
        ) {
          node.insertBefore(this.createNode(), childNode.nextSibling)
        }

        // If span.dynamic-value is the last child node - add an empty text node after
        if (childNodeName === 'span' && index === arr.length - 1) {
          node.appendChild(this.createNode())
        }
      })
      node.normalize()

      if (nodeName === 'br' && node.parentNode) {
        node.parentNode.removeChild(node)
      }
    })

    if (
      editor.childNodes.length === 1 &&
      firstChild &&
      firstChild.nodeName === 'P' &&
      firstChild.textContent?.length === 0
    ) {
      const textNode = this.createNode({ content: EMPTY_SPACE })
      firstChild.appendChild(textNode)
      setCaret(textNode, 0)
    }

    // Add default text node if empty
    if (editor.childNodes.length === 0) {
      const paragraphElement = document.createElement('p')
      paragraphElement.appendChild(this.createNode({ content: EMPTY_SPACE }))
      editor.appendChild(paragraphElement)
    }
  }
}
