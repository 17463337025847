import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Notification,
} from '@rapidapi/ui-lib'
import { useHistory } from 'react-router-dom'
import type { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { useRenameProject } from 'lib-hooks'

interface RenameProjectDialogProps {
  isDialogOpen: boolean
  onDialogClose: (didDelete?: boolean) => void
  project: CloudAPI.ProjectInfoResponse
}

const RenameProjectDialog: React.FC<RenameProjectDialogProps> = ({
  isDialogOpen,
  onDialogClose,
  project,
}) => {
  // project name
  const [projectName, setProjectName] = useState(project.name)

  // rename project
  const {
    start,
    status: renameProjectStatus,
    response: renameProjectResponse,
    error: renameProjectError,
  } = useRenameProject()
  const onSubmit = useCallback(() => {
    if (!project.id || !projectName.trim()) {
      return
    }
    start(project.id, projectName)
  }, [projectName, start, project])

  // redirect once project is renamed
  const history = useHistory()
  useEffect(() => {
    if (
      renameProjectResponse &&
      renameProjectResponse.id &&
      renameProjectStatus === 'succeeded'
    ) {
      onDialogClose(true)
    }
  }, [history, renameProjectStatus, renameProjectResponse, onDialogClose])

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onDialogClose}
      width="30vw"
      height="auto"
      minWidth={300}
      maxWidth={900}
      animation="none"
    >
      <DialogTitle onClose={onDialogClose} pl={1} divider>
        Rename Project
      </DialogTitle>
      <DialogContent p={0} mb={-2}>
        <Box>
          {renameProjectStatus === 'failed' && renameProjectError && (
            <Notification
              width="100%"
              icon="alert"
              variant="error"
              title="Failed to rename project"
              m={0}
              mb={3}
            >
              {renameProjectError?.message || false}
            </Notification>
          )}
          <TextField
            my={2}
            width="100%"
            label="Project Name"
            value={projectName}
            onChange={setProjectName}
          />
          <Box mt={3} mb={2} alignItems="center">
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={!projectName.trim()}
              loading={renameProjectStatus === 'loading'}
            >
              Rename Project
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default RenameProjectDialog
