/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import type {
  DynamicFieldProps,
  DynamicFieldSuggestion,
  IconSymbol,
} from '@rapidapi/ui-lib'
import type { Project } from 'lib/project'
import { getAllSuggestions, getImplementation } from 'lib/dynamic-values'
import { getUuid } from 'lib/utils'
import { applyProjectSetter } from 'store/actions'
import {
  mapDynamicFieldValueToStrings,
  parseTokenId,
  mapDynamicStringToFieldValue,
  useTokenInfoArray,
} from './helpers'
import DynamicStringInput from './dynamic-string-input'
import { emptyTokenDict } from './helpers/use-token-info-array'

type DynamicStringInlineEditorProps = DynamicFieldProps & {
  inputValue: string
  setInputValue: (str: string) => void
  label?: string
  hint?: string
  hintIcon?: IconSymbol
  autocompleteCategory?:
    | 'LMCompletionCategoryHTTPRequestHeaders'
    | 'LMCompletionCategoryHTTPRequestHeadersValue'
    | 'LMCompletionCategoryHTTPRequestParameters'
}

const DynamicStringInlineEditor: React.FC<DynamicStringInlineEditorProps> = ({
  inputValue,
  setInputValue,
  ...other
}) => {
  const [stringValue, dynamicValueRefs] = useMemo(() => {
    const dynamicString: Project.DynamicString = {
      strings: mapDynamicFieldValueToStrings(inputValue),
      type: 'dynamicString',
      uuid: '',
    }
    return mapDynamicStringToFieldValue(dynamicString)
  }, [inputValue])
  const dynamicValues = useTokenInfoArray(dynamicValueRefs)

  const dispatch = useDispatch()

  const suggestions = useMemo(() => getAllSuggestions(), [])
  const onChange = useCallback(
    (
      value: string | undefined,
      insertedSuggestion?: DynamicFieldSuggestion | undefined,
    ) => {
      let insertDynamicValues: Project.DynamicValue[] = []
      if (insertedSuggestion) {
        const insertedSuggestionId = parseTokenId(insertedSuggestion.id)
        const suggestion =
          insertedSuggestionId &&
          suggestions.find(({ id }) => id === insertedSuggestionId.ref)
        const insertedSuggestionNewUuid = insertedSuggestion.newId ?? getUuid()
        if (suggestion) {
          const impl = getImplementation({
            uuid: insertedSuggestionNewUuid,
            identifier: suggestion.dvIdentifier,
            type: 'dynamicValue',
          })
          insertDynamicValues = [
            {
              ...impl?.defaultValue,
              uuid: insertedSuggestionNewUuid,
              identifier: suggestion.dvIdentifier,
              type: 'dynamicValue',
            },
          ]
        }
      }

      if (insertDynamicValues.length > 0) {
        dispatch(
          applyProjectSetter('insertDynamicValues')({
            dynamicValues: insertDynamicValues,
          }),
        )
      }
      setInputValue(value || '')
    },
    [dispatch, suggestions, setInputValue],
  )

  return (
    <DynamicStringInput
      onChange={onChange}
      dynamicValues={dynamicValues || emptyTokenDict}
      stringValue={stringValue}
      {...other}
      suggestions={suggestions}
    />
  )
}

export default DynamicStringInlineEditor
