// @TODO: NOW
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Select, Typography } from '@rapidapi/ui-lib'
import type { SpaceProps } from 'styled-system'

import type { Project } from 'lib'
import { setProjectValue } from 'store/actions'
import { selectAllRequests } from 'store/selectors'
import { useAnyObjectProperty, useCurrentRequest } from 'utils/hooks'

type RequestPickerEditorProps<T extends Project.AnyObject> = SpaceProps & {
  objectRef: Project.GenericRef<T>
  objectProperty: keyof T
  label?: string
}

type RequestPickerEditorReturnType = React.ReactElement<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> | null

type SelectProps = React.ComponentProps<typeof Select>
const RequestPickerEditor = <T extends Project.AnyObject>({
  label,
  objectRef,
  objectProperty,
}: RequestPickerEditorProps<T>): RequestPickerEditorReturnType => {
  const requests = useSelector(selectAllRequests)

  const selectItems = useMemo(
    () => requests.map(({ uuid }) => uuid),
    [requests],
  )

  const selectItemDataSelector: SelectProps['itemDataSelector'] = useCallback(
    (id?: string | null) => {
      const request = requests.find(({ uuid }) => uuid === id)
      if (!request) {
        return null
      }

      return {
        id: request.uuid,
        label: request.title,
      }
    },
    [requests],
  )

  const itemRender: SelectProps['itemRender'] = useCallback(
    (item) => <Box css={{ minWidth: 100 }}>{item?.label}</Box>,
    [],
  )

  const currentRequestUuid = useCurrentRequest()?.uuid
  const dvRequestUuid = useAnyObjectProperty(
    objectRef,
    objectProperty,
    'string' /* expect */,
    true /* allowsNull */,
  ) as string | null
  const selectedRequestUuid = dvRequestUuid || currentRequestUuid

  const dispatch = useDispatch()
  const onChange = useCallback(
    (newRequestUuid?: string | null) => {
      if (newRequestUuid) {
        dispatch(
          setProjectValue({
            objectRef,
            update: {
              [objectProperty]: newRequestUuid,
            },
          }),
        )
      }
    },
    [dispatch, objectProperty, objectRef],
  )

  return (
    <Box>
      {label && (
        <Typography variant="body2" mb={1} color="highEmphasis">
          {label}
        </Typography>
      )}
      <Select
        itemDataSelector={selectItemDataSelector}
        onChange={onChange}
        items={selectItems}
        itemRender={itemRender}
        value={selectedRequestUuid}
      />
    </Box>
  )
}

export default RequestPickerEditor
