export const JSON_TREE_SIZE_LIMIT = 20 * 1024 // in bytes, 20 kB
export const JSON_TREE_MESSAGE_ACTION_TEXT = 'Show JSON Text'
export const JSON_TREE_MESSAGE_SIZE_ERROR =
  'Paw cannot display this JSON tree, you can instead view it as text.'

export const JSON_TEXT_SIZE_LIMIT = 10 * 1024 * 1024 // in bytes, 10 MB
export const JSON_TEXT_MESSAGE_ACTION_TEXT = 'Download JSON File'
export const JSON_TEXT_MESSAGE_SIZE_ERROR =
  'Paw cannot display this JSON text, you can instead download the file.'

export const JSON_TAB_MESSAGE_SIZE_TITLE = 'JSON data is too large'
export const JSON_TAB_MESSAGE_EMPTY_TITLE = 'Empty body'
export const JSON_TAB_MESSAGE_INVALID_TITLE = 'Invalid JSON'

export const JSON_TAB_MESSAGE_INVALID_BODY = (
  messageType: 'request' | 'response',
): string =>
  messageType === 'request'
    ? 'The request body contains invalid JSON data'
    : 'The response body contains invalid JSON data'

export const JSON_TAB_MESSAGE_EMPTY_BODY = (
  messageType: 'request' | 'response',
): string =>
  messageType === 'request'
    ? 'The request body is empty, there is no JSON to be shown'
    : 'The response body is empty, there is no JSON to be shown'
