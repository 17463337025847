import type { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import type { Project } from 'lib/project/types.d'
import { serializeObjectRefArray } from 'lib/cloud-sync/serializer/helpers'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

import serializeEnvironmentDomain from './serialize-environment-domain'
import serializeSession from './serialize-session'

const serializeProjectRoot = (
  serializer: CloudSerializer.ProjectSerializer,
  object: Project.Project,
): CloudAPI.SyncTreeRoot => ({
  // note: unfortunately, Paw Mac wasn't consistent and used `uuid` for projects
  // while using `_uuid` for other objects
  uuid: object.uuid,
  encryptionKeyHash: object.encryptionKeyHash,
  requests: serializeObjectRefArray(serializer, object.requests),
  environmentDomains: serializeObjectRefArray(
    serializer,
    object.environmentDomains,
    serializeEnvironmentDomain,
  ),
  sessions: serializeObjectRefArray(
    serializer,
    object.sessions,
    serializeSession,
  ),
})

export default serializeProjectRoot
