import type { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'
import addObject from './add-object'
import { getEnvironmentDomain } from '../getters'

export interface AddEnvironmentArgs {
  domainRef: Project.GenericRef<Project.EnvironmentDomain>
  uuid?: string
  name?: string
  useNumName?: boolean
}

const addEnvironment = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { domainRef, uuid, name, useNumName }: AddEnvironmentArgs,
): Project.GenericRef<Project.Environment> => {
  // get the domain
  const domain = getEnvironmentDomain(domainRef, objects, false)

  // name
  let newName = name
  if (!newName && useNumName) {
    newName = `Environment ${domain.environments.length + 1}`
  }

  // add a new environment
  const environment: Project.Environment = {
    uuid: uuid || getUuid(),
    type: 'environment',
    name: newName || 'New Environment',
    domain: domainRef,
  }
  const ref = addObject(objects, environment)

  // add environment to the domain
  domain.environments.push(ref)

  // for each variable, add a new value for this newly create environment
  domain.variables.forEach((variableRef) => {
    // add dynamic string for the value (an empty new string)
    const dynamicStringValue: Project.DynamicString = {
      uuid: getUuid(),
      type: 'dynamicString',
      strings: [''],
    }
    const dynamicStringRef = addObject(objects, dynamicStringValue)

    // add a new value
    const environmentVariableValue: Project.EnvironmentVariableValue = {
      uuid: getUuid(),
      type: 'environmentVariableValue',
      value: dynamicStringRef,
      environment: ref,
      variable: variableRef,
      domain: environment.domain,
    }
    addObject(objects, environmentVariableValue)
  })

  return ref
}

export default addEnvironment
