import type {
  AppUpdateStatusType,
  AppUpdateNotifierState,
} from 'store/slices/ui/settings/types.d'
import type { IconSymbol } from '@rapidapi/ui-lib'

declare global {
  interface Window {
    electropaw: {
      send: <T, R>(channel: string, data?: T) => Promise<R>
      invoke: <C, T>(channel: C, data?: T) => Promise<void> | void
      receive: <T, R>(
        channel: string,
        fn: (...args: T[]) => void,
      ) => Promise<R> | R
      appUpdate: <C, R>(channel: C) => Promise<R>
      removeListeners: <C>(channel: C) => void
    }
  }
}

export type UseAppUpdateHooksType = {
  checkForUpdates: (e: React.MouseEvent) => void
  downloadUpdate: (e: React.MouseEvent) => void
  applyUpdate: (e: React.MouseEvent) => void
  retryUpdate: (e: React.MouseEvent) => void
  dismiss: () => void
  ipcRenderer: typeof window.electropaw
}

export type AppUpdateViewSwitcherType = AppUpdateNotifierState &
  Omit<UseAppUpdateHooksType, 'ipcRenderer'>

export type AppUpdateViewComponentType = {
  open?: boolean
  version?: string
  icon?: IconSymbol
  message?: string
  buttonText?: string
  action?: (e: React.MouseEvent) => void
  cancel?: (e: React.MouseEvent) => void
}

export const appUpdateStatus: { [key: string]: AppUpdateStatusType } = {
  VIEW_UPDATE_AVAILABLE: 'updateAvailable',
  VIEW_UPDATE_NOT_AVAILABLE: 'updateNotAvailable',
  VIEW_UPDATE_DOWNLOADING: 'updateDownloading',
  VIEW_UPDATE_DOWNLOADED: 'updateDownloaded',
  VIEW_UPDATE_ERROR: 'updateError',
  VIEW_UPDATE_INSTALLED: 'updateInstalled',
}

export type IPCRendererChannelType =
  | 'checkForUpdate'
  | 'downloadUpdate'
  | 'downloadingUpdate'
  | 'downloadedUpdate'
  | 'relaunchToUpdate'
  | 'setReminder'
  | 'updateError'

export const ipcRendererChannel: { [key: string]: IPCRendererChannelType } = {
  CHNL_CHECK_UPDATE: 'checkForUpdate',
  CHNL_DOWNLOAD_UPDATE: 'downloadUpdate',
  CHNL_DOWNLOADING_UPDATE: 'downloadingUpdate',
  CHNL_DOWNLOADED_UPDATE: 'downloadedUpdate',
  CHL_SET_REMINDER: 'setReminder',
  CHNL_UPDATE_ERROR: 'updateError',
  CHNL_RELAUNCH: 'relaunchToUpdate',
}
