/* eslint-disable react/jsx-props-no-spreading */

import {
  useEffect,
  createRef,
  ReactElement,
  useState,
  MouseEvent,
  FocusEvent,
  forwardRef,
  MutableRefObject,
} from 'react'
import { createPortal } from 'react-dom'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import css from '@styled-system/css'
import { Box } from 'components/layout/box'
import { debounce } from 'ts-debounce'
import { usePortalRoot } from 'components/feedback/dialog/dialog-context'

export const popoverPlacements = [
  'top-start',
  'top',
  'top-end',
  'right-start',
  'right',
  'right-end',
  'bottom-start',
  'bottom',
  'bottom-end',
  'left-start',
  'left',
  'left-end',
  'hover',
]

export type PopoverPlacements =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-start'
  | 'bottom'
  | 'bottom-end'
  | 'left-start'
  | 'left'
  | 'left-end'
  | 'hover'

export type PopoverProps = {
  maxWidth?: number
  placement?: PopoverPlacements
  elevation?: number
  open?: boolean
  smartPositioning?: boolean
  anchorEl?: Element | null
  onClose?: () => void
  onMouseOver?: (event: MouseEvent<HTMLDivElement>) => void
  onMouseOut?: (event: MouseEvent<HTMLDivElement>) => void
  onFocus?: (event: FocusEvent) => void
  onBlur?: (event: FocusEvent) => void
  children?: ReactElement | string
  marginTop?: number
  marginLeft?: number
}

const PopoverStyled = withTheme(
  styled(Box)<
    PopoverProps & {
      anchorElProps: DOMRect
      refProps?: DOMRect
    }
  >(
    ({ refProps, marginTop, marginLeft }) =>
      css({
        position: 'absolute',
        visibility: refProps ? 'visible' : 'hidden',
        zIndex: 9500,
        marginTop: marginTop ? `${marginTop}px` : undefined,
        marginLeft: marginLeft ? `${marginLeft}px` : undefined,
      }),
    ({ anchorElProps, refProps, elevation = 5 }) =>
      refProps
        ? css({
            '&.hover': {
              top: anchorElProps.top + window.scrollY,
              left: anchorElProps.left + window.scrollX,
            },
            '&[class^="top"]': {
              top:
                anchorElProps.top +
                window.scrollY -
                refProps.height -
                elevation,
              '&.top': {
                left:
                  anchorElProps.left +
                  window.scrollX +
                  anchorElProps.width / 2 -
                  refProps.width / 2,
              },
              '&.top-start': { left: anchorElProps.left + window.scrollX },
              '&.top-end': {
                left:
                  anchorElProps.left +
                  window.scrollX -
                  refProps.width +
                  anchorElProps.width,
              },
            },
            '&[class^="right"]': {
              left:
                anchorElProps.left +
                window.scrollX +
                anchorElProps.width +
                elevation,
              '&.right': {
                top:
                  anchorElProps.top +
                  window.scrollY +
                  anchorElProps.height / 2 -
                  refProps.height / 2,
              },
              '&.right-start': { top: anchorElProps.top + window.scrollY },
              '&.right-end': {
                top:
                  anchorElProps.top +
                  window.scrollY +
                  anchorElProps.height -
                  refProps.height,
              },
            },
            '&[class^="bottom"]': {
              top: anchorElProps.bottom + window.scrollY + elevation,
              '&.bottom': {
                left:
                  anchorElProps.left +
                  window.scrollX +
                  anchorElProps.width / 2 -
                  refProps.width / 2,
              },
              '&.bottom-start': { left: anchorElProps.left + window.scrollX },
              '&.bottom-end': {
                left:
                  anchorElProps.left +
                  window.scrollX -
                  refProps.width +
                  anchorElProps.width,
              },
            },
            '&[class^="left"]': {
              left:
                anchorElProps.left +
                window.scrollX -
                refProps.width -
                elevation,
              '&.left': {
                top:
                  anchorElProps.top +
                  window.scrollY +
                  anchorElProps.height / 2 -
                  refProps.height / 2,
              },
              '&.left-start': { top: anchorElProps.top + window.scrollY },
              '&.left-end': {
                top:
                  anchorElProps.top +
                  window.scrollY +
                  anchorElProps.height -
                  refProps.height,
              },
            },
          })
        : undefined,
  ),
)

export const PopoverComponent = forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      elevation = 5,
      open = false,
      placement: placementProp = 'bottom-start',
      smartPositioning = true,
      children,
      anchorEl,
      onClose,
      marginTop,
      marginLeft,
      ...other
    },
    forwardedRef,
  ) => {
    const ref = forwardedRef as MutableRefObject<HTMLDivElement | null>
    const [refRect, setRefRect] = useState<DOMRect | undefined>()
    const portalRoot = usePortalRoot()

    useEffect(() => {
      if (ref?.current) {
        setRefRect(ref.current.getBoundingClientRect())
      }

      const onClickOutside = (event: Event) => {
        if (
          !ref.current ||
          ref.current.contains(event.target as Node) ||
          anchorEl?.contains(event.target as Node) ||
          !onClose
        ) {
          return
        }

        const openedPopovers = (
          (portalRoot ? portalRoot.parentElement : null) || window.document
        ).querySelectorAll('[data-role="popover"]')
        const lastOpenedPopover = openedPopovers[openedPopovers.length - 1]

        if (ref.current !== lastOpenedPopover) {
          return
        }

        // We need this delay in order to process any onBlur events of the child component first
        setTimeout(() => {
          onClose()
        }, 100)
      }

      const onWindowResize = debounce(() => {
        setRefRect(ref?.current?.getBoundingClientRect())
      }, 150)

      const listenerDomEl =
        (portalRoot ? portalRoot.parentElement : null) || window
      listenerDomEl.addEventListener('mousedown', onClickOutside)
      listenerDomEl.addEventListener('resize', onWindowResize)

      return () => {
        listenerDomEl.removeEventListener('mousedown', onClickOutside)
        listenerDomEl.removeEventListener('resize', onWindowResize)
      }
    }, [ref, anchorEl, onClose, portalRoot])

    if (!(open && anchorEl && portalRoot)) {
      return null
    }

    let placement = placementProp
    const anchorElProps = anchorEl.getBoundingClientRect()

    if (smartPositioning && refRect) {
      const { innerWidth, innerHeight } = window

      // Change the position from 'bottom' to 'top' if there is no enough space
      if (
        placement.includes('bottom') &&
        anchorElProps.bottom + elevation + refRect.height > innerHeight
      ) {
        placement = placement.replace('bottom', 'top') as PopoverPlacements
      }

      // Change the position from 'top' to 'bottom' if there is no enough space
      if (
        placement.includes('top') &&
        anchorElProps.top - elevation - refRect.height < 0
      ) {
        placement = placement.replace('top', 'bottom') as PopoverPlacements
      }

      // Change the position from 'end' to 'start' if there is no enough space
      if (
        placement.includes('end') &&
        anchorElProps.right - refRect.width < 0
      ) {
        placement = placement.replace('end', 'start') as PopoverPlacements
      }

      // Change the position from 'start' to 'end' if there is no enough space
      if (
        placement.includes('start') &&
        anchorElProps.left + refRect.width > innerWidth
      ) {
        placement = placement.replace('start', 'end') as PopoverPlacements
      }
    }

    return createPortal(
      <PopoverStyled
        refProps={refRect}
        anchorElProps={anchorElProps}
        className={placement}
        ref={forwardedRef}
        {...{ placement, elevation, marginTop, marginLeft }}
        {...other}
      >
        {children}
      </PopoverStyled>,
      portalRoot,
    )
  },
)

export const Popover: React.FC<PopoverProps> = (props) => {
  const ref = createRef<HTMLDivElement>()
  return <PopoverComponent data-role="popover" ref={ref} {...props} />
}

export default Popover
