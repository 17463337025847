import React from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentRequestBodyDynamicValueRef } from 'store/selectors'

import { DynamicValueEditor } from 'ecosystems/field-editors'
import { TabBoxContainer } from 'ecosystems/http-exchange-viewer/http-exchange-tabs'

const BodyTypeDynamicValue: React.FC = () => {
  const onlyDvRef = useSelector(selectCurrentRequestBodyDynamicValueRef)
  if (!onlyDvRef) {
    return null
  }
  return (
    <DynamicValueEditor
      objectRef={onlyDvRef}
      showPreview={false}
      flexBasis={0}
      flexGrow={1}
      fieldSetProps={{
        flexBasis: 0,
        flexGrow: 1,
      }}
      renderFieldContainer={(fieldKey, fieldType, children) => (
        <TabBoxContainer key={fieldKey} p={5} scrollable>
          {children}
        </TabBoxContainer>
      )}
    />
  )
}

export default BodyTypeDynamicValue
