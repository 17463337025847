/* eslint-disable no-param-reassign */

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Project } from 'lib/project/types.d'
import type { CurrentItemState } from './types.d'

const initialState: CurrentItemState = {
  currentTreeItemRef: null,
}

const currentItemSlice = createSlice({
  name: 'itemSelection',
  initialState,
  reducers: {
    setCurrentTreeItemRef: (
      state,
      action: PayloadAction<Project.GenericRef<Project.AnyRequestTreeItem> | null>,
    ) => {
      state.currentTreeItemRef = action.payload
    },
  },
})

export default currentItemSlice
