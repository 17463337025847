import type { DataTableItem } from '@rapidapi/ui-lib'
import type { Project } from 'lib/project'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setProjectValue } from 'store/actions'

type ItemData<T extends Project.AnyObject> = DataTableItem<
  T | Project.GenericRef<T>
>
const useChangeItemData = <T extends Project.AnyObject>(): ((
  newItemData: ItemData<T>,
) => void) => {
  const dispatch = useDispatch()
  return useCallback(
    <Y extends Project.AnyObject>(newItemData: ItemData<Y>) => {
      dispatch(
        setProjectValue({
          objectRef: { ref: newItemData.uuid },
          update: {
            enabled: !!newItemData.checked,
          },
        }),
      )
    },
    [dispatch],
  )
}
export default useChangeItemData
