import {
  authorizationCodeGrantType,
  clientCredentialsGrantType,
  resourceOwnerPasswordCredentialsGrantType,
} from 'lib/dynamic-values/implementations/oauth2-dynamic-value/impl-oauth2-dynamic-value'
import type { Project } from 'lib/project'
import { getHttpBasicAuth, urlEncodeBodyComponent } from 'lib/utils'

import type { OAuth2Utils } from './oauth2-functions-types.d'

interface GetOAuth2AccessTokenRequestProps {
  isRefreshToken?: boolean
  code?: string
  evaluatedOAuth2Params: OAuth2Utils.EvaluatedOAuth2Params
}

const getOAuth2AccessTokenRequest = ({
  isRefreshToken,
  code,
  evaluatedOAuth2Params,
}: GetOAuth2AccessTokenRequestProps): OAuth2Utils.OAuth2Request => {
  let headers: Project.ObjectMap<string> = {
    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
    accept:
      'application/json, text/json, text/javascript, application/xml, text/xml, application/x-plist, application/x-www-form-urlencoded, text/plain, text/html, application/xhtml+xml',
  }
  let bodyParams: Record<string, string | undefined> = {}

  if (isRefreshToken) {
    /* 6.  Refreshing an Access Token
     * https://tools.ietf.org/html/rfc6749#section-6
     *
     * If the authorization server issued a refresh token to the client, the
     * client makes a refresh request to the token endpoint by adding the
     * following parameters using the "application/x-www-form-urlencoded"
     * format per Appendix B with a character encoding of UTF-8 in the HTTP
     * request entity-body:
     *
     * grant_type
     *   REQUIRED.  Value MUST be set to "refresh_token".
     *
     * refresh_token
     *   REQUIRED.  The refresh token issued to the client.
     *
     * scope
     *      OPTIONAL.  The scope of the access request as described by
     *      Section 3.3.
     *
     * Because refresh tokens are typically long-lasting credentials used to
     * request additional access tokens, the refresh token is bound to the
     * client to which it was issued.  If the client type is confidential or
     * the client was issued client credentials (or assigned other
     * authentication requirements), the client MUST authenticate with the
     * authorization server as described in Section 3.2.1.
     *
     *
     * 3.2.1.  Client Authentication
     * https://tools.ietf.org/html/rfc6749#section-3.2.1
     *
     * Confidential clients or other clients issued client credentials MUST
     * authenticate with the authorization server as described in
     * Section 2.3 when making requests to the token endpoint.
     *
     *
     * 2.3.1.  Client Password
     * https://tools.ietf.org/html/rfc6749#section-2.3.1
     *
     * Clients in possession of a client password MAY use the HTTP Basic
     * authentication scheme as defined in [RFC2617] to authenticate with
     * the authorization server.  The client identifier is encoded using the
     * "application/x-www-form-urlencoded" encoding algorithm per
     * Appendix B, and the encoded value is used as the username; the client
     * password is encoded using the same algorithm and used as the
     * password.  The authorization server MUST support the HTTP Basic
     * authentication scheme for authenticating clients that were issued a
     * client password.
     *
     * For example (with extra line breaks for display purposes only):
     *
     * Authorization: Basic czZCaGRSa3F0Mzo3RmpmcDBaQnIxS3REUmJuZlZkbUl3
     *
     * Alternatively, the authorization server MAY support including the
     * client credentials in the request-body using the following
     * parameters:
     *
     * client_id
     *   REQUIRED.  The client identifier issued to the client during
     *   the registration process described by Section 2.2.
     *
     * client_secret
     *   REQUIRED.  The client secret.  The client MAY omit the
     *   parameter if the client secret is an empty string.
     *
     * Including the client credentials in the request-body using the two
     * parameters is NOT RECOMMENDED and SHOULD be limited to clients unable
     * to directly utilize the HTTP Basic authentication scheme (or other
     * password-based HTTP authentication schemes).  The parameters can only
     * be transmitted in the request-body and MUST NOT be included in the
     * request URI.
     */
    bodyParams = {
      ...bodyParams,
      grant_type: 'refresh_token',
      refresh_token: evaluatedOAuth2Params.refreshToken || undefined,
      scope: evaluatedOAuth2Params.scope || undefined,
    }
  } else if (evaluatedOAuth2Params.grantType === authorizationCodeGrantType) {
    /* 4.1.3.  Access Token Request
     * https://tools.ietf.org/html/rfc6749#section-4.1.3
     *
     * The client makes a request to the token endpoint by sending the
     * following parameters using the "application/x-www-form-urlencoded"
     * format with a character encoding of UTF-8 in the HTTP
     * request entity-body:
     *
     * grant_type
     *   REQUIRED.  Value MUST be set to "authorization_code".
     *
     * code
     *   REQUIRED.  The authorization code received from the
     *   authorization server.
     *
     * redirect_uri
     *   REQUIRED, if the "redirect_uri" parameter was included in the
     *   authorization request as described in Section 4.1.1, and their
     *   values MUST be identical.
     *
     * client_id
     *   REQUIRED, if the client is not authenticating with the
     *   authorization server as described in Section 3.2.1.
     *
     * If the client type is confidential or the client was issued client
     * credentials (or assigned other authentication requirements), the
     * client MUST authenticate with the authorization server as described
     * in Section 3.2.1.
     *
     *
     * 3.2.1.  Client Authentication
     * https://tools.ietf.org/html/rfc6749#section-3.2.1
     *
     * Confidential clients or other clients issued client credentials MUST
     * authenticate with the authorization server as described in
     * Section 2.3 when making requests to the token endpoint.
     *
     *
     * 2.3.1.  Client Password
     * https://tools.ietf.org/html/rfc6749#section-2.3.1
     *
     * Clients in possession of a client password MAY use the HTTP Basic
     * authentication scheme as defined in [RFC2617] to authenticate with
     * the authorization server.  The client identifier is encoded using the
     * "application/x-www-form-urlencoded" encoding algorithm per
     * Appendix B, and the encoded value is used as the username; the client
     * password is encoded using the same algorithm and used as the
     * password.  The authorization server MUST support the HTTP Basic
     * authentication scheme for authenticating clients that were issued a
     * client password.
     *
     * For example (with extra line breaks for display purposes only):
     *
     * Authorization: Basic czZCaGRSa3F0Mzo3RmpmcDBaQnIxS3REUmJuZlZkbUl3
     *
     * Alternatively, the authorization server MAY support including the
     * client credentials in the request-body using the following
     * parameters:
     *
     * client_id
     *   REQUIRED.  The client identifier issued to the client during
     *   the registration process described by Section 2.2.
     *
     * client_secret
     *   REQUIRED.  The client secret.  The client MAY omit the
     *   parameter if the client secret is an empty string.
     *
     * Including the client credentials in the request-body using the two
     * parameters is NOT RECOMMENDED and SHOULD be limited to clients unable
     * to directly utilize the HTTP Basic authentication scheme (or other
     * password-based HTTP authentication schemes).  The parameters can only
     * be transmitted in the request-body and MUST NOT be included in the
     * request URI.
     */
    bodyParams = {
      ...bodyParams,
      grant_type: 'authorization_code',
      code: code || undefined,
      scope: evaluatedOAuth2Params.scope || undefined,
      redirect_uri:
        evaluatedOAuth2Params.explicitCallbackURL &&
        evaluatedOAuth2Params.callbackURL
          ? evaluatedOAuth2Params.callbackURL
          : undefined,
    }
  } else if (
    evaluatedOAuth2Params.grantType ===
    resourceOwnerPasswordCredentialsGrantType
  ) {
    /* 4.3.2.  Access Token Request
     * https://tools.ietf.org/html/rfc6749#section-4.3.2
     *
     * The client makes a request to the token endpoint by adding the
     * following parameters using the "application/x-www-form-urlencoded"
     * format per Appendix B with a character encoding of UTF-8 in the HTTP
     * request entity-body
     *
     * grant_type
     *      REQUIRED.  Value MUST be set to "password".
     *
     * username
     *      REQUIRED.  The resource owner username.
     *
     * password
     *      REQUIRED.  The resource owner password.
     *
     * scope
     *      OPTIONAL.  The scope of the access request as described by
     *      Section 3.3.
     */
    bodyParams = {
      ...bodyParams,
      grant_type: 'password',
      username: evaluatedOAuth2Params.username || undefined,
      password: evaluatedOAuth2Params.password || undefined,
      scope: evaluatedOAuth2Params.scope || undefined,
    }
  } else if (evaluatedOAuth2Params.grantType === clientCredentialsGrantType) {
    /* 4.4.2.  Access Token Request
     * https://tools.ietf.org/html/rfc6749#section-4.4.2
     *
     * The client makes a request to the token endpoint by adding the
     * following parameters using the "application/x-www-form-urlencoded"
     * format per Appendix B with a character encoding of UTF-8 in the HTTP
     * request entity-body:
     *
     * grant_type
     *   REQUIRED.  Value MUST be set to "client_credentials".
     *
     * scope
     *   OPTIONAL.  The scope of the access request as described by
     *   Section 3.3.

     * 3.2.1.  Client Authentication
     *
     * Confidential clients or other clients issued client credentials MUST
     * authenticate with the authorization server as described in
     * Section 2.3 when making requests to the token endpoint.

     *
     * 2.3.  Client Authentication
     *
     * If the client type is confidential, the client and authorization
     * server establish a client authentication method suitable for the
     * security requirements of the authorization server.  The authorization
     * server MAY accept any form of client authentication meeting its
     * security requirements.
     *
     * Confidential clients are typically issued (or establish) a set of
     * client credentials used for authenticating with the authorization
     * server (e.g., password, public/private key pair).
     *
     * The authorization server MAY establish a client authentication method
     * with public clients.  However, the authorization server MUST NOT rely
     * on public client authentication for the purpose of identifying the
     * client.
     *
     * The client MUST NOT use more than one authentication method in each
     * request.
     *
     * 2.3.1.  Client Password
     *
     * Clients in possession of a client password MAY use the HTTP Basic
     * authentication scheme as defined in [RFC2617] to authenticate with
     * the authorization server.  The client identifier is encoded using the
     * "application/x-www-form-urlencoded" encoding algorithm per
     * Appendix B, and the encoded value is used as the username; the client
     * password is encoded using the same algorithm and used as the
     * password.  The authorization server MUST support the HTTP Basic
     * authentication scheme for authenticating clients that were issued a
     * client password.
     *
     * For example (with extra line breaks for display purposes only):
     *
     * Authorization: Basic czZCaGRSa3F0Mzo3RmpmcDBaQnIxS3REUmJuZlZkbUl3
     *
     * Alternatively, the authorization server MAY support including the
     * client credentials in the request-body using the following
     * parameters:
     *
     * client_id
     *      REQUIRED.  The client identifier issued to the client during
     *                 the registration process described by Section 2.2.
     *
     * client_secret
     *      REQUIRED.  The client secret.  The client MAY omit the
     *                 parameter if the client secret is an empty string.
     *
     * Including the client credentials in the request-body using the two
     * parameters is NOT RECOMMENDED and SHOULD be limited to clients unable
     * to directly utilize the HTTP Basic authentication scheme
     */
    bodyParams = {
      ...bodyParams,
      grant_type: 'client_credentials',
      scope: evaluatedOAuth2Params.scope || undefined,
    }
  }

  /*
   * 2.3.1.  Client Password
   * https://tools.ietf.org/html/rfc6749#section-2.3.1
   */
  if (evaluatedOAuth2Params.sendClientCredentialsInBody) {
    /*
     * client_id
     *   REQUIRED.  The client identifier as described in Section 2.2.
     *
     * client_secret
     *   REQUIRED.  The client secret.  The client MAY omit the
     *   parameter if the client secret is an empty string.
     */
    bodyParams = {
      ...bodyParams,
      client_id: evaluatedOAuth2Params.clientID || undefined,
      client_secret: evaluatedOAuth2Params.clientSecret || undefined,
    }
  } else {
    /* Basic Auth */
    headers = {
      ...headers,
      authorization: getHttpBasicAuth(
        evaluatedOAuth2Params.clientID || '',
        evaluatedOAuth2Params.clientSecret || '',
      ),
    }
  }

  /* extra params */
  bodyParams = {
    ...bodyParams,
    ...evaluatedOAuth2Params.tokenRequestParams.reduce(
      (acc, { key, value }) => ({
        ...acc,
        [key]: value,
      }),
      {},
    ),
  }

  /* Set parameters in the request body */
  const bodyString = Object.entries(bodyParams)
    .filter(([, value]) => value !== undefined)
    .map(
      ([key, value]) =>
        `${urlEncodeBodyComponent(key)}=${urlEncodeBodyComponent(
          value as string,
        )}`,
    )
    .join('&')

  return {
    url: evaluatedOAuth2Params.accessTokenURL,
    options: {
      method: 'POST',
      headers,
      body: bodyString,
    },
  }
}

export default getOAuth2AccessTokenRequest
