import { useEffect, useState } from 'react'

import type { CloudAPI } from 'lib/cloud-sync/cloud-api'
import { getAccountInfo } from 'lib/cloud-sync/cloud-api'
import { getOAuthToken } from './cloud-api-auth'

type Status = 'idle' | 'loading' | 'succeeded' | 'failed'

type UseGetAccountInfoResult = {
  status: Status
  error: Error | null
  response: CloudAPI.UserAccountResponse | null
}

const useGetAccountInfo = (): UseGetAccountInfoResult => {
  const [status, setStatus] = useState<Status>('idle')
  const [error, setError] = useState<Error | null>(null)
  const [response, setResponse] = useState<CloudAPI.UserAccountResponse | null>(
    null,
  )

  useEffect(() => {
    ;(async () => {
      setStatus('loading')
      try {
        // get token
        const token = getOAuthToken()
        if (!token) {
          setError(new Error('User is not authenticated'))
          setStatus('failed')
          return
        }

        // perform call
        const res = await getAccountInfo(token)

        // success
        setResponse(res)
        setStatus('succeeded')
      } catch (anError) {
        // failure
        setError(anError as Error)
        setStatus('failed')
      }
    })()
  }, [])

  return {
    status,
    error,
    response,
  }
}

export default useGetAccountInfo
