import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import type { RequestHandling } from 'lib/request-handling/types.d'
import { selectHttpExchangeForCurrentRequest } from 'store/selectors'

type UseHttpExchangeMessageResult = {
  httpExchange: RequestHandling.HTTPExchange | null
  httpMessage: RequestHandling.AnyHTTPMessage | null
}

// eslint-disable-next-line import/prefer-default-export
export const useHttpExchangeMessage = (
  messageType: 'request' | 'response',
): UseHttpExchangeMessageResult => {
  const httpExchange = useSelector(selectHttpExchangeForCurrentRequest)
  const httpMessage = useMemo(
    () =>
      messageType === 'request'
        ? httpExchange?.request || null
        : httpExchange?.response || null,
    [messageType, httpExchange],
  )

  return {
    httpExchange,
    httpMessage,
  }
}
