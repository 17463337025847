import { useState, useEffect } from 'react'
import { getOAuthToken } from './cloud-api-auth'

export type AuthStatus = 'authenticated' | 'missing' | 'loading'

const useAuthStatus = (): AuthStatus => {
  const [authStatus, setAuthStatus] = useState<AuthStatus>('loading')
  useEffect(() => {
    const auth = getOAuthToken()
    setAuthStatus(auth ? 'authenticated' : 'missing')
  }, [])
  return authStatus
}

export default useAuthStatus
