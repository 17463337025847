import * as React from 'react'
import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import config from 'config'
import { initFromSearch } from 'templates/app-template/dev-previews'
import { segmentLoad, StoreProvider } from 'utils'

import './index.css'
import { ErrorBoundary } from 'ecosystems'
import App from './app'
import * as serviceWorker from './serviceWorker'

initFromSearch()

// Check if prod
const isProd = process.env.REACT_APP_NODE_ENV === 'production'

if (isProd) {
  // Setup Sentry
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: (() => {
      const configType: string = config.build || 'unknown'
      return `${isProd ? 'prod' : 'debug'}-${configType}`
    })(),
    /**
     * @todo ResizeObserver
     * sentry logs `ResizeObserver loop limit exceeded` which is a known, 3-year old bug
     * in Chromium see link below. While it's a benign error that doesn't really break
     * the app, Sentry POSTs this info back to its api which still eats up memory when
     * initiating an XHR request and could run out a Sentry api limit quota
     *
     * @see {@link https://stackoverflow.com/a/50387233}
     * @see {@link https://bugs.chromium.org/p/chromium/issues/detail?id=809574}
     */
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  })

  // Setup LogRocket
  LogRocket.init(config.logRocket.id || '', {
    release: (() => {
      const configType: string = config.build || 'unknown'
      return `${isProd ? 'prod' : 'debug'}-${configType}-4.0.0`
    })(),
    network: {
      requestSanitizer: (request) => {
        // skip the requests that the user sends (should be private)
        // skip also blob: request
        if (
          request.url.match(
            /^https:\/\/paw-cloud-proxy\.octopaw\.workers\.dev/,
          ) ||
          request.url.match(/^https?:\/\/localhost:\d+/i) ||
          request.url.match(/^blob:/)
        ) {
          return null
        }
        return request
      },
    },
  })
  setupLogRocketReact(LogRocket)

  // Connect Sentry to LogRocket
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('logRocketSessionURL', sessionURL)
    })
  })

  // Segment
  segmentLoad()
}

const Root: React.FC<{
  BASE_PATH?: string
  BASE_APP_PREFIX?: string
  INITIAL_API_ID?: string
}> = ({ BASE_PATH, BASE_APP_PREFIX, INITIAL_API_ID }) => (
  <StoreProvider usePersistGate>
    <ErrorBoundary>
      <App
        BASE_PATH={BASE_PATH}
        BASE_APP_PREFIX={BASE_APP_PREFIX}
        INITIAL_API_ID={INITIAL_API_ID}
      />
    </ErrorBoundary>
  </StoreProvider>
)

export default Root

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
