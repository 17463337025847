import React from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentRequestAuthDynamicValueRef } from 'store/selectors'

import { DynamicValueEditor } from 'ecosystems/field-editors'
import { TabBoxContainer } from 'ecosystems/http-exchange-viewer/http-exchange-tabs'

const AuthTypeDynamicValue: React.FC = () => {
  const onlyDvRef = useSelector(selectCurrentRequestAuthDynamicValueRef)
  if (!onlyDvRef) {
    return null
  }
  return (
    <TabBoxContainer p={5} scrollable>
      <DynamicValueEditor
        objectRef={onlyDvRef}
        showPreview={false}
        flexBasis={0}
        flexGrow={1}
      />
    </TabBoxContainer>
  )
}

export default AuthTypeDynamicValue
