import React from 'react'
import { Theme } from 'themes'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import css, { CssFunctionReturnType } from '@styled-system/css'
import { DynamicFieldInputSizes } from '../dynamic-field.types'

export const dynamicFieldVariableStyle = ({
  variant,
  theme: { typography, addAlpha, colors },
  isAnchor,
}: {
  variant?: DynamicFieldInputSizes
  theme: Theme
  isAnchor?: boolean
}): CssFunctionReturnType =>
  css({
    zIndex: 10,
    ...typography[variant === 'large' ? 'body1' : 'body2'],
    position: 'relative',
    whiteSpace: 'nowrap',
    // @TODO: temporary fix
    px: '8px',
    display: 'inline-flex',
    outline: '0 none',
    backgroundClip: 'padding-box',
    minWidth: '1ch',
    color: 'texts.textInfo',
    alignItems: 'center',
    height: variant === 'large' ? '24px' : '20px',
    '&::before': {
      position: 'absolute',
      content: '""',
      width: '100%',
      height: variant === 'large' ? '24px' : '20px',
      borderRadius: '100px',
      // @TODO: temporary fix
      bg: addAlpha(
        isAnchor ? colors.neutral.neutral500 : colors.blue.blue500,
        0.12,
      ),
      zIndex: -1,
      left: 0,
      boxSizing: 'content-box',
      top: 0,
    },
  })

const DynamicFieldVariableStyled = withTheme(
  styled('span')(dynamicFieldVariableStyle),
)

const DynamicFieldVariable: React.FC<{
  variant?: DynamicFieldInputSizes
}> = ({ children, variant }) => (
  <DynamicFieldVariableStyled variant={variant}>
    {children}
  </DynamicFieldVariableStyled>
)

export default DynamicFieldVariable
