import type { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import type { Project } from 'lib/project/types.d'
import {
  assertType,
  serializeAnything,
} from 'lib/cloud-sync/serializer/helpers'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

const serializeDynamicValue = (
  serializer: CloudSerializer.ProjectSerializer,
  object: Project.DynamicValue,
): CloudAPI.SyncTreeItem => {
  // check type
  assertType(object, 'dynamicValue')

  // extra 'dynamic` keys
  const extraKeys = Object.keys(object).filter(
    (key) => key !== 'uuid' && key !== 'type' && key !== 'identifier',
  )
  const extraValues = extraKeys.reduce((acc, key) => {
    acc[key] = serializeAnything(serializer, object[key])
    return acc
  }, {} as Project.ObjectMap<unknown>)

  const syncTreeItem: CloudAPI.SyncTreeItem = {
    _uuid: object.uuid,
    _type: 'dynamicValue',
    identifier: object.identifier,
    ...extraValues,
  }
  return syncTreeItem
}

export default serializeDynamicValue
