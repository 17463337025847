/* eslint-disable react/jsx-props-no-spreading */

import React, { isValidElement } from 'react'
import { Box } from 'components/layout/box'
import { SpaceProps } from 'styled-system'
import { Typography, Icon } from 'components/data-display'

export type DialogTitleProps = SpaceProps & {
  open?: boolean
  divider?: boolean
  onClose?: () => void
}

const DialogTitle: React.FC<DialogTitleProps> = ({
  divider = false,
  children,
  onClose,
  ...other
}) => (
  <Box mb={4} css={{ position: 'relative' }} {...other}>
    <Box paddingRight={onClose ? 30 : undefined}>
      {isValidElement(children) ? (
        children
      ) : (
        <Typography variant="headline">{children}</Typography>
      )}
    </Box>
    {divider && (
      <Box mt={4} mx={-5} backgroundColor="borders.default" height={1} />
    )}
    {onClose && (
      // TODO: we need a dedicated button-icon component
      <Box
        css={{ position: 'absolute', top: 2, right: 0, cursor: 'pointer' }}
        onClick={onClose}
      >
        <Icon symbol="close" />
      </Box>
    )}
  </Box>
)

export default DialogTitle
