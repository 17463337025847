import Dexie from 'dexie'
import type { RequestHandling } from 'lib/request-handling/types.d'

/**
 * HttpExchangeStorage
 *
 * @desc A class to handle storage of HTTP Exchanges in IndexedDB.
 *
 */
export default class HttpExchangeStorage extends Dexie {
  public httpExchanges: Dexie.Table<RequestHandling.HTTPExchange, string>

  public files: Dexie.Table<RequestHandling.FileStored, string>

  /** @member {number} historyLimit - sets the maximum length of items in a table */
  private historyLimit = 300

  constructor() {
    super('Octopaw')

    // initialize database tables' indices
    // note: first key in the list is the primary key
    this.version(2).stores({
      httpExchanges: 'uuid, projectUuid, requestUuid, library, date',
      files: 'uuid, fileName, content',
    })

    // initialize database tables
    this.httpExchanges = this.table('httpExchanges')
    this.files = this.table('files')
  }

  public async fetchHttpExchangeList(
    requestUuid: string,
  ): Promise<RequestHandling.HTTPExchange[]> {
    if (!requestUuid) {
      throw new Error('Missing argument requestUuid')
    }
    return this.httpExchanges
      .where({ requestUuid })
      .limit(100)
      .reverse()
      .sortBy('date')
  }

  public async fetchHttpExchangeItem(
    httpExchangeUuid: string,
  ): Promise<RequestHandling.HTTPExchange | null> {
    if (!httpExchangeUuid) {
      throw new Error('Missing argument httpExchangeUuid')
    }
    return (await this.httpExchanges.get(httpExchangeUuid)) || null
  }

  public async insertHttpExchangeItem(
    httpExchange: RequestHandling.HTTPExchange,
  ): Promise<RequestHandling.HTTPExchange> {
    if (!httpExchange) {
      throw new Error('Missing argument httpExchange')
    }

    const httpExchangeUuid = await this.httpExchanges.add(httpExchange)
    if (httpExchangeUuid !== httpExchange.uuid) {
      throw new Error('[insertHttpExchangeItem()] Error inserting item')
    }

    return httpExchange
  }

  public async deleteHttpExchangeItem(httpExchangeUuid: string): Promise<void> {
    if (!httpExchangeUuid) {
      throw new Error('Missing argument httpExchangeUuid')
    }
    await this.httpExchanges.delete(httpExchangeUuid)
  }

  public async deleteHttpExchangeList(requestUuid: string): Promise<void> {
    if (!requestUuid) {
      throw new Error('Missing argument requestUuid')
    }
    await this.httpExchanges.where({ requestUuid }).delete()
  }

  public async performHouseKeeping(requestUuid: string): Promise<void> {
    if (!requestUuid) {
      throw new Error('Missing argument requestUuid')
    }
    const count = await this.httpExchanges.count()
    if (count >= this.historyLimit) {
      const toDelete = await this.httpExchanges
        .where({ requestUuid })
        .limit(1)
        .sortBy('date')

      if (toDelete && toDelete.length > 0) {
        const { uuid } = toDelete[0]
        await this.httpExchanges.delete(uuid)
      }
    }
  }

  public async addFile(
    fileStored: RequestHandling.FileStored,
  ): Promise<string> {
    const fetchedFile = await this.fetchFile(fileStored.uuid)
    if (fetchedFile) {
      await this.files.delete(fileStored.uuid)
    }
    const addedFile = await this.files.add(fileStored)
    return addedFile
  }

  public async fetchFile(
    uuid: string,
  ): Promise<RequestHandling.FileStored | null> {
    if (!uuid) {
      throw new Error('Missing argument uuid')
    }
    return (await this.files.get(uuid)) || null
  }
}
