import type { Project } from 'lib/project/types.d'
import { getKeyValue } from '../atoms'
import getHeaderByName from './get-header-by-name'

const getAuthHeaderDynamicString = (
  requestRef: Project.GenericRef<Project.Request>,
  objects: Project.ObjectMap,
): Project.GenericRef<Project.DynamicString> | null => {
  const authHeaderRef = getHeaderByName(requestRef, objects, 'authorization')
  if (!authHeaderRef) {
    return null
  }
  const authHeaderKeyValue = getKeyValue(authHeaderRef, objects, false)
  return authHeaderKeyValue.value || null
}

export default getAuthHeaderDynamicString
