/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'

import { Flex, Box, Button, Elevation, Notification } from '@rapidapi/ui-lib'
import type { Project } from 'lib/project/types.d'
import { useDynamicValueObject } from 'utils/hooks'
import implOAuth2DynamicValue, {
  authorizationCodeGrantType,
  implicitGrantType,
  resourceOwnerPasswordCredentialsGrantType,
} from 'lib/dynamic-values/implementations/oauth2-dynamic-value/impl-oauth2-dynamic-value'
import type { OAuth2DynamicValueInterface } from 'lib/dynamic-values'
import { useIsInElectron } from 'utils/electron'

import { DynamicStringEditor } from '../../dynamic-string-editor'
import { useGetOAuth2Token } from './oauth2-hooks'

type OAuth2TokenEditorProps = {
  objectRef: Project.GenericRef<Project.DynamicValue>
}

const OAuth2TokenEditor: React.FC<OAuth2TokenEditorProps> = ({ objectRef }) => {
  // get OAuth 2 Dynamic Value
  const dynamicValue =
    useDynamicValueObject<OAuth2DynamicValueInterface>(objectRef)
  if (dynamicValue.identifier !== implOAuth2DynamicValue.identifier) {
    throw new Error(
      '[OAuth2TokenEditor] Cannot use for a dynamic value other than OAuth 2',
    )
  }

  const showRefreshToken =
    dynamicValue.grantType === authorizationCodeGrantType ||
    dynamicValue.grantType === resourceOwnerPasswordCredentialsGrantType

  const needsElectronWindow =
    dynamicValue.grantType === authorizationCodeGrantType ||
    dynamicValue.grantType === implicitGrantType

  const {
    startGetAccessToken,
    startGetRefreshToken,
    clearCookies,
    loadingState,
  } = useGetOAuth2Token(objectRef)

  const isInElectron = useIsInElectron()

  return (
    <Elevation variant="small" rounded>
      {showRefreshToken && (
        <Box mt={1} mb={2}>
          <DynamicStringEditor
            objectRef={objectRef}
            objectProperty="refreshToken"
            label="Refresh Token"
          />
        </Box>
      )}
      <Flex mt={1} mb={2} flexDirection="row">
        <Box flexBasis={0} flexGrow={1} mr={3}>
          <DynamicStringEditor
            objectRef={objectRef}
            objectProperty="tokenPrefix"
            label="Token Prefix"
          />
        </Box>
        <Box flexBasis={0} flexGrow={4}>
          <DynamicStringEditor
            objectRef={objectRef}
            objectProperty="token"
            label="Token"
          />
        </Box>
      </Flex>
      {/* <Box mt={1} mb={2}>
        // @TODO not yet supported in Octopaw
        <CheckboxEditor
          objectRef={objectRef}
          objectProperty="autoAuthorize"
          label="Automatically get or refresh token"
        />
      </Box> */}
      {!isInElectron && needsElectronWindow && (
        <Box mt={1} mb={2}>
          <Notification
            mx={0}
            my={3}
            width="100%"
            icon="info"
            title="Paw Desktop app is needed"
          >
            {`In order to complete the OAuth flow, you'll need to install the Paw Desktop app.`}
          </Notification>
        </Box>
      )}
      <Flex mt={2}>
        {(isInElectron || !needsElectronWindow) && (
          <Box mr={2}>
            <Button
              variant="primary"
              loading={loadingState === 'loading-token'}
              disabled={loadingState !== 'idle'}
              onClick={startGetAccessToken}
            >
              Get Access Token
            </Button>
          </Box>
        )}
        {showRefreshToken && (
          <Box>
            <Button
              variant="primary"
              loading={loadingState === 'loading-refresh'}
              disabled={loadingState !== 'idle'}
              onClick={startGetRefreshToken}
            >
              Refresh Token
            </Button>
          </Box>
        )}
        {isInElectron && (
          <Box ml="auto">
            <Button variant="default" onClick={clearCookies}>
              Clear Cookies
            </Button>
          </Box>
        )}
      </Flex>
    </Elevation>
  )
}

export default OAuth2TokenEditor
