import type { Project } from 'lib/project/types.d'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'
import serializeObjectRef from './serialize-object-ref'

const serializeObjectNullableRef = <T extends Project.AnyObject>(
  serializer: CloudSerializer.ProjectSerializer,
  ref: Project.GenericRef<T> | null,
  serializerFn?: CloudSerializer.ObjectSerializerFn<T>,
): Project.GenericRef<T> | null => {
  if (!ref) {
    return null
  }
  return serializeObjectRef(serializer, ref, serializerFn)
}

export default serializeObjectNullableRef
