import type { DynamicValues } from 'lib/dynamic-values/types.d'
import type { Evaluation } from 'lib/evaluation/types.d'
import getStringEllipsis from 'lib/utils/get-string-ellipsis'

import type { URLEncodingDynamicValueInterface } from './types.d'
import {
  urlEncodingDynamicValueModeEncode,
  urlEncodingDynamicValueModeDecode,
} from './constants'

const identifier = 'com.luckymarmot.URLEncodingDynamicValue'

const defaultValue: URLEncodingDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  input: null,
  mode: null,
  charset: null,
  reservedCharacters: null,
}

const editForm: DynamicValues.EditForm<URLEncodingDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'input',
      fieldType: 'dynamicString',
      label: 'Input',
    },
    {
      fieldKey: 'mode',
      fieldType: 'choice',
      label: 'Mode',
      choices: [
        {
          value: urlEncodingDynamicValueModeEncode,
          label: 'Encode',
        },
        {
          value: urlEncodingDynamicValueModeDecode,
          label: 'Decode',
        },
      ] as DynamicValues.EditFormRadioChoice[],
    },
  ],
}

const implURLEncodingDynamicValue: DynamicValues.Implementation<URLEncodingDynamicValueInterface> =
  {
    title: 'URL-encoding',
    identifier,
    defaultValue,
    editForm,
    getAllRefs(dv) {
      const ret = []
      /* eslint-disable no-unused-expressions */
      dv.input && ret.push(dv.input)
      dv.reservedCharacters && ret.push(dv.reservedCharacters)
      /* eslint-enable no-unused-expressions */

      return ret.length ? ret : null
    },
    getEvaluatedString: async (
      dv: URLEncodingDynamicValueInterface,
      ctx: Evaluation.Ctx,
    ) => {
      // input
      const input = dv.input
        ? await ctx.evals.evaluateDynamicString(dv.input, ctx)
        : ''

      // decode
      if (dv.mode === urlEncodingDynamicValueModeDecode) {
        return decodeURIComponent(input)
      }

      // encode
      return encodeURIComponent(input)
    },
    getTokenInfo: async (
      dv: URLEncodingDynamicValueInterface,
      ctx: Evaluation.Ctx,
    ) => ({
      title:
        dv.mode === urlEncodingDynamicValueModeDecode
          ? 'URL-Decode'
          : 'URL-Encode',
      text: getStringEllipsis(
        dv.input ? await ctx.evals.evaluateDynamicString(dv.input, ctx) : '',
        40,
      ),
    }),
  }

export default implURLEncodingDynamicValue
