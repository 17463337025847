import css from '@styled-system/css'
import { Box, styled } from '@rapidapi/ui-lib'

const MarkdownEditorWrapper = styled(Box)(
  css({
    position: 'relative',
    height: '90%',
    minHeight: 150,
    marginTop: 1,
    paddingTop: 2,
    paddingLeft: 2,
    paddingBottom: '48px',
    borderRadius: 'default',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'borders.borderDefault',
    transition: 'box-shadow .2s ease, border-color .2s ease',
    overflow: 'hidden',

    '& .monaco-editor-background.lines-content': {
      backgroundColor: 'backgrounds.backgroundPrimary',
    },

    '& .monaco-editor .scroll-decoration': {
      boxShadow: 'none',
    },

    '&:focus, &:focus-within': {
      borderColor: 'borders.borderAcent',
      backgroundColor: 'backgrounds.backgroundPrimary',
    },

    '&:focus .monaco-editor-background.lines-content, &:focus-within .monaco-editor-background.lines-content':
      {
        backgroundColor: 'backgrounds.backgroundPrimary',
      },
  }),
)

export default MarkdownEditorWrapper
