import type { AppFetch } from 'utils/app-fetch/app-fetch-types.d'

import type { OAuth2Utils } from './oauth2-functions-types.d'
import getOAuth2AccessTokenRequest from './get-oauth2-access-token-request'
import getOAuth2AccessTokenFromResponse from './get-oauth2-access-token-from-response'

interface FetchOAuth2AccessTokenProps {
  isRefreshToken?: boolean
  code?: string
  evaluatedOAuth2Params: OAuth2Utils.EvaluatedOAuth2Params
  appFetch: AppFetch.AppFetch
}

export interface FetchOAuth2AccessTokenResult {
  accessToken: string
  refreshToken: string | null
}

const fetchOAuth2AccessToken = async ({
  isRefreshToken,
  code,
  evaluatedOAuth2Params,
  appFetch,
}: FetchOAuth2AccessTokenProps): Promise<FetchOAuth2AccessTokenResult> => {
  const { url, options } = getOAuth2AccessTokenRequest({
    isRefreshToken,
    code,
    evaluatedOAuth2Params,
  })

  const response = await appFetch(url, options)
  return getOAuth2AccessTokenFromResponse(response)
}

export default fetchOAuth2AccessToken
