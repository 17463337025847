import React, { useMemo } from 'react'
import { Box, Notification } from '@rapidapi/ui-lib'

import { Editor } from 'ecosystems/editor'
import type { RequestHandling } from 'lib/request-handling/types.d'
import { getUrlPath } from 'lib/utils'
import { getHttpMessageBodyString } from 'lib/request-handling'

import type { HttpExchangeTabViewProps } from '../http-exchange-tabs-props.d'
import { useHttpExchangeMessage } from '../http-exchange-helper-hooks'
import TabBoxContainer from '../tab-box-container'

const getRawRequest = (httpMessage: RequestHandling.HTTPExchangeRequest) => {
  const path = getUrlPath(httpMessage.url)
  const headers = [...(httpMessage?.headers || [])]
    .map((item) => [item.name, item.value].join(': '))
    .join('\n')
  const firstLine = `${httpMessage.method} ${path} HTTP/1.1`
  return `${firstLine}\n${headers}\n\n${getHttpMessageBodyString(httpMessage)}`
}

const getRawResponse = (httpMessage: RequestHandling.HTTPExchangeResponse) => {
  const headers = [...(httpMessage?.headers || [])]
    .map((item) => [item.name, item.value].join(': '))
    .join('\n')
  const firstLine = `HTTP/1.1 ${httpMessage.status} ${httpMessage.statusText}`
  return `${firstLine}\n${headers}\n\n${getHttpMessageBodyString(httpMessage)}`
}

const HttpExchangeRawTab: React.FC<HttpExchangeTabViewProps> = ({
  messageType,
}) => {
  const { httpMessage } = useHttpExchangeMessage(messageType)

  const content = useMemo(() => {
    if (!httpMessage) {
      return null
    }
    return messageType === 'request'
      ? getRawRequest(httpMessage as RequestHandling.HTTPExchangeRequest)
      : getRawResponse(httpMessage as RequestHandling.HTTPExchangeResponse)
  }, [httpMessage, messageType])

  return (
    <TabBoxContainer pt={5}>
      <Box mx={5} mb={3}>
        <Notification
          m={0}
          width="100%"
          variant="info"
          icon="info"
          title="Paw Beta"
        >
          This may not accurately reflect the raw HTTP data.
        </Notification>
      </Box>
      {typeof content === 'string' && <Editor content={content} />}
    </TabBoxContainer>
  )
}

export default HttpExchangeRawTab
