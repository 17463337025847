import React from 'react'

import { useCurrentRequestRef } from 'utils/hooks'
import { DynamicStringEditor } from 'ecosystems/field-editors'
import { TabBoxContainer } from 'ecosystems/http-exchange-viewer/http-exchange-tabs'

const BodyTypeText: React.FC = () => {
  const requestRef = useCurrentRequestRef()
  if (!requestRef) {
    return null
  }

  return (
    <TabBoxContainer p={5}>
      <DynamicStringEditor
        objectRef={requestRef}
        objectProperty="bodyString"
        multiline
      />
    </TabBoxContainer>
  )
}

export default BodyTypeText
