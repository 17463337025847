import type { DynamicValues } from 'lib/dynamic-values/types.d'
import type { Evaluation } from 'lib/evaluation/types.d'
import type { Project } from 'lib/project/types.d'
import { isDynamicStringEmpty } from 'lib/project/getters/dynamic-value-functions'
import getDynamicString from 'lib/project/getters/atoms/get-dynamic-string'
import type { OAuth2DynamicValueInterface } from './types.d'

export const authorizationCodeGrantType = 0
export const implicitGrantType = 1
export const resourceOwnerPasswordCredentialsGrantType = 2
export const clientCredentialsGrantType = 3

const identifier = 'com.luckymarmot.OAuth2DynamicValue'

const defaultValue: OAuth2DynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  // DynamicStrings
  clientID: null /* new EmptyDynamicString() */,
  clientSecret: null /* new EmptyDynamicString() */,
  username: null /* new EmptyDynamicString() */,
  password: null /* new EmptyDynamicString() */,
  authorizationURL: null /* new EmptyDynamicString() */,
  accessTokenURL: null /* new EmptyDynamicString() */,
  callbackURL: null /* new EmptyDynamicString() */,
  scope: null /* new EmptyDynamicString() */,
  stateNonce: null /* new EmptyDynamicString() */,
  token: null /* new EmptyDynamicString() */,
  tokenPrefix: null /* new EmptyDynamicString() */,
  refreshToken: null /* new EmptyDynamicString() */,
  // numbers
  grantType: authorizationCodeGrantType,
  // booleans
  sendClientCredentialsInBody: false,
  explicitCallbackURL: true,
  strict: false,
  // @TODO not yet supported in Octopaw
  autoAuthorize: true,
  // params
  authRequestParams: null,
  tokenRequestParams: null,
}

const editForm: DynamicValues.EditForm<OAuth2DynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'grantType',
      fieldType: 'choice',
      label: 'Grant Type',
      choices: [
        {
          value: authorizationCodeGrantType,
          label: 'Authorization Code',
        },
        {
          value: implicitGrantType,
          label: 'Implicit',
        },
        {
          value: resourceOwnerPasswordCredentialsGrantType,
          label: 'Resource Owner Password Credentials',
        },
        {
          value: clientCredentialsGrantType,
          label: 'Client Credentials',
        },
      ] as DynamicValues.EditFormRadioChoice[],
    },
    {
      fieldKey: 'clientID',
      fieldType: 'dynamicString',
      label: 'Client ID',
    },
    {
      fieldKey: 'clientSecret',
      fieldType: 'dynamicString',
      label: 'Client Secret',
      isHidden: (dv: OAuth2DynamicValueInterface): boolean =>
        dv.grantType === implicitGrantType,
    },
    {
      fieldKey: 'sendClientCredentialsInBody',
      fieldType: 'checkbox',
      label: 'Set client credentials in the body',
      isHidden: (dv: OAuth2DynamicValueInterface): boolean =>
        dv.grantType === implicitGrantType,
    },
    {
      fieldKey: 'username',
      fieldType: 'dynamicString',
      label: 'Username',
      isHidden: (dv: OAuth2DynamicValueInterface): boolean =>
        dv.grantType !== resourceOwnerPasswordCredentialsGrantType,
    },
    {
      fieldKey: 'password',
      fieldType: 'dynamicString',
      label: 'Password',
      isHidden: (dv: OAuth2DynamicValueInterface): boolean =>
        dv.grantType !== resourceOwnerPasswordCredentialsGrantType,
    },
    {
      fieldKey: 'authorizationURL',
      fieldType: 'dynamicString',
      label: 'Authorization URL',
      isHidden: (dv: OAuth2DynamicValueInterface): boolean =>
        dv.grantType === resourceOwnerPasswordCredentialsGrantType ||
        dv.grantType === clientCredentialsGrantType,
    },
    {
      fieldKey: 'accessTokenURL',
      fieldType: 'dynamicString',
      label: 'Access Token URL',
      isHidden: (dv: OAuth2DynamicValueInterface): boolean =>
        dv.grantType === implicitGrantType,
    },
    {
      fieldKey: 'callbackURL',
      fieldType: 'dynamicString',
      label: 'Callback URL',
      isHidden: (dv: OAuth2DynamicValueInterface): boolean =>
        dv.grantType === resourceOwnerPasswordCredentialsGrantType ||
        dv.grantType === clientCredentialsGrantType,
    },
    {
      fieldKey: 'explicitCallbackURL',
      fieldType: 'checkbox',
      label: 'Specify the Callback URL in requests',
      isHidden: (dv: OAuth2DynamicValueInterface): boolean =>
        dv.grantType === resourceOwnerPasswordCredentialsGrantType ||
        dv.grantType === clientCredentialsGrantType,
    },
    {
      fieldKey: 'scope',
      fieldType: 'dynamicString',
      label: 'Scope',
    },
    {
      fieldKey: 'strict',
      fieldType: 'checkbox',
      label: 'Strict Mode',
      hint: 'Perform additional checks on responses',
      hintIcon: 'info',
      advanced: true,
    },
    {
      fieldKey: 'stateNonce',
      fieldType: 'dynamicString',
      label: 'State',
      advanced: true,
      isHidden: (dv: OAuth2DynamicValueInterface): boolean =>
        dv.grantType === resourceOwnerPasswordCredentialsGrantType ||
        dv.grantType === clientCredentialsGrantType,
    },
    {
      fieldKey: 'authRequestParams',
      fieldType: 'parameterList',
      label: 'Additional Params for Authorization Request',
      advanced: true,
      isHidden: (dv: OAuth2DynamicValueInterface): boolean =>
        dv.grantType === resourceOwnerPasswordCredentialsGrantType ||
        dv.grantType === clientCredentialsGrantType,
    },
    {
      fieldKey: 'tokenRequestParams',
      fieldType: 'parameterList',
      label: 'Additional Params for Token Request',
      advanced: true,
      isHidden: (dv: OAuth2DynamicValueInterface): boolean =>
        dv.grantType === implicitGrantType,
    },
    {
      fieldKey: 'token',
      fieldType: 'customOAuth2Token',
      label: 'Token',
    },
  ],
}

const implOAuth2DynamicValue: DynamicValues.Implementation<OAuth2DynamicValueInterface> =
  {
    title: 'OAuth 2',
    identifier,
    defaultValue,
    editForm,
    getAllRefs(dv) {
      const ret: Project.GenericRef<Project.AnyObject>[] = [
        dv.clientID,
        dv.clientSecret,
        dv.username,
        dv.password,
        dv.authorizationURL,
        dv.accessTokenURL,
        dv.callbackURL,
        dv.scope,
        dv.stateNonce,
        dv.token,
        dv.tokenPrefix,
        dv.refreshToken,
        ...(dv.authRequestParams ?? []),
        ...(dv.tokenRequestParams ?? []),
      ].filter((x): x is Project.GenericRef<Project.AnyObject> => !!x)

      return ret.length ? ret : null
    },

    getEvaluatedString: async (
      dv: OAuth2DynamicValueInterface,
      ctx: Evaluation.Ctx,
    ) => {
      const tokenPrefix = dv.tokenPrefix
        ? await ctx.evals.evaluateDynamicString(dv.tokenPrefix, ctx)
        : ''
      const token = dv.token
        ? await ctx.evals.evaluateDynamicString(dv.token, ctx)
        : ''
      return [tokenPrefix.trim(), token.trim()]
        .filter((item) => !!item)
        .join(' ')
    },
    getTokenInfo: async () => ({
      title: 'OAuth 2',
      text: 'Authorization',
    }),
    isEmpty: (dv: OAuth2DynamicValueInterface, objects: Project.ObjectMap) =>
      (!dv.clientID ||
        isDynamicStringEmpty(getDynamicString(dv.clientID, objects, false))) &&
      (!dv.clientSecret ||
        isDynamicStringEmpty(
          getDynamicString(dv.clientSecret, objects, false),
        )) &&
      (!dv.username ||
        isDynamicStringEmpty(getDynamicString(dv.username, objects, false))) &&
      (!dv.password ||
        isDynamicStringEmpty(getDynamicString(dv.password, objects, false))) &&
      (!dv.token ||
        isDynamicStringEmpty(getDynamicString(dv.token, objects, false))) &&
      (!dv.refreshToken ||
        isDynamicStringEmpty(
          getDynamicString(dv.refreshToken, objects, false),
        )),
  }

export default implOAuth2DynamicValue
