import { createAction } from '@reduxjs/toolkit'
import requestHandlingSlice from './slice'

export const requestHandlingActions = requestHandlingSlice.actions

export const sendRequestAction = createAction<string>(
  'requestHandling/sendRequest',
)

export const sendCurrentRequestAction = createAction(
  'requestHandling/sendCurrentRequest',
)

export const setAgentStatusAction = createAction<boolean>(
  'requestHandling/setAgentStatus',
)
