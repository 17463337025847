import React from 'react'
import { Box, Flex, ProgressIndicator, Typography } from '@rapidapi/ui-lib'

const DownloadContentLoading: React.FC = () => (
  <Box textAlign="center">
    <Flex mx="auto" justifyContent="center">
      <ProgressIndicator type="linear" variant="indeterminate" />
    </Flex>
    <Typography variant="caption" display="block" color="mediumEmphasis">
      Please hang tight while we fetch the latest build...
    </Typography>
  </Box>
)
export default DownloadContentLoading
