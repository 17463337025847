import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import type { Project } from 'lib/project'
import getTokenId from 'lib/utils/dynamic-string/get-token-id'
import selectCtxEvaluationResultFactory from 'store/slices/project/selectors/select-ctx-evaluation-result-factory'

type TokenDict = Project.ObjectMap<string>

export const emptyTokenDict: TokenDict = {}

const useTokenInfoArray = (
  dynamicValueRefs: Project.GenericRef<Project.DynamicValue>[],
): TokenDict => {
  const [selector, setSelector] = useState(() => () => ({}))
  const ctxSelector = useMemo(
    () => selectCtxEvaluationResultFactory((_, context) => context),
    [],
  )
  const ctx = useSelector(ctxSelector)
  useEffect(() => {
    Promise.all(
      dynamicValueRefs.map(async (dvr) => {
        if (!ctx) {
          return null
        }
        const tokens: TokenDict = {}
        const { title, text } = await ctx.evals.evaluateDynamicValueTokenInfo(
          dvr,
          ctx,
        )
        const display = [...(title ? [title] : []), ...(text ? [text] : [])]
        tokens[getTokenId(dvr)] = display
          ? display.join(' ')
          : 'Missing Dynamic Value'

        return tokens
      }),
    ).then((tokens) => {
      const tokenDict: TokenDict = tokens
        .map((e) => e || {})
        .reduce((p, c) => {
          if (!c) {
            return p
          }
          const key = Object.keys(c)[0]
          if (!key) {
            return p
          }
          // eslint-disable-next-line no-param-reassign
          p[key] = c[key]
          return p
        }, {} as TokenDict)
      setSelector(() => selectCtxEvaluationResultFactory(() => tokenDict))
    })
  }, [dynamicValueRefs, ctx])

  return useSelector(selector)
}
export default useTokenInfoArray
