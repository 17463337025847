import React from 'react'
import { Box, Icon } from '@rapidapi/ui-lib'

const CoverTemplateHeader: React.FC = ({ children }) => (
  <Box as="header" textAlign="center">
    <Box pb={5} mx="auto" justifyContent="center" alignItems="center">
      <Icon symbol="luckymarmot" size={50} />
    </Box>
    <Box>{children}</Box>
  </Box>
)

export default CoverTemplateHeader
