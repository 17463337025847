import React, { useCallback, useMemo } from 'react'
import { Flex, ButtonTabs, ButtonTab } from '@rapidapi/ui-lib'

import type { RequestTabViewProps } from 'ecosystems/project/request-editor/request-tabs/request-tabs-props.d'
import {
  selectCurrentRequestBodyDynamicStringFactory,
  selectCurrentRequestBodyDynamicValueIdentifier,
  selectCurrentRequestBodyDynamicValuesFactory,
  selectProjectObjects,
} from 'store/selectors'
import { applyProjectSetter } from 'store/actions'
import { useCurrentRequestRef } from 'utils/hooks'

import { useSelector, useStore } from 'store/hooks'
import {
  getBodyTabKey,
  bodyTabComponents,
  getNewBodyDynamicValue,
  convertBodyDynamicValue,
} from './body-tabs-functions'
import type { BodyTabKey } from './body-tabs-types.d'

const renderBodyTypeTab = (tabKey: BodyTabKey): JSX.Element => {
  const Tab = bodyTabComponents[tabKey]
  if (!Tab) {
    throw new Error(`[RequestBodyTab] Missing tab for key ${tabKey}`)
  }
  return <Tab />
}

const tabs = [
  { key: 'text', label: 'Text' },
  { key: 'json', label: 'JSON' },
  { key: 'jsonTree', label: 'JSON Tree' },
  { key: 'urlEncoded', label: 'Form URL-Encoded' },
  { key: 'multipart', label: 'Multipart' },
  // { key: 'file', label: 'File' },
  { key: 'gqlQuery', label: 'GraphQL' },
]

const RequestBodyTab: React.FC<RequestTabViewProps> = () => {
  // get the body's dynamic value type (if only one dynamic value)
  // if we use a JSON, Form URL-Encoded, Multipart, File, or GraphQL body
  // it's gonna be a dynamic value
  // otherwise, it's gonna be a text field
  const requestRef = useCurrentRequestRef()
  const bodyOnlyDvIdentifier = useSelector(
    selectCurrentRequestBodyDynamicValueIdentifier,
  )
  const store = useStore()

  const currentTab = useMemo(
    () => getBodyTabKey(bodyOnlyDvIdentifier),
    [bodyOnlyDvIdentifier],
  )

  const currentTabIndex = tabs.findIndex(({ key }) => key === currentTab)

  const setCurrentTab = useCallback(
    (tabIndex) => {
      const tabValue = tabs[tabIndex].key

      if (!requestRef || currentTab === tabValue) {
        return
      }

      const state = store.getState()

      const objects = selectProjectObjects(state)
      const currentBodyDynamicString =
        selectCurrentRequestBodyDynamicStringFactory()(state)
      const currentBodyDynamicValues =
        selectCurrentRequestBodyDynamicValuesFactory()(state)

      const { inserts, strings } = currentBodyDynamicString
        ? convertBodyDynamicValue({
            from: currentTab,
            to: tabValue as BodyTabKey,
            currentBodyDynamicString,
            currentBodyDynamicValues,
            objects,
          })
        : getNewBodyDynamicValue(tabValue as BodyTabKey)

      if (strings === null) {
        return
      }

      store.dispatch(
        applyProjectSetter('updateDynamicString')({
          parentRef: requestRef,
          parentProperty: 'bodyString',
          strings,
          inserts,
        }),
      )
    },
    [requestRef, currentTab, store],
  )

  return (
    <>
      <ButtonTabs
        value={currentTabIndex}
        onChange={setCurrentTab}
        mt={5}
        px={5}
      >
        {tabs.map(({ key, label }) => (
          <ButtonTab key={key}>{label}</ButtonTab>
        ))}
      </ButtonTabs>
      <Flex flexBasis={0} flexGrow={1} overflow="hidden" flexDirection="column">
        {renderBodyTypeTab(currentTab as BodyTabKey)}
      </Flex>
    </>
  )
}

export default RequestBodyTab
