import type { Project } from 'lib/project/types.d'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

const serializeFutureRef = <T extends Project.AnyObject>(
  serializer: CloudSerializer.ProjectSerializer,
  ref: Project.GenericRef<T>,
): Project.GenericRef<T> => {
  if (serializer.syncTree[ref.ref]) {
    throw new Error(
      'An object with the same UUID is already in the object graph',
    )
  }
  return ref
}

export default serializeFutureRef
