import React from 'react'

import { EmptyState } from '@rapidapi/ui-lib'
import { TabBoxContainer } from 'ecosystems/http-exchange-viewer/http-exchange-tabs'

const AuthTypeCustom: React.FC = () => (
  <TabBoxContainer p={5}>
    <EmptyState
      symbol="lock"
      headline="Custom Authorization"
      body="Go to the Headers tab to view the custom Authorization scheme that has been set."
    />
  </TabBoxContainer>
)

export default AuthTypeCustom
