/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, ReactElement, useCallback } from 'react'
import { SpaceProps } from 'styled-system'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'components/feedback/dialog'
import { Button } from 'components/inputs/button'

export type PromptProps = SpaceProps & {
  title?: string | ReactElement
  content?: string | ReactElement
  proccedText?: string | null
  cancelText?: string | null
  onProceed?: () => void
  onCancel?: () => void
}

const Prompt: React.FC<PromptProps> = ({
  title,
  content,
  proccedText = 'Ok',
  cancelText = 'Cancel',
  onProceed,
  onCancel,
  children,
  ...other
}) => {
  const [open, setOpen] = useState(true)

  const onCancelFn = useCallback(() => {
    setOpen(false)
    if (onCancel) {
      onCancel()
    }
  }, [onCancel, setOpen])

  const onProceedFn = useCallback(() => {
    setOpen(false)
    if (onProceed) {
      onProceed()
    }
  }, [onProceed, setOpen])

  return (
    <Dialog
      open={open}
      onClose={onCancelFn}
      maxWidth={384}
      height="auto"
      {...other}
    >
      <React.Fragment>
        {title && <DialogTitle>{title}</DialogTitle>}
        {content && <DialogContent>{content}</DialogContent>}
        <DialogActions>
          {children ? (
            React.Children.map(children, (child) => {
              if (!React.isValidElement(child)) {
                return null
              }
              return React.cloneElement(child, {
                onClick: () => {
                  setOpen(false)
                  if (onCancel) {
                    onCancel()
                  }
                  if (child.props.onClick) {
                    child.props.onClick()
                  }
                },
              })
            })
          ) : (
            <React.Fragment>
              {cancelText !== null && (
                // @TODO: temporary fix
                <Button variant="default" onClick={onCancelFn}>
                  {cancelText}
                </Button>
              )}
              {proccedText !== null && (
                <Button onClick={onProceedFn}>{proccedText}</Button>
              )}
            </React.Fragment>
          )}
        </DialogActions>
      </React.Fragment>
    </Dialog>
  )
}

export default Prompt
