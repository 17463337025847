import { getFetchFn } from '../fetch'
import type { FetchResult } from '../fetch/fetch-types.d'
import type { CloudApiFetchProps } from './api-fetch-types.d'

/**
 * Fetch from the Cloud API (https://paw.cloud/api/v3/).
 * Some transformations are done for the purpose of this API:
 * - body will be JSON-encoded
 * - authorization header is added
 * - path is prefixed with `/api/v3`
 */
const cloudApiFetch = async ({
  path,
  method,
  headers,
  body,
  token,
}: CloudApiFetchProps): Promise<FetchResult> =>
  getFetchFn()(
    `/api/v3${path}`,
    method,
    {
      ...(headers || {}),
      ...(body ? { 'content-type': 'application/json' } : {}),
      authorization: `Bearer ${token}`,
    },
    body ? JSON.stringify(body) : undefined,
  )

export default cloudApiFetch
