import React, { useCallback, useMemo } from 'react'
import { Box, Button } from '@rapidapi/ui-lib'
import { useDispatch } from 'react-redux'
import { tabsStateActions, userActionAddRequestAction } from 'store/actions'
import useJsonTreeBuilder from 'ecosystems/field-editors/json-tree-editor/json-tree-builder'
import DataTableFieldRenderer from 'ecosystems/field-editors/json-tree-editor/data-table-field-renderer'
import JSONDataTable from 'ecosystems/field-editors/json-tree-editor/json-data-table'
import type { DataJSONField } from 'ecosystems/field-editors/json-tree-editor/json-data-table-types.d'
import {
  getHttpMessageBodyString,
  getMessageByteSize,
} from 'lib/request-handling'
import type { HttpExchangeTabViewProps } from '../http-exchange-tabs-props.d'
import { useHttpExchangeMessage } from '../http-exchange-helper-hooks'
import type { HttpExchangeJsonTextErrorMessage } from './json-tab-errors'
import HttpExchangeJsonErrorContent from './json-tab-errors'
import {
  JSON_TREE_SIZE_LIMIT,
  JSON_TREE_MESSAGE_ACTION_TEXT,
  JSON_TAB_MESSAGE_INVALID_BODY,
  JSON_TAB_MESSAGE_EMPTY_BODY,
  JSON_TAB_MESSAGE_EMPTY_TITLE,
  JSON_TAB_MESSAGE_INVALID_TITLE,
  JSON_TAB_MESSAGE_SIZE_TITLE,
  JSON_TREE_MESSAGE_SIZE_ERROR,
} from './json-tab-constants'

type JsonTreeContextType = [
  unknown | string | null,
  number,
  HttpExchangeJsonTextErrorMessage | null,
]

const HttpExchangeJsonTreeTab: React.FC<HttpExchangeTabViewProps> = ({
  messageType,
  tabId,
}) => {
  const { httpMessage } = useHttpExchangeMessage(messageType)
  const [jsonTreeContent, jsonContentSize, jsonTreeContentError] =
    useMemo((): JsonTreeContextType => {
      if (!httpMessage) {
        return [
          null,
          0,
          {
            title: JSON_TAB_MESSAGE_EMPTY_TITLE,
            text: JSON_TAB_MESSAGE_EMPTY_BODY(messageType),
          },
        ]
      }

      // If the size exceeds the limit,
      // omit further setup and return an error right away
      const byteSize = getMessageByteSize(httpMessage)
      if (byteSize > JSON_TREE_SIZE_LIMIT) {
        return [
          null,
          byteSize,
          {
            title: JSON_TAB_MESSAGE_SIZE_TITLE,
            text: JSON_TREE_MESSAGE_SIZE_ERROR,
          },
        ]
      }

      // get string
      const jsonString = getHttpMessageBodyString(httpMessage)
      if (!jsonString || jsonString.trim().length === 0) {
        return [
          null,
          byteSize,
          {
            title: JSON_TAB_MESSAGE_EMPTY_TITLE,
            text: JSON_TAB_MESSAGE_EMPTY_BODY(messageType),
          },
        ]
      }

      return [jsonString, byteSize, null]
    }, [httpMessage, messageType])

  const {
    jsonTreeFilteredContent,
    jsonTreeFilteredContentResults,
    jsonTreeFilteredContentError,
    filterKeyword,
    bindFilterKeyword,
  } = useJsonTreeBuilder(jsonTreeContent as string, () => ({
    title: JSON_TAB_MESSAGE_INVALID_TITLE,
    text: JSON_TAB_MESSAGE_INVALID_BODY(messageType),
  }))

  const dispatch = useDispatch()
  const handleSwitchTab = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      if (!tabId) {
        return
      }
      dispatch(
        tabsStateActions.setCurrentTab({
          identifier: tabId,
          currentTab: 'jsonTree',
        }),
      )
    },
    [dispatch, tabId],
  )

  const createNewRequest = useCallback(
    (url: string) => dispatch(userActionAddRequestAction({ url })),
    [dispatch],
  )

  const jsonContentHasError =
    jsonTreeContentError || jsonTreeFilteredContentError

  const renderItemKey = useMemo(
    () => DataTableFieldRenderer({ keyOrValue: 'key' }),
    [],
  )
  const renderItemValue = useMemo(
    () => DataTableFieldRenderer({ keyOrValue: 'value', createNewRequest }),
    [createNewRequest],
  )

  const fields = useMemo(
    (): DataJSONField[] => [
      {
        key: 'Key',
        value: renderItemKey,
      },
      {
        key: 'Value',
        value: renderItemValue,
      },
    ],
    [renderItemKey, renderItemValue],
  )

  if (jsonContentHasError) {
    return (
      <HttpExchangeJsonErrorContent errorMessage={jsonContentHasError}>
        {jsonContentSize > JSON_TREE_SIZE_LIMIT && (
          <Box width="100%">
            <Button onClick={handleSwitchTab}>
              {JSON_TREE_MESSAGE_ACTION_TEXT}
            </Button>
          </Box>
        )}
      </HttpExchangeJsonErrorContent>
    )
  }

  return (
    <Box p={4}>
      <JSONDataTable
        fields={fields}
        jsonTreeFilteredContent={jsonTreeFilteredContent}
        filterKeyword={filterKeyword}
        bindFilterKeyword={bindFilterKeyword}
        jsonTreeFilteredContentResults={jsonTreeFilteredContentResults}
      />
    </Box>
  )
}

export default HttpExchangeJsonTreeTab
