/* eslint-disable react/jsx-props-no-spreading */

import { KeyboardEvent, ReactElement, useCallback, MouseEvent } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import css from '@styled-system/css'
import { Box } from 'components/layout/box'
import { Typography } from 'components/data-display/typography'

export type MenuItemComponentProps = {
  description?: string | ReactElement
  disabled?: boolean
  endAdornment?: ReactElement
  startAdornment?: ReactElement
  onSelect?: () => void
}

const MenuItemStyled = withTheme(
  styled(Box)<Pick<MenuItemComponentProps, 'disabled'>>(
    ({ theme: { isDark } }) =>
      css({
        alignItems: 'flex-start',
        backgroundColor: `backgrounds.${
          isDark ? 'backgroundElevated2' : 'backgroundPrimary'
        }`,
        color: 'texts.highEmphasis',
        cursor: 'pointer',
        display: 'flex',
        fontFamily: 'default',
        fontSize: 'body2',
        letterSpacing: 'body2',
        lineHeight: 'body2',
        minWidth: 'auto',
        margin: 0,
        padding: 2,
        svg: {
          color: 'inherit',
        },
        userSelect: 'none',
        whiteSpace: 'nowrap',
        '&>div': {
          outline: '0 none',
        },
        '&:hover, &:focus': {
          backgroundColor: 'backgrounds.backgroundDefaultHover',
          borderRadius: 'default',
          outline: '0 none',
        },
      }),
  ),
)

export const MenuItem: React.FC<MenuItemComponentProps> = ({
  children,
  description,
  endAdornment,
  onSelect,
  startAdornment,
  ...other
}) => {
  const onKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter' && onSelect) {
      onSelect()
    }
  }
  const onClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      event.preventDefault()
      if (onSelect) {
        onSelect()
      }
    },
    [onSelect],
  )

  return (
    <MenuItemStyled
      data-role="menu-item"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...other}
    >
      {startAdornment}
      <Box tabIndex={-1}>
        {children}
        {description && (
          <Typography color="inherit" variant="caption">
            {description}
          </Typography>
        )}
      </Box>
      {endAdornment}
    </MenuItemStyled>
  )
}

export default MenuItem
