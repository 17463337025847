/* eslint-disable no-use-before-define */
import type { Project } from 'lib/project/types.d'
import type {
  JSONItem,
  JSONPrimitive,
  JSONDictKey,
  JSONDict,
  JSONArray,
} from './json-parser-types.d'
import { JSONPrimitiveType, JSONItemType } from './json-parser-types.d'

type JSONSerializerEvaluationFn<SType> = (input: SType) => Promise<string>

interface JSONSerializerInternalContext<SType> {
  output: string
  outputDisabledItems: boolean
  evaluationFn: JSONSerializerEvaluationFn<SType> | null
}
// @TODO handle pretty-printing options `escapeUnicode` and `escapeForwardSlashes`
export const escapeString = (str: string): string => {
  const replacer = (substring: string): string => {
    let c: string
    switch (substring) {
      case '\b':
        c = 'b'
        break
      case '\f':
        c = 'f'
        break
      case '\r':
        c = 'r'
        break
      case '\n':
        c = 'n'
        break
      case '\t':
        c = 't'
        break
      default:
        c = substring
        break
    }
    return `\\${c}`
  }
  return str.replace(/["\\\b\f\r\n\t]/g, replacer)
}

const appendString = async <SType>(
  value: SType,
  context: JSONSerializerInternalContext<SType>,
): Promise<void> => {
  const str = context.evaluationFn
    ? await context.evaluationFn(value)
    : (value as unknown as string)
  const escaped = escapeString(str)
  context.output += `"${escaped}"`
}

const appendPrimitive = async <SType>(
  item: JSONPrimitive<SType>,
  context: JSONSerializerInternalContext<SType>,
): Promise<void> => {
  switch (item.primitiveType) {
    case JSONPrimitiveType.String:
      await appendString(item.value as SType, context)
      break
    case JSONPrimitiveType.Number:
      context.output += (item.value as number).toString()
      break
    case JSONPrimitiveType.True:
      context.output += 'true'
      break
    case JSONPrimitiveType.False:
      context.output += 'false'
      break
    case JSONPrimitiveType.Null:
      context.output += 'null'
      break
    default:
      throw new Error('Invalid JSON Primitive')
  }
}

const appendDictKey = async <SType>(
  item: JSONDictKey<SType>,
  context: JSONSerializerInternalContext<SType>,
): Promise<void> => {
  await appendString(item.value, context)
}

const appendObject = async <SType>(
  item: JSONDict<SType>,
  context: JSONSerializerInternalContext<SType>,
): Promise<void> => {
  // @TODO pretty printing
  const { items } = item
  let isPreviousCommented = false

  context.output += '{'

  for (let i = 0; i < items.length; i += 1) {
    const { key, value, enabled } = items[i]

    const isVariableEnabled = enabled || context.outputDisabledItems
    if (isVariableEnabled) {
      if (!enabled) {
        const isFirstItem = i === 0 || !isPreviousCommented
        if (isFirstItem) {
          context.output += '/*'
        }
        isPreviousCommented = true
      }

      if (i > 0) {
        context.output += ','
      }

      const isEndOfComment = enabled && isPreviousCommented
      if (isEndOfComment) {
        context.output += '*/'
        isPreviousCommented = false
      }

      // eslint-disable-next-line no-await-in-loop
      await appendDictKey(key, context)
      context.output += ':'
      // eslint-disable-next-line no-await-in-loop,@typescript-eslint/no-use-before-define
      await appendItem(value, context)
    }
  }
  if (isPreviousCommented) {
    context.output += '*/'
  }

  context.output += '}'
}

const appendArray = async <SType>(
  item: JSONArray<SType>,
  context: JSONSerializerInternalContext<SType>,
): Promise<void> => {
  // @TODO pretty printing
  const { items } = item
  let isPreviousCommented = false

  context.output += '['

  for (let i = 0; i < items.length; i += 1) {
    const { item: subItem, enabled } = items[i]
    const isVariableEnabled = enabled || context.outputDisabledItems
    if (isVariableEnabled) {
      if (!enabled) {
        const isFirstItem = i === 0 || !isPreviousCommented
        if (isFirstItem) {
          context.output += '/*'
        }
        isPreviousCommented = true
      }

      if (i > 0) {
        context.output += ','
      }

      const isEndOfComment = enabled && isPreviousCommented
      if (isEndOfComment) {
        context.output += '*/'
        isPreviousCommented = false
      }

      // eslint-disable-next-line no-await-in-loop,@typescript-eslint/no-use-before-define
      await appendItem(subItem, context)
    }
  }

  if (isPreviousCommented) {
    context.output += '*/'
  }

  context.output += ']'
}

const appendItem = async <SType>(
  item: JSONItem<SType>,
  context: JSONSerializerInternalContext<SType>,
): Promise<void> => {
  switch (item.itemType) {
    case JSONItemType.Primitive:
      await appendPrimitive(item, context)
      break
    case JSONItemType.DictKey:
      await appendDictKey(item, context)
      break
    case JSONItemType.Object:
      await appendObject(item, context)
      break
    case JSONItemType.Array:
      await appendArray(item, context)
      break
    default:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      throw new Error(`Invalid Primitive Type for ${(item as any).itemType}`)
  }
}

/**
 * Serializes a JSON tree.
 * Supports the JSON language extensions that Paw uses:
 * - comments for disabled fields
 * - multiple entires for the same key
 * - ordered keys
 *
 * @returns The serialized JSON string
 * @param root
 * @param outputDisabledItems
 */
const serializeJson = async (
  root: JSONItem<string>,
  outputDisabledItems = false,
): Promise<string> => {
  const context: JSONSerializerInternalContext<string> = {
    output: '',
    evaluationFn: null,
    outputDisabledItems,
  }
  await appendItem(root, context)
  return context.output
}

/**
 * Serializes a JSON tree.
 * Supports the JSON language extensions that Paw uses:
 * - comments for disabled fields
 * - multiple entires for the same key
 * - ordered keys
 * As the tree may contain dynamic strings, the user should pass
 * a function that will evaluate each dynamic string found in the tree
 *
 * @param root
 * @param evaluationFn A function that will evalulate dynamic values
 * @param outputDisabledItems
 * @returns The serialized JSON string
 */
export const serializeJsonWithDynamicStrings = async (
  root: JSONItem<Project.GenericRef<Project.DynamicString>>,
  evaluationFn: JSONSerializerEvaluationFn<
    Project.GenericRef<Project.DynamicString>
  >,
  outputDisabledItems = false,
): Promise<string> => {
  const context: JSONSerializerInternalContext<
    Project.GenericRef<Project.DynamicString>
  > = {
    output: '',
    evaluationFn,
    outputDisabledItems,
  }
  await appendItem(root, context)
  return context.output
}

export default serializeJson
