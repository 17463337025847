import { useCallback, useState } from 'react'

import { deleteProject } from 'lib/cloud-sync/cloud-api'
import { getOAuthToken } from './cloud-api-auth'

type Status = 'idle' | 'loading' | 'succeeded' | 'failed'

type UseGetDeleteProjectResult = {
  start: (projectId: number) => Promise<void>
  status: Status
  error: Error | null
}

const useDeleteProject = (): UseGetDeleteProjectResult => {
  const [status, setStatus] = useState<Status>('idle')
  const [error, setError] = useState<Error | null>(null)

  const start = useCallback(async (projectId: number) => {
    setStatus('loading')
    try {
      // get token
      const token = getOAuthToken()
      if (!token) {
        setError(new Error('User is not authenticated'))
        setStatus('failed')
        return
      }

      // delete project via Paw Cloud API
      await deleteProject(projectId, token)

      // success
      setStatus('succeeded')
    } catch (anError) {
      // failure
      setError(anError as Error)
      setStatus('failed')
    }
  }, [])

  return {
    start,
    status,
    error,
  }
}

export default useDeleteProject
