import { useEffect, useState } from 'react'
import { useAppFetch } from 'utils/app-fetch'
import type {
  ContextLinkInfo,
  AssetManifestType,
} from 'pages/download-page/types.d'

const useDownloadLinks = (): [
  ContextLinkInfo[],
  boolean,
  string | undefined,
] => {
  const macNative: ContextLinkInfo = {
    os: 'macNative',
    label: 'macOS',
    link: 'https://paw.cloud/download',
  }

  const [targets, setTargets] = useState<ContextLinkInfo[]>([macNative])
  const [loadingState, setLoadingState] = useState(false)
  const [errorState, setErrorState] = useState<string | undefined>(undefined)

  const appFetch = useAppFetch()

  const devenvs = ['localhost', 'octopaw-beta.paw.app', 'node.test']
  const environment = devenvs.includes(window.location.hostname)
    ? 'octopaw-staging'
    : 'octopaw'

  const baseURL = new URL(
    [environment, 'build-manifest.json'].join('/'),
    'https://cdn-builds.paw.cloud',
  )

  useEffect(() => {
    setLoadingState(true)
    if (!appFetch) {
      setLoadingState(false)
      return
    }

    const fetchManifest = async () => {
      const manifest = await appFetch(baseURL.href, {
        method: 'GET',
      })
      const assets = await manifest.body.json()
      setLoadingState(false)
      setTargets((assets as AssetManifestType).assets)
    }

    fetchManifest().catch((err: unknown) => {
      setErrorState((err as Error).message)
      setLoadingState(false)
    })
  }, [appFetch, baseURL.href])

  return [targets, loadingState, errorState]
}

export default useDownloadLinks
