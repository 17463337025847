import type { Project } from 'lib/project'

import getTokenId from './get-token-id'

const mapDynamicStringToFieldValue = (
  dynamicString: Project.DynamicString | null,
): [string, Project.GenericRef<Project.DynamicValue>[]] => {
  const refs: Project.GenericRef<Project.DynamicValue>[] = []
  const stringVal = (dynamicString?.strings || [])
    .map((item) => {
      if (typeof item === 'string') {
        return item
      }
      refs.push(item)
      return getTokenId(item)
    })
    .join('')
  return [stringVal, refs]
}

export default mapDynamicStringToFieldValue
