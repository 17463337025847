import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { space as spaceStyled, variant as variantStyled } from 'styled-system'
import css from '@styled-system/css'
import { Box } from 'components/layout/box'
import { DynamicFieldInputSizes } from './dynamic-field.types'

const getBorder = ({
  variant,
  hover,
}: {
  variant?: DynamicFieldInputSizes
  hover?: boolean
}) => {
  if (!hover) {
    return { top: '-2px', bottom: '-2px', left: '-2px', right: '-2px' }
  }
  return variant === 'large'
    ? { top: '-8px', bottom: '-8px', left: '-14px', right: '-14px' }
    : { top: '-2px', bottom: '-2px', left: '-10px', right: '-10px' }
}

// @TODO fix type annotation
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DynamicFieldContainerStyled: any = withTheme(
  styled(Box)<{
    variant?: DynamicFieldInputSizes
    width?: number | string
    height?: number | string
    representation: 'default' | 'hover'
    fullWidth?: boolean
    multiline?: boolean
  }>(
    spaceStyled,
    ({
      width = '25ch',
      height,
      variant,
      fullWidth,
      representation,
      multiline,
      theme: { isDark },
    }) =>
      css({
        position: 'relative',
        resize: 'none',
        width: fullWidth ? '100%' : width,
        display: 'inline-flex',
        verticalAlign: 'top',
        borderRadius: 'default',
        zIndex: 10,
        overflow: 'visible',
        height,
        alignItems: 'flex-start',
        alignSelf: 'center',
        margin: '2px',
        py: () => {
          if (!multiline) {
            return undefined
          }
          return variant === 'large' ? 2 : '6px'
        },
        px: () => {
          if (representation === 'hover') {
            return undefined
          }
          return variant === 'large' ? 3 : 2
        },
        minHeight: () => (variant === 'large' ? 36 : 32),
        '>div': {
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
          zIndex: 10,
        },
        '&::before': {
          position: 'absolute',
          content: '""',
          boxSizing: 'content-box',
          margin: 'auto',
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 'default',
          borderColor: 'borders.borderAcent',
          backgroundColor: isDark
            ? 'backgrounds.backgroundSecondary'
            : 'backgrounds.backgroundPrimary',
          zIndex: 1,
          opacity: 0,
          transition: 'opacity .2s ease, border-color .2s ease',
          ...getBorder({ hover: representation === 'hover', variant }),
        },
      }),
    () =>
      variantStyled({
        prop: 'representation',
        variants: {
          default: {
            '&::before': {
              borderColor: 'borders.borderDefault',
              backgroundColor: 'backgrounds.backgroundPrimary',
              opacity: 1,
            },
            '&:focus-within, &.focused': {
              '&::before': {
                borderColor: 'borders.borderAcent',
              },
            },
          },
          hover: {
            '&:focus-within, &.focused': {
              '&::before': {
                display: 'block',
                opacity: 1,
                zIndex: -1,
              },
            },
          },
        },
      }),
  ),
)

export default DynamicFieldContainerStyled
