/* eslint-disable react/jsx-props-no-spreading */

import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { space as spaceStyled } from 'styled-system'
import css from '@styled-system/css'
import { Link, LinkComponentProps } from '../../../navigation/link'

export interface SidebarItemComponentProps<C extends React.ElementType>
  extends LinkComponentProps<C> {}

const SidebarItemStyled = withTheme(
  styled(Link)(
    css({
      bg: 'backgrounds.backgroundPrimary',
      borderColor: 'backgrounds.backgroundPrimary',
      color: 'texts.mediumEmphasis',
      justifyContent: 'flex-start',
      mb: 2,
      p: 2,
      '&:last-child': {
        mb: 0,
      },
      '&:hover, &.focused': {
        color: 'texts.highEmphasis',
        bg: 'backgrounds.backgroundDefaultHover',
        borderColor: 'borders.backgroundDefaultHover',
        textDecoration: 'none',
      },
      '&.selected': {
        bg: 'backgrounds.backgroundSelected',
        borderColor: 'backgrounds.backgroundPrimary',
        color: 'texts.textSelected',
        '&:hover:enabled, &.focused': {
          bg: 'backgrounds.backgroundSelected',
          borderColor: 'backgrounds.backgroundPrimary',
          color: 'texts.textSelected',
        },
      },
    }),
    spaceStyled,
  ),
)

export const SidebarItem = <C extends React.ElementType = 'a'>({
  children,
  selected,
  ...other
}: SidebarItemComponentProps<C> &
  Omit<
    React.ComponentPropsWithoutRef<C>,
    keyof SidebarItemComponentProps<C>
  >) => (
  <SidebarItemStyled
    className={selected ? 'selected' : undefined}
    startIconProps={{ mr: '14px' }}
    {...other}
  >
    {children}
  </SidebarItemStyled>
)

export default SidebarItem
