import type { PayloadAction } from '@reduxjs/toolkit'
import { extensionsActions } from 'store/actions'
import type ExtensionStore from 'store/slices/extensions/types.d'
import type { PawExtension } from 'worker/primary-worker/implementation/extensions/types.d'
import { extensionsGenerateCodeWorkerAction } from 'worker/primary-worker/actions'

import type { Environments } from 'lib/environments/types.d'
import type { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import type { ActionHandler } from '../types.d'

const errorMessage = `
⚠️ ContextJS or RequestJS might not be ready.
  Please try again.
`

const extensionsGenerateCodeActionHandler = async ({
  action: {
    payload: { extension, requestUuid, options, currentEnvironments, userInfo },
  },
  next,
  worker,
}: ActionHandler<
  PayloadAction<{
    extension: ExtensionStore.Model
    requestUuid: string | string[]
    options?: PawExtension.Options
    userInfo?: CloudAPI.UserAccountResponse | null
    currentEnvironments: Environments.CurrentEnvironmentMap
  }>
>): Promise<void> => {
  // if no worker, throw
  if (!worker) {
    throw new Error('Worker is missing')
  }

  try {
    const generatedCode = await worker.runAction(
      extensionsGenerateCodeWorkerAction,
      {
        extension,
        options,
        requestUuid,
        userInfo,
        currentEnvironments,
      },
    )

    next(
      extensionsActions.setGeneratedCodeOutput({
        content: generatedCode,
        language: extension.language || 'text',
        extname:
          extension.fileExtension && extension.fileExtension.length > 0
            ? extension.fileExtension[0]
            : 'txt',
      }),
    )
  } catch (error) {
    const { message } = error as Error
    window.console.warn(`Code generation failed: ${message}`)
    next(
      extensionsActions.setGeneratedCodeOutput({
        content: errorMessage,
        language: 'text',
        extname: 'txt',
      }),
    )
  }
}

export default extensionsGenerateCodeActionHandler
