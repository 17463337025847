/* eslint-disable no-param-reassign */
import type { AnyAction } from '@reduxjs/toolkit'
import type { Patch } from 'immer'
import { applyPatches, produceWithPatches, castDraft } from 'immer'
import type { Project } from 'lib'
import { getNamedSetter } from 'lib/project/setters/named-setters'
import updateCombo from 'lib/project/setters/update-combo'
import { getUuid } from 'lib/utils'
import { projectSetterAction } from 'store/actions'
import { Mutex } from 'utils/concurrency'

import { getAllUsedIds } from 'lib/project/helpers/walk'
import {
  setProject,
  setProjectValue,
  undoAction,
  redoAction,
  acknowledgePendingChanges,
  updateSyncingActionCount,
} from './actions'
import type { ProjectChange, ProjectState } from './types.d'

const initialState: ProjectState = {
  projectId: null,
  root: null,
  objects: {},
  objectsBase: 0,
  pendingChanges: [],
  applyPendingChangesMutex: new Mutex(),
  syncingActionCount: 0,
  undoStack: [],
  redoStack: [],
  syncState: {
    syncBranch: null,
    syncCommitMeta: null,
    syncBranches: {},
    realtimeIsConnected: false,
    cloudPendingChangesCount: 0,
  },
}

function removeDanglingObjects(
  state: ProjectState,
  op: () => [ProjectState['objects'], Patch[], Patch[]],
): ProjectState {
  if (!state.root) {
    return state
  }
  const [objects, patches, inversePatches] = op()

  const used = getAllUsedIds(objects, state.root)

  const [objects2, patches2, inversePatches2] = produceWithPatches(
    objects,
    (draft) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const refRef in objects) {
        if (!used.has(refRef)) {
          if (objects[refRef]?.type !== 'project') {
            delete draft[refRef]
          }
        }
      }
    },
  )

  const change: ProjectChange = {
    uuid: getUuid(),
    patches: [...patches, ...patches2],
    inversePatches: [...inversePatches, ...inversePatches2],
  }

  return {
    ...state,
    objects: objects2 as Project.ObjectMap,
    pendingChanges: [...state.pendingChanges, change],
    undoStack: [...state.undoStack, change],
    redoStack: [],
  }
}

function removeDanglingReferencesDeep(state: ProjectState) {
  const nextObjects = { ...state.objects }
  if (state.root) {
    const used = getAllUsedIds(nextObjects, state.root)

    // eslint-disable-next-line no-restricted-syntax
    for (const refRef in nextObjects) {
      if (!used.has(refRef)) {
        if (nextObjects[refRef]?.type !== 'project') {
          delete nextObjects[refRef]
        }
      }
    }
  }

  return {
    ...state,
    objects: nextObjects,
  }
}

const projectReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: AnyAction,
): ProjectState => {
  if (setProject.match(action)) {
    const nextProject = {
      ...state,
      ...action.payload,
    }
    return removeDanglingReferencesDeep(nextProject)
  }

  if (setProjectValue.match(action)) {
    const { objectRef, update } = action.payload
    const [objects, patches, inversePatches] = produceWithPatches(
      state.objects,
      (draft) => {
        const keys = Object.keys(update)
        // if only 1 key is updated, try to simplify the JSON patch
        if (draft[objectRef.ref]) {
          if (keys.length === 1) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ;(draft[objectRef.ref] as any)[keys[0]] = (update as any)[keys[0]]
          } else {
            draft[objectRef.ref] = {
              ...draft[objectRef.ref],
              ...update,
            } as Project.AnyObject
          }
        }
      },
    )

    const change: ProjectChange = {
      uuid: getUuid(),
      patches,
      inversePatches,
    }

    if (patches.length === 0 && inversePatches.length === 0) {
      return state
    }

    return {
      ...state,
      objects: objects as Project.ObjectMap,
      pendingChanges: [...state.pendingChanges, change],
      undoStack: [...state.undoStack, change],
      redoStack: [],
    }
  }

  if (projectSetterAction.match(action)) {
    const { setter, args } = action.payload

    // get setter function
    // updateCombo is treated differently to avoid dependency cycles
    const setterFn =
      setter === 'updateCombo' ? updateCombo : getNamedSetter(setter)

    const { root } = state
    if (!root) {
      throw new Error('Cannot apply setter if root is not set')
    }
    return removeDanglingObjects(state, () =>
      castDraft(
        produceWithPatches(state.objects, function callSetterFn(draft) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setterFn(draft, root, (args || {}) as any)
        }),
      ),
    )
  }

  if (undoAction.match(action)) {
    const lastChange =
      state.undoStack.length > 0
        ? state.undoStack[state.undoStack.length - 1]
        : null
    if (!lastChange) {
      return state
    }
    const change: ProjectChange = {
      uuid: getUuid(),
      patches: lastChange.inversePatches,
      inversePatches: lastChange.patches,
    }
    return {
      ...state,
      objects: applyPatches(state.objects, lastChange.inversePatches),
      pendingChanges: [...state.pendingChanges, change],
      undoStack: state.undoStack.slice(0, state.undoStack.length - 1),
      redoStack: [...state.redoStack, lastChange],
    }
  }

  if (redoAction.match(action)) {
    const lastChange =
      state.redoStack.length > 0
        ? state.redoStack[state.redoStack.length - 1]
        : null
    if (!lastChange) {
      return state
    }
    const change: ProjectChange = {
      ...lastChange,
      uuid: getUuid(),
    }
    return {
      ...state,
      objects: applyPatches(state.objects, lastChange.patches),
      pendingChanges: [...state.pendingChanges, change],
      undoStack: [...state.undoStack, lastChange],
      redoStack: state.redoStack.slice(0, state.redoStack.length - 1),
    }
  }

  if (acknowledgePendingChanges.match(action)) {
    const actionsIds = new Set(action.payload.actionsIds)
    return {
      ...state,
      ...action.payload.partialUpdate,
      pendingChanges: state.pendingChanges.filter(
        ({ uuid }) => !actionsIds.has(uuid),
      ),
    }
  }

  if (updateSyncingActionCount.match(action)) {
    return {
      ...state,
      syncingActionCount: state.syncingActionCount + action.payload,
    }
  }

  return state
}

export default projectReducer
