/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import type { Project } from 'lib/project'
import type {
  JSONArray,
  JSONArrayElement,
  JSONDict,
  JSONDictElement,
  JSONDictKey,
  JSONItem,
  JSONPrimitive,
  JSONPrimitiveValue,
} from './json-parser-types.d'
import { JSONItemType, JSONPrimitiveType } from './json-parser-types.d'

export enum JsmnTokenType {
  Primitive = 0,
  Object = 1,
  Array = 2,
  String = 3,
}

export interface JsmnToken {
  start: number
  end: number
  type: JsmnTokenType
  size: number
  parent: number
  posinparent: number
  enabled: boolean
  in_comment: boolean
}

export interface Jsmn {
  input: string
  inputLength: number
  tokens: JsmnToken[]
  toksuper: number
  pos: number
}

export const tokenTemplate: JsmnToken = {
  start: -1,
  end: -1,
  type: JsmnTokenType.Primitive,
  size: 0,
  parent: -1,
  posinparent: -1,
  enabled: true,
  in_comment: false,
}

const jsmnParseString = (parser: Jsmn, enabled: boolean): void => {
  const start = parser.pos

  // Skip starting quote
  parser.pos += 1

  for (; parser.pos < parser.inputLength; parser.pos += 1) {
    const c = parser.input[parser.pos]

    // Quote: end of string
    if (c === '"') {
      const token = {
        ...tokenTemplate,
        ...({
          start: start + 1,
          end: parser.pos,
          type: JsmnTokenType.String,
          size: 0,
          enabled,
          parent: parser.toksuper,
          posinparent:
            parser.toksuper !== -1 ? parser.tokens[parser.toksuper].size : -1,
        } as Partial<JsmnToken>),
      }
      parser.tokens.push(token)
      return
    }

    // Backslash: Quoted symbol expected
    if (c === '\\') {
      parser.pos += 1
      switch (parser.input[parser.pos]) {
        // Allowed escaped symbols
        case '"':
        case '/':
        case '\\':
        case 'b':
        case 'f':
        case 'r':
        case 'n':
        case 't':
        case 'u':
          // all good, no op
          break
        // Unexpected symbol
        default:
          parser.pos = start
          throw new Error('JSMN_ERROR_INVAL')
      }
    }
  }

  parser.pos = start
  throw new Error('JSMN_ERROR_PART')
}

const jsmnParsePrimitive = (parser: Jsmn, enabled: boolean): void => {
  const start = parser.pos
  let loop = true

  for (; parser.pos < parser.inputLength; parser.pos += 1) {
    const c = parser.input[parser.pos]
    switch (c) {
      case ':':
      case '\t':
      case '\r':
      case '\n':
      case ' ':
      case ',':
      case ']':
      case '}':
      case '0x0b':
      case '\x0c':
      case '\x85':
      case '\xa0':
      case '\u1680':
      case '\u2000':
      case '\u2001':
      case '\u2002':
      case '\u2003':
      case '\u2004':
      case '\u2005':
      case '\u2006':
      case '\u2007':
      case '\u2008':
      case '\u2009':
      case '\u200a':
      case '\u200b':
      case '\u2028':
      case '\u2029':
      case '\u202f':
      case '\u205f':
      case '\u3000':
      case '/':
      case '*':
        // stop loop
        loop = false
        break
      default:
        // eslint-disable-next-line no-case-declarations
        const charCode = c.charCodeAt(0)
        if (charCode < 32 || charCode >= 127) {
          parser.pos = start
          throw new Error('JSMN_ERROR_INVAL')
        }
        break
    }
    if (!loop) {
      break
    }
  }

  const token = {
    ...tokenTemplate,
    ...({
      start,
      end: parser.pos,
      type: JsmnTokenType.Primitive,
      size: 0,
      enabled,
      parent: parser.toksuper,
      posinparent:
        parser.toksuper !== -1 ? parser.tokens[parser.toksuper].size : -1,
    } as Partial<JsmnToken>),
  }
  parser.tokens.push(token)
  parser.pos -= 1
}

/**
 * JSON tokenizer that serves Paw's custom JSON parsing, which
 * includes JSON format extensions:
 * - comments for disabled fields
 * - multiple entires for the same key
 * - ordered keys
 *
 * Inspired by https://github.com/zserge/jsmn
 *
 * @param input Input string
 * @returns A list of JSON tokens
 */
const jsmnParse = (input: string): JsmnToken[] => {
  const parser: Jsmn = {
    input,
    inputLength: input.length,
    tokens: [],
    toksuper: -1,
    pos: 0,
  }
  let token: JsmnToken = tokenTemplate

  for (; parser.pos < parser.inputLength; parser.pos += 1) {
    const c = input[parser.pos]
    switch (c) {
      case '{':
      case '[':
        token = { ...tokenTemplate }
        if (parser.toksuper !== -1) {
          const parentToken = parser.tokens[parser.toksuper]
          parentToken.size += 1
          token.parent = parser.toksuper
          token.posinparent = parentToken.size - 1
          token.enabled = !parentToken.in_comment
        }
        token.type = c === '{' ? JsmnTokenType.Object : JsmnTokenType.Array
        token.start = parser.pos
        parser.tokens.push(token)
        parser.toksuper = parser.tokens.length - 1
        break

      case '}':
      case ']':
        if (parser.tokens.length < 1) {
          throw new Error('JSMN_ERROR_INVAL')
        }
        token = parser.tokens[parser.tokens.length - 1]
        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (token.start !== -1 && token.end === -1) {
            if (
              token.type !==
              (c === '}' ? JsmnTokenType.Object : JsmnTokenType.Array)
            ) {
              throw new Error('JSMN_ERROR_INVAL')
            }
            token.end = parser.pos + 1
            parser.toksuper = token.parent
            break
          }
          if (token.parent === -1) {
            break
          }
          token = parser.tokens[token.parent]
        }
        break

      case '"':
        jsmnParseString(
          parser,
          parser.toksuper !== -1
            ? !parser.tokens[parser.toksuper].in_comment
            : true,
        )
        if (parser.toksuper !== -1) {
          parser.tokens[parser.toksuper].size += 1
        }
        break
      case '\t':
      case '\r':
      case '\n':
      case ':':
      case ',':
      case ' ':
        // no op
        break

      case '/':
        if (parser.input[parser.pos + 1] !== '*') {
          throw new Error('JSMN_ERROR_INVAL')
        }
        if (parser.toksuper !== -1) {
          parser.tokens[parser.toksuper].in_comment = true
        }
        parser.pos += 1
        break

      case '*':
        if (parser.input[parser.pos + 1] !== '/') {
          throw new Error('JSMN_ERROR_INVAL')
        }
        if (parser.toksuper !== -1) {
          parser.tokens[parser.toksuper].in_comment = false
        }
        parser.pos += 1
        break

      default:
        jsmnParsePrimitive(
          parser,
          parser.toksuper !== -1
            ? !parser.tokens[parser.toksuper].in_comment
            : true,
        )
        if (parser.toksuper !== -1) {
          parser.tokens[parser.toksuper].size += 1
        }
        break
    }
  }

  // check
  const { tokens } = parser
  for (let i = tokens.length - 1; i >= 0; i -= 1) {
    // Unmatched opened object or array
    if (tokens[i].start !== -1 && tokens[i].end === -1) {
      throw new Error('JSMN_ERROR_PART')
    }
  }

  return parser.tokens
}

export { jsmnParse }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeObjectFromJSONDictElements = <T>(
  items: JSONDictElement<T>[],
): Project.ObjectMap =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  items.reduce((p, c) => ({ ...p, [c.key.value]: c.value.value }), {})

type StartEndPair = { start: number; end: number }

export const getJSONDictKey = <T>(
  value: T,
  { start = 0, end = 0 }: StartEndPair = { start: 0, end: 0 },
): JSONDictKey<T> => ({
  end,
  start,
  itemType: JSONItemType.DictKey,
  value,
})

export const getJSONPrimitive = <T>(
  value: JSONPrimitiveValue<T>,
  primitiveType: JSONPrimitiveType = JSONPrimitiveType.String,
  { start = 0, end = 0 }: StartEndPair = { start: 0, end: 0 },
): JSONPrimitive<T> => ({
  end,
  primitiveType,
  start,
  itemType: JSONItemType.Primitive,
  value,
})

export const getJSONDict = <T>(
  items: JSONDictElement<T>[] = [],
  { start = 0, end = 0 }: StartEndPair = { start: 0, end: 0 },
): JSONDict<T> => ({
  itemType: JSONItemType.Object,
  start,
  end,
  items,
})

export const getJSONArray = <T>(
  items: JSONArrayElement<T>[] = [],
  { start = 0, end = 0 }: StartEndPair = { start: 0, end: 0 },
): JSONArray<T> => ({
  itemType: JSONItemType.Array,
  start,
  end,
  items,
})

export const getJSONDictElement = <T>(
  index: number,
  key: JSONDictKey<T>,
  value: JSONPrimitive<T>,
  enabled = true,
): JSONDictElement<T> => ({
  enabled,
  index,
  key,
  value,
})
/**
 * @template IV Type of the value of the item
 * @template IT Type of the item. is a JSONItem with a value of IV
 * @param {number} index
 * @param {IT} item
 * @return {*}  {JSONArrayElement<IV>}
 */
export const getJSONArrayElement = <IV, IT extends JSONItem<IV>>(
  index: number,
  item: IT,
  enabled = true,
): JSONArrayElement<IV> => ({
  enabled,
  index,
  item,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeObjectFromJSONArrayElement = <T>(
  items: JSONArrayElement<T>[],
): Project.ObjectMap =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  items.map((e) => {
    switch (e.item.itemType) {
      case JSONItemType.Object: {
        const jsonObject = e.item
        return makeObjectFromJSONDictElements(jsonObject.items)
      }
      case JSONItemType.Array: {
        const jsonObject = e.item
        return makeObjectFromJSONArrayElement(jsonObject.items)
      }
      case JSONItemType.DictKey:
      case JSONItemType.Primitive: {
        const jsonObject = e.item
        return jsonObject.value
      }
      default: {
        return ''
      }
    }
  })
