/* eslint-disable react/jsx-props-no-spreading */

import React, {
  memo,
  useCallback,
  useState,
  useEffect,
  useMemo,
  MouseEvent,
  forwardRef,
} from 'react'
import { withTheme } from '@emotion/react'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { space } from 'styled-system'
import { IconButton } from 'components/inputs/icon-button'
import { Menu, MenuItem } from 'components/navigation/menu'
import { Box } from 'components/layout/box'
import { Icon } from 'components/data-display/icon'
import { TabBarItemButton } from './tab-bar-item-button'
import {
  TabBarItemRefProps,
  TabBarItemProps,
  TabBarItemComponentProps,
} from './tab-bar-item.types'
import { TabProps } from '../tab-bar.types'

type TabBarItemStyledProps = {
  hasOptions: boolean
  selected: boolean
}

const TabBarItemStyled = withTheme(
  styled('li')<TabBarItemStyledProps>(space, ({ hasOptions, selected }) =>
    css({
      display: 'inline-flex',
      marginRight: 2,
      marginBottom: 1,
      paddingY: 2,
      paddingLeft: 3,
      paddingRight: hasOptions ? 2 : 3,
      borderRadius: 'default',
      transition: 'background-color .2s ease',
      backgroundColor: selected
        ? 'backgrounds.backgroundSelected'
        : 'transparent',
      whiteSpace: 'nowrap',
      '&:last-of-type': {
        marginRight: 0,
      },
    }),
  ),
)

const getSelection = (selected: boolean) =>
  selected ? (
    <Icon symbol="check" size={20} mr={1} />
  ) : (
    <Box width={20} mr={1} />
  )

export const TabBarItem = forwardRef<
  TabBarItemRefProps,
  TabBarItemComponentProps
>(
  (
    {
      liProps,
      selectedOptions,
      selectedTab,
      tab,
      onChange,
      ...props
    }: TabBarItemProps,
    forwardedRef,
  ) => {
    const [anchorMenu, setAnchorMenu] = useState<Element | null>(null)
    const [item, setItem] = useState<TabProps | undefined>(tab)

    const setParentItem = useCallback(
      (option) => {
        if (tab.options !== undefined && tab.options.length > 0) {
          setItem({
            key: option.key,
            label: `${tab.label}: ${option.label}`,
          })
          return
        }
        if (option) {
          setItem(option)
        }
      },
      [tab],
    )

    useEffect(() => {
      if (selectedTab === tab.key) {
        setParentItem(tab)
        return
      }

      if (!(tab.options !== undefined && tab.options.length > 0)) {
        return
      }

      const option =
        tab.options?.find((t) => t.key === selectedTab) ??
        (!item ? tab.options[0] : undefined)

      if ((!item && option) || (option && item && option.key !== item.key)) {
        setParentItem(option)
      }
    }, [tab, item, selectedTab, setParentItem])

    useEffect(() => {
      if (
        !(
          selectedOptions !== undefined &&
          selectedOptions.length > 0 &&
          tab.options !== undefined &&
          tab.options.length > 0
        )
      ) {
        return
      }

      const option =
        selectedOptions.find((selectedOption) =>
          tab.options?.find((t) => t.key === selectedOption),
        ) ?? tab.options[0]

      if (option) {
        setParentItem(option)
      }
    }, [selectedOptions, tab, setParentItem])

    const onClickMenu = (event: MouseEvent<Element>) => {
      setAnchorMenu(event.currentTarget)
    }

    const onCloseMenu = () => {
      setAnchorMenu(null)
    }

    const onClick = useMemo(
      () => (key: string) => (event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault()
        event?.stopPropagation()

        const onChangeHandler = tab.onChange ?? onChange

        if (onChangeHandler) {
          onChangeHandler(key)
        }

        setAnchorMenu(null)
      },
      [tab, onChange],
    )

    if (!item) {
      return null
    }

    return (
      <TabBarItemStyled
        ref={forwardedRef}
        selected={item.key === selectedTab}
        hasOptions={Boolean(tab.options)}
        {...liProps}
        {...props}
      >
        <TabBarItemButton onClick={onClick(item.key)}>
          {item.label}
        </TabBarItemButton>
        {tab.options && (
          <React.Fragment>
            <IconButton
              icon="caret-down"
              size="small"
              // @TODO: change it to ml after refactoring of IconButton
              css={{ marginLeft: 4 }}
              onClick={onClickMenu}
              noBorder
            />
            <Menu
              popoverProps={{
                anchorEl: anchorMenu,
                open: Boolean(anchorMenu),
                onClose: onCloseMenu,
                placement: 'bottom-end',
              }}
              fullWidth
            >
              {!tab.options && (
                <MenuItem
                  startAdornment={getSelection(tab.key === item.key)}
                  key={tab.key}
                  onSelect={onClick(tab.key)}
                >
                  {tab.label}
                </MenuItem>
              )}
              {tab.options &&
                tab.options.map((option) => (
                  <MenuItem
                    startAdornment={getSelection(option.key === item.key)}
                    key={option.key}
                    onSelect={onClick(option.key)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
            </Menu>
          </React.Fragment>
        )}
      </TabBarItemStyled>
    )
  },
)

export default memo(TabBarItem)
