import type { PayloadAction } from '@reduxjs/toolkit'
import { extensionsActions } from 'store/actions'
import { extensionUnloadItemWorkerAction } from 'worker/primary-worker/actions'

import type ExtensionStore from 'store/slices/extensions/types.d'
import type { ActionHandler } from '../types.d'

const extensionsUnloadItemActionHandler = async ({
  action: { payload },
  next,
  worker,
}: ActionHandler<PayloadAction<ExtensionStore.Model>>): Promise<void> => {
  // if no worker, throw
  if (!worker) {
    throw new Error('Worker is missing')
  }

  // remove extension from worker context
  await worker.runAction(extensionUnloadItemWorkerAction, payload)

  // remove extension resource
  next(
    extensionsActions.unSetExtensionsResource(
      Array.isArray(payload)
        ? payload.map((ext: ExtensionStore.Model): string => ext.url)
        : payload.url,
    ),
  )
}

export default extensionsUnloadItemActionHandler
