import type { Project } from 'lib/project'

import addEnvironment from './add-environment'
import addEnvironmentDomain from './add-environment-domain'
import addRequest from './add-request'

const getNewProject = (): {
  root: Project.GenericRef<Project.Project>
  objects: Project.ObjectMap
} => {
  const root: Project.Project = {
    uuid: 'root',
    type: 'project',
    encryptionKeyHash: null,
    requests: [],
    environmentDomains: [],
    sessions: [],
  }
  const rootRef: Project.GenericRef<Project.Project> = { ref: root.uuid }
  const objects: Project.ObjectMap = {
    [rootRef.ref]: root,
  }
  addRequest(objects, rootRef, {})
  const domainRef = addEnvironmentDomain(objects, rootRef, {
    name: 'Default Group',
  })
  addEnvironment(objects, rootRef, { domainRef, name: 'Production' })
  return {
    root: rootRef,
    objects,
  }
}

export default getNewProject
