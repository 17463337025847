import type { PayloadAction } from '@reduxjs/toolkit'

import { setProject } from 'store/actions'

import type { ActionHandler } from '../types.d'

const projectUnloadActionHandler = async ({
  next,
  worker,
  setWorker,
}: ActionHandler<PayloadAction<number>>): Promise<void> => {
  // already has a worker, terminate it
  if (worker) {
    worker.terminate()
    setWorker(null)
  }
  next(
    setProject({
      root: null,
      objects: {},
      objectsBase: 0,
    }),
  )
}

export default projectUnloadActionHandler
