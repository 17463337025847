/* eslint-disable react/jsx-props-no-spreading */

import { withTheme } from '@emotion/react'
import { Box } from 'components/layout/box'
import styled from '@emotion/styled'
import { space, SpaceProps } from 'styled-system'
import css from '@styled-system/css'

export type DialogActionsProps = SpaceProps

const DialogActionsStyled = withTheme(
  styled(Box)(
    space,
    css({
      '> *': {
        marginRight: 2,
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    }),
  ),
)

const DialogActions: React.FC<DialogActionsProps> = ({
  children,
  ...other
}) => (
  <DialogActionsStyled
    display="flex"
    justifyContent="flex-end"
    mt={4}
    {...other}
  >
    {children}
  </DialogActionsStyled>
)

export default DialogActions
