import type { GraphQLSchema } from 'graphql'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import type { GraphQLDynamicValueInterface, Project } from 'lib'
import { useDynamicStringOptionalObject, useDynamicValueObject } from 'utils'
import { setProjectValue } from 'store/actions'
import type { MonacoLanguage } from 'ecosystems/advanced-editor/types'
import AdvancedMonaco from '../../advanced-editor/advanced-monaco'
import useSyncVariables from './use-sync-variables'
import generateProvideCompletionItems from './generate-provide-completion-items'

interface GQLEditorProps {
  objectRef: Project.GenericRef<GraphQLDynamicValueInterface>
  schema: GraphQLSchema
}

const GQLEditor: React.FC<GQLEditorProps> = ({ objectRef, schema }) => {
  const dispatch = useDispatch()
  const dynamicValue = useDynamicValueObject<GraphQLDynamicValueInterface>(
    objectRef || { ref: '' },
  )

  const gqlDS = useDynamicStringOptionalObject(dynamicValue.gqlQuery)

  const uuid = gqlDS?.uuid
  useSyncVariables(objectRef)

  const onBlur = useCallback(
    (e: string) => {
      dispatch(
        setProjectValue({
          objectRef: { ref: uuid || '' },
          update: { strings: [e] },
        }),
      )
    },
    [dispatch, uuid],
  )

  const provideCompletionItemsCB = useCallback(
    () => generateProvideCompletionItems(schema),
    [schema],
  )

  const language: MonacoLanguage = useMemo(
    () => ({
      language: 'graphql',
      provideCompletionItems: provideCompletionItemsCB(),
      schema,
    }),
    [schema, provideCompletionItemsCB],
  )
  return (
    <AdvancedMonaco
      content={(gqlDS?.strings[0] as string) || ''}
      objectRef={objectRef}
      onBlur={onBlur}
      language={language}
    />
  )
}

export default GQLEditor
