/* eslint-disable import/prefer-default-export */
import { useContext, useMemo } from 'react'
import DialogContext from './dialog-context'

export const usePortalRoot = (): HTMLDivElement => {
  // Get portal root from the context
  const { portalRoot } = useContext(DialogContext)

  // In cases `portal-root` is not available,
  // create and append it to body.
  const portalRootEffective = useMemo(() => {
    if (!portalRoot) {
      // try to find an existing portal root by id
      let newPortalRoot = document.getElementById(
        'portal-root',
      ) as HTMLDivElement

      // if none, create one
      if (!newPortalRoot) {
        newPortalRoot = document.createElement('div') as HTMLDivElement
        newPortalRoot.setAttribute('id', 'portal-root')
        document.body.appendChild(newPortalRoot)
      }

      return newPortalRoot
    }

    return portalRoot
  }, [portalRoot])

  return portalRootEffective as HTMLDivElement
}
