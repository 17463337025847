import { createSelector } from '@reduxjs/toolkit'
import { selectProject } from './internal'
import type { ProjectChange } from '../types.d'

export const selectProjectPendingChanges = createSelector(
  selectProject,
  (project): { changes: ProjectChange[]; objectsBase: number } => ({
    objectsBase: project.objectsBase,
    changes: project.pendingChanges,
  }),
)

export const selectProjectApplyPendingChangesMutex = createSelector(
  selectProject,
  (project) => project.applyPendingChangesMutex,
)
