import config from 'config'
import { getFullUrl, randomInt32 } from 'lib/utils'
import apiConfig from 'lib/cloud-sync/cloud-api/config'

const getAuthorizeUrl = (state: string) =>
  getFullUrl(config.pawCloud.authorizeUrl, {
    client_id: config.pawCloud.clientId,
    response_type: 'token',
    scope: 'paw-for-teams',
    state,
    // important! `redirect_uri` should be constant for Electron app
    // always use 'https://paw.app/oauth' for Electron
    redirect_uri:
      config.build === 'web'
        ? `${window.location.origin}/oauth`
        : 'https://paw.app/oauth',
  })

interface OAuthPair {
  state: string
  authorizeUrl: string
}

export const getAuthorizePair = (): OAuthPair => {
  const state = randomInt32().toString(36)

  return {
    state,
    authorizeUrl: getAuthorizeUrl(state),
  }
}

const OAUTH_TOKEN_KEY = 'pawOAuthToken'

// @TODO fix this as localStorage is browser only
export const getOAuthToken = (): string | null => {
  const { API_USE_MOCKS } = apiConfig
  if (API_USE_MOCKS) {
    return 'mock-token'
  }
  return window ? window.localStorage.getItem(OAUTH_TOKEN_KEY) : null
}

// @TODO fix this as localStorage is browser only
export const setOAuthToken = (token: string): void =>
  window && window.localStorage.setItem(OAUTH_TOKEN_KEY, token)

export const removeOAuthToken = (): void =>
  window && window.localStorage.removeItem(OAUTH_TOKEN_KEY)
