/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import config from 'config'
import type {
  AppUpdateNotifierState,
  PanelState,
  SettingsState,
} from './types.d'

const initialState: SettingsState = {
  theme: 'light',
  hotkeysHelp: false,
  panel: {
    leftPaneTopWidth: 400,
    leftPaneBotHeight: 400,
    rightPaneWidth: 600,
  },
  editor: {
    app: {
      vsPath: '/vs',
    },
    options: {
      readOnly: true,
      theme: 'PawLight',
      minimap: {
        enabled: false,
      },
      scrollbar: {
        verticalScrollbarSize: 5,
        horizontalScrollbarSize: 5,
      },
      renderLineHighlight: 'none' as const,
    },
    /**
     * @deprecated
     * the key/values below are deprecated and are kept for the time being
     * to avoid breaking user experience, moving forward all references to
     * monaco editor options and app paths will be referenced from the
     * declaration above this comment.
     */
    readOnly: true,
    theme: 'PawLight',
    vsPath: '/vs',
    minimap: {
      enabled: false,
    },
    scrollbar: {
      verticalScrollbarSize: 5,
      horizontalScrollbarSize: 5,
    },
    renderLineHighlight: 'none' as const,
  },
  agentNotifier: {
    lastDismissedAt: null,
  },
  appUpdateNotifier:
    config.build === 'web'
      ? null
      : {
          current: config.version || '',
          latest: undefined,
          status: undefined,
          error: null,
        },
  extensions: {
    showPanel: false,
    codeGeneratorIdentifier: null,
  },
}

const settingsStateSlice = createSlice({
  name: 'settingsState',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<'light' | 'dark' | 'auto'>) => {
      state.theme = action.payload
    },
    toggleTheme: (state) => {
      state.theme = state.theme === 'light' ? 'dark' : 'light'
    },
    setPanel: (state, action: PayloadAction<PanelState>) => {
      state.panel = { ...action.payload }
    },
    setAgentNotifierLastDismissedAt: (
      state,
      action: PayloadAction<number | null>,
    ) => {
      state.agentNotifier = {
        ...state.agentNotifier,
        lastDismissedAt: action.payload,
      }
    },
    setAppUpdateNotifierContext: (
      state,
      action: PayloadAction<AppUpdateNotifierState>,
    ) => {
      if (config.build === 'web') {
        state.appUpdateNotifier = null
        return
      }
      state.appUpdateNotifier = {
        ...state.appUpdateNotifier,
        ...action.payload,
      }
    },
    toggleExtensionsPanel: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        state.extensions = {
          ...state.extensions,
          showPanel: false,
        }
      }

      state.extensions = {
        ...state.extensions,
        showPanel: action.payload,
      }
    },
    setActiveCodeGenerator: (state, action: PayloadAction<string | null>) => {
      if (!action.payload) {
        return
      }

      state.extensions = {
        ...state.extensions,
        codeGeneratorIdentifier: action.payload,
      }
    },
    toggleHotkeyCheatsheet: (state, action: PayloadAction<boolean>) => {
      state.hotkeysHelp = action.payload
    },
    setMonacoVsPath: (state, action: PayloadAction<string>) => {
      state.editor = {
        ...state.editor,
        app: { vsPath: action.payload },
        /**
         * @deprecated
         * please move all references of vsPath to the the declaration above:
         * e.g. sta`te.editor.app.vsPath`
         */
        vsPath: action.payload,
      }
    },
  },
})

export default settingsStateSlice
