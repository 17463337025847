import { createSelector } from '@reduxjs/toolkit'

import { getBranchNameInfo, isStagingBranch } from 'lib/utils'
import { selectUserId } from 'store/slices/account/selectors'
import { selectProject } from './internal'

export const selectProjectCurrentSyncBranch = createSelector(
  selectProject,
  (project) => project.syncState.syncBranch,
)

export const selectProjectCurrentSyncBranches = createSelector(
  selectProject,
  (project) => project.syncState.syncBranches,
)

export const selectProjectUserBranches = createSelector(
  selectProjectCurrentSyncBranches,
  selectProjectCurrentSyncBranch,
  selectUserId,
  (syncBranches, currentSyncBranch, userId) =>
    Object.fromEntries(
      Object.entries(syncBranches).filter(
        ([branchName]) =>
          !isStagingBranch(branchName) ||
          branchName === currentSyncBranch ||
          getBranchNameInfo(branchName).authorId === userId,
      ),
    ),
)

export const selectIsCloudSyncing = createSelector(
  selectProject,
  (project): boolean => project.syncingActionCount > 0,
)

export const selectCloudRealtimeIsConnected = createSelector(
  selectProject,
  (project): boolean => project.syncState.realtimeIsConnected,
)

export const selectCloudNeedsSyncing = createSelector(
  selectProject,
  (project): boolean => project.syncState.cloudPendingChangesCount > 0,
)
