/* eslint-disable no-param-reassign */
import type { Project } from 'lib/project/types.d'
import { getHeaderByName } from '../getters/molecules'
import addKeyValue from './add-key-value'
import deleteKeyValue from './delete-key-value'
import updateDynamicString from './update-dynamic-string'

export interface UpdateRequestAuthDynamicStringArgs<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Y extends Project.ObjectMap<any>,
> {
  requestRef: Project.GenericRef<Project.Request>
  strings: (string | Project.GenericRef<Project.DynamicValue>)[] | null
  insertDynamicValues?: Project.InsertDynamicValue<Y>[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateRequestAuthDynamicString = <Y extends Project.ObjectMap<any>>(
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  {
    requestRef,
    strings,
    insertDynamicValues,
  }: UpdateRequestAuthDynamicStringArgs<Y>,
): void => {
  // get request auth header
  let authHeaderRef = getHeaderByName(requestRef, objects, 'authorization')

  // insert header if missing
  if (!authHeaderRef) {
    // add header
    authHeaderRef = addKeyValue(objects, root, {
      parentRef: requestRef,
      parentProperty: 'headers',
    })

    // set name value (as it's a newly created header)
    updateDynamicString(objects, root, {
      parentRef: authHeaderRef,
      parentProperty: 'name',
      strings: ['Authorization'],
    })
  }

  if (strings === null) {
    // set to null, we delete the auth header
    deleteKeyValue(objects, root, {
      ref: authHeaderRef,
      parentRef: requestRef,
      parentProperty: 'headers',
    })
  } else {
    // update header
    updateDynamicString(objects, root, {
      parentRef: authHeaderRef,
      parentProperty: 'value',
      strings,
      inserts: insertDynamicValues ?? [],
    })
  }
}

export default updateRequestAuthDynamicString
