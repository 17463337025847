/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import type { SpaceProps } from 'styled-system'

import { tabsStateActions } from 'store/actions'
import { currentTabSelectorFactory } from 'store/selectors'

import type { BoxProps } from '@rapidapi/ui-lib'
import { Box, Tabs, Tab, ButtonTabs, ButtonTab } from '@rapidapi/ui-lib'

export type TabBarContainerTab = {
  key: string
  label: string
}

export type TabBarContainerConfig = SpaceProps & {
  identifier: string
  tabs: TabBarContainerTab[]
  defaultTab: string
}

type TabBarContainerProps = BoxProps & {
  config: TabBarContainerConfig
  tabAlign?: string
  renderTab: (tabValue: string) => JSX.Element | null | false
  variant?: 'tab' | 'segment'
  tabBarProps?: SpaceProps
}

const TabBarContainer: React.FC<TabBarContainerProps> = ({
  config,
  renderTab,
  variant = 'tab',
  tabBarProps,
  ...more
}) => {
  const { tabs, identifier } = config

  const currentTabSelector = useMemo(
    () =>
      currentTabSelectorFactory(
        config.identifier,
        config.defaultTab,
        tabs.map(({ key }) => key),
      ),
    [config, tabs],
  )
  const currentTab = useSelector(currentTabSelector)
  const currentTabIndex = tabs.findIndex(({ key }) => key === currentTab)

  const dispatch = useDispatch()
  const setCurrentTab = useCallback(
    (tab) => {
      dispatch(
        tabsStateActions.setCurrentTab({
          identifier,
          currentTab: tabs[tab].key,
        }),
      )
    },
    [identifier, dispatch, tabs],
  )

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Box {...(more as any)}>
      {variant === 'segment' ? (
        <ButtonTabs
          value={currentTabIndex}
          onChange={setCurrentTab}
          {...tabBarProps}
        >
          {tabs.map(({ key, label }) => (
            <ButtonTab key={key}>{label}</ButtonTab>
          ))}
        </ButtonTabs>
      ) : (
        <Tabs value={currentTabIndex} onChange={setCurrentTab} {...tabBarProps}>
          {tabs.map(({ key, label }) => (
            <Tab key={key}>{label}</Tab>
          ))}
        </Tabs>
      )}
      {renderTab(currentTab)}
    </Box>
  )
}

export default TabBarContainer
