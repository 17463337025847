export const isValidBranchName = (branchName: string): boolean =>
  !!branchName.match(/^[a-z0-9\\-]{3,30}$/)

export const isStagingBranch = (branchName: string): boolean =>
  branchName.indexOf('@') >= 0

export const isAutoSaveCommit = (commitMessage: string): boolean =>
  !!commitMessage.match(/^~/)

interface BranchNameInfo {
  branchName: string
  mainBranchName: string
  isMainBranch: boolean
  baseCommitSha: string | null
  authorId: number | null
}

export const getBranchNameInfo = (branchName: string): BranchNameInfo => {
  const m = branchName.match(/^([^+@]+)#([0-9a-f]+)@(\d+)/i)
  if (m) {
    return {
      branchName,
      mainBranchName: m[1],
      isMainBranch: false,
      baseCommitSha: m[2],
      authorId: parseInt(m[3], 10),
    }
  }

  return {
    branchName,
    mainBranchName: branchName,
    isMainBranch: true,
    baseCommitSha: null,
    authorId: null,
  }
}
