import type { Evaluation } from '../types.d'

const getLayer = (
  ctx: Evaluation.Ctx,
  layerType: string,
): Evaluation.CtxLayer | null =>
  ctx.layers
    .slice()
    .reverse()
    .find((layer) => layer.layerType === layerType) || null

export default getLayer
