/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react'
import type { EditorProps, Monaco } from '@monaco-editor/react'
import MonacoEditor from '@monaco-editor/react'
import type * as _monaco_ from 'monaco-editor/esm/vs/editor/editor.api'
import { useDispatch, useSelector } from 'react-redux'
import { selectEditorConfig, selectPanelSettings } from 'store/selectors'
import { useAppConfig, useMonacoHook } from 'utils'
import editorKeyBindings from './editor-keybindings'

interface Props {
  content: string
  language?: string
  height?: number
  readOnly?: boolean
  monacoVsPath?: string
  onChange?: (value: string) => void
}

const Editor: React.FC<Props> = ({
  content = '',
  language = 'text',
  readOnly = true,
  onChange,
}) => {
  const settings = useSelector(selectEditorConfig)

  // Reuse the customized monaco instance for
  // theme settings+colors to take effect.
  useMonacoHook(settings)

  const panelSettings = useSelector(selectPanelSettings)
  const dispatch = useDispatch()
  const handleEditorChange = useCallback(
    (value) => {
      if (onChange) {
        onChange(value)
      }
    },
    [onChange],
  )

  /**
   * Get data from AppConfig to determine if we should move styles to a Shadow DOM.
   * This is being used for the Request Generator.
   * The goal is to move the Monaco CSS files into the Shadow DOM when needed.
   */
  const appConfig = useAppConfig()
  const onMount = useCallback(
    (editor: _monaco_.editor.IStandaloneCodeEditor, monaco: Monaco) => {
      const shadowRoot = appConfig.monacoStylesRoot

      // interface of app shortcuts for monaco editor
      editorKeyBindings(editor, monaco, dispatch, panelSettings)

      if (!shadowRoot) {
        return
      }

      // From: https://github.com/microsoft/monaco-editor/issues/1956
      // We must move all CSS inside the shadow root, pick only link tags relevant to the editor
      const documentLinks = Array.prototype.slice
        .call(document.getElementsByTagName('link'), 0)
        .filter(
          (documentLink) =>
            !!/vs\/(base|editor|platform)/.test(
              documentLink.getAttribute('href'),
            ),
        )
      documentLinks.forEach((documentLink) =>
        shadowRoot.appendChild(documentLink),
      )
    },
    [appConfig.monacoStylesRoot, dispatch, panelSettings],
  )

  const editorProps: EditorProps = {
    options: {
      ...settings.options,
      hideCursorInOverviewRuler: true,
      overviewRulerLanes: 0,
      readOnly,
      tabSize: 2,
      contextmenu: false,
      automaticLayout: true,
      scrollBeyondLastColumn: 0,
      scrollBeyondLastLine: false,
    },
    language,
    theme: settings.options.theme,
    value: content.trim(),
    onChange: handleEditorChange,
    onMount,
  }

  return <MonacoEditor {...editorProps} />
}

export default Editor
