import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex, ButtonTabs, ButtonTab, usePrompt } from '@rapidapi/ui-lib'

import type { RequestTabViewProps } from 'ecosystems/project/request-editor/request-tabs/request-tabs-props.d'
import {
  selectAuthIsEmptyFactory,
  selectCurrentRequestAuthDynamicValueIdentifier,
} from 'store/selectors'
import { applyProjectSetter } from 'store/actions'
import { useCurrentRequestRef } from 'utils/hooks'

import {
  getAuthTabKey,
  authTabComponents,
  getNewAuthDynamicValue,
  getAuthTabName,
} from './auth-tabs-functions'
import type { AuthTabComponentList, AuthTabKey } from './auth-tabs-types.d'

const renderAuthTypeTab = (tabKey: AuthTabKey): JSX.Element => {
  const ActiveTabContent = authTabComponents[tabKey]
  if (!ActiveTabContent) {
    throw new Error(`[RequestAuthTab] Missing tab for key ${tabKey}`)
  }
  return <ActiveTabContent />
}

const RequestAuthTab: React.FC<RequestTabViewProps> = () => {
  // get the auth dynamic value type
  // (if only one dynamic value is set in the Authorization header)
  const requestRef = useCurrentRequestRef()
  const authOnlyDvIdentifier = useSelector(
    selectCurrentRequestAuthDynamicValueIdentifier,
  )
  const selectAuthIsEmptySelector = useMemo(
    () => selectAuthIsEmptyFactory(requestRef || { ref: '' }),
    [requestRef],
  )
  const authIsEmpty = useSelector(selectAuthIsEmptySelector)

  const currentTab = useMemo(
    () => getAuthTabKey(authOnlyDvIdentifier, authIsEmpty),
    [authIsEmpty, authOnlyDvIdentifier],
  )

  const renderedTabs = React.useMemo(() => {
    const tabs: Array<keyof AuthTabComponentList> = [
      'none',
      'basic',
      'oauth1',
      'oauth2',
    ]
    if (currentTab === 'custom') {
      tabs.push('custom')
    }
    return tabs
  }, [currentTab])

  const prompt = usePrompt()
  const dispatch = useDispatch()

  // switch auth tab: for that it needs to update the auth scheme
  // - we warn the user that they may loose data by switching
  // - we set a dynamic value in the Authorization header corresponding to the new selected scheme
  const setCurrentTab = useCallback(
    (tabIndex: number) => {
      const tabValue = renderedTabs[tabIndex]
      if (!tabValue || !requestRef || currentTab === tabValue) {
        return
      }
      const { insertDynamicValues, strings } = getNewAuthDynamicValue(
        tabValue as AuthTabKey,
      )
      const doAction = () => {
        dispatch(
          applyProjectSetter('updateRequestAuthDynamicString')({
            requestRef,
            strings,
            insertDynamicValues,
          }),
        )
      }
      // if auth is empty or pristine, do not ask for prompt
      if (authIsEmpty) {
        doAction()
        return
      }
      prompt({
        title:
          (tabValue as AuthTabKey) === 'none'
            ? 'Remove authorization?'
            : 'Switch authorization scheme?',
        content:
          (tabValue as AuthTabKey) === 'none'
            ? 'This will remove the current authorization header.'
            : `Selecting auth type ${getAuthTabName(
                tabValue as AuthTabKey,
              )} will erase any previous values.`,
        proccedText:
          (tabValue as AuthTabKey) === 'none' ? 'Remove Auth' : 'Switch Auth',
        onProceed: doAction,
      })
    },
    [renderedTabs, requestRef, currentTab, authIsEmpty, prompt, dispatch],
  )

  return (
    <>
      <ButtonTabs
        value={renderedTabs.indexOf(currentTab)}
        onChange={setCurrentTab}
        mt={5}
        px={5}
      >
        {renderedTabs.map((key) => (
          <ButtonTab key={key}>{key}</ButtonTab>
        ))}
      </ButtonTabs>
      <Flex flexBasis={0} flexGrow={1} overflow="hidden" flexDirection="column">
        {renderAuthTypeTab(currentTab as AuthTabKey)}
      </Flex>
    </>
  )
}

export default RequestAuthTab
