import type { DynamicValues } from 'lib/dynamic-values/types.d'
import type { Evaluation } from 'lib/evaluation'
import { evaluateBody, evaluateHeader } from 'lib/evaluation/request'
import { getRequestLayer } from 'lib/evaluation/layers'
import type { Project } from 'lib'
import getRequest from '../../../project/getters/atoms/get-request'
import getHeaderByName from '../../../project/getters/molecules/get-header-by-name'
import type { RequestBodyLengthDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.RequestBodyLengthDynamicValue'
const title = 'Request Body Length'

const contentLengthFromBody = async (
  requestRef: Project.GenericRef<Project.Request>,
  ctx: Evaluation.Ctx,
) => {
  const request = getRequest(requestRef, ctx.project.objects, true)
  if (!request) {
    return ''
  }

  const bodyString = await evaluateBody(request, ctx)
  return new Blob([bodyString]).size.toString()
}

const contentLengthFromHeader = async (
  contentLengthHeaderRef: Project.GenericRef<Project.KeyValue>,
  ctx: Evaluation.Ctx,
) => {
  const evaluatedHeader = await evaluateHeader(contentLengthHeaderRef, ctx)
  return evaluatedHeader?.value || ''
}

const defaultValue: RequestBodyLengthDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  request: null,
}

const editForm: DynamicValues.EditForm<RequestBodyLengthDynamicValueInterface> =
  {
    fields: [
      {
        fieldKey: 'request',
        fieldType: 'request',
        label: 'Request',
      },
    ],
  }

function getRequestRef(requestUUID: string | null, ctx: Evaluation.Ctx) {
  return requestUUID ? { ref: requestUUID } : getRequestLayer(ctx)?.requestRef
}

const implRequestBodyLengthDynamicValue: DynamicValues.Implementation<RequestBodyLengthDynamicValueInterface> =
  {
    title,
    identifier,
    defaultValue,
    editForm,
    getAllRefs() {
      return null
    },
    getEvaluatedString: async ({ request: requestUUID }, ctx) => {
      const requestRef = getRequestRef(requestUUID, ctx)
      if (!requestRef) {
        return ''
      }

      const contentLengthHeaderRef = getHeaderByName(
        requestRef,
        ctx.project.objects,
        'content-length',
      )
      if (contentLengthHeaderRef) {
        return contentLengthFromHeader(contentLengthHeaderRef, ctx)
      }

      return contentLengthFromBody(requestRef, ctx)
    },
    getTokenInfo: async ({ request: requestUUID }, ctx) => {
      const requestRef = getRequestRef(requestUUID, ctx)
      if (requestRef) {
        const requestObject = getRequest(requestRef, ctx.project.objects, true)
        if (requestObject) {
          return {
            title,
            text: requestObject.title || null,
          }
        }
      }
      return {
        title,
        text: '',
      }
    },
  }

export default implRequestBodyLengthDynamicValue
