/* eslint-disable react/jsx-props-no-spreading */

import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import css from '@styled-system/css'
import { Box } from 'components/layout/box'

const DividerStyled = withTheme(
  styled(Box)(
    css({
      backgroundColor: 'borders.borderDefault',
      height: 1,
    }),
  ),
)

export const Divider: React.FC = () => <DividerStyled my={2} mx={-2} />

export default Divider
