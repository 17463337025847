/* eslint-disable react/jsx-props-no-spreading */

import {
  createRef,
  forwardRef,
  memo,
  MouseEvent,
  MutableRefObject,
  useMemo,
  useState,
} from 'react'
import { withTheme } from '@emotion/react'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { space, layout } from 'styled-system'
import useResizeObserver from 'use-resize-observer'
import { Box } from 'components/layout/box'
import { Icon } from 'components/data-display/icon'
import { IconButton } from 'components/inputs/icon-button'
import { Menu, MenuItem } from 'components/navigation/menu'
import {
  SegmentProps,
  SegmentBarRefProps,
  SegmentBarProps,
  SegmentBarComponentProps,
} from './segment-bar.types'

const SegmentBarStyled = withTheme(
  styled('ul')<{
    hasHidden: boolean
  }>(
    ({ hasHidden }) =>
      css({
        margin: 0,
        height: '32px',
        paddingY: 0,
        paddingX: '3px',
        justifyContent: 'flex-start',
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        width: 'fit-content',
        border: 1,
        borderRadius: 'default',
        borderStyle: 'solid',
        borderColor: 'borders.borderDefault',
        position: 'relative',
        paddingRight: hasHidden ? '30px' : undefined,
      }),
    space,
    layout,
  ),
)

const SegmentBarItemButtonStyled = withTheme(
  styled('button')<{
    selected: boolean
  }>(
    ({ selected }) =>
      css({
        marginY: '3px',
        paddingX: 3,
        outline: '0 none',
        border: 'none',
        background: 'none',
        height: '26px',
        transition: 'background-color .2s ease, color .2s ease',
        backgroundColor: selected
          ? 'backgrounds.backgroundSecondary'
          : 'transparent',
        color: selected ? 'texts.highEmphasis' : 'texts.mediumEmphasis',
        borderRadius: '3px',
        display: 'block',
        alignItems: 'center',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '&:hover, &:focus-within': {
          color: 'texts.highEmphasis',
        },
        '&:last-of-type': {
          marginRight: 0,
        },
      }),
    space,
  ),
)

const getSelection = (selected: boolean) =>
  selected ? (
    <Icon symbol="check" size={20} mr={1} />
  ) : (
    <Box width={20} mr={1} />
  )

/**
 * `SegmentBar` allow users to switch between different pieces of content using via a segment bar.
 */
export const SegmentBarComponent = forwardRef<
  SegmentBarRefProps,
  SegmentBarComponentProps
>(
  (
    {
      selectedSegment,
      segments,
      onChange,
      ulProps,
      liProps,
      ...props
    }: SegmentBarProps,
    forwardedRef,
    ...other
  ) => {
    const ref = forwardedRef as MutableRefObject<HTMLUListElement | null>
    const [hiddenElements, setHiddenElements] = useState<
      SegmentProps[] | undefined
    >()
    const [anchorMenu, setAnchorMenu] = useState<Element | null>(null)

    useResizeObserver<HTMLUListElement>({
      ref,
      onResize: () => {
        if (!(ref.current && segments)) {
          return
        }
        const hiddenElementsArr: SegmentProps[] = []
        ref.current.querySelectorAll('li').forEach((item, index) => {
          if (item.offsetTop > 26) {
            hiddenElementsArr.push(segments[index])
          }
        })
        setHiddenElements(hiddenElementsArr)
      },
    })

    const onClick = useMemo(
      () => (key: string) => () => {
        if (onChange) {
          onChange(key)
        }
        setAnchorMenu(null)
      },
      [onChange],
    )

    const onClickMenu = (event: MouseEvent<Element>) => {
      setAnchorMenu(event.currentTarget)
    }

    const onCloseMenu = () => {
      setAnchorMenu(null)
    }

    if (!segments || segments.length === 0) {
      return null
    }

    const hasHidden = Boolean(hiddenElements && hiddenElements.length > 0)

    return (
      <SegmentBarStyled
        ref={ref}
        hasHidden={hasHidden}
        {...ulProps}
        {...props}
        {...other}
      >
        {segments?.map((segment) => (
          <li
            key={segment.key}
            css={{ display: 'inline-flex', maxWidth: 200 }}
            {...liProps}
          >
            <SegmentBarItemButtonStyled
              selected={segment.key === selectedSegment}
              title={segment.label}
              onClick={onClick(segment.key)}
            >
              {segment.label}
            </SegmentBarItemButtonStyled>
          </li>
        ))}
        {hasHidden && (
          <li style={{ paddingTop: 4, position: 'absolute', right: 3 }}>
            <IconButton
              icon="more-vertical"
              size="small"
              onClick={onClickMenu}
            />
            <Menu
              popoverProps={{
                anchorEl: anchorMenu,
                open: Boolean(anchorMenu),
                onClose: onCloseMenu,
                placement: 'bottom-end',
              }}
              fullWidth
            >
              {hiddenElements?.map((hiddenElement) => (
                <MenuItem
                  startAdornment={getSelection(
                    hiddenElement.key === selectedSegment,
                  )}
                  key={hiddenElement.key}
                  onSelect={onClick(hiddenElement.key)}
                >
                  {hiddenElement.label}
                </MenuItem>
              ))}
            </Menu>
          </li>
        )}
      </SegmentBarStyled>
    )
  },
)

export const SegmentBar: React.FC<SegmentBarComponentProps> = (props) => {
  const ref = createRef<HTMLUListElement>()
  return <SegmentBarComponent ref={ref} {...props} />
}

export default memo(SegmentBar)
