/* eslint-disable react/destructuring-assignment */
import type { Middleware } from '@reduxjs/toolkit'

import type { Project } from 'lib'
import { getObject } from 'lib/project/getters'
import { getUuid } from 'lib/utils'

import type { RootState } from 'store/root-reducer'
import { applyProjectSetter, currentItemStateActions } from 'store/actions'
import {
  selectParentForNewRequestTreeItem,
  selectCurrentTreeItemRef,
} from 'store/selectors'
import { segmentTrack } from 'utils/segment'

import {
  userActionAddEnvironmentDomainAction,
  userActionAddRequestAction,
  userActionAddRequestGroupAction,
  userActionDeleteCurrentRequestTreeItemAction,
  userActionDeleteRequestTreeItemAction,
  userActionDuplicateRequestAction,
} from './actions'

// eslint-disable-next-line @typescript-eslint/ban-types
const userActionsMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    if (userActionAddRequestAction.match(action)) {
      // analytics log
      segmentTrack('Request Add')

      // find parent
      let parentRef: Project.GenericRef<Project.RequestGroup> | null
      if (action.payload.parent !== undefined) {
        // parent is specified in payload
        parentRef = action.payload.parent
      } else {
        // parent is not specified in payload, use the same as the selected item
        parentRef = selectParentForNewRequestTreeItem(store.getState())
      }

      const uuid = getUuid()
      next(
        applyProjectSetter('addRequest')({
          uuid,
          parent: parentRef || undefined,
          requestUrl: action.payload.url,
          useNumName: true,
        }),
      )
      next(currentItemStateActions.setCurrentTreeItemRef({ ref: uuid }))
      return null
    }

    if (userActionAddRequestGroupAction.match(action)) {
      // analytics log
      segmentTrack('Request Group Add')

      // find parent
      let parentRef: Project.GenericRef<Project.RequestGroup> | null = null
      if (action.payload.parent !== undefined) {
        // parent is specified in payload
        parentRef = action.payload.parent
      } else if (!action.payload.isRootElement) {
        // parent is not specified in payload, use the same as the selected item
        parentRef = selectParentForNewRequestTreeItem(store.getState())
      }

      const uuid = getUuid()
      next(
        applyProjectSetter('addRequestGroup')({
          uuid,
          parent: parentRef || undefined,
          useNumName: true,
        }),
      )
      next(currentItemStateActions.setCurrentTreeItemRef({ ref: uuid }))
      return null
    }

    if (userActionAddEnvironmentDomainAction.match(action)) {
      // analytics log
      segmentTrack('Environment Domain Add')

      next(
        applyProjectSetter('addEnvironmentDomain')({
          useNumName: true,
          addNewEnvironment: true,
        }),
      )
      return null
    }

    if (
      userActionDeleteRequestTreeItemAction.match(action) ||
      userActionDeleteCurrentRequestTreeItemAction.match(action)
    ) {
      // analytics log
      segmentTrack('Request Tree Item Delete')

      const deleteRequestTreeItem = (
        ref: Project.GenericRef<Project.AnyRequestTreeItem>,
      ) => {
        const targetObject = getObject(
          ref,
          store.getState().project.objects,
          true,
        )
        if (!targetObject) {
          return
        }

        // remove selection
        next(currentItemStateActions.setCurrentTreeItemRef(null))

        // delete tree item
        next(
          applyProjectSetter('deleteRequestTreeItem')({
            ref,
          }),
        )
      }

      // delete by ref
      if (userActionDeleteRequestTreeItemAction.match(action)) {
        return deleteRequestTreeItem(action.payload.ref)
      }

      // delete currently selected
      if (userActionDeleteCurrentRequestTreeItemAction.match(action)) {
        const currentRequestTreeItemRef = selectCurrentTreeItemRef(
          store.getState(),
        )
        if (!currentRequestTreeItemRef) {
          // nothing to delete, no op
          return null
        }
        return deleteRequestTreeItem(currentRequestTreeItemRef)
      }

      return null
    }

    if (userActionDuplicateRequestAction.match(action)) {
      // analytics log
      segmentTrack('Request Duplicate')

      // find the request
      let requestRef: Project.GenericRef<Project.Request>
      if (action.payload.requestRef) {
        requestRef = action.payload.requestRef
      } else {
        const state = store.getState()
        const currentRequestTreeItemRef = selectCurrentTreeItemRef(state)
        const request = currentRequestTreeItemRef
          ? getObject(currentRequestTreeItemRef, state.project.objects, false)
          : null

        // won't duplicate
        if (!request || request.type !== 'request') {
          return null
        }

        // we found the request to duplicate
        requestRef =
          currentRequestTreeItemRef as Project.GenericRef<Project.Request>
      }

      const uuid = getUuid()
      next(
        applyProjectSetter('duplicateRequest')({
          uuid,
          sourceRef: requestRef,
        }),
      )
      next(currentItemStateActions.setCurrentTreeItemRef({ ref: uuid }))
      return null
    }

    return next(action)
  }

export default userActionsMiddleware
