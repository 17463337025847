import type { Project } from 'lib/project/types.d'
import { getDynamicString } from '../atoms'
import getAuthHeaderDynamicString from './get-auth-header-dynamic-string'
import isDynamicStringEmptyOrPristine from './is-dynamic-string-empty-or-pristine'

const isAuthEmptyOrPristine = (
  ref: Project.GenericRef<Project.Request>,
  objects: Project.ObjectMap,
): boolean => {
  const authDynamicStringRef = getAuthHeaderDynamicString(ref, objects)
  const authDynamicString = authDynamicStringRef
    ? getDynamicString(authDynamicStringRef, objects, true)
    : null
  return isDynamicStringEmptyOrPristine(authDynamicString, objects)
}

export default isAuthEmptyOrPristine
