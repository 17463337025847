import type { Project } from 'lib/project/types.d'
import getObject from './get-object'

const getEnvironment = <Optional extends true | false>(
  ref: Project.GenericRef<Project.Environment>,
  objects: Project.ObjectMap,
  isOptional: Optional,
): Optional extends true ? Project.Environment | null : Project.Environment =>
  getObject(ref, objects, isOptional, 'environment')

export default getEnvironment
