import type { DynamicValues } from 'lib/dynamic-values/types.d'
import type { Evaluation } from 'lib/evaluation/types.d'
import type { Project } from '../../../project'
import getDynamicString from '../../../project/getters/atoms/get-dynamic-string'
import getParameter from '../../../project/getters/atoms/get-parameter'
import isDynamicStringEmpty from '../../../project/getters/dynamic-value-functions/is-dynamic-string-empty'
import type { BodyMultipartFormDataDynamicValue } from './types.d'

const identifier = 'com.luckymarmot.BodyMultipartFormDataDynamicValue'

const defaultValue: BodyMultipartFormDataDynamicValue = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  keyValues: [],
}

const defaultTokenInfo: DynamicValues.TokenInfo = {
  title: 'Multipart Body',
  text: null,
}

const editForm: DynamicValues.EditForm<BodyMultipartFormDataDynamicValue> = {
  fields: [
    {
      fieldKey: 'keyValues',
      fieldType: 'parameterList',
      label: 'Key Values',
    },
  ],
}

const implBodyMultipartFormDataDynamicValue: DynamicValues.Implementation<BodyMultipartFormDataDynamicValue> =
  {
    title: 'Multipart Body',
    identifier,
    defaultValue,
    editForm,
    getAllRefs(dv) {
      return dv.keyValues
    },
    getEvaluatedString: async (
      dv: BodyMultipartFormDataDynamicValue,
      ctx: Evaluation.Ctx,
    ) => {
      const evalutatedMap = await Promise.all(
        (dv.keyValues || [])
          .map((kvRef) => getParameter(kvRef, ctx.project.objects, false))
          .filter((param) => param && param.enabled)
          .map(
            async (param): Promise<string[]> => [
              [
                `--__X_PAW_BOUNDARY__\r\nContent-Disposition: form-data; name=`,
                param.key
                  ? `"${await ctx.evals.evaluateDynamicString(param.key, ctx)}"`
                  : '',
              ].join(''),
              param.value
                ? `${await ctx.evals.evaluateDynamicString(param.value, ctx)}`
                : '',
            ],
          ),
      )
      return evalutatedMap
        .map((e) =>
          e[1].indexOf('filename="') !== -1 ? e.join('') : e.join('\r\n\r\n'),
        )
        .join('\r\n')
        .toString()
        .concat('\r\n--__X_PAW_BOUNDARY__--\r\n')
    },

    getTokenInfo: async () => defaultTokenInfo,

    isEmpty: (
      dv: BodyMultipartFormDataDynamicValue,
      objects: Project.ObjectMap,
    ) => {
      const kvs = (dv.keyValues || [])
        .map((kvRef) => getParameter(kvRef, objects, false))
        .filter((param) => {
          if (!param) {
            return false
          }
          return (
            (param.key &&
              !isDynamicStringEmpty(
                getDynamicString(param.key, objects, false),
              )) ||
            (param.value &&
              !isDynamicStringEmpty(
                getDynamicString(param.value, objects, false),
              ))
          )
        })
      return kvs.length === 0
    },
  }

export default implBodyMultipartFormDataDynamicValue
