const setCaret = (
  target: HTMLElement | ChildNode | null,
  start: number,
  end?: number,
): void => {
  const selection = window?.getSelection()
  if (target && selection) {
    const range = document.createRange()
    try {
      range.setStart(target, start)
      range.setEnd(target, end ?? start)
    } catch {
      range.setStart(target, 0)
    }
    selection.removeAllRanges()
    selection.addRange(range)
  }
}

export default setCaret
