import type { Project } from 'lib/project/types.d'
import { getDynamicString } from '../atoms'
import { getOnlyDynamicValue } from '../dynamic-value-functions'
import getAuthHeaderDynamicString from './get-auth-header-dynamic-string'

const getAuthHeaderDynamicValue = (
  requestRef: Project.GenericRef<Project.Request>,
  objects: Project.ObjectMap,
): Project.GenericRef<Project.DynamicValue> | null => {
  const authHeaderDynamicString = getAuthHeaderDynamicString(
    requestRef,
    objects,
  )
  if (!authHeaderDynamicString) {
    return null
  }
  const authHeaderValue = getDynamicString(
    authHeaderDynamicString,
    objects,
    false,
  )
  return getOnlyDynamicValue(authHeaderValue)
}

export default getAuthHeaderDynamicValue
