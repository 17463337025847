import React from 'react'
import {
  Box,
  Button,
  Dialog,
  Icon,
  IconButton,
  Typography,
  Snackbar,
  styled,
} from '@rapidapi/ui-lib'
import type {
  AppUpdateViewSwitcherType,
  AppUpdateViewComponentType,
} from './app-update-constants'
import { appUpdateStatus } from './app-update-constants'

const {
  VIEW_UPDATE_AVAILABLE,
  VIEW_UPDATE_NOT_AVAILABLE,
  VIEW_UPDATE_DOWNLOADING,
  VIEW_UPDATE_DOWNLOADED,
  VIEW_UPDATE_ERROR,
  // VIEW_UPDATE_INSTALLED, // currently not available
} = appUpdateStatus

const UpdateAvailable: React.FC<AppUpdateViewComponentType> = ({
  open,
  action,
  cancel,
  version,
}) => (
  <Dialog open={open} width={264} height={284}>
    <Icon
      mb={3}
      size={48}
      mx="auto"
      symbol="updates"
      display="block"
      color="inherit"
    />
    <Box textAlign="center">
      <Typography variant="body1" fontWeight="bold" mb={3}>
        New Update Available
      </Typography>

      <Typography variant="body2" color="mediumEmphasis" mb={3}>
        Version {version} is available and ready to install
      </Typography>

      <Button variant="primary" width={200} mb={1} onClick={action}>
        Install Now
      </Button>
      <Button mb={2} width={200} variant="text" onClick={cancel}>
        Remind Me Later
      </Button>
    </Box>
  </Dialog>
)

const UpdateInstalled: React.FC<AppUpdateViewComponentType> = ({
  action,
  cancel,
}) => (
  <Dialog open width={264} height={264}>
    <Icon
      mb={3}
      size={48}
      mx="auto"
      symbol="check"
      display="block"
      color="secondary"
    />
    <Box textAlign="center">
      <Typography variant="body1" fontWeight="bold" mb={3}>
        Ready to Update
      </Typography>

      <Typography variant="body2" color="mediumEmphasis" mb={3}>
        Relaunch the app now to use the new version
      </Typography>

      <Button variant="primary" width={200} mb={1} onClick={action}>
        Relaunch Now
      </Button>
      <Button mb={2} width={200} variant="text" onClick={cancel}>
        Remind Me Later
      </Button>
    </Box>
  </Dialog>
)

const SpinnerIcon = styled(Icon)({
  '&': {
    animation: 'spin 0.75s linear infinite',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
})

const AppUpdateViewSwitcher: React.FC<AppUpdateViewSwitcherType> = ({
  applyUpdate,
  dismiss,
  downloadUpdate,
  error,
  retryUpdate,
  status,
  latest,
}) => {
  switch (status) {
    case VIEW_UPDATE_AVAILABLE: {
      return (
        <UpdateAvailable
          open
          version={latest}
          action={downloadUpdate}
          cancel={dismiss}
        />
      )
    }

    case VIEW_UPDATE_NOT_AVAILABLE: {
      return (
        <Snackbar
          actions={[
            {
              children: 'Dismiss',
              onClick: dismiss,
            },
          ]}
          show
          duration={0}
          icon="check"
          onClose={dismiss}
        >
          <Typography variant="body2" mr={2} color="inherit">
            Version <code>{latest}</code> installed
          </Typography>
        </Snackbar>
      )
    }

    case VIEW_UPDATE_DOWNLOADING: {
      return (
        <Snackbar
          actions={[
            {
              children: 'Dismiss',
              onClick: dismiss,
            },
          ]}
          show
          duration={0}
          onClose={dismiss}
        >
          <SpinnerIcon symbol="spinner" size={24} mr={2} />
          <Typography variant="body2" mr={2} color="inherit">
            Installing update...
          </Typography>
        </Snackbar>
      )
    }

    case VIEW_UPDATE_DOWNLOADED: {
      return (
        <UpdateInstalled
          open
          version={latest}
          action={applyUpdate}
          cancel={dismiss}
        />
      )
    }

    case VIEW_UPDATE_ERROR: {
      return (
        <>
          <Snackbar
            actions={[
              {
                children: 'Try again',
                onClick: retryUpdate,
              },
            ]}
            show
            variant="negative"
            icon="danger"
            duration={0}
            onClose={dismiss}
          >
            <Typography variant="body2" color="inherit" mr={2}>
              Failed to install update
            </Typography>
            <IconButton onClick={dismiss} icon="close" />
          </Snackbar>
          <Box display="none">{error}</Box>
        </>
      )
    }

    case undefined:
    default:
      return null
  }
}

export default AppUpdateViewSwitcher
