/* eslint-disable react/jsx-props-no-spreading */

import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { space as spaceStyled } from 'styled-system'
import css from '@styled-system/css'
import { Button } from '../../../inputs/button'
import { ButtonComponentProps } from '../../../inputs/button/button.types'

export interface ButtonTabComponentProps
  extends Omit<ButtonComponentProps, 'variant' | 'onClick'> {
  selected?: boolean
}

const ButtonTabStyled = withTheme(
  styled(Button)<ButtonTabComponentProps>(
    css({
      bg: 'backgrounds.backgroundPrimary',
      borderColor: 'backgrounds.backgroundPrimary',
      color: 'texts.mediumEmphasis',
      mr: 2,
      '&:last-child': {
        mr: 0,
      },
      '&:hover:enabled, &.focused': {
        color: 'texts.highEmphasis',
        bg: 'backgrounds.backgroundDefaultHover',
        borderColor: 'borders.backgroundDefaultHover',
      },
      '&.selected': {
        bg: 'backgrounds.backgroundSelected',
        borderColor: 'backgrounds.backgroundPrimary',
        color: 'texts.textSelected',
        '&:hover:enabled, &.focused': {
          bg: 'backgrounds.backgroundSelected',
          borderColor: 'backgrounds.backgroundPrimary',
          color: 'texts.textSelected',
        },
      },
    }),
    spaceStyled,
  ),
)

export const ButtonTab: React.FC<ButtonTabComponentProps> = ({
  children,
  selected,
  ...other
}) => (
  <ButtonTabStyled className={selected ? 'selected' : undefined} {...other}>
    {children}
  </ButtonTabStyled>
)

export default ButtonTab
