import type { Project } from 'lib/project/types.d'

const getOnlyString = (dynamicString: Project.DynamicString): string | null => {
  if (dynamicString.strings.length === 0) {
    return ''
  }
  if (dynamicString.strings.length > 1) {
    return null
  }
  const first = dynamicString.strings[0]
  if (typeof first === 'string') {
    return first
  }
  return null
}

export default getOnlyString
