import React from 'react'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import lightTheme from '../light-theme'

type ThemeProvider = {
  theme: typeof lightTheme
}

const ThemeProvider: React.FC<ThemeProvider> = ({
  theme: customTheme = lightTheme,
  children,
}) => (
  <EmotionThemeProvider theme={customTheme}>{children}</EmotionThemeProvider>
)

export default ThemeProvider
