import React, { useMemo } from 'react'
import DialogContext from './dialog-context'

interface DialogContextProviderProps {
  portalRoot?: HTMLElement | null
}

const DialogContextProvider: React.FC<DialogContextProviderProps> = ({
  portalRoot,
  children,
}) => {
  const value = useMemo(
    () => ({
      portalRoot: portalRoot || null,
    }),
    [portalRoot],
  )

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  )
}

export default DialogContextProvider
