import type { Project } from 'lib/project/types.d'

const assertType = (
  { type: actualType }: Project.AnyObject,
  expectedType: Project.ObjectTypes,
): void => {
  if (actualType !== expectedType) {
    throw new Error(
      `[assertType] Expect object type ${expectedType}, got ${actualType}`,
    )
  }
}

export default assertType
