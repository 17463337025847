import React from 'react'
import { Flex, Box, Elevation, styled } from '@rapidapi/ui-lib'
import { Helmet } from 'react-helmet-async'
import type { BackgroundImageProps } from 'styled-system'
import { background } from 'styled-system'
import CoverTemplateHeader from './cover-header'
import AuthFooter from './cover-footer'

export type CoverTemplatePageProps = {
  title: string
  description?: string
  body?: {
    [key: string]: string | string[] | unknown
  }
}

export type CoverTemplateProps = BackgroundImageProps & {
  page?: CoverTemplatePageProps
  header?: JSX.Element
  footer?: JSX.Element
  loading?: boolean
  width?: number
  minHeight?: number | string
  authScreen?: boolean
}

const CoverTemplateStyled = styled(Flex)(background, {
  width: '100%',
  maxWidth: '100%',
  height: '100%',
  justifyContent: 'center',
})

const CoverTemplateBody: React.FC<{
  header?: JSX.Element
  footer?: JSX.Element
}> = ({ header, footer, children }) => (
  <>
    {header && <CoverTemplateHeader>{header}</CoverTemplateHeader>}
    <Box as="section" py={6}>
      {children}
    </Box>
    {footer && <AuthFooter>{footer}</AuthFooter>}
  </>
)

const CoverTemplate: React.FC<CoverTemplateProps> = ({
  page = { title: 'Sign in to Paw' },
  width = { sm: 140, md: 540 },
  minHeight = 280,
  authScreen = true,
  header,
  footer,
  children,
}) => (
  <>
    <Helmet>
      <title>
        {page.title} {page.description ? page.description : ''} | Paw Cloud
      </title>
    </Helmet>
    <CoverTemplateStyled>
      {authScreen ? (
        <Elevation
          flexBasis={width}
          minHeight={minHeight}
          padding={6}
          alignSelf="center"
          alignContent="center"
          variant="large"
        >
          <CoverTemplateBody {...{ header, footer }}>
            {children}
          </CoverTemplateBody>
        </Elevation>
      ) : (
        <CoverTemplateBody {...{ header, footer }}>
          {children}
        </CoverTemplateBody>
      )}
    </CoverTemplateStyled>
  </>
)

export default CoverTemplate
