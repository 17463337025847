/* eslint-disable react/jsx-props-no-spreading */

import { Box } from '../../layout/box'
import { SwitchBase } from '../switch-base'
import { SwitchBaseComponentProps } from '../switch-base/switch-base.types'

export const RadioButton: React.FC<SwitchBaseComponentProps> = (props) => (
  <SwitchBase
    css={{
      '& [data-role="switch-base-icon"]': {
        borderRadius: '50%',
      },
    }}
    icon={
      <Box
        css={({ colors }) => ({
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor:
            colors.texts[
              props.disabled ? 'lowEmphasis' : 'highEmphasisConstant'
            ],
        })}
      />
    }
    type="radio"
    {...props}
  />
)

export default RadioButton
