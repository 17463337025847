import type { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'
import addObject from './add-object'

type AddDynamicStringProps = {
  string?: string
  strings?: (string | Project.GenericRef<Project.DynamicValue>)[]
}

const addDynamicString = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  props?: AddDynamicStringProps,
): Project.GenericRef<Project.DynamicString> => {
  const dynamicString: Project.DynamicString = {
    uuid: getUuid(),
    type: 'dynamicString',
    strings: (() => {
      if (props?.strings) {
        return props.strings
      }
      if (props?.string) {
        return [props.string]
      }
      return ['']
    })(),
  }
  return addObject(objects, dynamicString)
}

export default addDynamicString
