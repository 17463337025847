/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
  AddPendingRequest,
  AgentStatus,
  RemovePendingRequest,
  RequestHandlingState,
} from './types.d'

const initialState: RequestHandlingState = {
  pendingRequests: [],
  agentStatus: {
    // whether we have checked the status already
    checked: false,
    // whether we believe the agent is installed
    installed: false,
    // whether we believe the agent is active
    active: false,
  },
}

const requestHandlingSlice = createSlice({
  name: 'requestHandling',
  initialState,
  reducers: {
    addPendingRequest: (
      state: RequestHandlingState,
      { payload: { requestUuid } }: PayloadAction<AddPendingRequest>,
    ) => {
      state.pendingRequests.unshift(requestUuid)
    },
    removePendingRequest: (
      state: RequestHandlingState,
      { payload: { requestUuid } }: PayloadAction<RemovePendingRequest>,
    ) => {
      state.pendingRequests = state.pendingRequests.filter(
        (val) => val !== requestUuid,
      )
    },
    setAgentStatus: (
      state: RequestHandlingState,
      action: PayloadAction<Partial<AgentStatus>>,
    ): void => {
      state.agentStatus = {
        ...state.agentStatus,
        ...action.payload,
      }
    },
  },
})

export default requestHandlingSlice
