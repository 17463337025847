/* eslint-disable @typescript-eslint/no-unused-vars, import/no-named-as-default-member */
import config from 'config'
import { HttpExchangeStorage } from 'worker/primary-worker/implementation/http-exchanges'
import type { JSAPIInternals } from '../types/internal-types.d'
import type {
  BodyFormKeyValueDynamicValueInterface,
  BodyMultipartFormDataDynamicValue,
  BasicAuthDynamicValueInterface,
  OAuth2DynamicValueInterface,
  OAuth1HeaderDynamicValueInterface,
  JSONDynamicValueInterface,
} from '../../dynamic-values'
import { implJSONDynamicValue } from '../../dynamic-values'
import type Paw from '../types/paw.d'
import { getUuid } from '../../utils/uuid'
import type { Evaluation } from '../../evaluation'
import { evaluateDynamicString } from '../../evaluation'
import { filterArrayNonNull } from '../../cloud-sync/loader/helpers'
import type { Project } from '../../project'
import {
  getRequest,
  getKeyValue,
  getHeaderByName,
  getDynamicString,
  getDynamicValue,
  getParameter,
  getVariableByName,
  getVariableById,
  getOnlyDynamicValue,
} from '../../project'
import {
  addDynamicString,
  addKeyValue,
  addParameter,
  addRequestVariable,
  updateDynamicString,
  updateRequestAuthDynamicString,
} from '../../project/setters'
import KeyValueJS from './key-value-js'
import RequestVariableJS from './request-variable-js'
import DynamicStringJS from './dynamic-string-js'
import HTTPExchangeJS from './http-exchange-js'

type TypeMethodParamOrReturn = string | DynamicString | null
type TypeRequestBodyGenerator = {
  body: Record<string, string | DynamicString> | null
  identifier:
    | 'com.luckymarmot.BodyFormKeyValueDynamicValue'
    | 'com.luckymarmot.BodyMultipartFormDataDynamicValue'
    | 'com.luckymarmot.JSONDynamicValue'
}

function qsParse(str: string): Record<string, string> {
  const input = str
    .slice(str.indexOf('?') + 1)
    .split('&')
    .filter((item) => !!item)
    .map((item) => {
      const matched = item.match(/^([^=]*)=(.*)$/)
      return matched
        ? [decodeURIComponent(matched[1]), decodeURIComponent(matched[2])]
        : [decodeURIComponent(item), '']
    })
  return Object.fromEntries(input)
}

const getJSDynamicString = async <NullValue = string>(
  ref: Project.GenericRef<Project.DynamicString> | null,
  context: JSAPIInternals.Context,
  isDynamic: boolean,
  nullValue: string | NullValue = '',
): Promise<string | DynamicStringJS | NullValue> => {
  if (!ref) {
    return nullValue
  }
  if (isDynamic) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return new DynamicStringJS(context, ref)!
  }
  return evaluateDynamicString(ref, context.getEvaluationCtx())
}

export default class RequestJS implements Paw.Request {
  protected readonly internalObject!: Project.Request
  private context!: JSAPIInternals.Context
  private objectRef!: Project.GenericRef<Project.Request>
  private objectOrder = 0
  private httpExchangeStorage: HttpExchangeStorage = new HttpExchangeStorage()

  constructor(
    context: JSAPIInternals.Context,
    objectRef: Project.GenericRef<Project.Request>,
    objectOrder: number,
  ) {
    const request = getRequest(objectRef, context.getInternalObjects(), true)

    if (!request || !context || !objectRef) {
      throw new Error('Failed to get requestJs object.')
    }

    this.context = context
    this.objectRef = objectRef
    this.objectOrder = objectOrder
    this.internalObject = request
  }

  /**
   *
   *
   * @private
   * @param {TypeRequestBodyGenerator} opts
   * @memberof RequestJS
   */
  private requestBodyGenerator(opts: TypeRequestBodyGenerator): void {
    if (!opts.body || Object.keys(opts.body).length === 0) {
      return
    }

    const { objects } = this.context.getEvaluationCtx().project
    const { root } = this.context

    const dynamicValue: Project.DynamicValue = {
      uuid: getUuid(),
      type: 'dynamicValue',
      identifier: opts.identifier,
      keyValues: [],
    }
    const dynamicValueRef: Project.GenericRef<Project.DynamicValue> = {
      ref: dynamicValue.uuid,
    }

    updateDynamicString(objects, root, {
      inserts: [dynamicValue],
      parentProperty: 'bodyString',
      parentRef: { ref: this.id },
      strings: [dynamicValueRef],
    })

    Object.entries(opts.body).forEach(([name, value]) => {
      addParameter(objects, root, {
        objectRef: dynamicValueRef,
        objectProperty: 'keyValues',
        args: {
          key: addDynamicString(objects, root, {
            string: name,
          }),
          value:
            typeof value === 'string'
              ? addDynamicString(objects, root, { string: value })
              : { ref: value.id },
        },
      })
    })
  }

  // #==
  // Request Meta Info11

  /**
   * @getter
   * @summary
   *  Returns a the request title
   */
  public toString(): string {
    return `Request<${this.internalObject.title}>`
  }

  /**
   * @getter
   * @summary
   *  The request unique identifier, an UUID generated by Paw for each request (UUID) (Read-only).
   */
  get id(): string {
    if (!this.internalObject.uuid) {
      throw new Error('Paw JS: Record with invalid UUID')
    }
    return this.internalObject.uuid
  }

  /**
   *
   *
   * @readonly
   * @type {(Paw.RequestTreeItem | null)}
   * @memberof RequestJS
   */
  // eslint-disable-next-line class-methods-use-this
  get parent(): Paw.RequestTreeItem | null {
    throw new Error('Paw JS: Not Implemented')
  }

  /**
   * @getter
   * @summary
   * The request name as it is shown in the requests view.
   * (Writable property only for Importers).
   */
  get name(): string {
    return this.internalObject.title || ''
  }

  /**
   * @setter
   * @summary
   * The request name as it is shown in the requests view.
   * (Writable property only for Importers).
   */
  set name(name: string) {
    this.internalObject.title = name || this.internalObject.title
  }

  /**
   * @getter
   * @summary
   *  The order of the request in its parent RequestGroup or as a root item
   *  (orders starts at 0). (Writable property only for Importers).
   */
  get description(): string {
    return this.internalObject.summary || ''
  }

  /**
   * @setter
   * @summary
   *  The order of the request in its parent RequestGroup or as a root item
   *  (orders starts at 0). (Writable property only for Importers).
   */
  set description(description: string) {
    this.internalObject.summary =
      description || this.internalObject.summary || ''
  }

  /**
   * @getter
   * @summary returns the current request's position in a request-group
   */
  get order(): number {
    return this.objectOrder || 0
  }

  /**
   *
   *
   * @memberof RequestJS
   */
  // eslint-disable-next-line class-methods-use-this
  set order(order: number) {
    throw new Error('Paw JS: Not Implemented') // @TODO
  }

  // #==
  // Request URL
  /**
   * @summary
   * set's the url of the request
   * updates the dynamic string urlFull
   * @param {(string | DynamicString)} url
   * @return {Promise<void>}
   * @memberof RequestJS
   */
  async setUrl(url: string | DynamicString): Promise<void> {
    const { getInternalObjects, root } = this.context
    const value = typeof url === 'string' ? url : await url.getEvaluatedString()
    updateDynamicString(getInternalObjects(), root, {
      inserts: [],
      parentProperty: 'urlFull',
      parentRef: { ref: this.id },
      strings: [value],
    })
  }

  /**
   * @method
   * @summary method that returns the current request's url
   * @param {Boolean} isDynamic
   *  - parameter that sets  whether to return a string or a dynamic value.
   * @returns {Promise<String|DynamicString>}
   */
  public async getUrl(isDynamic = false): Promise<string | DynamicString> {
    if (isDynamic) {
      return getJSDynamicString(this.internalObject.urlFull, this.context, true)
    }

    const ctx = this.context.getEvaluationCtx()
    const params = (
      await Promise.all(
        this.urlParametersExtract().map(async ({ name, value }) => {
          if (!name || !value) {
            return []
          }
          return [
            encodeURIComponent(await evaluateDynamicString(name, ctx)),
            encodeURIComponent(await evaluateDynamicString(value, ctx)),
          ].join('=')
        }),
      )
    ).join('&')

    let url = this.internalObject.urlFull
      ? await evaluateDynamicString(this.internalObject.urlFull, ctx)
      : null

    if (!url || url.length === 0) {
      url = config.placeholderURL
    }

    return `${url}${params.length > 0 ? `?${params}` : ''}`
  }

  /**
   * @method
   * @summary method that returns the current request's origin host
   * @param {Boolean} isDynamic
   *  - parameter that sets  whether to return a string or a dynamic value.
   * @returns {Promise<String|DynamicString>}
   */
  public async getUrlBase(isDynamic = false): Promise<string | DynamicString> {
    const { urlFull } = this.internalObject
    if (!urlFull) {
      return config.placeholderURL
    }

    let res = await getJSDynamicString(urlFull, this.context, isDynamic)
    if (typeof res === 'string' && res.length === 0) {
      res = config.placeholderURL
    }

    if (isDynamic) {
      return res
    }
    const base = new URL(res.toString())
    return new URL(base.pathname, base.origin).href
  }

  // #==
  // Request Query Parameters

  /**
   * @method urlParametersExtract
   * @summary
   *  a private method that returns request's url params key/value in array format.
   * @returns {Array<Project.KeyValue>}
   */
  private urlParametersExtract(): Project.KeyValue[] {
    const objects = this.context.getInternalObjects()
    return this.internalObject.urlParameters
      .map((keyValueRef) => getKeyValue(keyValueRef, objects, false))
      .filter((kv) => kv.enabled)
  }

  /**
   * @method getUrlParameters
   * @summary
   *  a method that retuns the request's url params key/value
   * @param {Boolean} isDynamic - defaults to false
   * @returns {Promise<Object<Record<string, string | DynamicString>>>}
   */
  public async getUrlParameters(
    isDynamic = false,
  ): Promise<Record<string, string | DynamicString>> {
    const params = await Promise.all(
      this.urlParametersExtract().map(async ({ name, value }) => {
        if (!name || !value) {
          return []
        }
        return [
          (await evaluateDynamicString(
            name,
            this.context.getEvaluationCtx(),
          )) || '',
          await getJSDynamicString(value, this.context, isDynamic),
        ]
      }),
    )

    return Object.fromEntries(params)
  }

  /**
   * @method getUrlParametersArray
   * @summary
   *  a method that retuns the request's url params key/value array
   * @param {Boolean} isDynamic - defaults to false
   * @returns {Promise<Array<KeyValue>>}
   */
  public async getUrlParametersArray(): Promise<Paw.KeyValue[]> {
    return filterArrayNonNull(
      this.internalObject.urlParameters.map(
        (keyValueRef) => new KeyValueJS(this.context, keyValueRef, 0),
      ),
    )
  }

  /**
   * @summary
   * a method that returns the request's url query params
   *
   * @return {Promise<string>}
   * @memberof RequestJS
   */
  async getUrlQuery(): Promise<string> {
    const urlstring = (await this.getUrl()).toString()
    return urlstring.slice(urlstring.indexOf('?') + 1)
  }

  /**
   * @method getUrlParametersNames
   * @summary
   *  a method that retuns the request's url params names array
   * @returns {Promise<Array<string>>}
   */
  public async getUrlParametersNames(): Promise<string[]> {
    return (
      await Promise.all(
        this.urlParametersExtract().map(async ({ name, value }) => {
          if (!name || !value) {
            return ''
          }
          return evaluateDynamicString(name, this.context.getEvaluationCtx())
        }),
      )
    ).filter((item) => item.trim() !== '')
  }

  /**
   * @method getUrlParameterByName
   * @summary
   *  a method that retuns the request's url parameter value.
   * @param {String} name
   *  - the name of the parameter
   * @param {Boolean} isDynamic
   *  - whether to return a dynamic string defaults to false
   * @returns {Promise<String|DynamicString|null>} the value of the parameter name
   */
  public async getUrlParameterByName(
    name: string,
    isDynamic = false,
  ): Promise<string | DynamicString | null> {
    const context = this.context.getEvaluationCtx()
    const evaluatedParam = await Promise.all(
      this.urlParametersExtract().map(
        async (
          item: Project.KeyValue & { evaluatedName?: string },
        ): Promise<Project.KeyValue & { evaluatedName?: string }> => {
          const evaluatedName = item.name
            ? await evaluateDynamicString(item.name, context)
            : ''
          // eslint-disable-next-line no-param-reassign
          item.evaluatedName = evaluatedName
          return item
        },
      ),
    )
    const params = await Promise.all(
      evaluatedParam
        .filter((item) => item.evaluatedName === name.trim())
        .map((item) => getJSDynamicString(item.value, this.context, isDynamic)),
    )
    return params[0] || null
  }

  /**
   * @method setUrlParameter
   * @summary Sets the given value for the parameter name.
   * @param {String|Object} paramName - url parameter name
   * @param {String|Object} paramValue - url parameter value
   * @returns {Promise<Object<KeyValueJS>>}
   */
  public async setUrlParameter(
    paramName: string | DynamicString,
    paramValue: string | DynamicString,
  ): Promise<Paw.KeyValue> {
    const { objects } = this.context.getEvaluationCtx().project
    const { root } = this.context

    const valueRef =
      typeof paramName === 'string'
        ? ((await this.getUrlParameterByName(paramName, true)) as DynamicString)
        : ((await this.getUrlParameterByName(
            await paramName.getEvaluatedString(),
            true,
          )) as DynamicString)

    const value =
      typeof paramValue === 'string'
        ? paramValue
        : await paramValue.getEvaluatedString()

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const param = this.urlParametersExtract().find(
      (item: Project.KeyValue) => item.value && item.value.ref === valueRef.id,
    )!

    updateDynamicString(objects, root, {
      inserts: [],
      parentProperty: 'value',
      parentRef: { ref: param.uuid },
      strings: [value],
    })

    return new KeyValueJS(this.context, { ref: param.uuid }) as KeyValueJS
  }

  /**
   * @method setUrlParameterByName
   * @summary
   *  An alias of `this.setUrlParameter` which sets the given value for the
   *  parameter name.
   * @param {String|Object} paramName - url parameter name
   * @param {String|Object} paramValue - url parameter value
   * @returns {Promise<Object<KeyValueJS>>}
   */
  public async setUrlParameterByName(
    name: string | DynamicString,
    value: string | DynamicString,
  ): Promise<Paw.KeyValue> {
    return this.setUrlParameter(name, value)
  }

  /**
   * @method addUrlParameter
   * @summary
   * A method that sets
   *  parameter name.
   * @param {String|Object} paramName - url parameter name
   * @param {String|Object} paramValue - url parameter value
   * @returns {Promise<Object<KeyValueJS>>}
   */
  public async addUrlParameter(
    paramName: string | DynamicString,
    paramValue: string | DynamicString,
  ): Promise<Paw.KeyValue> {
    const { objects } = this.context.getEvaluationCtx().project
    const { root } = this.context

    const name =
      typeof paramName === 'string'
        ? paramName
        : await paramName.getEvaluatedString()
    const value =
      typeof paramValue === 'string'
        ? paramValue
        : await paramValue.getEvaluatedString()

    const ref = addKeyValue(objects, root, {
      parentRef: { ref: this.id },
      parentProperty: 'urlParameters',
      args: {
        name,
        value,
      },
    })

    return new KeyValueJS(this.context, ref) as KeyValueJS
  }

  /**
   * @method addRawUrlQuery
   * @summary
   * @param {String|Object<DynamicString>} query
   *  - a query string that will be parsed into an object.
   * @return {(Promise<Paw.KeyValue | Paw.KeyValue[]>)}
   */
  public async addRawUrlQuery(
    query: string | DynamicString,
  ): Promise<Paw.KeyValue | Paw.KeyValue[]> {
    const { objects } = this.context.getEvaluationCtx().project
    const { root } = this.context

    const queryString: Record<string, string> =
      typeof query === 'string'
        ? qsParse(query)
        : qsParse(await query.getEvaluatedString())

    const mapQueryString = Object.keys(queryString).map((item) => ({
      name: item,
      value: queryString[item],
    }))

    return mapQueryString.length > 0
      ? (mapQueryString
          .map(
            ({ name, value }): Project.GenericRef<Project.KeyValue> =>
              addKeyValue(objects, root, {
                parentRef: { ref: this.id },
                parentProperty: 'urlParameters',
                args: {
                  name,
                  value,
                },
              }),
          )
          .map(
            (item: Project.GenericRef<Project.KeyValue>) =>
              new KeyValueJS(this.context, item),
          ) as KeyValueJS[])
      : []
  }

  // #==
  // Request Method

  /**
   * @method getMethod
   * @summary returns the  request method
   * @param {Boolean} isDynamic - true returns the value as a DynamicString
   * @returns {Promise<String|Object<DynamicString>>}
   */
  public async getMethod(isDynamic = false): Promise<string | DynamicString> {
    return getJSDynamicString(
      this.internalObject.method,
      this.context,
      isDynamic,
    )
  }

  /**
   * @summary
   * update the method of the request
   * updates the dynamic string method
   * @param {(string | DynamicString)} method
   * @return {Promise<void>}
   * @memberof RequestJS
   */
  async setMethod(method: string | DynamicString): Promise<void> {
    const { objects } = this.context.getEvaluationCtx().project
    const { root } = this.context

    const value =
      typeof method === 'string' ? method : await method.getEvaluatedString()

    updateDynamicString(objects, root, {
      inserts: [],
      parentProperty: 'method',
      parentRef: { ref: this.id },
      strings: [value],
    })
  }

  // #==
  // Request Headers

  /**
   * @summary
   * returns the enabled key values from the request
   *
   * @private
   * @return {Project.KeyValue[]}
   * @memberof RequestJS
   */
  private requestHeadersExtract(): Project.KeyValue[] {
    const objects = this.context.getInternalObjects()
    return this.internalObject.headers
      .map((keyValueRef) => getKeyValue(keyValueRef, objects, false))
      .filter((item: Project.KeyValue) => item.enabled)
  }

  /**
   * @method getHeaders
   * @summary
   *  Returns an object (dictionary) pairing the request's headers values to their names.
   * @param {Boolean} isDynamic - true returns the value as a DynamicString
   * @returns {Promise<String|Object<DynamicStringJS>>}
   */
  public async getHeaders(
    isDynamic = false,
  ): Promise<Record<string, string | DynamicString>> {
    const context = this.context.getEvaluationCtx()
    const headers = await Promise.all(
      this.requestHeadersExtract().map(async (item: Project.KeyValue) => [
        item.name ? await evaluateDynamicString(item.name, context) : '',
        await getJSDynamicString(item.value, this.context, isDynamic),
      ]),
    )
    return Object.fromEntries(headers)
  }

  /**
   * @method getHeadersNames
   * @summary Returns all header names as an array of strings.
   * @returns {Promise<String[]>}
   */
  public async getHeadersNames(): Promise<string[]> {
    const context = this.context.getEvaluationCtx()
    return Promise.all(
      this.requestHeadersExtract().map(async (item: Project.KeyValue) =>
        item.name ? evaluateDynamicString(item.name, context) : '',
      ),
    )
  }

  /**
   *
   * @summary
   *  Returns an object (dictionary) pairing the request's headers values to their names.
   *  Headers values are returned as plain strings. The getter method getHeaders() is also
   *  available and allows access to the headers values as DynamicString.
   *  (Writable property only for Importers).*
   * @param {(Record<string, string | DynamicString>)} headers
   * @return {Promise<void>}
   * @memberof RequestJS
   */
  async setHeaders(
    headers: Record<string, string | DynamicString>,
  ): Promise<void> {
    const { objects } = this.context.getEvaluationCtx().project
    const { root } = this.context

    if (Object.keys(headers).length === 0) {
      return
    }

    const evalutatedKeyValues = await Promise.all(
      Object.keys(headers).map(async (name: string) => ({
        name,
        value:
          typeof headers[name] === 'string'
            ? (headers[name] as string)
            : await (headers[name] as DynamicString).getEvaluatedString(),
      })),
    )
    evalutatedKeyValues.forEach(({ name, value }) =>
      addKeyValue(objects, root, {
        parentRef: { ref: this.id },
        parentProperty: 'headers',
        args: {
          name,
          value,
        },
      }),
    )
  }

  /**
   * @method getHeaderByName
   * @summary
   *  Returns the value of the given header name or null.
   *    - If returnDynamicString is true: the header value is returned as a DynamicString.
   *    - Otherwise, the value is returned as plain strings.
   * @param {string} name       - The header name requested
   * @param {boolean} isDynamic - if true will return the value as a DynamicString
   * @returns {Promise<string | DynamicString | null>}
   */
  async getHeaderByName(
    name: string,
    isDynamic = false,
  ): Promise<string | DynamicString | null> {
    const objects = this.context.getInternalObjects()
    const keyValueRef = getHeaderByName(this.objectRef, objects, name)

    if (!keyValueRef) {
      return null
    }

    const keyValue = getKeyValue(keyValueRef, objects, false)
    if (!keyValue.enabled) {
      return null
    }
    return getJSDynamicString(keyValue.value, this.context, isDynamic)
  }

  /**
   * @method getHeadersArray
   * @summary
   * return the non evaluated headers of the request as KeyValuesJS
   * @returns {Array<Paw.KeyValue>}
   */
  public getHeadersArray(): Paw.KeyValue[] {
    return filterArrayNonNull(
      this.internalObject.headers.map(
        (keyValueRef) => new KeyValueJS(this.context, keyValueRef),
      ),
    )
  }

  /**
   * @method setHeader
   * @summary Sets the given value for the header.
   * @param {String|Object} name - The header name requested
   * @param {String|Object} value - The value to set
   * @returns {Promise<Object<Paw.KeyValue>>}
   */
  public async setHeader(
    headerName: string | DynamicString,
    headerValue: string | DynamicString,
  ): Promise<Paw.KeyValue> {
    const { objects } = this.context.getEvaluationCtx().project
    const { root } = this.context

    const valueRef =
      typeof headerName === 'string'
        ? ((await this.getHeaderByName(headerName, true)) as DynamicStringJS)
        : ((await this.getHeaderByName(
            await headerName.getEvaluatedString(),
            true,
          )) as DynamicStringJS)

    const value =
      typeof headerValue === 'string'
        ? headerValue
        : await headerValue.getEvaluatedString()

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const param = this.requestHeadersExtract().find(
      (item: Project.KeyValue) =>
        item.value && item.value.ref === valueRef.objectRef.ref,
    )!

    updateDynamicString(objects, root, {
      inserts: [],
      parentProperty: 'value',
      parentRef: { ref: param.uuid },
      strings: [value],
    })

    return new KeyValueJS(this.context, { ref: param.uuid }) as KeyValueJS
  }

  /**
   * @method setHeader
   * @summary Sets the given value for the header.
   * @param {String|Object} headerName - The header name to add
   * @param {String|Object} headerValue - The value to add
   * @returns {Promise<Object<Paw.KeyValue>>}
   */
  public async addHeader(
    headerName: string | DynamicString,
    headerValue: string | DynamicString,
  ): Promise<Paw.KeyValue> {
    const { objects } = this.context.getEvaluationCtx().project
    const { root } = this.context

    const name =
      typeof headerName === 'string'
        ? headerName
        : await headerName.getEvaluatedString()
    const value =
      typeof headerValue === 'string'
        ? headerValue
        : await headerValue.getEvaluatedString()

    const ref = addKeyValue(objects, root, {
      parentRef: { ref: this.id },
      parentProperty: 'headers',
      args: {
        name,
        value,
      },
    })

    return new KeyValueJS(this.context, ref) as KeyValueJS
  }

  // #==
  //  Request Body

  async setBody(
    bodyParam: string | DynamicString | null,
  ): Promise<string | DynamicString | null> {
    if (!bodyParam) {
      return null
    }
    const objects = this.context.getInternalObjects()
    const { root } = this.context

    if (typeof bodyParam === 'string') {
      updateDynamicString(objects, root, {
        inserts: [],
        parentProperty: 'bodyString',
        parentRef: { ref: this.id },
        strings: [bodyParam],
      })
    } else {
      const request = getRequest(this.objectRef, objects, false)
      request.bodyString = { ref: bodyParam.id }
    }

    const body = await this.getBody()
    return body
  }

  /**
   * @method getBody
   * @summary
   *
   */
  async getBody(isDynamic = false): Promise<TypeMethodParamOrReturn> {
    const stringRef = this.internalObject.bodyString
    if (!stringRef) {
      return null
    }
    return getJSDynamicString(stringRef, this.context, isDynamic)
  }

  // #==
  // Body (URL Encoded)

  setUrlEncodedBody(
    body: Record<string, string | DynamicStringJS> | null,
  ): void {
    this.requestBodyGenerator({
      body,
      identifier: 'com.luckymarmot.BodyFormKeyValueDynamicValue',
    })
  }

  /**
   * @method getUrlEncodedBody
   * @summary
   *  Returns an object (dictionary) of the request's `application/x-www-form-urlencoded`
   *  body parameters.
   *  If returnDynamicString is true: values are returned as DynamicStrings.
   *  Otherwise, values are returned as plain strings
   * (equivalent to accessing the urlEncodedBody property).
   *
   * @param {Boolean} isDynamic - where true returns values that are dynamic strings
   * @returns {Promise<Object|String>}
   */
  async getUrlEncodedBody(
    isDynamic = false,
  ): Promise<Record<string, string | DynamicString> | null> {
    const stringRef = this.internalObject.bodyString
    if (!stringRef) {
      return null
    }

    const objects = this.context.getInternalObjects()
    const ctx = this.context.getEvaluationCtx()

    const ds = getDynamicString(stringRef, objects, false)

    // if has a Body URL-Encoded DV
    const onlyDvRef = getOnlyDynamicValue(ds)
    if (!onlyDvRef) {
      return null
    }
    const dv = getDynamicValue<false, BodyFormKeyValueDynamicValueInterface>(
      onlyDvRef,
      objects,
      false,
    )

    if (dv.identifier === 'com.luckymarmot.BodyFormKeyValueDynamicValue') {
      return this.getEvaluatedBodyParams(dv, objects, ctx, isDynamic)
    }

    return null
  }

  private async getEvaluatedBodyParams(
    dv:
      | BodyFormKeyValueDynamicValueInterface
      | BodyMultipartFormDataDynamicValue,
    objects: Project.ObjectMap<Project.AnyObject>,
    ctx: Evaluation.Ctx,
    isDynamic = false,
  ) {
    const bodyParams: Record<string, string | DynamicStringJS> = {}
    await Promise.all(
      dv.keyValues.map(async (parameterRef) => {
        const param = getParameter(parameterRef, objects, false)
        const key = param.key ? await evaluateDynamicString(param.key, ctx) : ''
        bodyParams[key] = await getJSDynamicString(
          param.value,
          this.context,
          isDynamic,
        )
      }),
    )
    return bodyParams
  }

  /**
   * @method getUrlEncodedBodyKeys
   * @summary Returns all the keys as an array of strings.
   * @param {Boolean} isDynamic - where true returns values that are dynamic strings
   * @returns {Promise<Object|String>}
   */
  async getUrlEncodedBodyKeys(): Promise<string[] | null> {
    const getUrlEncodedBodyObjects = await this.getUrlEncodedBody(false)
    return getUrlEncodedBodyObjects &&
      Object.keys(getUrlEncodedBodyObjects).length > 0
      ? Object.keys(getUrlEncodedBodyObjects)
      : []
  }

  /**
   * @method getUrlEncodedBodyKey
   * @summary Returns the value of the requested key as a plain string.
   * @param {String} key - the requested key
   * @returns {Promise<Object|String>}
   */
  async getUrlEncodedBodyKey(key: string): Promise<string | null> {
    const getBody = await this.getUrlEncodedBody()
    return !getBody || !Object.prototype.hasOwnProperty.call(getBody, key)
      ? null
      : (getBody[key] as string)
  }

  // #==
  // Body (Multipart)
  /**
   *
   *
   * @param {(Record<string, string | DynamicStringJS> | null)} body
   * @memberof RequestJS
   */
  setMultipartBody(
    body: Record<string, string | DynamicStringJS> | null,
  ): void {
    this.requestBodyGenerator({
      body,
      identifier: 'com.luckymarmot.BodyMultipartFormDataDynamicValue',
    })
  }

  /**
   * @method getMultipartBody
   * @summary
   *  method that allows access to the multipartBody values as strings or DynamicStrings
   * @param {String} isDynamic - whether to return a dynamic string
   * @returns {Promise<Object|String>}
   */
  async getMultipartBody(
    isDynamic = false,
  ): Promise<Record<string, string | DynamicString> | null> {
    const stringRef = this.internalObject.bodyString
    if (!stringRef) {
      return null
    }

    const objects = this.context.getInternalObjects()
    const ctx = this.context.getEvaluationCtx()
    const ds = getDynamicString(stringRef, objects, false)

    // if has a Body URL-Encoded DV
    const onlyDvRef = getOnlyDynamicValue(ds)
    if (!onlyDvRef) {
      return null
    }
    const dv = getDynamicValue<false, BodyMultipartFormDataDynamicValue>(
      onlyDvRef,
      objects,
      false,
    )

    if (dv.identifier === 'com.luckymarmot.BodyMultipartFormDataDynamicValue') {
      return this.getEvaluatedBodyParams(dv, objects, ctx, isDynamic)
    }

    return null
  }

  // #==
  // Body (JSON)

  /**
  @summary
   *  The request body as an object (dictionary or array), when it uses the JSON
   *  body format.
   * @return {(Promise<Record<string, string | unknown> | null>)}
   * @memberof RequestJS
   */
  async getJsonBody(): Promise<Record<string, string | unknown> | null> {
    const stringRef = this.internalObject.bodyString

    if (!stringRef) {
      return null
    }

    const objects = this.context.getInternalObjects()
    const ds = getDynamicString(stringRef, objects, false)
    const onlyDvRef = getOnlyDynamicValue(ds)

    if (!onlyDvRef) {
      return null
    }

    const dv = getDynamicValue<true, JSONDynamicValueInterface>(
      onlyDvRef,
      objects,
      true,
    )
    if (dv && dv.identifier === implJSONDynamicValue.identifier) {
      return dv.json?.trim() !== '' ? JSON.parse(dv.json as string) : null
    }
    return null
  }

  /**
   * @summary
   *  Sets the request body as an object (dictionary or array), when it uses the JSON
   *  body format.
   * @param {(Record<string, string | unknown> | null)} body
   * @return {Promise<void>}
   * @memberof RequestJS
   */
  async setJsonBody(
    body: Record<string, string | unknown> | null,
  ): Promise<void> {
    if (!body || Object.keys(body).length === 0) {
      return
    }

    const { objects } = this.context.getEvaluationCtx().project
    const { root } = this.context

    const dynamicValue: Project.DynamicValue = {
      uuid: getUuid(),
      type: 'dynamicValue',
      identifier: implJSONDynamicValue.identifier,
      json: `${JSON.stringify(body)}`,
    }

    const dynamicValueRef: Project.GenericRef<Project.DynamicValue> = {
      ref: dynamicValue.uuid,
    }

    updateDynamicString(objects, root, {
      inserts: [dynamicValue],
      parentProperty: 'bodyString',
      parentRef: { ref: this.id },
      strings: [dynamicValueRef],
    })
  }

  /**
   * @method getJsonBodyKeyPath
   * @summary Returns part of the JSON body according to the keypath.
   * @param {String} keyPath - the filtering keypath
   * @returns {Promise<String|unknown>}
   */
  async getJsonBodyKeyPath(
    keyPath: string,
  ): Promise<Record<string, unknown> | null> {
    const jsonBody = await this.getJsonBody()
    return jsonBody && Object.prototype.hasOwnProperty.call(jsonBody, keyPath)
      ? (jsonBody[keyPath] as Record<string, unknown>)
      : null
  }

  // #==
  // Auth

  /**
   * @summary
   *  Assign HTTP Basic Auth "username" and "password" parameters through an object (dictionary).
   *  Values can be either strings or DynamicString. The object fields username, password are required.
   * @param {(Paw.BasicAuth | null)} httpBasicAuth
   * @return {Promise<void>}
   * @memberof RequestJS
   */
  async setHttpBasicAuth(httpBasicAuth: Paw.BasicAuth | null): Promise<void> {
    const objects = this.context.getInternalObjects()
    const { root } = this.context

    if (!httpBasicAuth) {
      return
    }

    const username =
      httpBasicAuth.username && typeof httpBasicAuth.username === 'string'
        ? new DynamicStringJS(
            this.context,
            addDynamicString(objects, root, {
              string: encodeURIComponent(httpBasicAuth.username),
            }),
          )
        : (httpBasicAuth.username as DynamicString)

    const password =
      httpBasicAuth.password && typeof httpBasicAuth.password === 'string'
        ? new DynamicStringJS(
            this.context,
            addDynamicString(objects, root, {
              string: encodeURIComponent(httpBasicAuth.password),
            }),
          )
        : (httpBasicAuth.password as DynamicString)

    const dynamicValue: BasicAuthDynamicValueInterface = {
      uuid: getUuid(),
      type: 'dynamicValue',
      identifier: 'com.luckymarmot.BasicAuthDynamicValue',
      charset: 'utf-8',
      username: { ref: username.id },
      password: { ref: password.id },
    }

    const dvRef: Project.GenericRef<Project.DynamicValue> = {
      ref: dynamicValue.uuid,
    }

    updateRequestAuthDynamicString(objects, root, {
      requestRef: { ref: this.id },
      strings: [dvRef],
      insertDynamicValues: [dynamicValue],
    })
  }

  /**
   * @method getHttpBasicAuth
   * @summary
   *  Returns the request HTTP Basic Auth configuration, if any, as an object (dictionary)
   *  with the "username" and "password" keys containing strings. The getter method
   *  `getHttpBasicAuth()` is also available and allows access to the HTTP Basic Auth
   *  parameters as DynamicString.
   * @param {Boolean} isDynamic -
   * @returns {Promise<void>}
   */
  async getHttpBasicAuth(isDynamic = false): Promise<Paw.BasicAuth | null> {
    const objects = this.context.getInternalObjects()

    const authHeaderRef = getHeaderByName(
      this.objectRef,
      objects,
      'Authorization',
    )
    if (!authHeaderRef) {
      return null
    }

    const authHeader = getKeyValue(authHeaderRef, objects, false)
    if (!authHeader.enabled) {
      return null
    }

    // if has a Basic Auth DV
    const ds = authHeader.value
      ? getDynamicString(authHeader.value, objects, false)
      : null
    const onlyDvRef = ds ? getOnlyDynamicValue(ds) : null

    if (!onlyDvRef) {
      return null
    }
    const dv = getDynamicValue<false, BasicAuthDynamicValueInterface>(
      onlyDvRef,
      objects,
      false,
    )

    if (dv.identifier === 'com.luckymarmot.BasicAuthDynamicValue') {
      return {
        username: await getJSDynamicString(
          dv.username,
          this.context,
          isDynamic,
          null,
        ),
        password: await getJSDynamicString(
          dv.password,
          this.context,
          isDynamic,
          null,
        ),
      }
    }

    return null
  }

  /**
   * @summary
   *  The request OAuth 1 configuration, if any, as an object (dictionary)
   *  containing strings. The getter method `getOAuth1()` is also available and allows
   *  access to the OAuth 1 parameters as DynamicStrings.
   *
   * @param {(Paw.OAuth1 | null)} oauth1
   * @return {Promise<void>}
   * @memberof RequestJS
   */
  async setOAuth1(oauth1: Paw.OAuth1 | null): Promise<void> {
    const objects = this.context.getInternalObjects()
    const { root } = this.context

    if (!oauth1) {
      return
    }

    const extractOAuth1Data = Object.keys(oauth1).map((name) => {
      const entry = oauth1[name as keyof Paw.OAuth1]
      const value =
        typeof entry === 'string'
          ? addDynamicString(objects, root, { string: entry })
          : entry

      return [name, name === 'oauth_signature_method' ? entry : value]
    })

    const authObject: Paw.OAuth1 = Object.fromEntries(extractOAuth1Data)
    const oAuth1DynamicValueInterface = {
      identifier: 'com.luckymarmot.OAuth1HeaderDynamicValue',
      consumerKey: authObject.oauth_consumer_key,
      consumerSecret: authObject.oauth_consumer_secret,
      tokenSecret: authObject.oauth_token_secret,
      token: authObject.oauth_token,
      nonce: authObject.oauth_nonce,
      timestamp: authObject.oauth_timestamp,
      callback: authObject.oauth_callback,
      additionalParameters: authObject.oauth_additional_parameters,
      algorithm: authObject.oauth_signature_method,
      hasBodyHash: false,
    }

    const dv: Project.DynamicValue = {
      ...oAuth1DynamicValueInterface,
      uuid: getUuid(),
      type: 'dynamicValue',
    }

    const dvRef: Project.GenericRef<Project.DynamicValue> = {
      ref: dv.uuid,
    }

    updateRequestAuthDynamicString(objects, root, {
      requestRef: { ref: this.id },
      strings: [dvRef],
      insertDynamicValues: [dv],
    })
  }

  /**
   * @method getOAuth1
   * @summary
   *  Returns OAuth 1 configuration, if any, as an object (dictionary).
   *  - If returnDynamicString is true: values are returned as DynamicStrings.
   *  - Otherwise, values are returned as plain strings
   *    (equivalent to accessing the oauth1 property).
   * @param {Boolean} isDynamic
   * @returns {Promise<Object<Paw.OAuth1|null>>}
   */
  async getOAuth1(isDynamic = false): Promise<Paw.OAuth1 | null> {
    const objects = this.context.getInternalObjects()
    const ctx = this.context.getEvaluationCtx()

    const authHeaderRef = getHeaderByName(
      this.objectRef,
      objects,
      'Authorization',
    )
    if (!authHeaderRef) {
      return null
    }
    const authHeader = getKeyValue(authHeaderRef, objects, false)
    if (!authHeader.enabled) {
      return null
    }

    // if has a Basic Auth DV
    const ds = authHeader.value
      ? getDynamicString(authHeader.value, objects, false)
      : null
    const onlyDvRef = ds ? getOnlyDynamicValue(ds) : null
    if (!authHeader.value || !onlyDvRef) {
      return null
    }
    const dv = getDynamicValue<false, OAuth1HeaderDynamicValueInterface>(
      onlyDvRef,
      objects,
      false,
    )

    if (dv.identifier === 'com.luckymarmot.OAuth1HeaderDynamicValue') {
      return {
        oauth_consumer_key: await getJSDynamicString(
          dv.consumerKey,
          this.context,
          isDynamic,
        ),
        oauth_consumer_secret: await getJSDynamicString(
          dv.consumerSecret,
          this.context,
          isDynamic,
        ),
        oauth_token: await getJSDynamicString(
          dv.token,
          this.context,
          isDynamic,
        ),
        oauth_token_secret: await getJSDynamicString(
          dv.tokenSecret,
          this.context,
          isDynamic,
        ),
        oauth_nonce: await getJSDynamicString(
          dv.nonce,
          this.context,
          isDynamic,
        ),
        oauth_timestamp: await getJSDynamicString(
          dv.timestamp,
          this.context,
          isDynamic,
        ),
        oauth_callback: await getJSDynamicString(
          dv.callback,
          this.context,
          isDynamic,
        ),
        oauth_signature: await evaluateDynamicString(authHeader.value, ctx),
        oauth_signature_method: dv.algorithm,
        oauth_version: '1.0',
        oauth_additional_parameters: await getJSDynamicString(
          dv.additionalParameters,
          this.context,
          isDynamic,
        ),
      }
    }

    return null
  }

  /**
   * @summary
   *  The request OAuth 2 (see RFC 6749) configuration, if any, as an object (dictionary)
   *  containing strings. The getter method getOAuth2() is also available and allows
   *  access to the OAuth 2 parameters as DynamicStrings.
   * @param {(Paw.OAuth2 | null)} oauth2
   * @return {Promise<void>}
   * @memberof RequestJS
   */
  async setOAuth2(oauth2: Paw.OAuth2 | null): Promise<void> {
    const objects = this.context.getInternalObjects()
    const { root } = this.context

    if (!oauth2) {
      return
    }

    const extractOAuth2Data = Object.keys(oauth2).map((name) => {
      const entry = oauth2[name as keyof Paw.OAuth2]
      const value =
        typeof entry === 'string'
          ? addDynamicString(objects, root, { string: entry })
          : entry
      return [name, value]
    })

    const authObject: Paw.OAuth2 = Object.fromEntries(extractOAuth2Data)
    const oAuth2DynamicValueInterface = {
      identifier: 'com.luckymarmot.OAuth2DynamicValue',
      clientID: authObject.client_id,
      clientSecret: authObject.client_secret,
      username: '',
      password: '',
      authorizationURL: authObject.authorization_uri,
      accessTokenURL: authObject.access_token_uri,
      callbackURL: authObject.redirect_uri,
      scope: authObject.scope,
      stateNonce: authObject.state,
      token: authObject.token,
      tokenPrefix: authObject.token_prefix,
      refreshToken: '',
      grantType: authObject.grant_type,
      sendClientCredentialsInBody: '',
      explicitCallbackURL: '',
      strict: '',
      autoAuthorize: '',
      authRequestParams: '',
      tokenRequestParams: '',
    }

    const dv: Project.DynamicValue = {
      ...oAuth2DynamicValueInterface,
      uuid: getUuid(),
      type: 'dynamicValue',
    }

    const dvRef: Project.GenericRef<Project.DynamicValue> = {
      ref: dv.uuid,
    }

    updateRequestAuthDynamicString(objects, root, {
      requestRef: { ref: this.id },
      strings: [dvRef],
      insertDynamicValues: [dv],
    })
  }

  /**
   * @method getOAuth2
   * @summary
   *  Returns the request OAuth 2 (see RFC 6749) configuration, if any,
   *  as an object (dictionary).
   *  If returnDynamicString is true: values are returned as DynamicStrings.
   *  Otherwise, values are returned as plain strings
   *  (equivalent to accessing the oauth2 property).
   *
   * @param {Boolean} isDynamic
   * @returns {Promise<Object<Paw.OAuth2|null>>}
   */
  async getOAuth2(isDynamic = false): Promise<Paw.OAuth2 | null> {
    const objects = this.context.getInternalObjects()
    const authHeaderRef = getHeaderByName(
      this.objectRef,
      objects,
      'Authorization',
    )
    if (!authHeaderRef) {
      return null
    }
    const authHeader = getKeyValue(authHeaderRef, objects, false)
    if (!authHeader.enabled) {
      return null
    }

    // if has a Basic Auth DV
    const ds = authHeader.value
      ? getDynamicString(authHeader.value, objects, false)
      : null
    const onlyDvRef = ds ? getOnlyDynamicValue(ds) : null
    if (!onlyDvRef) {
      return null
    }
    const dv = getDynamicValue<false, OAuth2DynamicValueInterface>(
      onlyDvRef,
      objects,
      false,
    )

    if (dv.identifier === 'com.luckymarmot.OAuth2DynamicValue') {
      return {
        client_id: await getJSDynamicString(
          dv.clientID,
          this.context,
          isDynamic,
        ),
        client_secret: await getJSDynamicString(
          dv.clientSecret,
          this.context,
          isDynamic,
        ),
        authorization_uri: await getJSDynamicString(
          dv.authorizationURL,
          this.context,
          isDynamic,
        ),
        access_token_uri: await getJSDynamicString(
          dv.accessTokenURL,
          this.context,
          isDynamic,
        ),
        redirect_uri: await getJSDynamicString(
          dv.callbackURL,
          this.context,
          isDynamic,
        ),
        scope: await getJSDynamicString(dv.scope, this.context, isDynamic),
        state: await getJSDynamicString(dv.stateNonce, this.context, isDynamic),
        token: await getJSDynamicString(dv.token, this.context, isDynamic),
        token_prefix: await getJSDynamicString(
          dv.tokenPrefix,
          this.context,
          isDynamic,
        ),
        grant_type: 'authorization_code',
      }
    }

    return null
  }

  // #==
  // Request Variables

  /**
   *
   *
   * @return {Promise<Paw.RequestVariable[]>}
   * @memberof RequestJS
   */
  // eslint-disable-next-line class-methods-use-this
  async getVariables(): Promise<Paw.RequestVariable[]> {
    return (
      (this.internalObject.variables
        .map(
          (item: Project.GenericRef<Project.RequestVariable>) =>
            new RequestVariableJS(this.context, item),
        )
        .filter((item) => item !== null) as Paw.RequestVariable[]) || []
    )
  }

  /**
   * @method getVariablesNames
   * @summary
   *  Returns an object (dictionary) pairing the request's headers values to their names.
   *  Headers values are returned as plain strings. The getter method getHeaders() is also
   *  available and allows access to the headers values as DynamicString.
   *  (Writable property only for Importers).returns {Promise<Array<string>>}
   * @return {{Promise<string[]>}
   */
  async getVariablesNames(): Promise<string[]> {
    return (await this.getVariables()).map((item): string => item.name)
  }

  /**
   * @method getVariableByName
   * @summary
   *   a method that returns a request variable instance value based on value
   *  of the passed parameter.
   * @param {string} name - the name of the variable to be returned
   * @returns {Promise<Object<Paw.RequestVariable>>}
   */
  async getVariableByName(name: string): Promise<Paw.RequestVariable | null> {
    const requestVariableRef = getVariableByName(
      this.objectRef,
      this.context.getInternalObjects(),
      name,
    )
    if (!requestVariableRef) {
      return null
    }
    return new RequestVariableJS(this.context, requestVariableRef)
  }

  async getVariableById(id: string): Promise<Paw.RequestVariable | null> {
    const requestVariableRef = getVariableById(
      this.objectRef,
      this.context.getInternalObjects(),
      id,
    )
    if (!requestVariableRef) {
      return null
    }

    return new RequestVariableJS(this.context, requestVariableRef)
  }

  /**
   * @method addVariable
   * @summary
   * @param {string} name - the name of the variable to be assigned with
   * @param {string|Object<DynamicStringJS>} value - sets the value of the variable
   * @param {string} description -  sets the description of the  variable
   * @returns {Promise<Object<Paw.RequestVariable>>}
   */
  async addVariable(
    name: string,
    value: string | DynamicStringJS,
    description: string,
  ): Promise<Paw.RequestVariable> {
    const { objects } = this.context.getEvaluationCtx().project
    const { root } = this.context
    const createRequestVariable = addRequestVariable(objects, root, {
      parentRef: { ref: this.id },
      parentProperty: 'variables',
      args: {
        name,
        description,
        value: typeof value === 'string' ? value : value.objectRef,
      },
    })
    const requestVariable = new RequestVariableJS(
      this.context,
      createRequestVariable,
    )
    return requestVariable as Paw.RequestVariable
  }

  // Options
  get timeout(): number {
    return (this.internalObject.timeoutInterval || 0) * 1000
  }

  set timeout(timeout: number) {
    this.internalObject.timeoutInterval = timeout
  }

  get followRedirects(): boolean {
    return this.internalObject.followRedirects
  }

  set followRedirects(followRedirects: boolean) {
    this.internalObject.followRedirects = followRedirects
  }

  get redirectAuthorization(): boolean {
    return this.internalObject.redirectAuthorization
  }

  set redirectAuthorization(redirectAuthorization: boolean) {
    this.internalObject.redirectAuthorization = redirectAuthorization
  }

  get redirectMethod(): boolean {
    return this.internalObject.redirectMethod
  }

  // eslint-disable-next-line class-methods-use-this
  set redirectMethod(redirectMethod: boolean) {
    this.internalObject.redirectMethod = redirectMethod
  }

  get sendCookies(): boolean {
    return this.internalObject.sendCookies
  }

  // eslint-disable-next-line class-methods-use-this
  set sendCookies(sendCookies: boolean) {
    this.internalObject.sendCookies = sendCookies
  }

  get storeCookies(): boolean {
    return this.internalObject.storeCookies
  }

  // eslint-disable-next-line class-methods-use-this
  set storeCookies(storeCookies: boolean) {
    this.internalObject.storeCookies = storeCookies
  }

  // eslint-disable-next-line class-methods-use-this
  get clientCertificate(): DynamicString | null {
    throw new Error('Paw JS: Not Implemented') // @TODO
  }

  // eslint-disable-next-line class-methods-use-this
  set clientCertificate(clientCertificate: DynamicString | null) {
    throw new Error('Paw JS: Not Implemented') // @TODO
  }

  // #==
  // HTTP Exchange

  // eslint-disable-next-line class-methods-use-this
  async getLastExchange(): Promise<Paw.HTTPExchange | null> {
    const item = await this.httpExchangeStorage.fetchHttpExchangeList(this.id)

    return item && item.length > 0
      ? new HTTPExchangeJS(item[0], (await this.getUrl()) as string)
      : null
  }

  // eslint-disable-next-line class-methods-use-this
  async getAllExchanges(): Promise<Paw.HTTPExchange[]> {
    const collection = await this.httpExchangeStorage.fetchHttpExchangeList(
      this.id,
    )

    return Promise.all(
      collection.map(
        async (item) =>
          new HTTPExchangeJS(item, (await this.getUrl()) as string),
      ) || [],
    )
  }

  // Clone, delete

  // eslint-disable-next-line class-methods-use-this
  clone(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    newName: string,
  ): Paw.Request {
    throw new Error('Paw JS: Not Implemented') // @TODO
  }

  // eslint-disable-next-line class-methods-use-this
  deleteRequest(): boolean {
    throw new Error('Paw JS: Not Implemented') // @TODO
  }
}
