import type { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import type { Project } from 'lib/project/types.d'
import {
  assertType,
  serializeObjectNullableRef,
  serializeObjectRefArray,
} from 'lib/cloud-sync/serializer/helpers'
import type { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

import serializeDynamicString from './serialize-dynamic-string'
import serializeKeyValue from './serialize-key-value'
import serializeRequestVariable from './serialize-request-variable'

const serializeRequest = (
  serializer: CloudSerializer.ProjectSerializer,
  object: Project.Request,
): CloudAPI.SyncTreeItem => {
  // check type
  assertType(object, 'request')

  const syncTreeItem: CloudAPI.SyncTreeItem = {
    _uuid: object.uuid,
    _type: 'request',
    title: object.title,
    summary: object.summary,
    method: serializeObjectNullableRef(
      serializer,
      object.method as Project.GenericRef<Project.DynamicString>,
      serializeDynamicString,
    ),
    urlFull: serializeObjectNullableRef(
      serializer,
      object.urlFull as Project.GenericRef<Project.DynamicString>,
      serializeDynamicString,
    ),
    bodyString: serializeObjectNullableRef(
      serializer,
      object.bodyString as Project.GenericRef<Project.DynamicString>,
      serializeDynamicString,
    ),
    headers: serializeObjectRefArray(
      serializer,
      object.headers as Project.GenericRef<Project.KeyValue>[],
      serializeKeyValue,
    ),
    urlParameters: serializeObjectRefArray(
      serializer,
      object.urlParameters as Project.GenericRef<Project.KeyValue>[],
      serializeKeyValue,
    ),
    variables: serializeObjectRefArray(
      serializer,
      object.variables as Project.GenericRef<Project.KeyValue>[],
      serializeRequestVariable,
    ),
    followRedirects: object.followRedirects,
    redirectAuthorization: object.redirectAuthorization,
    redirectMethod: object.redirectMethod,
    timeoutInterval: object.timeoutInterval,
    sendCookies: object.sendCookies,
    storeCookies: object.storeCookies,
    clientCertificate: serializeObjectNullableRef(
      serializer,
      object.clientCertificate as Project.GenericRef<Project.DynamicString>,
      serializeDynamicString,
    ),
  }
  return syncTreeItem
}

export default serializeRequest
